import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { I18n } from "../../utilities/i18n/i18n";

export class AdblockComponent extends BaseComponent {

    static adblockCheck = false;

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
        AdblockComponent.javascript();
    }

    static javascript() {

        let html = /*html*/ `
                <div class="adblock-banner" id="adblockBanner">
                <!-- dynamic message from I18N comes here -->
                </div>
    `;
        // Add the banner to the page
        document.getElementById('produced-html').insertAdjacentHTML("beforeend", html);
        document.getElementById('adblockBanner').innerHTML = I18n.translateString('adblockDetector.adblockParagraph');

        function closeBanner() {
            document.getElementById('adblockBanner').style.display = 'none';
        }

        async function detectAdBlock() {
            const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

            try {
                await fetch(new Request(googleAdUrl)).catch(() => AdblockComponent.adblockCheck = true); // Method 1

                await fetch('https://api.country.is/', {
                    method: "GET",
                    cache: "no-cache",
                    headers: { "Content-Type": "application/json" },
                }).catch(() => AdblockComponent.adblockCheck = true); // Method 2

            } catch (e) {
                AdblockComponent.adblockCheck = true;
            } finally {
                if (AdblockComponent.adblockCheck) {
                    document.getElementById('adblockBanner').style.display = 'block';
                } else {
                    closeBanner();
                }
            }
        }

        // Detect Adblock when the page loads
        window.onload = function () {
            detectAdBlock();
        };
        if (document.getElementById('close-button'))
            document.getElementById('close-button').addEventListener('click', closeBanner);
    }
}

AdblockComponent.template = /*html*/ `
<style>
    adblockerdetect-component    .adblock-banner {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #f8d7da;
            color: #721c24;
            text-align: center;
            padding: calc(0.5rem + 1.2vw);
            font-family: Arial, sans-serif;
            font-size: calc(0.4rem + 0.7vw);
            z-index: 2147483999;
            display: none; /* Initially hidden */
        }

    adblockerdetect-component    .adblock-banner button {
            background-color: #dc3545;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
        }

    adblockerdetect-component    .adblock-banner button:hover {
            background-color: #c82333;
        }
    </style>

<div id="produced-html"></div>
`;

AdblockComponent.reg('adblockerdetect-component');
