const icon_32x32 = require('./assets/icons/any/icon-32x32.png')
const icon_128x128 = require('./assets/icons/any/icon-128x128.png')
const icon_180x180 = require('./assets/icons/any/icon-180x180.png')
const icon_192x192 = require('./assets/icons/any/icon-192x192.png')
const icon_196x196 = require('./assets/icons/any/icon-196x196.png')
const icon_256x256 = require('./assets/icons/any/icon-256x256.png')
const icon_384x384 = require('./assets/icons/any/icon-384x384.png')
const icon_512x512 = require('./assets/icons/any/icon-512x512.png')
const icon_maskable_256 = require('./assets/icons/maskable/maskable_icon_256.png')
const icon_maskable_192 = require('./assets/icons/maskable/maskable_icon_x192.png')
const icon_maskable_384 = require('./assets/icons/maskable/maskable_icon_x384.png')
const icon_maskable_512 = require('./assets/icons/maskable/maskable_icon_x512.png')
const logo_svg = require('./assets/icons/other/logo_svg.svg')
const logo_favicon = require('./assets/icons/other/favicon.ico')

