import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { WidgetMatches } from "../widget-matches/widget-matches";
import { DiscordTemplate } from "../discord-template/discord-template";
import { AuthorsComponent } from "../author-component/author-component";

export class AuthorsContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }
}

AuthorsContainer.template = /*html*/ `

        <div class="main-content col-12 col-lg-8 g-0 mt-2">
        <div class="row m-2">
               <authors-component></authors-component>
        </div>
        </div>

        <div class="sidebar col-12 col-lg-4 mt-2">
        <div class="row m-2">
            <widget-matches></widget-matches>
        </div>
        <div class="row m-2">
            <predictions-homecomponent></predictions-homecomponent>
        </div>
        <div class="row m-2">
            <discord-template></discord-template>
        </div>
        <div class="row m-2">
            <widget-bookmakers></widget-bookmakers>
        </div>
    </div>
`;

AuthorsContainer.reg('authors-container');
