import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { BookmakersSkinComponent } from "../bookmakers-skin-component/bookmakers-skin-component";

export class BookmakersSkinContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters()
    }
}

BookmakersSkinContainer.template = /*html*/ `
    <div class="main-content col-12 g-0 mt-2">
        <div class="row m-2">
               <bookmakers-skin-component></bookmakers-skin-component>
        </div>
    </div>
`;

BookmakersSkinContainer.reg('bookmakers-skin-container');
