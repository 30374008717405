import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";

const image = require('./assets/noInternet.png')

export class NetworkGuard extends BaseComponent {

  initCallback() {
    NetworkGuard.translations()
  }

  static translations() {
    document.getElementById('networkGuardTitle').innerText = I18n.translateString('networkGuard.title')
    document.getElementById('networkGuardDescription').innerText = I18n.translateString('networkGuard.description')
  }

}

NetworkGuard.template = /*html*/ `
<style>
  notfound-component img {
    width: calc(5rem + 15vw);
    height: auto;
  }

  notfound-component .theNetworkGuard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  notfound-component #networkGuardTitle {
    margin-top: calc(1rem + 1vw);
  }
</style>

<div class="container-fluid theNetworkGuard"> 
    <img src="${image}">
    <h2 class="text-center" id="networkGuardTitle"></h2>
    <h4 class="text-center" id="networkGuardDescription"></h4>
</div>

`;

NetworkGuard.reg('notfound-component');