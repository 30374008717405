import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { csgologo, dota2, lol, valorant, fifa, fortnite, hearthstone, minecraft, overwatch, pubg, r6, roblox, rocketleague, wow, starcraft, cod, ea, apex, kingofglory, mlegends } from "../../utilities/general/gameIdImgMapping";

export class GameNavbar extends BaseComponent {
  initCallback() {
    GameNavbar.createListItems();
    const navItems = document.querySelectorAll('.gameNavSelector');
    navItems.forEach((item) => {
      item.addEventListener('click', GameNavbar.changeUrlAccordingToFilters)
    });
    document.querySelector('game-navbar').addEventListener('wheel', GameNavbar.horizontalScrolling)
    window.gameNavBarEventListeners = {}
    window.addEventListener('resize', GameNavbar.performOrientationChangeActions);
    GameNavbar.performOrientationChangeActions();
  }

  static games = [
    { name: "League Of Legends", imgSrc: `${lol}`, alt: "lol", disabled: false },
    { name: "Counter Strike 2", imgSrc: `${csgologo}`, alt: "csgo", disabled: false },
    { name: "Dota 2", imgSrc: `${dota2}`, alt: "dota2", disabled: false },
    { name: "Valorant", imgSrc: `${valorant}`, alt: "valorant", disabled: false },
    { name: "Rocket League", imgSrc: `${rocketleague}`, alt: "rocketleague", disabled: false },
    { name: "Mobile Legends", imgSrc: `${mlegends}`, alt: "mlegends", disabled: false },
    { name: "Starcraft", imgSrc: `${starcraft}`, alt: "starcraft", disabled: false },
    { name: "Rainbow 6", imgSrc: `${r6}`, alt: "r6", disabled: false },
    { name: "Call Of Duty", imgSrc: `${cod}`, alt: "cod", disabled: false },
    { name: "Overwatch", imgSrc: `${overwatch}`, alt: "overwatch", disabled: false },
    { name: "King Of Glory", imgSrc: `${kingofglory}`, alt: "kingofglory", disabled: false },
    { name: "EA Sports FC", imgSrc: `${ea}`, alt: "easports", disabled: false },

    // Disabled games
    { name: "Apex Legends", imgSrc: `${apex}`, alt: "apex", disabled: true },
    { name: "Fortnite", imgSrc: `${fortnite}`, alt: "fortnite", disabled: true },
    { name: "PUBG", imgSrc: `${pubg}`, alt: "pubg", disabled: true },
    { name: "HearthStone", imgSrc: `${hearthstone}`, alt: "hearthstone", disabled: true }
  ];

  static checkUrlToActivateFilters() {
    try {
      document.querySelector(".activegamefilter")?.classList.remove("activegamefilter");
      document.querySelector(".gameNameActive")?.classList.remove("gameNameActive");
    } catch (e) {
      console.debug("found no active game filter to remove");
    }

    const currentActiveGameFilter = RouterAssistant.getCurrentVariableValue('game');

    if (GameNavbar.games.some(game => game.alt === currentActiveGameFilter)) {
      const gameElement = document.querySelector(`[alt="${currentActiveGameFilter}"]`);
      if (gameElement) {
        const parentElement = gameElement.parentElement?.parentElement;
        parentElement?.classList.add('activegamefilter');
        parentElement?.querySelector("span")?.classList.add('gameNameActive');
      }
    } else {
      try {
        document.querySelector(".activegamefilter")?.classList.remove("activegamefilter");
        document.querySelector(".gameNameActive")?.classList.remove("gameNameActive");
      } catch (e) {
        console.debug("found no active game filter to remove");
      }
    }
  }

  static changeUrlAccordingToFilters(event) {

    const gameName = event.target.parentElement.parentElement.parentElement.getAttribute('aria-label') || event.target.parentElement.parentElement.getAttribute('aria-label')
      || event.target.parentElement.getAttribute('aria-label') || event.target.getAttribute('aria-label');

    if (window.location.href.includes(`game=${gameName}`)) {
      RouterAssistant.removeCurrentPageVariable("game")
      GameNavbar.checkUrlToActivateFilters()
    } else {
      RouterAssistant.addOrUpdateCurrentPageVariable("game", gameName)
      GameNavbar.checkUrlToActivateFilters()
    }
    GameNavbar.notifyEventListenersForGameFilterChanges()
  }

  static getCurrentlyActivatedGameIfAny() {
    return RouterAssistant.getCurrentVariableValue('game')
  }

  static notifyEventListenersForGameFilterChanges() {
    Object.keys(window.gameNavBarEventListeners).forEach(eventListenerName => {
      window.gameNavBarEventListeners[eventListenerName](GameNavbar.getCurrentlyActivatedGameIfAny())
    })
  }

  static performOrientationChangeActions() {
    setTimeout(() => {
      const el = document.querySelector('game-navbar ul')
      if ((el.scrollWidth === el.clientWidth) && (window.innerWidth > window.innerHeight)) {
        el.classList.add('gameNavBarItemsShouldBeCentered')
      } else {
        el.classList.remove('gameNavBarItemsShouldBeCentered')
      }
    }, 1);
  }

  static horizontalScrolling(event) {
    if (Math.abs(event.wheelDeltaX) < Math.abs(event.wheelDeltaY)) {
      event.preventDefault();
      document.querySelector("game-navbar ul").scrollBy({ left: event.deltaY });
    }
  }

  static subscribeToGameFilterChanges(eventListenerName, EventListenerFunction) {
    window.gameNavBarEventListeners[eventListenerName] = EventListenerFunction
  }

  static createListItems() {

    const gameNavList = document.getElementById("gameNavList");

    GameNavbar.games.forEach(game => {
      const listItem = document.createElement("li");
      listItem.className = `nav-item gameNavSelector col-auto ${game.disabled ? "disabled" : "clickable"}`;
      listItem.setAttribute("aria-label", game.alt);

      const navLink = document.createElement("div");
      navLink.className = `nav-link w-100  d-flex align-items-center`;

      // Image Container
      const imgContainer = document.createElement("div");
      imgContainer.className = "img-circle-container d-flex justify-content-center flex-column";

      // Image Element
      const img = document.createElement("img");
      img.className = "img-fluid mx-auto d-block";
      img.src = game.imgSrc;
      img.alt = game.alt;

      // Text
      const span = document.createElement("span");
      span.textContent = game.name;


      // Build Structure
      imgContainer.appendChild(img);
      navLink.appendChild(imgContainer);
      navLink.appendChild(span);
      listItem.appendChild(navLink);
      gameNavList.appendChild(listItem);
    });
  }

}

GameNavbar.template = /*html*/`
<style>
  game-navbar #gameNavList {
    overflow-x: auto;
    white-space: nowrap;
  }

  game-navbar .disabled {
    cursor: not-allowed;
    opacity: 25%;
  }

  game-navbar .gameNameActive {
    margin: 0.5rem;
    opacity: 1;
    max-width: 100%;
    transform: translate3d(0, 0, 0);
  }

  game-navbar span {
    display: inline-block;
    overflow: hidden;
    max-width: 0;
    opacity: 0;
    transform: translate3d(0.5em, 0, 0);
    transition: opacity 1s, max-width 0s, transform 0.7s;
    transition-timing-function: ease-in-out;
    font-size: calc(0.5rem + 0.4vw);
    font-weight: 800;
  }

  game-navbar .nav-link {
    transition: 0s ease-in-out !important;
  }

  game-navbar li {
    display: flex;
    align-items: center;
    padding-right: calc(0.3rem + 0.3vw);
    padding-left: calc(0.2rem + 0.3vw);
    margin-right: calc(0.5rem + 1vw);
  }

  game-navbar .activegamefilter {
    background-color: #ffffff59;
    border-radius: 8px;
    filter: drop-shadow(4px 3px 10px black);
    padding-left: calc(0.3rem + 0.3vw);
    padding-right: calc(0.3rem + 0.3vw);
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
  }

  game-navbar .gameNavBarItemsShouldBeCentered {
    justify-content: center;
  }

  game-navbar .img-circle-container {
    width: calc(1.3rem + 1vw);
    height: calc(1.3rem + 1vw);
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    game-navbar .navbar {
      padding-top: calc(0.7rem + 0.5vw) !important;
    }
  }
</style>

<nav class="navbar rounded mt-0 mt-xxl-2">
  <div class="container-fluid">
    <div class="navbar-collapse col-12">
      <ul id="gameNavList" class="navbar-nav flex-row scrollbar">
        <!-- Dynamic content will be inserted here by JavaScript -->
      </ul>
    </div>
  </div>
</nav>

`;

GameNavbar.reg("game-navbar");