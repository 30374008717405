import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { getMatchName } from '../../../functions/across-stack/utils/globals';
import { translations } from "../../../functions/across-stack/i18n/i18n";
import { reversegameIdFilter, gameIdImg } from "../../utilities/general/gameIdImgMapping";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { info1swapper, info2swapper, manipulateInfos, removeHotMatchFromMatches } from "../../utilities/general/hotmatchHelpers";
import { findMatchType } from "../../utilities/general/matchType";
import { resizetoTwitchHeight } from "../../utilities/general/resizeToTwitchHeight";
import { PlaceholderHotmatch } from "../placeholder-matcheswidget/placeholder-hotmatch";

import { emptyMessage } from "../../utilities/general/emptyMessage";

const noImageFound = require('../../global-assets/no image found.png');

export class HotmatchComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('hotmatch-component', HotmatchComponent.initiator);
    window.addEventListener('resize', () => resizetoTwitchHeight(document.getElementById('twitch-home'), true));

  }

  static initiator() {

    getDataFrontend('savedStreams', 'getStreams', 'fromHotmatch', function () { // Callback function
      if (document.querySelectorAll('#hotmatch-component').length !== 0) { // if our component exists on the page
        HotmatchComponent.updateHotmatch(GameNavbar.getCurrentlyActivatedGameIfAny())
      }
    });
    GameNavbar.subscribeToGameFilterChanges('fromHotmatchComponent', (eventId) => {
      if (document.querySelectorAll('hotmatch-component').length !== 0) // if our component exists on the page
        HotmatchComponent.updateHotmatch(eventId);
    })
  }

  static updateHotmatch(gameId) {

    let topMatch, topStream, viewers;
    const gameData = window.savedStreams?.[reversegameIdFilter?.[gameId]];
    if (gameData) { // check if gameId was given

      topMatch = gameData?.topMatch?.status === "running" ? gameData?.topMatch : gameData?.altMatch?.status === "running" ? gameData?.altMatch : undefined;
      topStream = gameData?.topStream?.includes('twitch') ? `${gameData?.topStream}${window.location.hostname}` : gameData?.topStream;
      viewers = gameData?.topStreamAggregatedViewers;

    }
    if (!topMatch) { // No gameId or invalid or no live stream one — fallback to best stream across all   

      let bestGameEntry = null;
      for (const gameKey in window.savedStreams) {
        const entry = window.savedStreams[gameKey];
        if (entry?.topMatch?.status === 'running' || entry?.altMatch?.status === 'running') {
          // Pick the entry with the highest viewer count
          if (!bestGameEntry || entry?.topStreamAggregatedViewers > bestGameEntry.topStreamAggregatedViewers) {
            bestGameEntry = entry;
          }
        }
      }

      if (bestGameEntry) {
        topMatch = bestGameEntry.topMatch || bestGameEntry.altMatch;
        topStream = bestGameEntry.topStream.includes('twitch') ? `${bestGameEntry.topStream}${window.location.hostname}` : bestGameEntry.topStream;
        viewers = bestGameEntry.topStreamAggregatedViewers;
      }

    }

    if (topMatch !== undefined) {

      removeHotMatchFromMatches(topMatch.id);
      const matchName = getMatchName(topMatch);
      const tourLogo = topMatch.league.image_url ? `<img src="${topMatch.league.image_url}" alt="tournament logo" class="img-fluid mx-auto d-block " ></img>` : '';
      const matchTypeFinal = findMatchType(topMatch.match_type, topMatch.number_of_games);
      const score = `${topMatch.results[0].score.toString()} - ${topMatch.results[1].score.toString()}`;

      const matchHtml = /*html*/ `
                                  <div class="container p-0 text-white">

                                    <a href="/${I18n.getUsersPreferredLanguage()}/match/${topMatch.id}/${matchName}" class="row rounded-top color-lightdark clickable">
                                      <div class="col-12 p-0 d-flex justify-content-between color-dark rounded-top">
                                        <div class="text-start d-flex align-items-center p-1">
                                          <img src="${gameIdImg[topMatch.videogame.id]}" alt="tournament logo" class="img-fluid object-fit-scale  game-logo-resize">
                                          <span class="mx-1 mx-lg-2 d-flex align-items-center text-resize">${topMatch.league.name} ${topMatch.serie.full_name}</span>
                                          <div class="img-circle-container-tournament-logo d-flex align-items-center">
                                            ${tourLogo}
                                          </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                          <span id="spicyInfo_1" class="badge bg-danger text-resize d-inline d-lg-none red-animation" style="margin-right: 0.3rem; margin-top: 0.3rem;"></span>
                                          <span class="red-animation badge text-resize m-2 d-none d-lg-inline badgeTranslation"></span>
                                        </div>
                                      </div>

                                      <div class="row color-lightdark my-md-4 p-0 m-0">
                                        <div class="col-5 d-flex justify-content-end align-items-center p-0 clickable">
                                          <p class="team-hot-resize mx-xl-2 my-0 flex-fill text-center">${topMatch.opponents[0].opponent.name}</p>
                                          <div class="img-circle-container d-flex justify-content-center flex-column m-2">
                                            <img src="${topMatch.opponents[0].opponent.image_url || noImageFound}" alt="" class="img-fluid mx-auto d-block">
                                          </div>
                                        </div>

                                        <div class="col-2 d-flex justify-content-center align-items-center text-center p-0">
                                          <span class="score-resize m-0 fw-bold" id="hotScoreId">${score}</span>
                                        </div>

                                        <div class="col-5 d-flex justify-content-start align-items-center p-0 clickable">
                                          <div class="img-circle-container d-flex justify-content-center flex-column m-2">
                                            <img src="${topMatch.opponents[1].opponent.image_url || noImageFound}" alt="" class="img-fluid mx-auto d-block">
                                          </div>
                                          <p class="team-hot-resize mx-xl-2 my-0 flex-fill text-center">${topMatch.opponents[1].opponent.name}</p>
                                        </div>

                                        <div class="col-12 text-center d-flex h-auto justify-content-center align-items-center p-0 m-0">
                                          <span class="my-2 badge bg-secondary text-resize d-none d-lg-inline matchTypeFromHM">${matchTypeFinal}</span>
                                        </div>
                                      </div>
                                    </a>

                                    <div class="row color-lightdark rounded-bottom">
                                      <div class="col-sm-12 col-xxl-7 d-flex justify-content-center my-2">
                                        <div class="ratio ratio-16x9">
                                          <iframe id="twitch-home"
                                                  src="${topStream}"
                                                  width="100%"
                                                  height="100%"
                                                  allowfullscreen="true">
                                          </iframe>
                                        </div>
                                      </div>

                                      <div class="col-0 col-xxl-5 d-none d-xxl-flex my-2">
                                        <div id="timeline-home" class="scrollbar w-100">
                                          <div id="timelineInnerHome" class="container" style="position: relative; top: 50%; left: 50%;">
                                            <!-- First section -->
                                            <div class="row align-items-center how-it-works d-flex">
                                              <div class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
                                                <div class="circle font-weight-bold"><i class="bi bi-people-fill"></i></div>
                                              </div>
                                              <div class="col-10">
                                                <p class="fs-5"></p>
                                                <p id="spicyInfo_1" class="fs-6 text-white-50" style="margin: 0 !important;"></p>
                                              </div>
                                            </div>

                                            <!-- Path between 1-2 -->
                                            <div class="row timeline">
                                              <div class="col-2"><div class="corner top-right"></div></div>
                                              <div class="col-8"><hr></div>
                                              <div class="col-2"><div class="corner left-bottom"></div></div>
                                            </div>

                                            <!-- Second section -->
                                            <div class="row align-items-center justify-content-end how-it-works d-flex">
                                              <div class="col-10">
                                                <p id="spicyInfoNumberTwo" class="fs-6 text-white-50 text-end" style="margin: 0 !important;"></p>
                                              </div>
                                              <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                                                <div class="circle font-weight-bold"><i class="bi bi-globe-americas"></i></div>
                                              </div>
                                            </div>

                                            <!-- Path between 2-3 -->
                                            <div id="hotMatchInfoThirdsectionPart1" class="row timeline">
                                              <div class="col-2"><div class="corner right-bottom"></div></div>
                                              <div class="col-8"><hr></div>
                                              <div class="col-2"><div class="corner top-left"></div></div>
                                            </div>

                                            <!-- Third section -->
                                            <div id="hotMatchInfoThirdsectionPart2" class="row align-items-center how-it-works d-flex">
                                              <div class="col-2 text-center top d-inline-flex justify-content-center align-items-center">
                                                <div class="circle font-weight-bold"><i class="bi bi-cake2"></i></div>
                                              </div>
                                              <div class="col-10">
                                                <p id="spicyInfoNumberThree" class="fs-6 text-white-50" style="margin: 0 !important;"></p>
                                              </div>
                                            </div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  `;

      if (document.getElementById('twitch-home') !== null) {
        if (document.getElementById('twitch-home').src !== topStream) {
          // console.debug('I found the iframe and its different, so im updating the hotmatch component!')
          document.getElementById('hotmatch-component').innerHTML = '';
          document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
          HotmatchComponent.dynamicTranslations(topMatch.number_of_games);
        } else {
          // console.debug('I am not changing the iframe src cause its the same,im just updating the score!')
          document.getElementById('hotScoreId').innerText = score;
          info1swapper(viewers, topMatch, '[id^="spicyInfo_1"]', topStream.includes('twitch'));
          document.querySelectorAll('[id^="spicyInfo_1"]').forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.viewers", { maxviewers: viewers }));
        }
      }
      else {
        // console.debug('I am not able to find the iframe so im creating the hotmatch for the first time')
        document.querySelector('#hotmatch-component').innerHTML = '';
        document.querySelector('#hotmatch-component').insertAdjacentHTML('beforeend', matchHtml);
        HotmatchComponent.dynamicTranslations(topMatch.number_of_games);
      }

      info1swapper(viewers, topMatch, '[id^="spicyInfo_1"]', topStream.includes('twitch'));
      resizetoTwitchHeight(document.getElementById('twitch-home'), true);

      if (topMatch.opponents[0].type === 'Team') {
        const tournamentId = topMatch.tournament_id || topMatch.tournament.id;
        HotmatchComponent.getRostersData(tournamentId.toString(), topMatch.opponents[0].opponent.id, topMatch.opponents[1].opponent.id);
      } else if (topMatch.opponents[0].type === 'Player') {
        const { nationalities, maxAge } = info2swapper(topMatch.opponents);
        manipulateInfos(nationalities, maxAge);
      }

    } else {
      document.querySelector('#hotmatch-component').innerHTML = '';
      emptyMessage(document.querySelector('#hotmatch-component'), 'hotmatchTranslations.noLiveMsg', 'noLiveMsgHM');
    }


    document.getElementById('placeholder-hotmatch').style.display = "none";

  }

  static async getRostersData(tournamentId, team1Id, team2Id) {
    try {
      FirebaseAssistant.callGetRostersFunctions({ tournamentId: tournamentId, rosterId1: team1Id, rosterId2: team2Id }, (response) => {
        const teamData1 = response.rosterId1;
        const teamData2 = response.rosterId2;
        const allPlayers = [...teamData1.players, ...teamData2.players];

        const { nationalities, maxAge } = info2swapper(allPlayers);
        manipulateInfos(nationalities, maxAge);
      });
    } catch (error) {
      console.error('Error fetching rosters data:', error);

    }
  }

  static dynamicTranslations(numberOfGames) {
    document.querySelectorAll('.badgeTranslation').forEach(el => el.innerHTML = I18n.translateString("hotmatchTranslations.badgeHot"));
    const matchTypeElement = document.querySelector(`.matchTypeFromHM`);
    if (matchTypeElement.innerHTML === 'all_games_played') {
      matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: numberOfGames });
    } else if (matchTypeElement.innerHTML === 'best_of_X') {
      matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: translations[I18n.getLanguageFromUrl()]["matchTranslations"]["boNCalculation"](numberOfGames) });
    } else if (matchTypeElement.innerHTML === 'first_to_X') {
      matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: numberOfGames });
    } else if (matchTypeElement.innerHTML === 'best_of_2') {
      matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
    } else if (matchTypeElement.innerHTML === 'single_game') {
      matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
    }
  }

}


HotmatchComponent.template = /*html*/ `
<style>
  hotmatch-component .team-hot-resize {
    font-size: calc(0.5rem + 0.8vw);
    font-weight: 600;
  }

  hotmatch-component .score-resize {
    font-size: calc(0.7rem + 0.6vw);
  }

  hotmatch-component .text-resize {
    font-size: calc(0.4rem + 0.4vw);
  }

  hotmatch-component .noLiveMsgHM {
    text-align: center;
    padding: 20px;
    background-color: #161b22;
    border-color: #f5c6cb;
    border-radius: 0.25rem;
  }

  hotmatch-component .container {
    position: relative;
  }

  hotmatch-component .game-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  hotmatch-component .game-logo img {
    width: 1rem;
    height: 1rem;
  }

  hotmatch-component .game-logo-resize {
    max-width: calc(1.2rem + 1.3vw);
    max-height: calc(1.2rem + 1.3vw);
  }

  hotmatch-component .img-circle-container {
    width: calc(1rem + 3vw);
    height: calc(1rem + 3vw);
  }

  hotmatch-component .img-circle-container img {
    max-width: calc(1rem + 3vw);
    max-height: calc(1rem + 3vw);
  }

  hotmatch-component .img-circle-container-tournament-logo {
    width: calc(1.1rem + 1.1vw);
    height: calc(1.1rem + 1.1vw);
  }

  hotmatch-component .img-circle-container-tournament-logo img {
    max-width: calc(1rem + 1vw);
    max-height: calc(1rem + 1vw);
  }

  hotmatch-component .circle {
    padding: 13px 20px;
    border-radius: 50%;
    background-color: #B22A23;
    color: #fff;
    max-height: 50px;
    z-index: 2;
  }

  hotmatch-component .how-it-works.row .col-2 {
    align-self: stretch;
  }

  hotmatch-component .how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
  }

  hotmatch-component .how-it-works.row .col-2.full::after {
    height: 100%;
    left: calc(50% - 3px);
  }

  hotmatch-component .how-it-works.row .col-2.top::after {
    height: 50%;
    left: 50%;
    top: 0;
  }

  hotmatch-component .timeline div {
    padding: 0;
    height: 40px;
  }

  hotmatch-component .timeline hr {
    border-top: 3px solid #B22A23;
    margin: 0;
    top: 17px;
    position: relative;
  }

  hotmatch-component .timeline .col-2 {
    display: flex;
    overflow: hidden;
  }

  hotmatch-component .timeline .corner {
    border: 3px solid #B22A23;
    width: 100%;
    position: relative;
    border-radius: 15px;
  }

  hotmatch-component .timeline .top-right {
    left: 50%;
    top: -50%;
  }

  hotmatch-component .timeline .left-bottom {
    left: -50%;
    top: calc(50% - 3px);
  }

  hotmatch-component .timeline .top-left {
    left: -50%;
    top: -50%;
  }

  hotmatch-component .timeline .right-bottom {
    left: 50%;
    top: calc(50% - 3px);
  }

  /* These are global styles not scoped to hotmatch-component */
  #timelineInnerHome {
    min-width: clamp(18rem, 5vw + 15rem, 22rem);
  }

  @media (min-width: 1200px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.45) !important;
    }
  }

  @media (min-width: 1300px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.50) !important;
    }
  }

  @media (min-width: 1400px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.55) !important;
    }
  }

  @media (min-width: 1500px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.60) !important;
    }
  }

  @media (min-width: 1580px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.65) !important;
    }
  }

  @media (min-width: 1700px) {
    #timelineInnerHome {
      transform: translate(-50%, -50%) scale(0.7) !important;
    }
  }

  @media (min-width: 1920px) {
    #timelineInnerHome {
      min-width: unset;
    }
  }
</style>

<placeholder-hotmatch></placeholder-hotmatch>
<div id="hotmatch-component"> </div>
`;

HotmatchComponent.reg('hotmatch-component');