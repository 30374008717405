import { globalSocials } from "../../utils/globals.js";

export const koreanTranslation =
{
    "translationLanguageInLocal": `한국어`,
    "metaTags": {
        "keyword1": `이스포츠 라이브 스트림`,
        "keyword2": `이스포츠 토너먼트`,
        "keyword3": `리그 오브 레전드 이스포츠`,
        "keyword4": `CS:GO / 카운터 스트라이크 토너먼트`,
        "keyword5": `도타 2 TI (디 인터내셔널)`,
        "keyword6": `AI 및 전문가 매치 분석 이스포츠`,
        "keyword7": `이스포츠 매치 예측 및 베팅 팁`,
        "keyword8": `이스포츠 뉴스 및 기사`,
        "singleMatchTitleLive": `{{team1}} vs {{team2}} | 라이브: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} vs {{team2}} | 결과: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleUpcoming": `예정: {{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchDescriptionLive": `{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} ({{tournament}}). 지금 라이브 시청하세요! 경기는 {{date}}에 시작되었습니다. MyEsports에서 통계, 예측 및 AI 분석을 확인하세요!`,
        "singleMatchDescriptionFinished": `{{game}}: {{teamA}}가 {{tournament}}에서 {{teamB}}와 대결했습니다. 경기는 {{date}}에 {{score1}} - {{score2}} 결과로 종료되었습니다. MyEsports에서 통계, 예측 및 AI 분석을 확인하세요!`,
        "singleMatchDescriptionUpcoming": `{{game}}: {{teamA}}가 {{tournament}}에서 {{teamB}}와 대결합니다. 경기는 {{date}}에 시작됩니다. MyEsports에서 통계, 예측 및 AI 분석을 확인하세요! 예정된 경기를 여기에서 라이브로 시청하세요!`,
        "singleMatchDescriptionFallback": `{{game}}: {{teamA}}가 {{tournament}}에서 {{teamB}}와 대결합니다. 경기는 {{date}}에 시작됩니다. MyEsports에서 통계, 예측 및 AI 분석을 확인하세요! 라이브 시청하세요!`,
        "singleTournamentTitle": `{{game}} 토너먼트 – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `MyEsports에서 {{tournament}}의 최신 경기 및 결과를 확인하세요! {{opponents}} 등이 참가하는 {{tier}} 티어 {{game}} 토너먼트!`,
        "singleAuthorTitle": `{{author}} - MyEsports 작가`,
        "matchesTitle": `이스포츠 경기 – 라이브 점수 및 결과 | MyEsports`,
        "matchesDescription": `MyEsports에서 최신 이스포츠 경기 결과 및 예정된 경기를 확인하세요. CS2, 도타 2, LoL 등의 라이브 점수, 일정, 분석, 팁 및 팀 통계를 확인하세요!`,
        "tournamentsTitle": `이스포츠 토너먼트 – 예정 및 과거 이벤트 | MyEsports`,
        "tournamentsDescription": `MyEsports에서 가장 큰 이스포츠 토너먼트를 찾아보세요! CS2, 도타 2, LoL, 발로란트 등의 일정, 결과, 상금 풀 및 팀 순위를 확인하세요!`,
        "newsTitle": `이스포츠 뉴스 및 기사 – 최신 업데이트 및 인사이트 | MyEsports`,
        "newsDescription": `MyEsports에서 최신 이스포츠 뉴스, 기사 및 인사이트를 확인하세요! CS2, 도타 2, LoL, 발로란트 등의 업데이트와 전문가 분석 및 인터뷰를 확인하세요!`,
        "authorsTitle": `MyEsports 이스포츠 작가 소개 – 뉴스, 예측 및 팁 | MyEsports`,
        "authorsDescription": `MyEsports 전문가를 만나보세요! CS2, 도타 2, LoL 등의 뉴스, 심층 기사, 예측 및 팁을 제공하는 이스포츠 작가들을 만나보세요!`,
        "bookmakersTitle": `상위 10개 이스포츠 베팅 사이트(카지노) – 신뢰할 수 있는 제휴 및 혜택 | MyEsports`,
        "bookmakersDescription": `MyEsports의 신뢰할 수 있는 베팅 파트너를 탐색하세요! CS2, 도타 2, LoL 등을 위한 독점 혜택, 보너스 및 안전한 플랫폼을 제공하는 최고의 이스포츠 베팅 사이트를 찾아보세요!`,
        "skinbookmakersTitle": `이스포츠 스킨 거래 – 스킨 및 케이스를 위한 신뢰할 수 있는 파트너 | MyEsports`,
        "skinbookmakersDescription": `MyEsports의 신뢰할 수 있는 스킨 거래 파트너를 찾아보세요! CS2, 도타 2, 러스트 등의 스킨 및 케이스를 안전하게 구매, 판매, 거래 및 베팅하세요. 최고의 마켓플레이스 및 독점 거래를 찾아보세요!`,
        "predictionsTitle": `이스포츠 예측 및 베팅 팁 – 전문가 분석 | MyEsports`,
        "predictionsDescription": `MyEsports에서 전문가 이스포츠 베팅 예측 및 팁을 받으세요! CS2, 도타 2, LoL 등의 전문가 및 AI의 정확한 경기 분석, 배당률 인사이트 및 승리 전략을 확인하세요!`,
        "termsOfUseTitle": `이용 약관 – 규칙 및 사용자 동의 | MyEsports`,
        "termsOfUseDescription": `MyEsports 이용 약관을 읽어보세요. 이스포츠 콘텐츠, 베팅 제휴 등을 포함한 플랫폼 사용에 대한 사용자 동의, 규칙 및 정책을 알아보세요.`,
        "privacyPolicyTitle": `개인정보 처리방침 – 데이터 보호 및 보안 | MyEsports`,
        "privacyPolicyDescription": `MyEsports가 데이터를 어떻게 보호하는지 알아보세요. 안전한 경험을 위해 사용자 정보, 보안 조치 및 GDPR 준수에 대한 개인정보 처리방침을 읽어보세요.`,
        "faqTitle": `FAQ – 자주 묻는 질문 및 지원 | MyEsports`,
        "faqDescription": `MyEsports에 대한 일반적인 질문에 대한 답변을 찾아보세요. 이스포츠 커버리지, 베팅 제휴, 예측, 스킨 거래 및 기타 지원 주제에 대한 FAQ를 읽어보세요.`,
        "streamersTitle": `이스포츠 스트리머 – 라이브 채널 및 게임 콘텐츠 | MyEsports`,
        "streamersDescription": `MyEsports에서 전 세계 최고의 이스포츠 스트리머를 찾아보세요! 파트너 크리에이터의 라이브 게임 플레이, 프로 선수 스트림 및 독점 콘텐츠를 시청하세요.`,
        "homeTitle": `MyEsports – 뉴스, 경기 및 베팅 팁을 위한 최고의 이스포츠 허브`,
        "homeDescription": `MyEsports로 이스포츠 세계에서 앞서 나가세요! CS2, 도타 2, LoL 등의 최신 뉴스, 경기 결과, 라이브 점수, 베팅 팁, 예측, 토너먼트 업데이트를 받아보세요!`,
    },
    "homePage": {
        "latestAnalyses": `최신 AI 분석`
    },
    "networkGuard": {
        "title": `앗!`,
        "description": `찾으시는 페이지가 존재하지 않습니다!`
    },
    "navMainMenu": {
        "Home": `홈`,
        "Tournaments": `토너먼트`,
        "Matches": `경기`,
        "Giveaways": `경품`,
        "Casinos": `베팅 업체`,
        "AboutUs": `회사 소개`,
        "Streamers": `스트리머`,
        "News": `뉴스`,
        "Predictions": `예측`,
        "navDesktopTitle": `MyEsports`
    },
    "hotmatchTranslations": {
        "playerNationalities": `{{countries}} 출신의 선수들이 경쟁합니다.`,
        "playerAges": `최연소 선수는 {{Age}}세 입니다.`,
        "viewers": `현재 {{maxviewers}}명이 시청 중입니다!`,
        "alternativeTimeLineHours": `{{RGN}} 게임 진행 중. {{hourz}}시간 {{minutez}}분 전에 시작되었습니다.`,
        "alternativeTimeLineMinutes": `{{RGN}} 게임 진행 중. {{minutez}}분 전에 시작되었습니다.`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>인기`,
        "noLiveMsg": `<h2>잠시만 기다려 주세요, 곧 경기가 시작됩니다!</h2><p>{{game}} 경기가 잠시 휴식 중인 것 같습니다.</p>`
    },
    "matchTranslations": {
        "title": `{{team1}} vs {{team2}} | {{tournament}}`,
        "description": `MyEsports에서 {{tournament}}의 {{teamA}}와 {{teamB}}의 대결을 시청하세요 | AI 분석과 전문가들의 경기 예측을 확인하세요!`,
        "h2": `경기 부제목입니다.`,
        "body": `경기 본문 내용입니다.`,
        "matchMatchUpcoming": `{{minutes}}분 남음`,
        "matchMatchLive": `라이브`,
        "matchMatchFinished": `종료`,
        "matchMatchPostponed": `연기됨`,
        "matchMatchCanceled": `취소됨`,
        "matchMatchAboutTo": `시작 예정`,
        "homeMatchDHM": `{{days}}일 {{hours}}시간 {{minutes}}분`,
        "homeMatchHM": `{{hours}}시간 {{minutes}}분`,
        "homeMatchM": `{{minutes}}분`,
        "homeMatchLive": `라이브`,
        "homeMatchAboutTo": `시작`,
        "pillLive": `라이브`,
        "pillUpcoming": `예정`,
        "pillFinished": `종료`,
        "pillPredictions": `예측`,
        "pillAll": `전체`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> 즐겨찾기`,
        "bestofX": `{{gamesNumber}}전`,
        "bestof1": `1전 1선승제`,
        "bestof2": `2전 2선승제`,
        "allGamesPlayed": `{{gamesNumber}} 경기`,
        "allGamesPlayedMicro": `경기{{gamesNumber}}`,
        "firstToX": `{{gamesNumber}}선승`,
        "showMoreMatches": `더 보기`,
        "emptyGameFilterMessage": `현재 {{game}} 경기가 없습니다.`,
        "emptyStateFilterMessage": `선택한 필터에 맞는 {{game}} 경기가 없습니다.`,
        "emptyPredictionFilterMessage": `선택한 날짜에 대한 {{predGame}} 예측이 없습니다.`,
        "emptyAnalysisFilterMessage": `선택한 날짜에 대한 최근 분석이 없습니다 {{game}}`,
        "searchPlaceholder": `토너먼트 또는 팀 검색...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames }
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `AI 기반 경기 분석`,
        "tipTranslationTitle": `전문가 팁`,
        "team1players": `선수`,
        "team2players": `선수`,
        "ageLabel": `나이: `,
        "positionLabel": `포지션: `,
        "h2hNoRecentMsg": `최근 두 팀 간의 경기가 없습니다.`,
        "matchHistory": `경기 기록`,
        "h2hPill": `상대 전적`,
        "badgeWon": `승`,
        "badgeLost": `패`,
        "badgeDraw": `무`,
        "noVideoMessage": `이 경기에 대한 영상이 없습니다.`,
        "analysis": `저희의 'PlayPulse' AI는 경기 시작 전 24시간 이내에 분석을 진행합니다. 전체 분석을 확인하려면 경기 시작 시간 가까이에 다시 방문해 주세요!`,
        "whatDoesAiThink": `이 경기에 대한 AI 기반 인사이트:`,
        "aiAnalysis": `AI 분석`,
        "betNow": `지금 베팅하기`,
        "oddsDraw": `무승부`,
        "predictToWin": `승리 예측`,
        "predictDraw": `무승부 예측`,
        "predictExactScore": `정확한 점수 예측`,
        "predictOverUnder": `게임 수 예측`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>최근 기사가 없습니다.</h2><p>죄송합니다. 최근 {{newsGame}} 관련 기사가 없습니다.</p>`,
        "newsTitle": `기사`,
        "writtenAt": `작성일`,
        "footerArticle": `🔥 기사가 마음에 드셨나요? 🔥`,
        "footerArticleFollow": `놓치지 마세요! 더 흥미로운 업데이트를 위해 소셜 미디어를 팔로우하세요!`,
        "newsSeeAll": `전체 보기`,
        "seeMore": `더 보기...`,
        "goBack": `뉴스로 돌아가기`,
        "tournamentNews": `토너먼트`,
        "rosterNews": `선수 명단`,
        "gameNews": `일반`,
    },
    "footer": {
        "about": `모든 이스포츠 팬을 위한 종합적인 목적지, MyEsports에 오신 것을 환영합니다. 게임 세계에 대한 깊은 사랑과 열정에서 탄생한 저희는 이스포츠 커뮤니티가 경기 및 이벤트의 라이브 방송부터 모든 종류의 게임에 대한 예측, 분석 및 기사에 이르기까지 모든 것을 찾을 수 있는 공간을 만들었습니다.`,
        "followUs": `팔로우`,
        "usefulLinks": `유용한 링크`,
        "contactUs": `문의하기`,
        "aboutUs": `회사 소개`,
        "eula": `이용 약관`,
        "privacy": `개인정보 처리방침`,
        "faq": `자주 묻는 질문`,
        "Authors": `작가`,
    },
    "tournamentsTranslations": {
        "noDateMsg": `종료 날짜 미정`,
        "tier": `티어`,
        "emptyTournamentFilterMessage": `선택한 상태 필터에 맞는 {{game}} 토너먼트가 없습니다.`,
        "pillOngoing": `진행 중`,
        "pillUpcoming": `예정`,
        "pillFinished": `종료`,
        "pillAll": `전체`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>진행 중</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">종료</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">{{days}}일 후 시작</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">{{days}}일 후 시작</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">{{hours}}시간 {{minutes}}분 후 시작</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">{{minutes}}분 후 시작</span>`,
        "noPrizePoolAvailable": `해당 없음`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `팀 참가`,
        "hotTournamentsTitle": `인기 토너먼트`,
        "hotTournamentFixedLabel": `진행 중`,
        "noHotTournamentsMsg": `<h2>현재 라이브 토너먼트가 없습니다.</h2><p>죄송합니다. 현재 {{game}} 라이브 토너먼트가 없습니다.</p>`,
        "noHotTournamentsButton2": `전체 보기`,
        "clickHotTournaments": `자세한 정보를 보려면 클릭하세요.`,
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `참가 팀: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `참가 선수: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `상금 풀: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `상금 풀: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `상금 풀: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `티어:`,
        "winnerPost": `토너먼트 우승자:`,
        "startPost": `시작 날짜:`,
        "endPost": `종료 날짜:`,
        "noDateMsgPost": `종료 날짜 미정`,
        "ladderWins": `승리`,
        "ladderLosses": `패배`,
        "ladderTies": `무승부`,
        "ladderGamesWon": `승리 게임`,
        "ladderGamesLost": `패배 게임`,
        "ladderTieBreaker": `타이브레이커`,
        "upperBracket": `승자조`,
        "lowerBracket": `패자조`,
        "grandFinal": `결승전`,
        "semiFinal": `준결승전`,
        "quarterFinal": `8강전`,
        "roundBrackets": `라운드`,
        "matchBrackets": `경기`,
        "laddersMatchesTitle": `경기`,
        "week": `주차`,
        "liveWeek": `라이브`,
    },
    "predictionsTranslations": {
        "predTitle": `예측`,
        "comingSoon": `곧 공개`,
        "today": `오늘`,
        "title": `이스포츠 예측`,
        "subtitle": `MyEsports에서는 최고의 이스포츠 베팅 팁을 제공하기 위해 각 경기를 면밀히 연구합니다. 저희 전문가는 최고의 베팅 결정을 내릴 수 있도록 팀, 선수 및 전략을 분석합니다.`,
        "time": `시간`,
        "date": `날짜`,
        "match": `경기`,
        "tournament": `토너먼트`,
        "tipster": `팁스터`,
        "betButton1": `지금 다음에서 베팅하세요: `,
        "odd": `@`,
        "noPredictionForThisDate": `아직 이 날짜에 대한 예측이 없습니다. 나중에 다시 방문해주세요.`,
        "statsAvgOdds": `평균 배당률`,
        "statsEarn": `수익`,
        "statsBets": `베팅`,
        "statsOpenBets": `진행 중인 베팅`,
        "authStats": `예측 통계`,
        "articles": `기사`,
        "last10": `최근 예측`,
        "authBio": `약력`,
        "bioComing": `약력 준비 중`,
        "showMorePredictions": `더 보기`,
        "EarnExplain": `수익은 종료된 예측을 기준으로 계산됩니다. 각 팁스터는 0에서 시작하며, 각 정확한 예측은 총 수익에 획득한 배당률 x1을 더합니다. 예를 들어, 1.80 배당률의 정확한 예측에서 팁스터의 수익은 0.80 증가합니다. 잘못된 예측의 경우 총 수익에서 1.00이 차감됩니다.`,
        "WinrateExplain": `승률은 종료된 예측을 기준으로 계산됩니다. 이는 총 예측 수에서 정확한 예측의 비율입니다.`,
        "RoiExplain": `ROI(투자 수익률)는 총 베팅에 대한 팁스터의 수익을 기준으로 계산됩니다. 기본적으로 ROI는 팁스터의 순이익을 나타냅니다. 예를 들어, 각 1단위로 50번의 베팅을 했고 ROI가 20%인 경우 10단위를 획득한 것입니다.`,
        "AvgOddsExplain": `평균 배당률은 종료된 예측의 배당률을 기준으로 계산됩니다. 이는 배당률의 합계를 총 예측 수로 나눈 값입니다.`,
        "plainPrediction": `예측`,
        "statisticPeriod": `기간`,
        "alltimeStats": `전체`,
    },
    "banners": {
        "desktopLeft": `en_desktop_left`,
        "desktopRight": `en_desktop_right`
    },
    "streamerTranslations": {
        "cardFollowers": `팔로워`,
        "cardInfo": `{{user}}님은 현재 {{status}} 상태입니다.`,
        "cardViewers": `시청자!`,
        "detailsInformation": `스트리머 정보`,
        "detailsName": `본명`,
        "detailsNationality": `국적`,
        "detailsAge": `나이`,
        "detailsGame": `게임`,
        "detailsRole": `역할`,
        "detailsTeam": `팀`,
        "detailsSignature": `대표 영웅`,
        "accordionHowTo": `<strong>-MyEsports 스트리머는 어떻게 될 수 있나요?</strong>`,
        "accordionHowToReply": `<a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord 서버</a>에 가입하여 저희 팀에 문의하거나 이 <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">양식</a>을 작성하여 신청할 수 있습니다.`,
        "accordionEarn": `<strong>-MyEsports 스트리머가 되면 무엇을 얻을 수 있나요?</strong>`,
        "accordionEarnReply": `MyEsports에 소개되면 시청자를 늘리고 채널을 성장시킬 수 있는 기회를 얻을 수 있습니다. 또한 저희 기사 및 소셜 미디어에 소개될 기회도 주어집니다.`,
        "accordionGraphics": `<strong>-채널 그래픽을 받을 수 있나요?</strong>`,
        "accordionGraphicsReply": `예, 채널 그래픽을 제공하고 사용 방법 및 스트리밍 경험을 개선하는 방법에 대한 지원 및 안내를 제공합니다.`,
        "accordionHowToStream": `<strong>-스트리밍을 처음 하는데도 참여할 수 있나요? 어떻게 시작하나요?</strong>`,
        "accordionHowToStreamReply": `물론입니다! 저희는 모든 수준의 스트리머를 환영합니다. <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord 서버</a>에 가입하여 저희 팀에 문의하시면 자세한 정보를 얻을 수 있습니다.`,
        "accordionViewers": `<strong>-참여하려면 시청자가 몇 명이어야 하나요?</strong>`,
        "accordionViewersReply": `저희는 모든 규모의 스트리머를 환영합니다. 시청자가 1명이든 10,000명이든 MyEsports에 참여하실 수 있습니다!`,
    },
    "authorsTranslations": {
        "title": `MyEsports 작가 팀을 만나보세요.`
    },
    "bookmakersPostTranslations": {
        "odds": `배당률`,
        "varietyOfEsports": `다양한 이스포츠`,
        "liveSupport": `라이브 지원`,
        "liveStreaming": `라이브 스트리밍`,
        "payments": `결제`,
        "fullReview": `전체 리뷰`,
        "esports": `이스포츠`,
        "register": `지금 베팅하기`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `전체 리뷰`,
        "register": `지금 베팅하기`
    },
    "bookmakersSkinList": {
        "widgetTitle": `스킨 플랫폼`,
        "widgetButton": `더보기`,
        "playNow": `플레이!`,
        "introSection": `<h2 class="title-section">2024년에 가입할 최고의 이스포츠 베팅 사이트: 최고의 이스포츠 북메이커 스킨을 찾아보세요</h2>\n        <p>\n        이스포츠의 폭발적인 성장과 함께 선도적인 베팅 스킨들이 수요를 충족시키기 위해 등장하여 Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends 및 Call of Duty와 같은 인기 게임에 대한 광범위한 시장을 제공합니다. 이러한 게임은 지속적인 스릴 넘치는 토너먼트와 리그를 제공하여 베터들에게 간단한 경기 승리부터 게임별 베팅에 이르기까지 다양한 옵션을 제공합니다.            </p>\n        <p>\n        <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>에서 저희는 귀하의 베팅 요구에 맞는 완벽한 스킨을 찾을 수 있도록 다양한 최고의 이스포츠 베팅 스킨 플랫폼을 신중하게 검토했습니다.            </p>`,
        "sectionTitle": `이스포츠 베팅 장소: 최고의 이스포츠 베팅 스킨 및 보너스`,
        "pleaseBetResponsibly": `책임감 있게 베팅하세요`,
        "reviewMethodology": `\n            <h2 class="title-section">저희의 검토 방법론</h2>\n    <p>\n    <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>에서 저희의 임무는 간단합니다. 귀하의 이스포츠 베팅에 이상적인 북메이커 스킨을 선택하도록 안내하는 것입니다. 저희는 각 스킨을 사용 편의성, 다양한 이스포츠 시장 및 보안 기능을 기준으로 평가하여 입출금 시 안전을 보장합니다.\n    </p>\n    <p>\n    또한 귀하의 지역에서 북메이커 스킨이 합법적으로 운영되는지 확인하기 위해 라이선스를 평가합니다. 저희의 높은 기준을 충족하는 스킨만이 저희의 승인을 받습니다. 귀하의 베팅 경험을 더욱 향상시키기 위해 독점적인 이스포츠 보너스 및 프로모션을 제공합니다.        </p>\n    <p>\n    저희의 검토 기준은 사용자 친화성, 시장 제공 및 결제 방법을 포함한 여러 영역에 걸쳐 있습니다. 각 북메이커 스킨은 디자인 및 기능부터 고객 서비스에 이르기까지 모든 것을 다루는 8가지 주요 범주에 걸쳐 철저하게 평가됩니다. 이러한 중요한 영역에서 각 북메이커 스킨의 성능을 탐색하려면 심층 검토를 확인하십시오.        </p>\n    `,
        "review": `리뷰`,
        "betNow": `지금 베팅하기`,
        "noBookmakerSkinsMsg": `<h2>귀하의 지역 및 언어에 사용할 수 있는 스킨 플랫폼이 없습니다.</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `북메이커`,
        "widgetButton": `더보기`,
        "playNow": `플레이!`,
        "introSection": `<h2 class="title-section">2024년에 가입할 최고의 이스포츠 베팅 사이트: 최고의 이스포츠 북메이커를 찾아보세요</h2>\n        <p>\n        이스포츠의 폭발적인 성장과 함께 선도적인 베팅 사이트들이 수요를 충족시키기 위해 등장하여 Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends 및 Call of Duty와 같은 인기 게임에 대한 광범위한 시장을 제공합니다. 이러한 게임은 지속적인 스릴 넘치는 토너먼트와 리그를 제공하여 베터들에게 간단한 경기 승리부터 게임별 베팅에 이르기까지 다양한 옵션을 제공합니다.\n        </p>\n        <p>\n        <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>에서 저희는 귀하의 베팅 요구에 맞는 완벽한 사이트를 찾을 수 있도록 다양한 최고의 이스포츠 베팅 플랫폼을 신중하게 검토했습니다.\n        </p>`,
        "sectionTitle": `이스포츠 베팅 장소: 최고의 이스포츠 베팅 사이트 및 보너스`,
        "pleaseBetResponsibly": `책임감 있게 베팅하세요`,
        "reviewMethodology": `\n            <h2 class="title-section">저희의 검토 방법론</h2>\n    <p>\n        <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>에서 저희의 임무는 간단합니다. 귀하의 이스포츠 베팅에 이상적인 북메이커를 선택하도록 안내하는 것입니다. 저희는 각 사이트를 사용 편의성, 다양한 이스포츠 시장 및 보안 기능을 기준으로 평가하여 입출금 시 안전을 보장합니다.\n    </p>\n    <p>\n        또한 귀하의 지역에서 북메이커가 합법적으로 운영되는지 확인하기 위해 라이선스를 평가합니다. 저희의 높은 기준을 충족하는 북메이커만이 저희의 승인을 받습니다. 귀하의 베팅 경험을 더욱 향상시키기 위해 독점적인 이스포츠 보너스 및 프로모션을 제공합니다.\n    </p>\n    <p>\n        저희의 검토 기준은 사용자 친화성, 시장 제공 및 결제 방법을 포함한 여러 영역에 걸쳐 있습니다. 각 북메이커는 디자인 및 기능부터 고객 서비스에 이르기까지 모든 것을 다루는 8가지 주요 범주에 걸쳐 철저하게 평가됩니다. 이러한 중요한 영역에서 각 북메이커의 성능을 탐색하려면 심층 검토를 확인하십시오.\n    </p>\n    `,
        "review": `리뷰`,
        "betNow": `지금 베팅하기`,
        "noBookmakersMsg": `<h2>귀하의 지역 및 언어에 사용할 수 있는 북메이커가 없습니다.</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>앗! 광고 차단기를 사용 중인 것 같습니다.</strong></p>\n    <p>저희가 제공하는 훌륭한 콘텐츠를 놓치고 있습니다. 광고가 아니라 유용한 기능입니다. 광고 차단기를 비활성화하는 것을 고려해 주세요.</p>\n    <button id="close-button">알겠습니다!</button>`
    },
    "discordTemplate": {
        "titleMain": `도움이 필요하신가요?`,
        "titleSub": `지금 Discord에 참여하세요!`
    }
}
