import { globalSocials } from "../../utils/globals.js";

export const frenchTranslation =
{
    "translationLanguageInLocal": `Français`,
    "metaTags": {
        "keyword1": `Streaming en direct d'esports`,
        "keyword2": `Tournois d'esports`,
        "keyword3": `Esports League of Legends`,
        "keyword4": `Tournois CS:GO / Counter-Strike`,
        "keyword5": `Dota 2 TI (The International)`,
        "keyword6": `Analyse de matchs esports par IA et experts`,
        "keyword7": `Prédictions et conseils de paris esports`,
        "keyword8": `Actualités et articles esports`,
        "singleMatchTitleLive": `{{team1}} vs {{team2}} | En direct : {{score1}} - {{score2}} | {{game}} : {{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} vs {{team2}} | Résultat : {{score1}} - {{score2}} | {{game}} : {{tournament}}`,
        "singleMatchTitleUpcoming": `À venir : {{team1}} vs {{team2}} | {{game}} : {{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} vs {{team2}} | {{game}} : {{tournament}}`,
        "singleMatchDescriptionLive": `{{game}} : {{teamA}} {{score1}}-{{score2}} {{teamB}} dans {{tournament}}. Regardez en direct maintenant ! Match commencé le {{date}}. Consultez les stats, prédictions et analyses IA sur MyEsports !`,
        "singleMatchDescriptionFinished": `{{game}} : {{teamA}} a affronté {{teamB}} dans {{tournament}}. Match terminé le {{date}} avec un score de {{score1}} - {{score2}}. Consultez les stats, prédictions et analyses IA sur MyEsports !`,
        "singleMatchDescriptionUpcoming": `{{game}} : {{teamA}} affronte {{teamB}} dans {{tournament}}. Début du match le {{date}}. Consultez les stats, prédictions et analyses IA sur MyEsports ! Regardez le match en direct ici !`,
        "singleMatchDescriptionFallback": `{{game}} : {{teamA}} affronte {{teamB}} dans {{tournament}}. Début du match le {{date}}. Consultez les stats, prédictions et analyses IA sur MyEsports ! Regardez en direct !`,
        "singleTournamentTitle": `Tournoi {{game}} – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `Découvrez les derniers matchs et résultats du {{tournament}} sur MyEsports ! Tournoi {{game}} de niveau {{tier}} avec {{opponents}} et plus encore !`,
        "singleAuthorTitle": `{{author}} - Auteur MyEsports`,
        "matchesTitle": `Matchs d'esports – Scores en direct & Résultats | MyEsports`,
        "matchesDescription": `Restez informé des derniers résultats de matchs d'esports et des jeux à venir sur MyEsports. Scores en direct, calendriers, analyses, conseils & stats des équipes sur CS2, Dota 2, LoL & plus !`,
        "tournamentsTitle": `Tournois d'esports – Événements à venir & passés | MyEsports`,
        "tournamentsDescription": `Découvrez les plus grands tournois d'esports sur MyEsports ! Calendriers, résultats, prize pools & classements d'équipes pour CS2, Dota 2, LoL, Valorant & plus !`,
        "newsTitle": `Actualités & Articles d'esports – Dernières infos & analyses | MyEsports`,
        "newsDescription": `Restez à jour avec les dernières actualités, articles et analyses sur MyEsports ! Infos sur CS2, Dota 2, LoL, Valorant & plus, avec des analyses d'experts et interviews exclusives !`,
        "authorsTitle": `Rencontrez nos auteurs esports – News, Prédictions & Conseils | MyEsports`,
        "authorsDescription": `Découvrez les experts de MyEsports ! Rencontrez nos rédacteurs spécialisés en esports qui vous apportent news, articles détaillés, prédictions & conseils pour CS2, Dota 2, LoL & plus !`,
        "bookmakersTitle": `Top 10 des sites de paris esports (Casinos) – Affiliés de confiance & Offres | MyEsports`,
        "bookmakersDescription": `Découvrez les sites de paris partenaires de confiance de MyEsports ! Trouvez les meilleures plateformes de paris esports avec offres exclusives, bonus & sécurité sur CS2, Dota 2, LoL & plus !`,
        "skinbookmakersTitle": `Échange de skins esports – Partenaires de confiance pour skins & caisses | MyEsports`,
        "skinbookmakersDescription": `Découvrez les partenaires d'échange de skins de MyEsports ! Achetez, vendez, échangez & pariez des skins et caisses en toute sécurité pour CS2, Dota 2, Rust & plus. Trouvez les meilleurs marchés & offres exclusives !`,
        "predictionsTitle": `Prédictions & Conseils de Paris Esports – Analyse experte | MyEsports`,
        "predictionsDescription": `Obtenez des prédictions et conseils de paris esports d'experts sur MyEsports ! Analyse détaillée des matchs par nos experts et notre IA, aperçu des cotes & stratégies gagnantes pour CS2, Dota 2, LoL & plus !`,
        "termsOfUseTitle": `Conditions d'utilisation – Règles & Accord utilisateur | MyEsports`,
        "termsOfUseDescription": `Lisez les conditions d'utilisation de MyEsports. Découvrez les accords utilisateurs, règles et politiques d'utilisation de notre plateforme, incluant le contenu esports, les affiliés de paris & plus.`,
        "privacyPolicyTitle": `Politique de confidentialité – Protection & Sécurité des données | MyEsports`,
        "privacyPolicyDescription": `Découvrez comment MyEsports protège vos données. Lisez notre politique de confidentialité sur la gestion des informations utilisateurs, mesures de sécurité & conformité RGPD pour une expérience sécurisée.`,
        "faqTitle": `FAQ – Questions fréquentes & Support | MyEsports`,
        "faqDescription": `Trouvez des réponses aux questions les plus courantes sur MyEsports. Lisez notre FAQ sur la couverture esports, les affiliés de paris, les prédictions, l'échange de skins & plus de sujets d'assistance.`,
        "streamersTitle": `Streamers Esports – Chaînes en direct & Contenu gaming | MyEsports`,
        "streamersDescription": `Découvrez les meilleurs streamers esports du monde entier sur MyEsports ! Regardez des parties en direct, des streams de joueurs pro & du contenu exclusif de nos créateurs partenaires.`,
        "homeTitle": `MyEsports – Votre hub ultime pour l'actualité, les matchs & conseils de paris esports`,
        "homeDescription": `Restez à la pointe de l'esports avec MyEsports ! Actus, résultats de matchs, scores en direct, conseils de paris, prédictions, mises à jour des tournois pour CS2, Dota 2, LoL & plus !`
    },
    "homePage": {
        "latestAnalyses": `DERNIÈRES ANALYSES IA`
    },
    "networkGuard": {
        "title": `Oups!`,
        "description": `La page que vous recherchez n'existe pas !`
    },
    "navMainMenu": {
        "Home": `Accueil`,
        "Tournaments": `Tournois`,
        "Matches": `Matchs`,
        "Giveaways": `Concours`,
        "Casinos": `Parieurs`,
        "AboutUs": `À propos`,
        "Streamers": `Streamers`,
        "News": `Actualités`,
        "Predictions": `Prédictions`,
        "navDesktopTitle": `MyEsports`
    },
    "hotmatchTranslations": {
        "playerNationalities": `Joueurs de {{countries}} en compétition`,
        "playerAges": `Le plus jeune joueur a {{Age}} ans`,
        "viewers": `{{maxviewers}} spectateurs en direct !`,
        "alternativeTimeLineHours": `Partie {{RGN}} en cours. Début il y a {{hourz}} heure(s) et {{minutez}} minute(s)`,
        "alternativeTimeLineMinutes": `Partie {{RGN}} en cours. Début il y a {{minutez}} minute(s)`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>CHAUD`,
        "noLiveMsg": `<h2>Patience, l'action revient bientôt !</h2><p>On dirait que le match {{game}} fait une pause.</p>`
    },
    "matchTranslations": {
        "title": `{{team1}} vs {{team2}} | {{tournament}}`,
        "description": `Regardez {{teamA}} affronter {{teamB}} dans {{tournament}} sur MyEsports | Consultez l'analyse IA et les conseils de nos experts pour ce match !`,
        "h2": `Voici le sous-paragraphe d'un match`,
        "body": `Ceci est le contenu principal d'un match`,
        "matchMatchUpcoming": `{{minutes}} min restantes`,
        "matchMatchLive": `EN DIRECT`,
        "matchMatchFinished": `Terminé`,
        "matchMatchPostponed": `Reporté`,
        "matchMatchCanceled": `Annulé`,
        "matchMatchAboutTo": `Sur le point de commencer`,
        "homeMatchDHM": `{{days}}j {{hours}}h {{minutes}}m`,
        "homeMatchHM": `{{hours}}h {{minutes}}m`,
        "homeMatchM": `{{minutes}}m`,
        "homeMatchLive": `EN DIRECT`,
        "homeMatchAboutTo": `Début imminent`,
        "pillLive": `EN DIRECT`,
        "pillUpcoming": `À VENIR`,
        "pillFinished": `TERMINÉ`,
        "pillPredictions": `PRÉDICTIONS`,
        "pillAll": `TOUS`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"></i> FAVORIS`,
        "bestofX": `Meilleur de {{gamesNumber}}`,
        "bestof1": `Meilleur de 1`,
        "bestof2": `Meilleur de 2`,
        "allGamesPlayed": `{{gamesNumber}} parties`,
        "allGamesPlayedMicro": `J{{gamesNumber}}`,
        "firstToX": `Premier à {{gamesNumber}}`,
        "showMoreMatches": `Voir plus`,
        "emptyGameFilterMessage": `Aucun match disponible pour {{game}} actuellement.`,
        "emptyStateFilterMessage": `Aucun match {{game}} disponible avec les filtres sélectionnés.`,
        "emptyPredictionFilterMessage": `Aucune prédiction disponible pour {{predGame}} à cette date.`,
        "emptyAnalysisFilterMessage": `Aucune analyse récente {{game}}`,
        "searchPlaceholder": `Recherchez des tournois ou des équipes...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames }
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `Analyse de match par IA`,
        "tipTranslationTitle": `Conseils d'experts`,
        "team1players": `Joueurs`,
        "team2players": `Joueurs`,
        "ageLabel": `Âge : `,
        "positionLabel": `Position : `,
        "h2hNoRecentMsg": `Aucun match récent entre ces équipes`,
        "matchHistory": `Historique des matchs`,
        "h2hPill": `Face à Face`,
        "badgeWon": `G`,
        "badgeLost": `P`,
        "badgeDraw": `N`,
        "noVideoMessage": `Aucune vidéo disponible pour ce match`,
        "analysis": `Notre IA 'PlayPulse' analysera ce match dans les 24 heures précédant le coup d'envoi. Revenez un peu avant le début du match pour consulter l'analyse complète!`,
        "whatDoesAiThink": `Aperçu IA de ce match :`,
        "aiAnalysis": `ANALYSE IA`,
        "betNow": `Pariez maintenant`,
        "oddsDraw": `Égalité`,
        "predictToWin": `pour gagner`,
        "predictDraw": `Égalité`,
        "predictExactScore": `Score exact`,
        "predictOverUnder": `Jeux`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>Aucun article récent</h2><p>Malheureusement, il n'y a pas d'articles récents sur {{newsGame}}.</p>`,
        "newsTitle": `ARTICLES`,
        "writtenAt": `Écrit à`,
        "footerArticle": `🔥 Vous avez aimé l'article ? 🔥`,
        "footerArticleFollow": `Ne manquez pas ça ! Suivez-nous sur les réseaux sociaux pour plus de mises à jour excitantes !`,
        "newsSeeAll": `Voir tout`,
        "seeMore": `Voir plus...`,
        "goBack": `Retour aux actualités`,
        "tournamentNews": `Tournois`,
        "rosterNews": `Effectifs`,
        "gameNews": `Général`
    },
    "footer": {
        "about": `Bienvenue sur MyEsports, votre destination complète pour tous les passionnés d'eSports. Né d'un amour profond pour le monde du jeu, nous avons créé un espace où la communauté eSports peut trouver tout : des diffusions en direct de matchs et d'événements, des prévisions, des analyses et des articles pour tous les types de jeux.`,
        "followUs": `Suivez-nous`,
        "usefulLinks": `Liens utiles`,
        "contactUs": `Contactez-nous`,
        "aboutUs": `À propos de nous`,
        "eula": `Conditions d'utilisation`,
        "privacy": `Politique de confidentialité`,
        "faq": `Foire aux questions`,
        "Authors": `Auteurs`
    },
    "tournamentsTranslations": {
        "noDateMsg": `Date de fin à déterminer`,
        "tier": `Niveau`,
        "emptyTournamentFilterMessage": `Aucun tournoi {{game}} disponible avec les filtres de statut sélectionnés.`,
        "pillOngoing": `EN COURS`,
        "pillUpcoming": `À VENIR`,
        "pillFinished": `TERMINÉ`,
        "pillAll": `TOUS`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>En cours</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Terminé</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Commence dans {{days}} jours</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Commence dans {{days}} jour</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Commence dans {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Commence dans {{minutes}}m</span>`,
        "noPrizePoolAvailable": `N/A`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `Équipes en compétition`,
        "hotTournamentsTitle": `TOURNAMENTS CHAUDS`,
        "hotTournamentFixedLabel": `EN COURS`,
        "noHotTournamentsMsg": `<h2>Aucun tournoi en direct actuellement</h2><p>Malheureusement, il n'y a actuellement aucun tournoi en direct {{game}}.</p>`,
        "noHotTournamentsButton2": `Voir tout`,
        "clickHotTournaments": `Cliquez pour voir plus d'informations.`
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Équipes en compétition : <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Joueurs en compétition : <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Cagnottes : <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Cagnottes : <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Cagnottes : <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `Niveau :`,
        "winnerPost": `Vainqueur du tournoi :`,
        "startPost": `Date de début :`,
        "endPost": `Date de fin :`,
        "noDateMsgPost": `Date de fin à déterminer`,
        "ladderWins": `Victoires`,
        "ladderLosses": `Défaites`,
        "ladderTies": `Égalités`,
        "ladderGamesWon": `Jeux gagnés`,
        "ladderGamesLost": `Jeux perdus`,
        "ladderTieBreaker": `Tirs au but`,
        "upperBracket": `Tableau supérieur`,
        "lowerBracket": `Tableau inférieur`,
        "grandFinal": `Grande Finale`,
        "semiFinal": `Demi-finale`,
        "quarterFinal": `Quarts de finale`,
        "roundBrackets": `Ronde`,
        "matchBrackets": `Match`,
        "laddersMatchesTitle": `Matchs`,
        "week": `Semaine`,
        "liveWeek": `EN DIRECT`
    },
    "predictionsTranslations": {
        "predTitle": `PRÉDICTIONS`,
        "comingSoon": `Bientôt disponible`,
        "today": `Aujourd'hui`,
        "title": `Prédictions eSports`,
        "subtitle": `Sur MyEsports, nous analysons chaque match de près pour vous offrir les meilleurs conseils de paris en eSports. Nos experts analysent les équipes, les joueurs et les stratégies pour vous aider à prendre les meilleures décisions de paris.`,
        "time": `Heure`,
        "date": `Date`,
        "match": `Match`,
        "tournament": `Tournoi`,
        "tipster": `Pronostiqueur`,
        "betButton1": `Placez votre pari maintenant sur `,
        "odd": `@`,
        "noPredictionForThisDate": `Aucune prédiction pour cette date pour l'instant, revenez plus tard.`,
        "statsAvgOdds": `Cote Moyenne`,
        "statsEarn": `Gagné`,
        "statsBets": `Paris`,
        "statsOpenBets": `Paris ouverts`,
        "authStats": `Statistiques de prédictions`,
        "articles": `Articles`,
        "last10": `Prédictions récentes`,
        "authBio": `Biographie`,
        "bioComing": `Biographie à venir`,
        "showMorePredictions": `Voir Plus`,
        "EarnExplain": `Les profits sont calculés sur la base des prédictions qui ont été clôturées. Chaque pronostiqueur commence à partir de 0, avec chaque prédiction correcte ajoutant la cote gagnée x1 à leur profit total. Par exemple, dans une prédiction correcte avec une cote de 1.80, le profit du pronostiqueur augmente de 0.80. En cas de prédiction incorrecte, 1.00 est soustrait du profit total.`,
        "WinrateExplain": `Le taux de victoire est calculé sur la base des prédictions qui ont été clôturées. Il représente le pourcentage de prédictions correctes par rapport au nombre total de prédictions effectuées.`,
        "RoiExplain": `Le ROI (Retour sur Investissement) est calculé en fonction des profits du pronostiqueur par rapport à leurs paris totaux. Essentiellement, le ROI représente le profit net du pronostiqueur. Par exemple, s'ils ont placé 50 paris avec une unité chacun et que le ROI est de 20 %, alors ils ont gagné 10 unités.`,
        "AvgOddsExplain": `La cote moyenne est calculée sur la base des cotes des prédictions qui ont été clôturées. Il s'agit de la somme des cotes divisée par le nombre total de prédictions.`,
        "plainPrediction": `Prédiction`,
        "statisticPeriod": `Période`,
        "alltimeStats": `Statistiques globales`
    },
    "banners": {
        "desktopLeft": `fr_desktop_left`,
        "desktopRight": `fr_desktop_right`
    },
    "streamerTranslations": {
        "cardFollowers": `Abonnés`,
        "cardInfo": `{{user}} est {{status}} en ce moment.`,
        "cardViewers": `Spectateurs !`,
        "detailsInformation": `Informations sur le Streamer`,
        "detailsName": `Nom Complet`,
        "detailsNationality": `Nationalité`,
        "detailsAge": `Âge`,
        "detailsGame": `Jeux`,
        "detailsRole": `Rôle`,
        "detailsTeam": `Équipe`,
        "detailsSignature": `Héros Signature`,
        "accordionHowTo": `<strong>-Comment devenir un streamer sur MyEsports ?</strong>`,
        "accordionHowToReply": `Pour devenir un streamer sur MyEsports, vous pouvez postuler en rejoignant notre <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">serveur Discord</a> et en contactant notre équipe ou remplir ce <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">formulaire</a>.`,
        "accordionEarn": `<strong>-Que gagne-je en devenant streamer sur MyEsports ?</strong>`,
        "accordionEarnReply": `Être présenté sur MyEsports vous offrira l'opportunité d'augmenter votre audience et de faire croître votre chaîne. Vous aurez également la chance d'être mis en avant dans nos articles et sur les réseaux sociaux.`,
        "accordionGraphics": `<strong>-Vais-je recevoir des graphiques pour ma chaîne ?</strong>`,
        "accordionGraphicsReply": `Oui, nous vous fournirons des graphiques pour votre chaîne, ainsi que du support et des conseils sur la façon de les utiliser et améliorer votre expérience de streaming.`,
        "accordionHowToStream": `<strong>-Je suis nouveau dans le streaming, puis-je quand même rejoindre ? Comment commencer ?</strong>`,
        "accordionHowToStreamReply": `Bien sûr ! Nous accueillons des streamers de tous niveaux. Vous pouvez commencer par rejoindre notre <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">serveur Discord</a> et contacter notre équipe pour plus d'informations.`,
        "accordionViewers": `<strong>-Combien de spectateurs dois-je avoir pour rejoindre ?</strong>`,
        "accordionViewersReply": `Nous accueillons des streamers de toutes tailles. Que vous ayez 1 ou 10 000 spectateurs, vous êtes le bienvenu sur MyEsports !`
    },
    "authorsTranslations": {
        "title": `Découvrez l'équipe des auteurs de MyEsports`
    },
    "bookmakersPostTranslations": {
        "odds": `Cotes`,
        "varietyOfEsports": `Variété d'eSports`,
        "liveSupport": `Support en direct`,
        "liveStreaming": `Streaming en direct`,
        "payments": `Paiements`,
        "fullReview": `Critique complète`,
        "esports": `eSports`,
        "register": `PARIER MAINTENANT`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `Critique complète`,
        "register": `PARIER MAINTENANT`
    },
    "bookmakersSkinList": {
        "widgetTitle": `Plateformes de Skin`,
        "widgetButton": `Plus`,
        "playNow": `Jouer!`,
        "introSection": `<h2 class="title-section">Meilleurs sites de paris eSports à rejoindre en 2024 : Découvrez les meilleurs skins de bookmakers eSports</h2>
        <p>
        Avec la croissance explosive des eSports, les skins de paris leaders ont émergé pour répondre à la demande, offrant des marchés étendus pour des jeux populaires tels que Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, et Call of Duty. Ces jeux offrent un flux continu de tournois et de ligues palpitants, offrant aux parieurs diverses options – des gagnants de matchs simples aux paris spécifiques aux jeux.
        </p>
        <p>
        Sur <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, nous avons soigneusement examiné une gamme de plateformes de skins de paris eSports pour vous aider à trouver le skin parfait pour vos besoins en paris.
        </p>`,
        "sectionTitle": `Où parier sur les eSports : Meilleurs skins de bookmakers eSports & Bonus`,
        "pleaseBetResponsibly": `Pariez de manière responsable`,
        "reviewMethodology": `
            <h2 class="title-section">Notre méthodologie d'examen</h2>
    <p>
    Sur <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, notre mission est simple – vous guider pour choisir le skin de bookmaker idéal pour vos paris eSports. Nous évaluons chaque skin en fonction de sa facilité d'utilisation, de la variété des marchés eSports, et des fonctionnalités de sécurité pour assurer votre sécurité lors de dépôts ou de retraits.
    </p>
    <p>
    Nous évaluons également la licence pour vérifier si le skin de bookmaker opère légalement dans votre région. Seuls ceux qui répondent à nos normes élevées reçoivent notre sceau d'approbation. Nous proposons des bonus exclusifs pour les eSports et des promotions pour améliorer encore votre expérience de paris.
    </p>
    <p>
    Nos critères d'évaluation couvrent plusieurs domaines, y compris la convivialité, l'offre de marchés et les méthodes de paiement. Chaque skin de bookmaker est minutieusement évalué sur huit catégories principales, couvrant tout, de la conception et de la fonctionnalité à l'assistance client. Consultez nos critiques approfondies pour explorer les performances de chaque skin de bookmaker dans ces domaines clés.
    </p>
    `,
        "review": `Critique`,
        "betNow": `PARIER MAINTENANT`,
        "noBookmakerSkinsMsg": `<h2>Aucune plateforme de skins disponible pour votre région et langue.</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `Bookmakers`,
        "widgetButton": `Plus`,
        "playNow": `Jouer!`,
        "introSection": `<h2 class="title-section">Meilleurs sites de paris eSports à rejoindre en 2024 : Découvrez les meilleurs bookmakers eSports</h2>
        <p>
        Avec la croissance explosive des eSports, les sites de paris leaders ont émergé pour répondre à la demande, offrant des marchés étendus pour des jeux populaires comme Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends et Call of Duty. Ces jeux offrent un flux continu de tournois et de ligues palpitants, offrant aux parieurs diverses options – des gagnants de matchs simples aux paris spécifiques aux jeux.
        </p>
        <p>
        Sur <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, nous avons soigneusement examiné une gamme de plateformes de paris eSports pour vous aider à trouver le site parfait pour vos besoins en paris.
        </p>`,
        "sectionTitle": `Où parier sur les eSports : Meilleurs sites de paris eSports & Bonus`,
        "pleaseBetResponsibly": `Pariez de manière responsable`,
        "reviewMethodology": `
            <h2 class="title-section">Notre méthodologie d'examen</h2>
    <p>
        Sur <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, notre mission est simple – vous guider pour choisir le bookmaker idéal pour vos paris eSports. Nous évaluons chaque site en fonction de sa facilité d'utilisation, de la variété des marchés eSports, et des fonctionnalités de sécurité pour assurer votre sécurité lors de dépôts ou de retraits.
    </p>
    <p>
        Nous évaluons également la licence pour vérifier si le bookmaker opère légalement dans votre région. Seuls ceux qui répondent à nos normes élevées reçoivent notre sceau d'approbation. Nous proposons des bonus exclusifs pour les eSports et des promotions pour améliorer encore votre expérience de paris.
    </p>
    <p>
        Nos critères d'évaluation couvrent plusieurs domaines, y compris la convivialité, l'offre de marchés et les méthodes de paiement. Chaque bookmaker est minutieusement évalué sur huit catégories principales, couvrant tout, de la conception et de la fonctionnalité à l'assistance client. Consultez nos critiques approfondies pour explorer les performances de chaque bookmaker dans ces domaines clés.
    </p>
    `,
        "review": `Critique`,
        "betNow": `PARIER MAINTENANT`,
        "noBookmakersMsg": `<h2>Aucun bookmaker disponible pour votre région et langue.</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Oups! Il semble que vous utilisez un bloqueur de publicités.</strong></p>
    <p>Vous manquez certains contenus intéressants que nous avons pour vous. Ce ne sont pas des publicités, mais des fonctionnalités utiles. Veuillez envisager de désactiver votre bloqueur de publicités.</p>
    <button id="close-button">D'accord !</button>`
    },
    "discordTemplate": {
        "titleMain": `Besoin d'aide?`,
        "titleSub": `Rejoignez notre Discord maintenant !`
    }

}

