import { globalSocials } from "../../../functions/across-stack/utils/globals";

export const authorSocialIcons = {
  instagram: "bi bi-instagram",
  discord: "bi bi-discord",
  twitter: "bi bi-twitter",
  facebook: "bi bi-facebook"
};

export function makeAtagsForAuthorsSocials(data) {
  const style = document.createElement("style");
  style.innerHTML = /*css*/ `
            newspost-component .text-resize-icons{
            font-size: calc(0.4rem + 1vw);
                top: 3px;
                left: 3px;
                position: absolute;
            }`;
  document.body.appendChild(style);
  let socialsHTML = '<div class="social-icons text-resize-icons" style="color: #ffffff;">';
  Object.entries(data).forEach(([platform, url]) => {
    if (!url || url === "N/A") return;
    socialsHTML += `
        <a href="${url}" target="_blank" rel="noopener noreferrer">
          <i class="bi bi-${authorSocialIcons[platform]}"></i>
        </a>`;
  });
  return socialsHTML + '</div>';
}

export function makeAtagsForGlobalSocials() {
  let socialsHTML = '';
  Object.entries(globalSocials).forEach(([platform, url]) => {
    socialsHTML += /*html*/ `
    <style>
  /* Footer Styling */
  footer-container .somedesign {
    color: #ffffff;
    margin: 5px;
    font-size: calc(1rem + 1vw);
  }

  footer-container .somedesign:hover {
    color: #B22A23;
    cursor: pointer;
  }

  /* News Post Social Buttons */
  newspost-component .follow-us .btn-news-socials {
    border-radius: 50%;
    width: calc(1.7rem + 1.7vw);
    height: calc(1.7rem + 1.7vw);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(0.4rem + 0.8vw);
    border: 2px solid #4CAF50; /* Red outline for buttons */
    transition: all 0.4s ease;
    color: #eaeaea !important;
    background: none;
  }

  newspost-component .follow-us .btn-news-socials:hover {
    color: #ffffff;
    background-color: #4CAF50; /* Red background on hover */
    transform: scale(1.2); /* Slight zoom effect */
    box-shadow: 0px 0px 15px 3px rgba(59, 255, 85, 0.6); /* Red glowing effect */
  }

  newspost-component .follow-us .btn-news-socials i {
    transition: transform 0.4s ease;
  }

  newspost-component .follow-us .btn-news-socials:hover i {
    transform: rotate(360deg); /* Icon rotation on hover */
  }
</style>

<a href="${url}" target="_blank" rel="noopener noreferrer" class="btn-news-socials me-2">
    <i class="bi bi-${platform} somedesign"></i>
</a>
`;
  });
  return socialsHTML;
}