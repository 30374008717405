import { BaseComponent } from "@symbiotejs/symbiote";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { newsCarouselForAuthors } from "../../utilities/general/newsCarouselForAuthors";
import { zoomIconsListener } from "../../utilities/general/zoomIconsListener";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { makeAtagsForAuthorsSocials } from "../../utilities/general/socialIcons";

const noAuthorImg = require('../../global-assets/hoodieman.webp');

export class AuthorsComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('authors-component', AuthorsComponent.initiator)
    document.querySelector('.titleAuthors').innerText = I18n.translateString("authorsTranslations.title");
  }

  static initiator() {
    if (document.querySelectorAll('#authors-container').length !== 0) {
      getDataFrontend('savedAuthors', 'getAuthors', 'fromAuthorsComponent', function () { // Callback function
        if (window.savedNews === undefined) {
          getDataFrontend('savedNews', 'getNews', 'fromAuthorsComponent', function () { // Callback function
            AuthorsComponent.updateAuthors();
          });
        } else {
          AuthorsComponent.updateAuthors();
        }
      });
    }


  }

  static updateAuthors() {
    const authorsContainer = document.getElementById('authors-container');
    authorsContainer.innerHTML = '';
    let html = '';
    Object.keys(window.savedAuthors).forEach(entry => {

      let author = window.savedAuthors[entry];
      const authorName = author.name;
      const authorFullName = author.fullName;
      const authorSocials = author.socials;
      const authorPhoto = (author.photo === "" || author.photo === "N/A") ? noAuthorImg : author.photo;

      const newshtml = newsCarouselForAuthors(authorName, author);

      html += /*html*/ `
      <div class="author-card row w-100 mt-3 color-lightdark rounded-top ${author.newsPosts.length === 0 ? 'd-none' : ''}">
          <div class="col-12 d-inline-flex justify-content-start align-items-center w-100 p-0 my-3">
              <h5 class="mb-0 ms-3 me-2 text-resize-authors-name">
                  ${authorFullName} (${authorName})
              </h5>

                  ${makeAtagsForAuthorsSocials(authorSocials)}

          </div>

          <div class="col-lg-2 col-md-12 col-12 d-flex flex-column align-items-center justify-content-center author-photo-container p-0">
              <a href="/${I18n.getUsersPreferredLanguage()}/author/${authorName}" 
                class="img-circle-container-author-photo d-flex align-items-center clickable" 
                id="author-${authorName}">
                  <img src="${authorPhoto}" alt="Author Photo" class="img-fluid mx-auto d-block">
              </a>
          </div>

          <div class="col-lg-10 col-md-12 col-12 pb-1 p-1 rounded-bottom ${author.newsPosts.length === 0 ? 'd-none' : ''}">
              <div class="position-relative">
                  <div id="articleCarousel-${authorName}" class="carousel slide mt-3" data-bs-ride="carousel">
                      <div class="carousel-inner">
                          ${newshtml}
                      </div>
                  </div>
              </div>
          </div>

      </div>
  `;

    });

    document.querySelector('#authors-container').innerHTML = html;
    zoomIconsListener(document.querySelectorAll('.author-card-img-top'));

  }
}

AuthorsComponent.template = /*html*/ `
<style>


  authors-component .title-text-rework {
    color: #fff;
    text-align: center;
  }

  authors-component .img-circle-container-author-photo {
    width: 100%;
    height: auto;
  }

  authors-component .img-circle-container-author-photo img {
    max-width: 90%;
    max-height: 90%;
  }

  authors-component .text-resize-authors-name {
    font-size: calc(0.8rem + 0.3vw);
    font-weight: 800;
    color: #ffffff;
  }



  @media only screen and (max-width: 992px) {
    authors-component .img-circle-container-author-photo {
      width: 20%;
      height: auto;
    }

    authors-component .img-circle-container-author-photo img {
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>

  <div class="container">
    <h2 class="titleAuthors title-text-rework mt-3"></h2>
    <div class="container-fluid d-flex flex-column justify-content-center align-content-center align-items-center" id="authors-container">
    </div>
  </div>

`;

AuthorsComponent.reg('authors-component');