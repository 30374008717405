export function zoomIconsListener(elements, imageSelector) {
    // Check if a <style> tag already exists inside <body>
    let styleTag = document.body.querySelector('#zoom-styles');

    // If not, create one
    if (!styleTag) {
        styleTag = document.createElement('style');
        styleTag.id = 'zoom-styles';
        document.body.appendChild(styleTag);
    }

    // Define the CSS rules
    styleTag.textContent = /*css*/ `
        .zoom {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
        }
        .unzoom {
            transform: scale(1);
            transition: all 0.5s ease-in-out;
        }
    `;

    // Attach event listeners to elements
    if (!imageSelector) {
        elements.forEach(element => {
            element.addEventListener('mouseenter', () => {
                element.classList.add('zoom');
                element.classList.remove('unzoom');
            });

            element.addEventListener('mouseleave', () => {
                element.classList.remove('zoom');
                element.classList.add('unzoom');
            });
        });
    } else {
        elements.forEach(element => {

            element.addEventListener('mouseenter', (evt) => {
                evt.target.querySelector(imageSelector).classList.add('zoom');
                evt.target.querySelector(imageSelector).classList.remove('unzoom');
            }, false)
            element.addEventListener('mouseleave', (evt) => {
                evt.target.querySelector(imageSelector).classList.add('unzoom');
                evt.target.querySelector(imageSelector).classList.remove('zoom');
            }, false);
        });
    }
}
