import { globalSocials } from "../../utils/globals.js";

export const portugueseTranslation =
{
    "translationLanguageInLocal": `Português Brasileiro`,
    "metaTags": {
        "keyword1": `Transmissão ao vivo de Esports`,
        "keyword2": `Torneios de Esports`,
        "keyword3": `Esports de League of Legends`,
        "keyword4": `Torneios de CS:GO / Counter-Strike`,
        "keyword5": `Dota 2 TI (The International)`,
        "keyword6": `Análise de partidas de Esports com IA e especialistas`,
        "keyword7": `Previsões de partidas de Esports e dicas de apostas`,
        "keyword8": `Notícias e artigos de Esports`,
        "singleMatchTitleLive": `{{team1}} vs {{team2}} | Ao vivo: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} vs {{team2}} | Resultados: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleUpcoming": `Próximo: {{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchDescriptionLive": `{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} em {{tournament}}. Assista ao vivo agora! A partida começou em {{date}}. Obtenha estatísticas, previsões e análise de IA no MyEsports!`,
        "singleMatchDescriptionFinished": `{{game}}: {{teamA}} enfrentou {{teamB}} em {{tournament}}. A partida terminou em {{date}} com o resultado {{score1}} - {{score2}}. Obtenha estatísticas, previsões e análise de IA no MyEsports!`,
        "singleMatchDescriptionUpcoming": `{{game}}: {{teamA}} enfrenta {{teamB}} em {{tournament}}. A partida começa em {{date}}. Obtenha estatísticas, previsões e análise de IA no MyEsports! Assista à próxima partida ao vivo aqui!`,
        "singleMatchDescriptionFallback": `{{game}}: {{teamA}} enfrenta {{teamB}} em {{tournament}}. A partida começa em {{date}}. Obtenha estatísticas, previsões e análise de IA no MyEsports! Assista ao vivo!`,
        "singleTournamentTitle": `Torneio de {{game}} – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `Explore as últimas partidas e resultados do {{tournament}} no MyEsports! Torneio de {{game}} Nível {{tier}} com {{opponents}} e muito mais!`,
        "singleAuthorTitle": `{{author}} - Autor MyEsports`,
        "matchesTitle": `Partidas de Esports – Resultados ao Vivo e Resultados | MyEsports`,
        "matchesDescription": `Mantenha-se atualizado com os últimos resultados de partidas de esports e jogos futuros no MyEsports. Obtenha resultados ao vivo, horários, análises, dicas e estatísticas de equipes em CS2, Dota 2, LoL e muito mais!`,
        "tournamentsTitle": `Torneios de Esports – Eventos Futuros e Passados | MyEsports`,
        "tournamentsDescription": `Descubra os maiores torneios de esports no MyEsports! Obtenha horários, resultados, premiações e classificações de equipes para CS2, Dota 2, LoL, Valorant e muito mais!`,
        "newsTitle": `Notícias e Artigos de Esports – Últimas Atualizações e Insights | MyEsports`,
        "newsDescription": `Mantenha-se atualizado com as últimas notícias, artigos e insights de esports no MyEsports! Obtenha atualizações sobre CS2, Dota 2, LoL, Valorant e muito mais, além de análises e entrevistas de especialistas!`,
        "authorsTitle": `Conheça Nossos Autores de Esports – Notícias, Previsões e Dicas | MyEsports`,
        "authorsDescription": `Descubra os especialistas por trás do MyEsports! Conheça nossos redatores de esports trazendo notícias, artigos aprofundados e notícias, previsões e dicas para CS2, Dota 2, LoL e muito mais!`,
        "bookmakersTitle": `Top 10 Sites de Apostas de Esports (Casinos) – Afiliados e Ofertas Confiáveis | MyEsports`,
        "bookmakersDescription": `Explore os parceiros de apostas confiáveis do MyEsports! Encontre os melhores sites de apostas de esports com ofertas exclusivas, bônus e plataformas seguras para CS2, Dota 2, LoL e muito mais!`,
        "skinbookmakersTitle": `Troca de Skins de Esports – Parceiros Confiáveis para Skins e Cases | MyEsports`,
        "skinbookmakersDescription": `Descubra os parceiros de troca de skins confiáveis do MyEsports! Compre, venda, troque e aposte skins e cases com segurança para CS2, Dota 2, Rust e muito mais. Encontre os melhores marketplaces e ofertas exclusivas!`,
        "predictionsTitle": `Previsões de Esports e Dicas de Apostas – Análise de Especialistas | MyEsports`,
        "predictionsDescription": `Obtenha previsões de apostas de esports e dicas de especialistas no MyEsports! Encontre análises de partidas precisas de nossos especialistas e IA, insights de probabilidades e estratégias vencedoras para CS2, Dota 2, LoL e muito mais!`,
        "termsOfUseTitle": `Termos de Uso – Regras e Acordo do Usuário | MyEsports`,
        "termsOfUseDescription": `Leia os Termos de Uso do MyEsports. Saiba mais sobre acordos de usuário, regras e políticas para usar nossa plataforma, incluindo conteúdo de esports, afiliados de apostas e muito mais.`,
        "privacyPolicyTitle": `Política de Privacidade – Proteção e Segurança de Dados | MyEsports`,
        "privacyPolicyDescription": `Saiba como o MyEsports protege seus dados. Leia nossa Política de Privacidade sobre informações do usuário, medidas de segurança e conformidade com o GDPR para uma experiência segura.`,
        "faqTitle": `FAQ – Perguntas Frequentes e Suporte | MyEsports`,
        "faqDescription": `Encontre respostas para perguntas comuns sobre o MyEsports. Leia nosso FAQ para cobertura de esports, afiliados de apostas, previsões, troca de skins e mais tópicos de suporte.`,
        "streamersTitle": `Streamers de Esports – Canais ao Vivo e Conteúdo de Jogos | MyEsports`,
        "streamersDescription": `Descubra os principais streamers de esports de todo o mundo no MyEsports! Assista a jogabilidade ao vivo, streams de jogadores profissionais e conteúdo exclusivo de nossos criadores parceiros.`,
        "homeTitle": `MyEsports – Seu Centro Definitivo de Esports para Notícias, Partidas e Dicas de Apostas`,
        "homeDescription": `Fique à frente no mundo dos esports com o MyEsports! Obtenha as últimas notícias, resultados de partidas, placares ao vivo, dicas de apostas, previsões, atualizações de torneios para CS2, Dota 2, LoL e muito mais!`,
    },
    "homePage": {
        "latestAnalyses": `ÚLTIMAS ANÁLISES DE IA`
    },
    "networkGuard": {
        "title": `Oops!`,
        "description": `A página que você está procurando não existe!`,
    },
    "navMainMenu": {
        "Home": `Início`,
        "Tournaments": `Torneios`,
        "Matches": `Partidas`,
        "Giveaways": `Sorteios`,
        "Casinos": `Casas de Apostas`,
        "AboutUs": `Sobre nós`,
        "Streamers": `Streamers`,
        "News": `Notícias`,
        "Predictions": `Previsões`,
        "navDesktopTitle": `MyEsports`,
    },
    "hotmatchTranslations": {
        "playerNationalities": `Jogadores de {{countries}} competem`,
        "playerAges": `O jogador mais jovem tem {{Age}} anos`,
        "viewers": `{{maxviewers}} assistindo agora!`,
        "alternativeTimeLineHours": `Jogo {{RGN}} em andamento. Começou {{hourz}} hora(s) e {{minutez}} minuto(s) atrás`,
        "alternativeTimeLineMinutes": `Jogo {{RGN}} em andamento. Começou {{minutez}} minuto(s) atrás`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>QUENTE`,
        "noLiveMsg": `<h2>Aguarde um momento, a ação está prestes a começar!</h2><p>Parece que a ação de {{game}} está fazendo uma pausa.</p>`,
    },
    "matchTranslations": {
        "title": `{{team1}} vs {{team2}} | {{tournament}}`,
        "description": `Assista {{teamA}} enfrentar {{teamB}} pelo {{tournament}} no MyEsports | Confira a análise de IA e o que nossos especialistas têm a dizer sobre esta partida!`,
        "h2": `Este é o sub-parágrafo de uma partida`,
        "body": `Este é o conteúdo principal de uma partida`,
        "matchMatchUpcoming": `{{minutes}} min restantes`,
        "matchMatchLive": `AO VIVO`,
        "matchMatchFinished": `Finalizado`,
        "matchMatchPostponed": `Adiado`,
        "matchMatchCanceled": `Cancelado`,
        "matchMatchAboutTo": `Prestesa começar`,
        "homeMatchDHM": `{{days}}d {{hours}}h {{minutes}}m`,
        "homeMatchHM": `{{hours}}h {{minutes}}m`,
        "homeMatchM": `{{minutes}}m`,
        "homeMatchLive": `AO VIVO`,
        "homeMatchAboutTo": `Começando`,
        "pillLive": `AO VIVO`,
        "pillUpcoming": `PRÓXIMOS`,
        "pillFinished": `FINALIZADOS`,
        "pillPredictions": `PREVISÕES`,
        "pillAll": `TODOS`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> FAVORITOS`,
        "bestofX": `Melhor de {{gamesNumber}}`,
        "bestof1": `Melhor de 1`,
        "bestof2": `Melhor de 2`,
        "allGamesPlayed": `{{gamesNumber}} jogos`,
        "allGamesPlayedMicro": `J{{gamesNumber}}`,
        "firstToX": `Primeiro a {{gamesNumber}}`,
        "showMoreMatches": `Ver Mais`,
        "emptyGameFilterMessage": `Não há partidas de {{game}} disponíveis no momento.`,
        "emptyStateFilterMessage": `Não há partidas de {{game}} disponíveis com os filtros selecionados.`,
        "emptyPredictionFilterMessage": `Não há previsões de {{predGame}} disponíveis para a data selecionada.`,
        "emptyAnalysisFilterMessage": `Não há análises recentes {{game}}`,
        "searchPlaceholder": `Procure por torneios ou equipes específicas...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames }
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `Análise de Partida com IA`,
        "tipTranslationTitle": `Dicas dos Especialistas`,
        "team1players": `Jogadores`,
        "team2players": `Jogadores`,
        "ageLabel": `Idade: `,
        "positionLabel": `Posição: `,
        "h2hNoRecentMsg": `Não há partidas recentes entre essas equipes`,
        "matchHistory": `Histórico de Partidas`,
        "h2hPill": `Confronto Direto`,
        "badgeWon": `V`,
        "badgeLost": `D`,
        "badgeDraw": `E`,
        "noVideoMessage": `Nenhum vídeo disponível para esta partida`,
        "analysis": `Nossa IA 'PlayPulse' analisará esta partida nas 24 horas anteriores ao início. Volte mais próximo do horário de início da partida para conferir a análise completa!`,
        "whatDoesAiThink": `Insights da IA sobre esta partida:`,
        "aiAnalysis": `ANÁLISE DA IA`,
        "betNow": `Aposte Agora`,
        "oddsDraw": `Empate`,
        "predictToWin": `para vencer`,
        "predictDraw": `Empate`,
        "predictExactScore": `Placar exato`,
        "predictOverUnder": `jogos`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>Nenhum Artigo Recente</h2><p>Infelizmente, não há artigos recentes de {{newsGame}}.</p>`,
        "newsTitle": `ARTIGOS`,
        "writtenAt": `Escrito em`,
        "footerArticle": `🔥 Gostou do Artigo? 🔥`,
        "footerArticleFollow": `Não perca nada! Siga-nos nas redes sociais para mais atualizações emocionantes!`,
        "newsSeeAll": `Ver Todos`,
        "seeMore": `Ver Mais...`,
        "goBack": `Voltar para Notícias`,
        "tournamentNews": `Torneios`,
        "rosterNews": `Elencos`,
        "gameNews": `Geral`
    },
    "footer": {
        "about": `Bem-vindo ao MyEsports, seu destino completo para todos os entusiastas de eSports. Nascidos de um profundo amor e paixão pelo mundo dos jogos, criamos um espaço onde a comunidade de eSports pode encontrar tudo: desde transmissões ao vivo de partidas e eventos até previsões, análises e artigos para todos os tipos de jogos.`,
        "followUs": `Siga-nos`,
        "usefulLinks": `Links Úteis`,
        "contactUs": `Contate-nos`,
        "aboutUs": `Sobre Nós`,
        "eula": `Termos de Uso`,
        "privacy": `Política de Privacidade`,
        "faq": `Perguntas Frequentes`,
        "Authors": `Autores`
    },
    "tournamentsTranslations": {
        "noDateMsg": `Data de término a ser definida`,
        "tier": `Nível`,
        "emptyTournamentFilterMessage": `Não há torneios de {{game}} disponíveis com os filtros de status selecionados.`,
        "pillOngoing": `EM ANDAMENTO`,
        "pillUpcoming": `PRÓXIMOS`,
        "pillFinished": `FINALIZADOS`,
        "pillAll": `TODOS`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>Em Andamento</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Finalizado</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Começa em {{days}} dias</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Começa em {{days}} dia</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Começa em {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Começa em {{minutes}}m</span>`,
        "noPrizePoolAvailable": `N/D`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `Equipes competem`,
        "hotTournamentsTitle": `TORNEIOS EM ALTA`,
        "hotTournamentFixedLabel": `EM ANDAMENTO`,
        "noHotTournamentsMsg": `<h2>Nenhum Torneio Ao Vivo no Momento</h2><p>Infelizmente, não há torneios ao vivo de {{game}} no momento.</p>`,
        "noHotTournamentsButton2": `Ver Todos`,
        "clickHotTournaments": `Clique para ver mais informações.`
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Equipes Competem: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Jogadores Competem: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Premiação: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Premiação: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Premiação: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `Nível:`,
        "winnerPost": `Vencedor do Torneio:`,
        "startPost": `Data de Início:`,
        "endPost": `Data de Término:`,
        "noDateMsgPost": `Data de término a ser definida`,
        "ladderWins": `Vitórias`,
        "ladderLosses": `Derrotas`,
        "ladderTies": `Empates`,
        "ladderGamesWon": `Jogos Vencidos`,
        "ladderGamesLost": `Jogos Perdidos`,
        "ladderTieBreaker": `Desempates`,
        "upperBracket": `Chave Superior`,
        "lowerBracket": `Chave Inferior`,
        "grandFinal": `Grande Final`,
        "semiFinal": `Semifinal`,
        "quarterFinal": `Quartas de Final`,
        "roundBrackets": `Rodada`,
        "matchBrackets": `Partida`,
        "laddersMatchesTitle": `Partidas`,
        "week": `Semana`,
        "liveWeek": `AO VIVO`
    },
    "predictionsTranslations": {
        "predTitle": `PREVISÕES`,
        "comingSoon": `Em breve`,
        "today": `Hoje`,
        "title": `Previsões de Esports`,
        "subtitle": `No MyEsports, estudamos cada partida de perto para trazer as melhores dicas de apostas em esports. Nossos especialistas analisam as equipes, jogadores e estratégias para ajudá-lo a tomar as melhores decisões de apostas.`,
        "time": `Horário`,
        "date": `Data`,
        "match": `Partida`,
        "tournament": `Torneio`,
        "tipster": `Tipster`,
        "betButton1": `Faça sua aposta agora em `,
        "odd": `@`,
        "noPredictionForThisDate": `Nenhuma previsão para esta data ainda, volte mais tarde.`,
        "statsAvgOdds": `Odds Médias`,
        "statsEarn": `Ganho`,
        "statsBets": `Apostas`,
        "statsOpenBets": `Apostas Abertas`,
        "authStats": `Estatísticas de Previsão`,
        "articles": `Artigos`,
        "last10": `Previsões recentes`,
        "authBio": `Biografia`,
        "bioComing": `Biografia em breve`,
        "showMorePredictions": `Mostrar Mais`,
        "EarnExplain": `Os lucros são calculados com base nas previsões que foram encerradas. Cada tipster começa do 0, com cada previsão correta adicionando as odds ganhas x1 ao seu lucro total. Por exemplo, em uma previsão correta com odds de 1.80, o lucro do tipster aumenta em 0.80. No caso de uma previsão incorreta, 1.00 é subtraído do lucro total.`,
        "WinrateExplain": `A taxa de vitórias é calculada com base nas previsões que foram encerradas. É a porcentagem de previsões corretas do número total de previsões feitas.`,
        "RoiExplain": `O ROI (Retorno sobre o Investimento) é calculado com base nos lucros do tipster em relação às suas apostas totais. Essencialmente, o ROI representa o lucro líquido do tipster. Por exemplo, se eles fizeram 50 apostas com uma unidade cada e o ROI for de 20%, eles ganharam 10 unidades.`,
        "AvgOddsExplain": `As odds médias são calculadas com base nas odds das previsões que foram encerradas. É a soma das odds dividida pelo número total de previsões.`,
        "plainPrediction": `Previsão`,
        "statisticPeriod": `Período`,
        "alltimeStats": `Todos os Tempos`
    },
    "banners": {
        "desktopLeft": `pt_desktop_left`,
        "desktopRight": `pt_desktop_right`
    },
    "streamerTranslations": {
        "cardFollowers": `Seguidores`,
        "cardInfo": `{{user}} está {{status}} no momento.`,
        "cardViewers": `Espectadores!`,
        "detailsInformation": `Informações do Streamer`,
        "detailsName": `Nome Completo`,
        "detailsNationality": `Nacionalidade`,
        "detailsAge": `Idade`,
        "detailsGame": `Jogos`,
        "detailsRole": `Função`,
        "detailsTeam": `Equipe`,
        "detailsSignature": `Herói de Assinatura`,
        "accordionHowTo": `<strong>-Como posso me tornar um streamer no MyEsports?</strong>`,
        "accordionHowToReply": `Para se tornar um streamer no MyEsports, você pode se inscrever entrando em nosso <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">servidor Discord</a> e entrando em contato com nossa equipe ou preencher este <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">formulário</a>.`,
        "accordionEarn": `<strong>-O que ganho ao me tornar um streamer no MyEsports?</strong>`,
        "accordionEarnReply": `Ser apresentado no MyEsports lhe dará a oportunidade de aumentar seu público e expandir seu canal. Você também terá a chance de ser destaque em nossos artigos e redes sociais.`,
        "accordionGraphics": `<strong>-Receberei gráficos para meu canal?</strong>`,
        "accordionGraphicsReply": `Sim, forneceremos gráficos para seu canal, bem como suporte e orientação sobre como usá-los e melhorar sua experiência de streaming.`,
        "accordionHowToStream": `<strong>-Sou novo no streaming, ainda posso participar? Como começo?</strong>`,
        "accordionHowToStreamReply": `Claro! Recebemos streamers de todos os níveis. Você pode começar entrando em nosso <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">servidor Discord</a> e entrando em contato com nossa equipe para obter mais informações.`,
        "accordionViewers": `<strong>-Quantos espectadores preciso ter para participar?</strong>`,
        "accordionViewersReply": `Recebemos streamers de todos os tamanhos. Se você tiver 1 ou 10.000 espectadores, você é bem-vindo para se juntar ao MyEsports!`
    },
    "authorsTranslations": {
        "title": `Conheça a Equipe de Autores do MyEsports`
    },
    "bookmakersPostTranslations": {
        "odds": `Odds`,
        "varietyOfEsports": `Variedade de Esports`,
        "liveSupport": `Suporte ao Vivo`,
        "liveStreaming": `Transmissão ao Vivo`,
        "payments": `Pagamentos`,
        "fullReview": `Análise Completa`,
        "esports": `Esports`,
        "register": `APOSTE AGORA`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `Análise Completa`,
        "register": `APOSTE AGORA`
    },
    "bookmakersSkinList": {
        "widgetTitle": `Plataformas de Skins`,
        "widgetButton": `Mais`,
        "playNow": `Jogar!`,
        "introSection": `<h2 class="title-section">Principais Sites de Apostas de Esports para Participar em 2024: Descubra as Melhores Skins de Casas de Apostas de Esports</h2><p>Com o crescimento explosivo dos esports, as principais skins de apostas surgiram para atender à demanda, oferecendo mercados extensos para jogos populares como Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends e Call of Duty. Esses jogos trazem um fluxo contínuo de torneios e ligas emocionantes, proporcionando aos apostadores diversas opções – desde vencedores diretos de partidas até apostas específicas de jogos.</p><p>Em <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, analisamos cuidadosamente uma variedade de principais plataformas de skins de apostas de esports para ajudá-lo a encontrar a skin perfeita para suas necessidades de apostas.</p>`,
        "sectionTitle": `Onde Apostar em Esports: Melhores Skins e Bônus de Apostas de Esports`,
        "pleaseBetResponsibly": `Aposte com Responsabilidade`,
        "reviewMethodology": `<h2 class="title-section">Nossa Metodologia de Análise</h2><p>Em <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, nossa missão é direta – guiá-lo na seleção da skin de casa de apostas ideal para suas apostas em esports. Avaliamos cada skin com base na facilidade de uso, variedade de mercados de esports e recursos de segurança para garantir sua segurança ao depositar ou sacar fundos.</p><p>Também avaliamos o licenciamento para verificar se a skin de casa de apostas opera legalmente em sua região. Apenas aqueles que atendem aos nossos altos padrões recebem nosso selo de aprovação. Apresentamos bônus e promoções exclusivas de esports para aprimorar ainda mais sua experiência de apostas.</p><p>Nossos critérios de análise abrangem várias áreas, incluindo facilidade de uso, ofertas de mercado e métodos de pagamento. Cada skin de casa de apostas é minuciosamente avaliada em oito categorias principais, abrangendo desde design e funcionalidade até atendimento ao cliente. Confira nossas análises detalhadas para explorar o desempenho de cada skin de casa de apostas nessas áreas críticas.</p>`,
        "review": `Análise`,
        "betNow": `APOSTE AGORA`,
        "noBookmakerSkinsMsg": `<h2>Nenhuma plataforma de skins disponível para sua região e idioma.</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `Casas de Apostas`,
        "widgetButton": `Mais`,
        "playNow": `Jogar!`,
        "introSection": `<h2 class="title-section">Principais Sites de Apostas de Esports para Participar em 2024: Descubra as Melhores Casas de Apostas de Esports</h2><p>Com o crescimento explosivo dos esports, os principais sites de apostas surgiram para atender à demanda, oferecendo mercados extensos para jogos populares como Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends e Call of Duty. Esses jogos trazem um fluxo contínuo de torneios e ligas emocionantes, proporcionando aos apostadores diversas opções – desde vencedores diretos de partidas até apostas específicas de jogos.</p><p>Em <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, analisamos cuidadosamente uma variedade de principais plataformas de apostas de esports para ajudá-lo a encontrar o site perfeito para suas necessidades de apostas.</p>`,
        "sectionTitle": `Onde Apostar em Esports: Melhores Sites e Bônus de Apostas de Esports`,
        "pleaseBetResponsibly": `Aposte com Responsabilidade`,
        "reviewMethodology": `<h2 class="title-section">Nossa Metodologia de Análise</h2><p>Em <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, nossa missão é direta – guiá-lo na seleção da casa de apostas ideal para suas apostas em esports. Avaliamos cada site com base na facilidade de uso, variedade de mercados de esports e recursos de segurança para garantir sua segurança ao depositar ou sacar fundos.</p><p>Também avaliamos o licenciamento para verificar se a casa de apostas opera legalmente em sua região. Apenas aqueles que atendem aos nossos altos padrões recebem nosso selo de aprovação. Apresentamos bônus e promoções exclusivas de esports para aprimorar ainda mais sua experiência de apostas.</p><p>Nossos critérios de análise abrangem várias áreas, incluindo facilidade de uso, ofertas de mercado e métodos de pagamento. Cada casa de apostas é minuciosamente avaliada em oito categorias principais, abrangendo desde design e funcionalidade até atendimento ao cliente. Confira nossas análises detalhadas para explorar o desempenho de cada casa de apostas nessas áreas críticas.</p>`,
        "review": `Análise`,
        "betNow": `APOSTE AGORA`,
        "noBookmakersMsg": `<h2>Nenhuma casa de apostas disponível para sua região e idioma.</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Oops! Parece que você está usando um bloqueador de anúncios.</strong></p><p>Você está perdendo algum conteúdo excelente que temos para você. Não são anúncios, mas recursos úteis. Considere desativar seu bloqueador de anúncios.</p><button id="close-button">Entendi!</button>`
    },
    "discordTemplate": {
        "titleMain": `Precisa de Ajuda?`,
        "titleSub": `Junte-se ao nosso Discord agora!`
    }
}
