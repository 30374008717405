import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { I18n } from "../../utilities/i18n/i18n";
import { translations } from "../../../functions/across-stack/i18n/i18n";
import { emptyMessage } from "../../utilities/general/emptyMessage";
import { getMatches } from "../../utilities/firebase-assistant/frontend-transition";
import { reversegameIdFilter, gameIdImg } from "../../utilities/general/gameIdImgMapping";
import { getMatchName } from '../../../functions/across-stack/utils/globals';
import { startCountdown } from "../../utilities/general/countDownForMatches";
import { PlaceholderMatchesWidget } from "../placeholder-matcheswidget/placeholder-matcheswidget";



const noImageFound = require('../../global-assets/no image found.png');
const noImageFoundPlayer = require('../../global-assets/hoodieman.webp');


export class WidgetMatchesComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('widget-matches', WidgetMatchesComponent.initiator);
    window.WidgetMatchesComponent = WidgetMatchesComponent;
  }



  static initiator() {

    getMatches('fromWidgetMatches', () => {

      if (document.querySelectorAll('#widget-matches-element').length !== 0) {
        console.debug('I am running the widget-matches component call back!');
        WidgetMatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny());

        const upcomingMatchesDiv = document.querySelector('#widget-matches-element');
        if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
          emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
        }
        const showMoreButton = /*html*/     `<a id="show-more-button" href="/${I18n.getUsersPreferredLanguage()}/matches" class="show-more-widget btn" >View All</a>`;
        document.querySelector('#widget-matches-element').insertAdjacentHTML('beforeend', showMoreButton);
        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
      } else {
        console.debug('The widget-matches component call back is supposed to run but I am not on the correct page!!!')
      }
    });
    GameNavbar.subscribeToGameFilterChanges('fromWidgetMatchesComponent', (eventId) => {

      if (document.querySelectorAll('widget-matches').length !== 0) // if our component exists on the page
      {

        WidgetMatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny());

        const upcomingMatchesDiv = document.querySelector('#widget-matches-element');
        if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
          emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
        }
        const showMoreButton = /*html*/     `<a id="show-more-button" href="/${I18n.getUsersPreferredLanguage()}/matches" class="show-more-widget btn" >View All</a>`;
        document.querySelector('#widget-matches-element').insertAdjacentHTML('beforeend', showMoreButton);
        document.querySelector('#show-more-button').innerHTML = I18n.translateString("matchTranslations.showMoreMatches");
      }
    })

  }

  static updateMatches(gameId) {

    let counter = 0;
    document.querySelector('#widget-matches-element').innerHTML = ''

    const sortedMatchIds = Object.keys(window.savedMatches)
      .filter((id) => {
        const match = window.savedMatches[id];
        // Keep only if status is "running" or "not_started"
        if (!(match.status === "running" || match.status === "not_started")) return false;

        // If no gameId or reversegameIdFilter[gameId] is undefined => keep
        if (!gameId || reversegameIdFilter[gameId] === undefined) return true;

        // Otherwise keep only if match.videogame.id matches
        return reversegameIdFilter[gameId] === match.videogame.id;
      })
      .sort((a, b) => {
        const matchA = window.savedMatches[a];
        const matchB = window.savedMatches[b];
        const dateA = new Date(matchA.begin_at || matchA.scheduled_at);
        const dateB = new Date(matchB.begin_at || matchB.scheduled_at);
        return dateA - dateB;
      });

    for (let i = 0; i < sortedMatchIds.length; i++) {
      const matchCode = sortedMatchIds[i]
      const data = window.savedMatches[matchCode]

      const gameStatus = data.status;
      const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);

      // If a gameId is provided and it doesn't match the current game's id, skip this match


      if (!data.opponents || data.opponents.length < 2) {
        continue;
      } // if no opponents due to tourney format ongoing, skip the match

      const team1name = data.opponents[0].opponent.name;
      const team2name = data.opponents[1].opponent.name;
      const team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : data.winner_type === "Team" ? noImageFound : noImageFoundPlayer;
      const team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : data.winner_type === "Team" ? noImageFound : noImageFoundPlayer;
      const tournamentName = `${data.league.name} ${data.serie.full_name}`;
      const gameLogoWidgetHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="img-fluid  d-block game-logo-widget" >`;
      const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;

      let team1Odds = data.thunderpickData && data.thunderpickData.team1Odds !== 1 ? data.thunderpickData.team1Odds : data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? data.thunderpickData.team1StartingOdds : null;
      let team2Odds = data.thunderpickData && data.thunderpickData.team2Odds !== 1 ? data.thunderpickData.team2Odds : data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? data.thunderpickData.team2StartingOdds : null;
      if (team1Odds && team2Odds) {
        team1Odds = parseFloat(team1Odds).toFixed(2);
        team2Odds = parseFloat(team2Odds).toFixed(2);
      }

      const countdownElementIdhome = `countdownhome-${matchCode}`;
      const matchElementIdhome = `matchfromhome-${matchCode}`;
      const matchName = getMatchName(data);

      const matchesCardinalityThreshold = ((window.innerHeight > window.innerWidth)) ? 3 : 13;
      if (counter < matchesCardinalityThreshold) {
        const matchHtml = /*html*/ `
                                    <a id="${matchElementIdhome}" href="/${I18n.getUsersPreferredLanguage()}/match/${matchCode}/${matchName}" class="match-item-widget d-flex flex-column">
                                        <div class="match-header-widget rounded-top color-dark d-flex justify-content-between align-items-center">
                                            <div class="tournament-info-widget d-flex align-items-center">
                                            ${gameLogoWidgetHTML}
                                            <span class="text-resize-tournament-widget text-truncate ms-1" data-bs-toggle="tooltip" data-bs-placement="top" title="${tournamentName}">${tournamentName}</span>
                                            </div>
                                            <span id="${countdownElementIdhome}" class="badge-widget" aria-label="${countdownElementIdhome}"></span>
                                        </div>

                                        <div class="teams-odds-widget d-flex justify-content-between align-items-center">
                                            <div class="team-widget">
                                            <img src="${team1img}" alt="${team1name}" class="img-fluid">
                                            <div class="team-name-widget" data-bs-toggle="tooltip" data-bs-placement="top" title="${team1name}">${team1name}</div>
                                            <div class="team-odd-widget d-flex justify-content-center align-content-center align-items-center ${!team1Odds ? 'd-none' : ''}">${team1Odds}</div>
                                            </div>

                                            <div class="score-widget">${score}</div>

                                            <div class="team-widget">
                                            <img src="${team2img}" alt="${team2name}" class="img-fluid">
                                            <div class="team-name-widget" data-bs-toggle="tooltip" data-bs-placement="top" title="${team2name}">${team2name}</div>
                                            <div class="team-odd-widget d-flex justify-content-center align-content-center align-items-center ${!team2Odds ? 'd-none' : ''}">${team2Odds}</div>
                                            </div>
                                        </div>
                                    </a>
                                `;
        document.querySelector('#widget-matches-element').insertAdjacentHTML('beforeend', matchHtml);
        counter++;
      }
      if (counter === matchesCardinalityThreshold) {
        counter++;
      }

      counter = startCountdown('widget', dateRaw, countdownElementIdhome, matchElementIdhome, gameStatus, counter);

    }

    let element = document.getElementById('placeholder-matches-widget');
    if (element) element.style.display = "none"; // deactivate placeholder upcoming matches


  }
}

WidgetMatchesComponent.template =   /*html*/ `
<style>
widget-matches  .gameFilterNotMsg {
    text-align: center;
    padding: 20px;
    background-color: #161b22;
    border-color: #f5c6cb;
    border-radius: 0.25rem;
  }

widget-matches  .match-item-widget {
    background-color: #1E252E;
    border: none;
    border-radius: 8px;
    padding: 0;
    margin-bottom: calc(0.5rem + 0.5vw);
    text-decoration: none;
    color: inherit;
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

widget-matches  .match-item-widget:hover {
    background-color: #3A4B5C;
    transform: translateY(-3px);
  }

widget-matches  .match-header-widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(0.2rem + 0.5vw);
    padding: 3px;
  }

widget-matches  .match-header-widget .game-logo-widget {
    width: calc(0.2rem + 1vw);
    height: calc(0.2rem + 1vw);
    object-fit: contain;
  }

widget-matches  .text-resize-tournament-widget {
    font-size: calc(0.2rem + 0.5vw);

  }

widget-matches  .tournament-info-widget {
  flex: 1 1 auto;
  min-width: 0;
}

widget-matches  .live-badge-widget {
    background-color: #28A745 !important;
    animation: pulse-widget 2s infinite;
  }

widget-matches  .badge-widget {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: rgba(117, 117, 117, 0.7);
    padding: calc(0.07rem + 0.14vw) calc(0.21rem + 0.28vw);
    border-radius: 8px;
    font-size: calc(0.4rem + 0.3vw);
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.3s;
    flex-shrink: 0;
  }

widget-matches  .badge-widget i {
    margin-right: 4px;
    font-size: calc(0.4rem + 0.3vw);
  }

widget-matches  .badge-widget:hover {
    transform: scale(1.05);
  }

  @keyframes pulse-widget {
    0% {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    }
  }

widget-matches  .teams-odds-widget {
    margin-bottom: calc(0.2rem + 0.5vw);
  }

widget-matches  .team-widget {
    width: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

widget-matches  .team-widget img {
    width: calc(20px + 0.2vw);
    height: calc(20px + 0.2vw);
    margin-bottom: calc(0.2rem + 0.2vw);
    object-fit: contain;
    transition: transform 0.3s;
  }

widget-matches  .team-widget img:hover {
    transform: scale(1.1);
  }

widget-matches  .team-name-widget {
    font-size: calc(0.2rem + 0.5vw);
    font-weight: 600;
    color: #FFFFFF;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    cursor: pointer;
  }

widget-matches  .team-odd-widget {
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    font-size: calc(0.1rem + 0.5vw);
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 700;
    transition: background-color 0.3s;
    text-align: center;
  }

widget-matches  .team-odd-widget:hover {
    background-color: rgba(0, 0, 0, 0.64);
  }

widget-matches  .score-widget {
    width: 20%;
    font-size: calc(0.4rem + 0.5vw);
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    flex-shrink: 0;
  }

widget-matches  .show-more-widget {
    background-color: #FF4655;
    border: none;
    color: #FFFFFF !important;
    font-size: calc(0.4rem + 0.5vw);
    font-weight: 700;
    padding: calc(0.3rem + 0.3vw) calc(0.6rem + 0.6vw);
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    text-align: center;
  }

widget-matches  .show-more-widget:hover {
    background-color: #D73648;
    box-shadow: 0 6px 18px rgba(215, 54, 72, 0.3);
  }

widget-matches  .hidden-important {
    display: none !important;
  }

  @media only screen and (max-width: 991px) {
widget-matches    .text-resize-tournament-widget {
      font-size: calc(0.5rem + 0.3vw);
      max-width: 250px;
    }

widget-matches    .team-name-widget {
      font-size: calc(0.4rem + 1vw);
    }

widget-matches    .team-odd-widget {
      font-size: calc(0.3rem + 1vw);
    }

widget-matches    .match-header-widget .game-logo-widget {
      width: calc(1rem + 0.4vw);
      height: calc(1rem + 0.4vw);
      object-fit: contain;
    }

widget-matches    .score-widget {
      font-size: calc(0.6rem + 1vw);
    }
  }
</style>

<placeholder-matcheswidget></placeholder-matcheswidget>
<div id="widget-matches-element" class="mb-4 text-center"></div>

`;

WidgetMatchesComponent.reg('widget-matches');