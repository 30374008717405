export const csgologo = require("../../components/game-navbar/assets/images/csgologo 150x150.png");
export const dota2 = require('../../components/game-navbar/assets/images/dota2logo 150x150.png');
export const lol = require('../../components/game-navbar/assets/images/lollogo 150x150.png');
export const valorant = require('../../components/game-navbar/assets/images/valorantlogo 150x150.png');
export const fifa = require('../../components/game-navbar/assets/images/fifalogo 150x150.png');
export const fortnite = require('../../components/game-navbar/assets/images/fortnite 150x150.png');
export const hearthstone = require('../../components/game-navbar/assets/images/hearthstone 150x150.png');
export const minecraft = require('../../components/game-navbar/assets/images/minecraft 150x150.png');
export const overwatch = require('../../components/game-navbar/assets/images/overwatch 150x150.png');
export const pubg = require('../../components/game-navbar/assets/images/pubg 150x150.png');
export const r6 = require('../../components/game-navbar/assets/images/r6 150x150.webp');
export const r6dark = require('../../components/game-navbar/assets/images/rainbow six 150x150.png');
export const roblox = require('../../components/game-navbar/assets/images/roblox 150x150.png');
export const rocketleague = require('../../components/game-navbar/assets/images/rocketleague 150x150.png');
export const wow = require('../../components/game-navbar/assets/images/world of warcraft 150x150.png');
export const starcraft = require('../../components/game-navbar/assets/images/starcraft II 150x150.png');
export const cod = require('../../components/game-navbar/assets/images/call of duty 150x150.png');
export const ea = require('../../components/game-navbar/assets/images/EA 150x150.webp');
export const apex = require('../../components/game-navbar/assets/images/ApexLegends 150x150.webp');
export const kingofglory = require('../../components/game-navbar/assets/images/KingOfGlory 150x150.webp');
export const mlegends = require('../../components/game-navbar/assets/images/mobileLegends 150x150.webp');


export const gameIdImg = {
    '3': csgologo,
    '4': dota2,
    '1': lol,
    '25': ea,
    '26': valorant,
    '23': cod,
    '14': overwatch,
    '20': pubg,
    '24': r6,
    '22': rocketleague,
    '29': starcraft,
    '30': starcraft,
    '27': kingofglory,
    '34': mlegends
};

export const gameIdFilter = {
    '3': 'csgo',
    '4': 'dota2',
    '1': 'lol',
    '25': 'ea',
    '26': 'valorant',
    '23': 'cod',
    '14': 'overwatch',
    '20': 'pubg',
    '24': 'r6',
    '22': 'rocketleague',
    '29': 'starcraft',
    '30': 'starcraft',
    '27': 'kingofglory',
    '34': 'mlegends'
};

export const reversegameIdFilter = {
    'csgo': 3,
    'dota2': 4,
    'lol': 1,
    'easports': 25,
    'valorant': 26,
    'cod': 23,
    'overwatch': 14,
    'pubg': 20,
    'r6': 24,
    'rocketleague': 22,
    'starcraft': [29, 30],
    'hearthstone': 999,
    'fortnite': 999,
    'fifa': 999,
    'roblox': 999,
    'minecraft': 999,
    'wow': 999,
    'apex': 999,
    'kingofglory': 27,
    'mlegends': 34,

};

export const gameNames = {
    'csgo': 'Counter-Strike 2',
    'dota2': 'Dota 2',
    'lol': 'League of Legends',
    'ea': 'EA Sports',
    'valorant': 'Valorant',
    'cod': 'Call of Duty',
    'overwatch': 'Overwatch',
    'pubg': 'PUBG',
    'r6': 'Rainbow Six',
    'rocketleague': 'Rocket League',
    'starcraft': 'Starcraft',
    'hearthstone': 'Hearthstone',
    'fortnite': 'Fortnite',
    'fifa': 'FIFA',
    'roblox': 'Roblox',
    'minecraft': 'Minecraft',
    'wow': 'World of Warcraft',
    'apex': 'Apex Legends',
    'kingofglory': 'King of Glory',
    'mlegends': 'Mobile Legends'
};