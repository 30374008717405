import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { PredictionsHomeComponent } from "../predictions-homecomponent/predictions-homecomponent";
import { DiscordTemplate } from "../discord-template/discord-template";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { WidgetBookmakersSkin } from "../widget-bookmakers-skin/widget-bookmakers-skin";
import { MatchesComponent } from "../matches-component/matches-component";
import { PlaceholderMatches } from "../placeholder-matcheswidget/placeholder-matches";

export class MatchesContainer extends BaseComponent {

  initCallback() {
    GridOrchestrator.activateGameFilters();
  }
}

MatchesContainer.template = /*html*/ `
<div class="main-content col-12 col-lg-8 g-0 mt-2">
  <div class="row m-2">
    <placeholder-matches></placeholder-matches>
    <matches-component></matches-component>
  </div>
</div>

<div class="sidebar col-12 col-lg-4 mt-2">
  <div class="row m-2 d-none d-lg-flex">
    <predictions-homecomponent></predictions-homecomponent>
  </div>

  <div class="row m-2">
      <discord-template></discord-template>
  </div>

  <div class="row m-2">
      <widget-bookmakers></widget-bookmakers>
      <widget-bookmakers-skin></widget-bookmakers-skin>
  </div>
</div>
`;

MatchesContainer.reg('matches-container');
