import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { PostAuthorComponent } from "../postauthor-component/postauthor-component";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";
export class PostAuthorContainer extends BaseComponent {
    initCallback() {
        GridOrchestrator.deactivateGameFilters()
    }
}

PostAuthorContainer.template = /*html*/`
        <div class="main-content col-12 col-lg-8 g-0 mt-2">
        <div class="row m-2">
            <postauthor-component></postauthor-component>
        </div>
        </div>

        <div class="sidebar col-12 col-lg-4 mt-2">
        <div class="row m-2">
            <widget-matches></widget-matches>
        </div>
        <div class="row m-2">
            <predictions-homecomponent></predictions-homecomponent>
        </div>
        <div class="row m-2">
            <discord-template></discord-template>
        </div>
        <div class="row m-2">
            <widget-bookmakers></widget-bookmakers>
        </div>
        </div>
`;

PostAuthorContainer.reg('postauthor-container');