import { FirebaseAssistant } from './firebase-assistant';
import { MatchesComponent } from '../../components/matches-component/matches-component';

export function getDataFrontend(windowObject, theCaller, callbackName, callback) {
    const methodName = theCaller;
    const eventListenersKey = `${methodName}EventListeners`;
    const savedDataKey = windowObject;

    if (window[eventListenersKey] === undefined) {
        window[eventListenersKey] = {};
        window[eventListenersKey][callbackName] = callback;
        FirebaseAssistant[methodName](querySnapshot => {
            // ✅ Preserve existing data if available
            window[savedDataKey] = window[savedDataKey] || {};

            querySnapshot.forEach(single => {
                const data = single.data();
                if (theCaller === "getAuthors") {
                    window[savedDataKey][data.name] = data;
                } else {
                    window[savedDataKey][single.id] = {
                        ...(window[savedDataKey][single.id] || {}), // existing data if any
                        ...data // new data overwrites or adds properties
                    };
                }
            });

            // Notify all event listeners
            Object.keys(window[eventListenersKey]).forEach(listenerName => {
                window[eventListenersKey][listenerName]();
            });
        });
    } else if (window[savedDataKey] === undefined) {
        // Wait and try again if the data has not been saved yet.
        setTimeout(() => {
            getDataFrontend(windowObject, theCaller, callbackName, callback);
        }, 50);
    } else {
        // Add or update the callback, then immediately invoke it.
        window[eventListenersKey][callbackName] = callback;
        callback();
    }
}

export function getMatches(callbackName, callback) {
    if (window.getMatchesEventListeners === undefined) {
        window.getMatchesEventListeners = {}
        window.getMatchesEventListeners[callbackName] = callback
        if (window.savedDateMatches === undefined) {
            window.savedDateMatches = { [MatchesComponent.matchesSavedDate]: 'OK' };
        } else {
            window.savedDateMatches[MatchesComponent.matchesSavedDate] = 'OK';
        }
        FirebaseAssistant.getMatches(querySnapshot => {
            querySnapshot.forEach(single => {
                const data = single.data();
                if (window.savedMatches === undefined) {
                    window.savedMatches = { [data.id]: data }
                } else {
                    window.savedMatches[data.id] = {
                        ...(window.savedMatches[data.id] || {}), // existing data if any
                        ...data // new data overwrites or adds properties
                    };
                }
            });
            Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                window.getMatchesEventListeners[eventListenerName]()
            })
        });
        FirebaseAssistant.getLiveMatches(querySnapshot => {
            querySnapshot.forEach(single => {
                const data = single.data();
                if (window.savedMatches === undefined) {
                    window.savedMatches = { [data.id]: data }
                } else {
                    window.savedMatches[data.id] = {
                        ...(window.savedMatches[data.id] || {}), // existing data if any
                        ...data // new data overwrites or adds properties
                    };
                }
            });
            Object.keys(window.getMatchesEventListeners).forEach(eventListenerName => {
                window.getMatchesEventListeners[eventListenerName]()
            })
        });
    } else if (window.savedMatches === undefined) {
        setTimeout(() => {
            getMatches(callbackName, callback)
        }, 50)
    } else {
        window.getMatchesEventListeners[callbackName] = callback
        callback()
    }
}

