
import { BaseComponent } from "@symbiotejs/symbiote";
import gsap from "gsap";

export class LoadingSplasher extends BaseComponent {

  initCallback() {

  }

  static activateSplasher(message = '', aggressive = false) {
    if (document.querySelectorAll('loading-splasher').length === 0)
      document.body.insertAdjacentHTML("beforeend", '<loading-splasher></loading-splasher>')
    if (window.splasherActivated === undefined || window.splasherActivated === 0) {
      document.getElementById('splasher-text').innerText = message;
      if (aggressive) {
        document.querySelector('loading-splasher').style.opacity = '1'
        document.querySelector('loading-splasher').style.display = 'unset'
      } else {
        gsap.to(document.querySelector('loading-splasher'), {
          opacity: 1,
          display: 'unset'
        });
        window.splasherActivated = 1
      }
    }
  }

  static deactivateSplasher() {
    gsap.to(document.querySelector('loading-splasher'), {
      opacity: 0,
      display: 'none'
    });
    window.splasherActivated = 0
    setTimeout(function () {
      gsap.to(document.querySelector('loading-splasher'), {
        opacity: 0,
        display: 'none'
      });
      window.splasherActivated = 0
    }, 1000); // this is to avoid race conditions
  }
}

LoadingSplasher.template = /*html*/ `
<style>
 loading-splasher {
        position: fixed; top: 0; left: 0; z-index: 2000; right: 0; bottom: 0; opacity: 0;display: none;
        background: rgb(13,17,23);
    }

loading-splasher .wrapper{
    width:200px;
    height:60px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
}

loading-splasher .wrapper span{
   left: 50%;
    width: 5rem;
    height: 5rem;
    position: absolute;
    /*top: 75px;*/
    text-align: center;
    transform: translate(-50%, 0);
}

loading-splasher .loader {
        transform: rotateZ(45deg);
        perspective: 1000px;
        border-radius: 50%;
        width: 15rem;
        height: 15rem;
        color: #fff;
      }
loading-splasher  .loader:before,
loading-splasher  .loader:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: inherit;
          height: inherit;
          border-radius: 50%;
          transform: rotateX(70deg);
          animation: 1s spin linear infinite;
        }
        .loader:after {
          color: rgba(255,40,40,0.69);
          transform: rotateY(70deg);
          animation-delay: .4s;
        }

      @keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotateZ(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotateZ(360deg);
        }
      }

      @keyframes rotateccw {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(-360deg);
        }
      }

      @keyframes spin {
        0%,
        100% {
          box-shadow: .2em 0px 0 0px currentcolor;
        }
        12% {
          box-shadow: .2em .2em 0 0 currentcolor;
        }
        25% {
          box-shadow: 0 .2em 0 0px currentcolor;
        }
        37% {
          box-shadow: -.2em .2em 0 0 currentcolor;
        }
        50% {
          box-shadow: -.2em 0 0 0 currentcolor;
        }
        62% {
          box-shadow: -.2em -.2em 0 0 currentcolor;
        }
        75% {
          box-shadow: 0px -.2em 0 0 currentcolor;
        }
        87% {
          box-shadow: .2em -.2em 0 0 currentcolor;
        }
      }
</style>

    <div class="wrapper">
       <span class="loader"></span>
        <span id="splasher-text">Loading</span>
    </div>
`;

LoadingSplasher.reg('loading-splasher');
window.foo = function () {
  LoadingSplasher.deactivateSplasher();
}