import showdown from 'showdown';

export function theConverter() {
    function customExtensions() {
        return [{
            type: 'lang',
            filter: function (text) {
                // Handle {iframe}(url)
                text = text.replace(/\{iframe\}\((.*?)\)/g, function (match, url) {
                    return `<iframe src="https://${url}" width="560" height="315"></iframe>`;
                });

                // Handle {blockquote}(url)
                text = text.replace(/\{blockquote\}\((.*?)\)/g, function (match, url) {
                    return `<blockquote class="twitter-tweet" data-theme="dark"><a href="https://${url}"></a></blockquote>`;
                });

                return text;
            }
        }];
    }

    const converter = new showdown.Converter({
        extensions: [
            () => ({
                type: 'output',
                filter: (html) => {
                    return html
                        .replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')
                        .replace(/<h1\b/g, '<h2')
                        .replace(/<\/h1>/g, '</h2>')
                }
            }),
            customExtensions
        ]
    });

    return converter;
}