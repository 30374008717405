import { I18n } from '../i18n/i18n';
import { reversegameIdFilter, gameIdImg } from './gameIdImgMapping';

export function newsCarouselForAuthors(authorName, author) {
    author.newsPosts = [];

    for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
        if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue;
        if (authorName.toLowerCase() === NewsPostData.linkedAuthor.toLowerCase() && NewsPostData.public_draft === 'Public') {
            NewsPostData.tempID = NewsPostData.public_url_greeklish;
            author.newsPosts.push(NewsPostData);
        }
    }

    const itemsPerSlide = 2;

    let newshtml = /*html*/ `
<div id="articleCarousel-${authorName}" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
`;

    // We’ll keep track of how many items we’ve added to the current slide
    author.newsPosts.forEach((NewsPostData, index) => {
        // If it's the start of a new slide...
        if (index % itemsPerSlide === 0) {
            // Close the previous slide (if not the very first)
            if (index !== 0) {
                newshtml += `</div></div>`;
            }
            // Open a new slide item
            newshtml += /*html*/ `
      <div class="carousel-item ${index === 0 ? 'active' : ''}">
        <div class="row g-3"> <!-- g-3 adds some spacing between columns -->
    `;
        }

        // Prepare the optional game logo
        let gameLogo = gameIdImg[reversegameIdFilter[NewsPostData.game_tag]]
            ? `<img class="img-game-logo position-absolute img-fluid mx-auto d-block"
             src="${gameIdImg[reversegameIdFilter[NewsPostData.game_tag]]}"
             alt="${NewsPostData.game_tag} logo">`
            : "";

        // Build the article card
        newshtml += /*html*/ `
    <div class="col-6">
      <a href="/${I18n.getUsersPreferredLanguage()}/article/${NewsPostData.tempID}"
         class="article-box"
         data-authornewsid="${NewsPostData.tempID}"
         data-newsgameid="${NewsPostData.game_tag}">
         
        <div>
          <div class="ratio ratio-16x9 position-relative">
            <img class="author-card-img-top unzoom img-fluid rounded-top"
                 src="${NewsPostData.article_photo}"
                 alt="Card image cap">
            <div class="img-circle-container-game-logo d-flex align-items-center">
              ${gameLogo}
            </div>
          </div>
          <div class="card-body color-dark rounded-bottom caption-mobile p-1 p-sm-2">
            <p class="card-text text-start text-cut text-resize-title-article">
              ${NewsPostData.titles[I18n.getUsersPreferredLanguage()] || NewsPostData.titles[I18n.defaultLocale]}
            </p>
          </div>
        </div>
      </a>
    </div>
  `;
    });

    // Close out the final slide (if there were any posts at all)
    if (author.newsPosts.length > 0) {
        newshtml += `</div></div>`;
    }

    // Add carousel controls
    newshtml += /*html*/ `
  </div> <!-- end .carousel-inner -->
  
  <button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y"
          type="button"
          data-bs-target="#articleCarousel-${authorName}"
          data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  
  <button class="carousel-control-next position-absolute top-50 end-0 translate-middle-y"
          type="button"
          data-bs-target="#articleCarousel-${authorName}"
          data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
`;

    newshtml += /*html*/`
                    <style>
                    authors-component .carousel-control-prev, authors-component .carousel-control-next , postauthor-component .carousel-control-prev, postauthor-component .carousel-control-next{
                        background-color: rgba(0, 0, 0, 0.8);
                        border-radius: 50%;
                        width: 5%;
                        height: 20%;
                    }

                    authors-component .carousel-control-next-icon, authors-component .carousel-control-prev-icon, postauthor-component .carousel-control-next-icon, postauthor-component .carousel-control-prev-icon {
                        filter: brightness(0) invert(1) !important;
                    }

                    postauthor-component .text-cut, authors-component .text-cut {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    }
                    authors-component .ratio, postauthor-component .ratio {
                        position: relative;
                        overflow: hidden;
                    }
                        authors-component .text-resize-title-article, postauthor-component .text-resize-title-article {
                        font-size: calc(0.5rem + 0.3vw);
                        font-weight: 600;
                        color: #ffffff;
                    }
                    authors-component .img-circle-container-game-logo, postauthor-component .img-circle-container-game-logo {
                        width: calc(1rem + 1vw);
                        height: calc(1rem + 1vw);
                        top: 10px;
                        bottom: 0;
                        left: 10px;
                        right: 0;
                    }
                        authors-component .card-body, postauthor-component .card-body {
                        min-height: calc(2rem + 1.5vw);
                    }
                    </style>`;

    return newshtml;
}