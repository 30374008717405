import { globalSocials } from "../../utils/globals.js";

export const russianTranslation =
{
    "translationLanguageInLocal": `Русский`,
    "metaTags": {
        "keyword1": `Прямые трансляции киберспорта`,
        "keyword2": `Киберспортивные турниры`,
        "keyword3": `Киберспорт League of Legends`,
        "keyword4": `Турниры CS:GO / Counter-Strike`,
        "keyword5": `Dota 2 TI (The International)`,
        "keyword6": `ИИ и экспертный анализ матчей киберспорта`,
        "keyword7": `Прогнозы и советы по ставкам на киберспорт`,
        "keyword8": `Новости и статьи по киберспорту`,
        "singleMatchTitleLive": `{{team1}} против {{team2}} | Прямой эфир: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} против {{team2}} | Результаты: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleUpcoming": `Скоро: {{team1}} против {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} против {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchDescriptionLive": `{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} в {{tournament}}. Смотрите в прямом эфире! Матч начался {{date}}. Получите статистику, прогнозы и ИИ-анализ на MyEsports!`,
        "singleMatchDescriptionFinished": `{{game}}: {{teamA}} сразились с {{teamB}} в {{tournament}}. Матч завершен {{date}} с результатом {{score1}} - {{score2}}. Получите статистику, прогнозы и ИИ-анализ на MyEsports!`,
        "singleMatchDescriptionUpcoming": `{{game}}: {{teamA}} встретится с {{teamB}} в {{tournament}}. Матч начнется {{date}}. Получите статистику, прогнозы и ИИ-анализ на MyEsports! Смотрите матч в прямом эфире здесь!`,
        "singleMatchDescriptionFallback": `{{game}}: {{teamA}} встретится с {{teamB}} в {{tournament}}. Матч начнется {{date}}. Получите статистику, прогнозы и ИИ-анализ на MyEsports! Смотрите в прямом эфире!`,
        "singleTournamentTitle": `Турнир {{game}} – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `Изучите последние матчи и результаты турнира {{tournament}} на MyEsports! Турнир {{game}} уровня {{tier}} с участием {{opponents}} и не только!`,
        "singleAuthorTitle": `{{author}} - Автор MyEsports`,
        "matchesTitle": `Матчи киберспорта – Прямые трансляции и результаты | MyEsports`,
        "matchesDescription": `Будьте в курсе последних результатов матчей киберспорта и предстоящих игр на MyEsports. Получите прямые трансляции, расписания, анализы, советы и статистику команд по CS2, Dota 2, LoL и другим играм!`,
        "tournamentsTitle": `Турниры киберспорта – Предстоящие и прошедшие события | MyEsports`,
        "tournamentsDescription": `Откройте для себя крупнейшие турниры по киберспорту на MyEsports! Получите расписания, результаты, призовые фонды и таблицы команд по CS2, Dota 2, LoL, Valorant и другим играм!`,
        "newsTitle": `Новости и статьи киберспорта – Последние обновления и мнения | MyEsports`,
        "newsDescription": `Оставайтесь в курсе последних новостей, статей и анализов по киберспорту на MyEsports! Получайте обновления по CS2, Dota 2, LoL, Valorant и многим другим играм, а также экспертные анализы и интервью!`,
        "authorsTitle": `Знакомьтесь с авторами MyEsports – Новости, прогнозы и советы | MyEsports`,
        "authorsDescription": `Узнайте больше о наших экспертных авторах на MyEsports! Мы представляем вам новостные статьи, прогнозы и советы по CS2, Dota 2, LoL и многим другим играм!`,
        "bookmakersTitle": `Топ-10 сайтов для ставок на киберспорт (казино) – Доверенные партнеры и предложения | MyEsports`,
        "bookmakersDescription": `Изучите надежных партнеров MyEsports для ставок! Найдите лучшие сайты для ставок на киберспорт с эксклюзивными предложениями, бонусами и безопасными платформами для CS2, Dota 2, LoL и других игр!`,
        "skinbookmakersTitle": `Торговля скинами киберспорта – Доверенные партнеры для скинов и кейсов | MyEsports`,
        "skinbookmakersDescription": `Откройте для себя надежных партнеров MyEsports для торговли скинами! Покупайте, продавайте, обменивайтесь и ставьте скины и кейсы безопасно для CS2, Dota 2, Rust и других игр. Найдите лучшие торговые площадки и эксклюзивные предложения!`,
        "predictionsTitle": `Прогнозы на киберспорт и советы по ставкам – Экспертный анализ | MyEsports`,
        "predictionsDescription": `Получите экспертные прогнозы и советы по ставкам на киберспорт на MyEsports! Найдите точные прогнозы матчей от наших экспертов и ИИ, аналитику коэффициентов и стратегии для победы в CS2, Dota 2, LoL и других играх!`,
        "termsOfUseTitle": `Условия использования – Правила и соглашение пользователя | MyEsports`,
        "termsOfUseDescription": `Прочитайте Условия использования MyEsports. Узнайте об соглашении пользователя, правилах и политиках использования нашей платформы, включая контент по киберспорту, партнерские ставки и многое другое.`,
        "privacyPolicyTitle": `Политика конфиденциальности – Защита данных и безопасность | MyEsports`,
        "privacyPolicyDescription": `Узнайте, как MyEsports защищает ваши данные. Ознакомьтесь с нашей Политикой конфиденциальности относительно пользовательской информации, мер безопасности и соблюдения GDPR для безопасного использования.`,
        "faqTitle": `Часто задаваемые вопросы – Поддержка и ответы | MyEsports`,
        "faqDescription": `Найдите ответы на часто задаваемые вопросы о MyEsports. Прочитайте наш FAQ для получения информации о киберспортивных матчах, партнерских ставках, прогнозах, торговле скинами и других поддерживаемых темах.`,
        "streamersTitle": `Стримеры киберспорта – Прямые каналы и игровой контент | MyEsports`,
        "streamersDescription": `Откройте для себя лучших стримеров по киберспорту со всего мира на MyEsports! Смотрите прямые трансляции игрового процесса, стримы профессиональных игроков и эксклюзивный контент от наших партнеров.`,
        "homeTitle": `MyEsports – Ваш главный центр по киберспорту для новостей, матчей и советов по ставкам`,
        "homeDescription": `Будьте в курсе всего происходящего в мире киберспорта с MyEsports! Получайте последние новости, результаты матчей, прямые трансляции, советы по ставкам, прогнозы и обновления турниров по CS2, Dota 2, LoL и многим другим играм!`
    },
    "homePage": {
        "latestAnalyses": `ПОСЛЕДНИЕ ИИ АНАЛИЗЫ`
    },
    "networkGuard": {
        "title": `Ой!`,
        "description": `Страница, которую вы ищете, не существует!`
    },
    "navMainMenu": {
        "Home": `Главная`,
        "Tournaments": `Турниры`,
        "Matches": `Матчи`,
        "Giveaways": `Розыгрыши`,
        "Casinos": `Букмекеры`,
        "AboutUs": `О нас`,
        "Streamers": `Стримеры`,
        "News": `Новости`,
        "Predictions": `Прогнозы`,
        "navDesktopTitle": `MyEsports`
    },
    "hotmatchTranslations": {
        "playerNationalities": `Игроки из {{countries}} соревнуются`,
        "playerAges": `Самому младшему игроку {{Age}} лет`,
        "viewers": `{{maxviewers}} смотрят сейчас!`,
        "alternativeTimeLineHours": `Игра {{RGN}} в процессе. Началась {{hourz}} час(ов) и {{minutez}} минут(ы) назад`,
        "alternativeTimeLineMinutes": `Игра {{RGN}} в процессе. Началась {{minutez}} минут(ы) назад`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>ГОРЯЧЕЕ`,
        "noLiveMsg": `<h2>Подождите, действия не за горами!</h2><p>Похоже, {{game}} взял небольшой перерыв.</p>`
    },
    "matchTranslations": {
        "title": `{{team1}} vs {{team2}} | {{tournament}}`,
        "description": `Смотрите, как {{teamA}} сражается с {{teamB}} на {{tournament}} на MyEsports | Ознакомьтесь с ИИ-анализом и прогнозами наших экспертов на этот матч!`,
        "h2": `Это подпараграф матча`,
        "body": `Это основной контент матча`,
        "matchMatchUpcoming": `{{minutes}} мин. осталось`,
        "matchMatchLive": `В ЭФИРЕ`,
        "matchMatchFinished": `Завершено`,
        "matchMatchPostponed": `Отложено`,
        "matchMatchCanceled": `Отменено`,
        "matchMatchAboutTo": `Скоро начнется`,
        "homeMatchDHM": `{{days}}д {{hours}}ч {{minutes}}м`,
        "homeMatchHM": `{{hours}}ч {{minutes}}м`,
        "homeMatchM": `{{minutes}}м`,
        "homeMatchLive": `В ЭФИРЕ`,
        "homeMatchAboutTo": `Начинается`,
        "pillLive": `В ЭФИРЕ`,
        "pillUpcoming": `СКОРО`,
        "pillFinished": `ЗАВЕРШЕНО`,
        "pillPredictions": `ПРОГНОЗЫ`,
        "pillAll": `ВСЕ`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> ИЗБРАННОЕ`,
        "bestofX": `Лучший из {{gamesNumber}}`,
        "bestof1": `Лучший из 1`,
        "bestof2": `Лучший из 2`,
        "allGamesPlayed": `{{gamesNumber}} игр`,
        "allGamesPlayedMicro": `ИГ{{gamesNumber}}`,
        "firstToX": `До {{gamesNumber}} побед`,
        "showMoreMatches": `Показать больше`,
        "emptyGameFilterMessage": `В данный момент нет доступных матчей по {{game}}.`,
        "emptyStateFilterMessage": `Нет доступных матчей по {{game}} с выбранными фильтрами.`,
        "emptyPredictionFilterMessage": `Нет доступных прогнозов по {{predGame}} на выбранную дату.`,
        "emptyAnalysisFilterMessage": `Нет доступных недавних анализов {{game}}`,
        "searchPlaceholder": `Поиск турниров или команд...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames },
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `ИИ-Анализ матча`,
        "tipTranslationTitle": `Советы от экспертов`,
        "team1players": `Игроки`,
        "team2players": `Игроки`,
        "ageLabel": `Возраст: `,
        "positionLabel": `Позиция: `,
        "h2hNoRecentMsg": `Нет недавних матчей между этими командами`,
        "matchHistory": `История матчей`,
        "h2hPill": `Очные встречи`,
        "badgeWon": `В`,
        "badgeLost": `П`,
        "badgeDraw": `Н`,
        "noVideoMessage": `Видео для этого матча недоступно`,
        "analysis": `Наш ИИ 'PlayPulse' проанализирует этот матч в течение 24 часов перед началом игры. Пожалуйста, вернитесь ближе ко времени начала матча, чтобы увидеть полный анализ!`,
        "whatDoesAiThink": `ИИ-Инсайты по этому матчу:`,
        "aiAnalysis": `ИИ-АНАЛИЗ`,
        "betNow": `Сделать ставку`,
        "oddsDraw": `Ничья`,
        "predictToWin": `победит`,
        "predictDraw": `Ничья`,
        "predictExactScore": `Точный счет`,
        "predictOverUnder": `игры`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>Нет недавних статей</h2>  
<p>К сожалению, нет недавних статей по {{newsGame}}.</p>`,
        "newsTitle": `СТАТЬИ`,
        "writtenAt": `Написано`,
        "footerArticle": `🔥 Понравилась статья? 🔥`,
        "footerArticleFollow": `Не пропустите! Подписывайтесь на нас в соцсетях, чтобы получать больше интересных обновлений!`,
        "newsSeeAll": `Смотреть все`,
        "seeMore": `Подробнее...`,
        "goBack": `Назад к новостям`,
        "tournamentNews": `Турниры`,
        "rosterNews": `Составы`,
        "gameNews": `Общее`,
    },
    "footer": {
        "about": `Добро пожаловать в MyEsports – ваш универсальный источник для всех любителей киберспорта. Созданный из глубокой любви и страсти к игровому миру, мы создали пространство, где киберспортивное сообщество может найти все: от прямых трансляций матчей и событий до прогнозов, анализов и статей по различным играм.`,
        "followUs": `Подписывайтесь на нас`,
        "usefulLinks": `Полезные ссылки`,
        "contactUs": `Свяжитесь с нами`,
        "aboutUs": `О нас`,
        "eula": `Условия использования`,
        "privacy": `Политика конфиденциальности`,
        "faq": `Часто задаваемые вопросы`,
        "Authors": `Авторы`,
    },
    "tournamentsTranslations": {
        "noDateMsg": `Дата завершения не определена`,
        "tier": `Уровень`,
        "emptyTournamentFilterMessage": `Нет доступных турниров по {{game}} с выбранными статусными фильтрами.`,
        "pillOngoing": `ИДЕТ`,
        "pillUpcoming": `СКОРО`,
        "pillFinished": `ЗАВЕРШЕН`,
        "pillAll": `ВСЕ`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>В процессе</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Завершен</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Начнется через {{days}} дней</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Начнется через {{days}} день</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Начнется через {{hours}}ч {{minutes}}м</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Начнется через {{minutes}}м</span>`,
        "noPrizePoolAvailable": `Н/Д`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `Команды соревнуются`,
        "hotTournamentsTitle": `ГОРЯЧИЕ ТУРНИРЫ`,
        "hotTournamentFixedLabel": `ИДЕТ`,
        "noHotTournamentsMsg": `<h2>Нет текущих турниров</h2><p>К сожалению, в данный момент нет активных турниров по {{game}}.</p>`,
        "noHotTournamentsButton2": `Смотреть все`,
        "clickHotTournaments": `Нажмите, чтобы узнать больше информации.`,
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Команды соревнуются: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Игроки соревнуются: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Призовой фонд: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Призовой фонд: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Призовой фонд: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `Уровень:`,
        "winnerPost": `Победитель турнира:`,
        "startPost": `Дата начала:`,
        "endPost": `Дата окончания:`,
        "noDateMsgPost": `Дата завершения не определена`,
        "ladderWins": `Победы`,
        "ladderLosses": `Поражения`,
        "ladderTies": `Ничьи`,
        "ladderGamesWon": `Выигранные игры`,
        "ladderGamesLost": `Проигранные игры`,
        "ladderTieBreaker": `Дополнительные показатели`,
        "upperBracket": `Верхняя сетка`,
        "lowerBracket": `Нижняя сетка`,
        "grandFinal": `Гранд-финал`,
        "semiFinal": `Полуфинал`,
        "quarterFinal": `Четвертьфинал`,
        "roundBrackets": `Раунд`,
        "matchBrackets": `Матч`,
        "laddersMatchesTitle": `Матчи`,
        "week": `Неделя`,
        "liveWeek": `LIVE`,
    },
    "predictionsTranslations": {
        "predTitle": `ПРОГНОЗЫ`,
        "comingSoon": `Скоро`,
        "today": `Сегодня`,
        "title": `Киберспортивные прогнозы`,
        "subtitle": `В MyEsports мы тщательно изучаем каждый матч, чтобы предоставить вам лучшие советы по ставкам на киберспорт. Наши эксперты анализируют команды, игроков и стратегии, чтобы помочь вам принять верные решения.`,
        "time": `Время`,
        "date": `Дата`,
        "match": `Матч`,
        "tournament": `Турнир`,
        "tipster": `Эксперт`,
        "betButton1": `Сделайте ставку прямо сейчас на `,
        "odd": `@`,
        "noPredictionForThisDate": `На эту дату пока нет прогнозов, зайдите позже.`,
        "statsAvgOdds": `Средний коэффициент`,
        "statsEarn": `Прибыль`,
        "statsBets": `Ставки`,
        "statsOpenBets": `Открытые ставки`,
        "authStats": `Статистика прогнозов`,
        "articles": `Статьи`,
        "last10": `Недавние прогнозы`,
        "authBio": `Биография`,
        "bioComing": `Биография скоро появится`,
        "showMorePredictions": `Показать больше`,
        "EarnExplain": `Прибыль рассчитывается на основе закрытых прогнозов. Каждый эксперт начинает с 0, и при каждом верном прогнозе его прибыль увеличивается на коэффициент выигрыша x1. Например, при успешном прогнозе с коэффициентом 1.80 прибыль эксперта увеличивается на 0.80. В случае неверного прогноза вычитается 1.00.`,
        "WinrateExplain": `Процент побед рассчитывается на основе закрытых прогнозов. Это процент правильных прогнозов от общего количества сделанных прогнозов.`,
        "RoiExplain": `ROI (возврат инвестиций) рассчитывается на основе прибыли эксперта по отношению к общему количеству ставок. Например, если эксперт сделал 50 ставок по одной единице и ROI составляет 20%, значит, он выиграл 10 единиц.`,
        "AvgOddsExplain": `Средний коэффициент рассчитывается на основе коэффициентов закрытых прогнозов. Это сумма коэффициентов, деленная на общее количество прогнозов.`,
        "plainPrediction": `Прогноз`,
        "statisticPeriod": `Период`,
        "alltimeStats": `За все время`,
    },
    "banners": {
        "desktopLeft": 'ru_desktop_left',
        "desktopRight": 'ru_desktop_right'
    },
    "streamerTranslations": {
        "cardFollowers": `Подписчики`,
        "cardInfo": `{{user}} сейчас {{status}}.`,
        "cardViewers": `Зрители!`,
        "detailsInformation": `Информация о стримере`,
        "detailsName": `Полное имя`,
        "detailsNationality": `Национальность`,
        "detailsAge": `Возраст`,
        "detailsGame": `Игры`,
        "detailsRole": `Роль`,
        "detailsTeam": `Команда`,
        "detailsSignature": `Любимый герой`,
        "accordionHowTo": `<strong>-Как стать стримером на MyEsports?</strong>`,
        "accordionHowToReply": `Чтобы стать стримером на MyEsports, вы можете подать заявку, присоединившись к нашему <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord-серверу</a> и связавшись с нашей командой, либо заполнив эту <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">форму</a>.`,
        "accordionEarn": `<strong>-Что я получу, став стримером на MyEsports?</strong>`,
        "accordionEarnReply": `Будучи представленным на MyEsports, вы получите возможность увеличить свою аудиторию и развить канал. Также у вас будет шанс попасть в наши статьи и социальные сети.`,
        "accordionGraphics": `<strong>-Получу ли я графику для своего канала?</strong>`,
        "accordionGraphicsReply": `Да, мы предоставим вам графику для канала, а также поддержку и рекомендации по её использованию для улучшения качества трансляций.`,
        "accordionHowToStream": `<strong>-Я новичок в стриминге, могу ли я присоединиться? Как мне начать?</strong>`,
        "accordionHowToStreamReply": `Конечно! Мы приветствуем стримеров любого уровня. Начать можно с присоединения к нашему <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord-серверу</a> и связи с нашей командой для получения дополнительной информации.`,
        "accordionViewers": `<strong>-Сколько зрителей мне нужно, чтобы присоединиться?</strong>`,
        "accordionViewersReply": `Мы принимаем стримеров с любой аудиторией. Независимо от того, есть у вас 1 зритель или 10 000 – мы рады вам на MyEsports!`
    },
    "authorsTranslations": {
        "title": `Познакомьтесь с командой авторов MyEsports`
    },
    "bookmakersPostTranslations": {
        "odds": `Коэффициенты`,
        "varietyOfEsports": `Разнообразие киберспортивных игр`,
        "liveSupport": `Поддержка в реальном времени`,
        "liveStreaming": `Прямые трансляции`,
        "payments": `Платежи`,
        "fullReview": `Полный обзор`,
        "esports": `Киберспорт`,
        "register": `СДЕЛАТЬ СТАВКУ`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `Полный обзор`,
        "register": `СДЕЛАТЬ СТАВКУ`
    },
    "bookmakersSkinList": {
        "widgetTitle": `Платформы скинов`,
        "widgetButton": `Больше`,
        "playNow": `Играть!`,
        "introSection": `<h2 class="title-section">Лучшие сайты для ставок на киберспорт в 2024 году: Найдите идеальную платформу</h2>  
        <p>  
        С ростом киберспорта ведущие платформы ставок на скины расширили свои возможности, предлагая рынки для популярных игр, таких как Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends и Call of Duty. Эти игры обеспечивают постоянный поток захватывающих турниров и лиг, предоставляя бетторам множество вариантов – от простых ставок на победителя до специальных игровых пари.  
        </p>  
        <p>  
        На <a href="https://myesports.gg" class="text-a-style">myesports.gg</a> мы тщательно изучили лучшие платформы ставок на скины, чтобы помочь вам найти идеальную.  
        </p>`,
        "sectionTitle": `Где делать ставки на киберспорт: Лучшие платформы и бонусы`,
        "pleaseBetResponsibly": `Ставьте ответственно`,
        "reviewMethodology": `  
        <h2 class="title-section">Наш метод оценки</h2>  
        <p>  
        На <a class="text-a-style" href="https://myesports.gg">myesports.gg</a> наша цель – помочь вам выбрать идеальную платформу ставок на скины для киберспорта. Мы оцениваем каждую платформу по удобству использования, разнообразию рынков и мерам безопасности при внесении и выводе средств.  
        </p>  
        <p>  
        Мы также проверяем наличие лицензии, чтобы убедиться, что платформа работает легально в вашем регионе. Только те, кто соответствует нашим высоким стандартам, получают наш знак одобрения. Мы также предлагаем эксклюзивные бонусы и акции, чтобы сделать ставки еще более выгодными.  
        </p>  
        <p>  
        Наши критерии оценки охватывают несколько аспектов, включая удобство использования, количество доступных рынков и способы оплаты. Каждая платформа проходит тщательную проверку по восьми основным категориям – от дизайна и функциональности до качества обслуживания клиентов. Ознакомьтесь с нашими подробными обзорами, чтобы узнать больше.  
        </p>`,
        "review": `Обзор`,
        "betNow": `СДЕЛАТЬ СТАВКУ`,
        "noBookmakerSkinsMsg": `<h2>Нет доступных платформ ставок на скины для вашего региона и языка.</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `Букмекеры`,
        "widgetButton": `Больше`,
        "playNow": `Играть!`,
        "introSection": `<h2 class="title-section">Лучшие сайты для ставок на киберспорт в 2024 году: Найдите лучшего букмекера</h2>  
        <p>  
        С ростом киберспорта ведущие букмекерские сайты расширили свои возможности, предлагая рынки для популярных игр, таких как Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends и Call of Duty. Эти игры обеспечивают постоянный поток захватывающих турниров и лиг, предлагая различные виды ставок – от ставок на победителя до специальных игровых пари.  
        </p>  
        <p>  
        На <a href="https://myesports.gg" class="text-a-style">myesports.gg</a> мы тщательно проанализировали ведущие букмекерские платформы, чтобы помочь вам выбрать лучшую.  
        </p>`,
        "sectionTitle": `Где делать ставки на киберспорт: Лучшие букмекеры и бонусы`,
        "pleaseBetResponsibly": `Ставьте ответственно`,
        "reviewMethodology": `  
        <h2 class="title-section">Наш метод оценки</h2>  
        <p>  
        На <a class="text-a-style" href="https://myesports.gg">myesports.gg</a> наша миссия проста – помочь вам выбрать лучшего букмекера для ставок на киберспорт. Мы оцениваем каждый сайт по удобству использования, разнообразию рынков и мерам безопасности при внесении и выводе средств.  
        </p>  
        <p>  
        Мы также проверяем наличие лицензии, чтобы убедиться, что букмекер работает легально в вашем регионе. Только лучшие платформы получают нашу рекомендацию. Кроме того, мы предлагаем эксклюзивные бонусы и акции, чтобы сделать ваш игровой опыт еще выгоднее.  
        </p>  
        <p>  
        Наши критерии оценки включают удобство использования, широту выбора рынков и платежные методы. Каждый букмекер проходит тщательную проверку по восьми основным категориям – от дизайна и функционала до поддержки клиентов. Ознакомьтесь с нашими детальными обзорами, чтобы узнать больше.  
        </p>`,
        "review": `Обзор`,
        "betNow": `СДЕЛАТЬ СТАВКУ`,
        "noBookmakersMsg": `<h2>Нет доступных букмекеров для вашего региона и языка.</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Ой! Похоже, вы используете блокировщик рекламы.</strong></p>  
        <p>Вы можете пропустить полезный контент, который мы подготовили для вас. Это не реклама, а важные функции сайта. Пожалуйста, подумайте о том, чтобы отключить блокировщик.</p>  
        <button id="close-button">Понял!</button>`
    },
    "discordTemplate": {
        "titleMain": `Нужна помощь?`,
        "titleSub": `Присоединяйтесь к нашему Discord!`
    },
}

