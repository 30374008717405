import { I18n } from '../../utilities/i18n/i18n';
export function setupSkinBookies(containerId, htmlCallback) {
    let count = 0;

    let language = I18n.getUsersPreferredLanguage();
    const container = document.querySelector(containerId);

    if (container) {
        container.innerHTML = '';
    }

    const bookmakersSkinArray = Object.keys(window.savedSkinBookmakers).map(key => {
        const data = window.savedSkinBookmakers[key];
        data.id = key;
        return data;
    });

    bookmakersSkinArray.sort((a, b) => {
        const orderA = a.order_skin_bookmaker !== undefined ? Number(a.order_skin_bookmaker) : Infinity;
        const orderB = b.order_skin_bookmaker !== undefined ? Number(b.order_skin_bookmaker) : Infinity;
        return orderA - orderB;
    });


    bookmakersSkinArray.forEach((bookmakerData) => {
        if (bookmakerData.status === 'draft') {
            return;
        }

        const hasLocationRestriction = Array.isArray(bookmakerData.location_bookmaker_skin) && bookmakerData.location_bookmaker_skin.length > 0;
        if (hasLocationRestriction && bookmakerData.location_bookmaker_skin.includes(window.geoFencingGuard)) {
            return;
        }

        // Call the passed callback function to generate the specific HTML
        const html = htmlCallback(bookmakerData, language, count);

        // Append to the container
        container.insertAdjacentHTML('beforeend', html);
        count++;
    });

    // **Handle No Data Scenario**
    if (container && container.innerHTML === '') {
        container.innerHTML = I18n.translateString("bookmakersSkinList.noBookmakerSkinsMsg");
        document.querySelector('.removal-class').style.display = 'none';
        document.querySelector('.removal-title').style.display = 'none';
    }
}