import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";

import { I18n } from "../../utilities/i18n/i18n";

import tournamentimg2 from "../hottournaments-component/assets/img2.webp"
import { getTournamentName } from "../../../functions/across-stack/utils/globals";
import { emptyMessage } from "../../utilities/general/emptyMessage";
import { reversegameIdFilter, gameIdImg } from "../../utilities/general/gameIdImgMapping";
import { zoomIconsListener } from "../../utilities/general/zoomIconsListener";
import { sortTournaments } from "../../utilities/general/sortTournaments";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
const noImageFound = require('../../global-assets/no image found.png');

export class HotTournamentsComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('hottournaments-component', HotTournamentsComponent.initiator);
    document.querySelectorAll('.hotTournamentsTitle').forEach((el) => {
      el.innerHTML = I18n.translateString('tournamentsTranslations.hotTournamentsTitle');
    });
    document.querySelector('.show-more-tournaments-button').insertAdjacentHTML('beforeend', I18n.translateString("tournamentsTranslations.noHotTournamentsButton2"));
  }

  static initiator() {

    getDataFrontend('savedTournaments', 'getTournaments', 'fromHotmatch', function () {
      if (document.querySelectorAll('#hottournaments-html').length !== 0) {
        console.debug('I am running the hotTournaments component call back!')
        HotTournamentsComponent.updateTournaments(GameNavbar.getCurrentlyActivatedGameIfAny())
        const tournamentsDiv = document.querySelector('#hottournaments-html');

        if (!tournamentsDiv.innerHTML.trim()) {
          emptyMessage(tournamentsDiv, 'tournamentsTranslations.noHotTournamentsMsg', 'msgSMTclass');
        }

      } else {
        console.debug('The hottournaments component call back is supposed to run but I am not on the correct page!!!')
      }
    });

    GameNavbar.subscribeToGameFilterChanges('fromHotTournamentsComponent', (eventId) => {
      if (document.querySelectorAll('#hottournaments-html').length !== 0) {

        const tournamentsDiv = document.querySelector('#hottournaments-html');
        HotTournamentsComponent.updateTournaments(eventId, tournamentsDiv);

        if (!tournamentsDiv.innerHTML.trim()) {
          emptyMessage(tournamentsDiv, 'tournamentsTranslations.noHotTournamentsMsg', 'msgSMTclass');
        }

      }
    })
  }

  static updateTournaments(gameId) {

    document.querySelector('#hottournaments-html').innerHTML = '';

    let count = 0;
    // Sort all tournaments by tier, validity of currency, prize pool, and date
    const sortedTournamentIds = sortTournaments(Object.keys(window.savedTournaments));

    for (let i = 0; i < sortedTournamentIds.length; i++) {
      const TournamentCode = sortedTournamentIds[i]
      const tourElementId = `fromhottournamentsId-${TournamentCode}`

      const imgSources = [tournamentimg2];
      const imgSrc = imgSources[count % imgSources.length]; // exists so in the future we can have multiple images

      let data = window.savedTournaments[TournamentCode]

      const now = new Date();
      const startTimeDate = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
      const endTimeDate = new Date(data.end_at ? data.end_at : null);
      const formatedStartTime = startTimeDate.toLocaleString(I18n.getLanguageFromUrl(), { year: 'numeric', month: 'short', day: 'numeric' });
      const formatedEndTime = endTimeDate ? endTimeDate.toLocaleString(I18n.getLanguageFromUrl(), { year: 'numeric', month: 'short', day: 'numeric' }) : 'NoDateMsg';

      if (startTimeDate < now && (!endTimeDate || endTimeDate > now)) { // If the tournament is currently ongoing

        // If a gameId is provided and it doesn't match the current game's id, skip this tournament
        if (gameId && reversegameIdFilter[gameId] !== undefined) {
          if (Array.isArray(reversegameIdFilter[gameId])) {
            if (!reversegameIdFilter[gameId].includes(data.videogame.id)) {
              continue;
            }
          } else {
            if (reversegameIdFilter[gameId] !== data.videogame.id) {
              continue;
            }
          }
        }

        count++;
        if (count > 6) {
          break; // Exit loop after processing 6 tournaments
        }

        const tournamentName = data.league.name + ' ' + data.full_name;
        const seriesNameWithDashes = getTournamentName(data);
        const gameLogoHTML = gameIdImg[data.videogame.id]
        let seriesPrizepool = data.prizepoolMessage;
        if (data.actualprizepool && data.totalPrizePool === '0') {
          seriesPrizepool = data.actualprizepool;
        }
        const seriesTier = data.tier.toUpperCase();
        const tournamentLogo = data.league.image_url ? data.league.image_url : noImageFound;
        const playersParticipating = data.opponents_count;

        const hotTourhtml = /*html*/ `
                                            <div class="col-12 col-md-6 col-lg-6 col-xxl-4">
                                                <a href="/${I18n.getUsersPreferredLanguage()}/tournament/${TournamentCode}/${seriesNameWithDashes}" 
                                                class="card ht-tournament-card text-white border-0 rounded parentHottournament" 
                                                id="${tourElementId}" 
                                                aria-label="${tournamentName}">

                                                    <!-- Card Header -->
                                                    <div class="ht-card-header rounded-top">
                                                        <div class="ht-tournament-logo">
                                                            <div class="img-circle-container-logo-game d-flex justify-content-center overflow-hidden">
                                                                <img class="img-game-logo" src="${gameLogoHTML}" alt="gamelogo">
                                                            </div>
                                                        </div>
                                                        <div class="ht-tournament-info">
                                                            <h5 class="ht-tournament-title">${tournamentName}</h5>
                                                            <small class="ht-tournament-dates">${formatedStartTime} - ${formatedEndTime}</small>
                                                        </div>
                                                    </div>

                                                    <!-- Position Relative Container for Background Image and Tournament Logo -->
                                                    <div class="position-relative">
                                                        <!-- Tournament Logo Positioned Above Background Image -->
                                                        <div class="image-container-tournament-logo d-flex justify-content-center align-items-center">
                                                            <img src="${tournamentLogo}" 
                                                                onerror="this.onerror=null; this.src='';" 
                                                                alt="Tournament Logo" 
                                                                class="img-fluid mx-auto d-block image-for-zoom unzoom" 
                                                                style="filter: drop-shadow(0 0 1.3rem black);">
                                                        </div>

                                                        <!-- Background Image -->
                                                        <img src="${imgSrc}" 
                                                            class="card-img-top ht-card-img-top rounded-0" 
                                                            alt="tournament-background" 
                                                            loading="lazy">

                                                        <!-- Image Overlay with Badges -->
                                                        <div class="ht-img-overlay">
                                                            <span class="badge bg-success ht-badge-status" 
                                                                data-bs-toggle="tooltip" 
                                                                data-bs-placement="top" 
                                                                title="This tournament is currently active">
                                                                <i class="bi bi-play-fill me-1 ongoing-label"></i>
                                                            </span>
                                                            <span class="badge bg-info ht-badge-tier" 
                                                                data-bs-toggle="tooltip" 
                                                                data-bs-placement="top" 
                                                                title="Tier ${seriesTier} Tournament">
                                                                Tier: ${seriesTier}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <!-- Card Body -->
                                                    <div class="ht-card-body rounded-bottom">
                                                        <div class="d-flex justify-content-between mb-3">
                                                            <span class="ht-teams-prize-and-opps">
                                                                <i class="bi bi-people-fill"></i>${playersParticipating}
                                                            </span>
                                                            <span class="ht-teams-prize-and-opps prizepool-label">
                                                                <i class="bi bi-cash-stack"></i>${seriesPrizepool}
                                                            </span>
                                                        </div>
                                                        <div class="mt-auto">
                                                            <span class="text-muted small">
                                                                ${I18n.translateString('tournamentsTranslations.clickHotTournaments')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                </a>
                                            </div>
                                        `;

        document.querySelector('#hottournaments-html').insertAdjacentHTML('beforeend', hotTourhtml);
        HotTournamentsComponent.translations();

        zoomIconsListener(document.querySelectorAll(`[id="${tourElementId}"]`), ".image-for-zoom");

      }
    }
  }

  static translations() {
    const endElements = document.querySelectorAll('.endDate-label');
    endElements.forEach(element => {
      if (element.innerHTML === 'NoDateMsg') {
        element.innerHTML = I18n.translateString("tournamentsTranslations.noDateMsg");
      }
    });
    const tierElements = document.querySelectorAll('.tier-label');
    const prizepoolElements = document.querySelectorAll('.prizepool-label');
    prizepoolElements.forEach(element => {
      if (element.innerHTML.includes('No prize pool available')) {
        element.innerHTML = I18n.translateString("tournamentsTranslations.noPrizePoolAvailable")
      }
    });
    tierElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.tier") });
    const teamsCompeteElements = document.querySelectorAll('.teams-compete-label');
    teamsCompeteElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.teamsCompete") });
    const ongoingElements = document.querySelectorAll('.ongoing-label');
    ongoingElements.forEach(element => { element.innerHTML = I18n.translateString("tournamentsTranslations.hotTournamentFixedLabel") });
  }
}


HotTournamentsComponent.template = /*html*/ `
<style>
  hottournaments-component .ht-component {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #f1f1f1;
  }

  hottournaments-component .msgSMTclass {
    text-align: center;
    padding: 20px;
    background-color: #161b22;
    border-color: #f5c6cb;
    border-radius: 0.25rem;
  }


  hottournaments-component .ht-section-title {
    font-size: calc(0.5rem + 1vw);
    color: #f1f1f1;
    position: relative;
  }

  hottournaments-component .ht-section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 90%;
    height: 4px;
    background-color: #B22A23;
    border-radius: 2px;
  }

  /* Back Button */
  hottournaments-component .ht-btn-back {
        text-decoration: none;
        text-transform: none;
        background-color: transparent;
        border: 1px solid #D3D3D3 !important;
        color: #D3D3D3 !important;
        padding: calc(0.2rem + 0.2vw) calc(0.4rem + 0.4vw);
        border-radius: 8px;
        font-size: calc(0.3rem + 0.3vw);
        transition: background-color 0.3s ease, color 0.3s ease;
  }

  hottournaments-component .ht-btn-back:hover {
        background-color: #D3D3D3 !important;
        color: #0D1117 !important;
  }

        @media only screen and (max-width: 1024px) {
  hottournaments-component .ht-section-title {
            font-size: 1rem;
        }
    hottournaments-component .ht-btn-back {
            font-size: 0.6rem;
        }
    }

  /* Tournament Card */
  hottournaments-component .ht-tournament-card {
    background-color: #1e1e2f;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: #ccc !important;
  }

  hottournaments-component .ht-tournament-card:hover {
    transform: translateY(-7px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }

  hottournaments-component .ht-card-header {
    background-color: #252E39;
    padding: 5px;
    display: flex;
    align-items: center;
  }

  /* Tournament Logo */
  hottournaments-component .ht-tournament-logo img {
    object-fit: cover;
    transition: transform 0.3s;
  }

  hottournaments-component .ht-tournament-logo img:hover {
    transform: scale(1.05);
  }

  hottournaments-component .image-container-tournament-logo {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    inset: 0;
    text-align: center;
    width: 3.5rem;
    height: auto;
    z-index: 2;
  }

  /* Card Image Top */
  hottournaments-component .ht-card-img-top {
    height: 180px;
    object-fit: cover;
    position: relative;
  }

  hottournaments-component .ht-img-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
  }

  /* Tournament Info */
  hottournaments-component .ht-tournament-info {
    flex-grow: 1;
    margin-left: 15px;
    min-width: 0;
  }

  hottournaments-component .ht-tournament-title {
    font-size: calc(0.4rem + 0.4vw);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    display: block;
    transition: color 0.3s;
  }

  hottournaments-component .ht-tournament-card:hover .ht-tournament-title {
    color: #B22A23;
  }

  hottournaments-component .ht-tournament-dates {
    font-size: calc(0.3rem + 0.3vw);
    color: #ccc;
  }

  /* Card Body */
  hottournaments-component .ht-card-body {
    background-color: #1E252E;
    padding: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* Badges and Extras */
  hottournaments-component .ht-badge-status,
  hottournaments-component .ht-badge-tier {
    font-size: calc(0.4rem + 0.2vw);
    padding: calc(0.2rem + 0.1vw) calc(0.4rem + 0.2vw);
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: #fff;
  }

  hottournaments-component .ht-teams-prize-and-opps {
    font-size: calc(0.6rem + 0.2vw);
    display: flex;
    align-items: center;
    gap: 5px;
  }

  /* Game Logo Image */
  hottournaments-component .img-circle-container-logo-game {
    width: 2rem;
    height: 2rem;
  }

  hottournaments-component .img-circle-container-logo-game img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Responsive Adjustments */
  @media only screen and (max-width: 767px) {
    hottournaments-component .ht-tournament-title {
      font-size: calc(0.8rem + 0.6vw);
    }

    hottournaments-component .ht-tournament-dates {
      font-size: calc(0.6rem + 0.6vw);
    }
  }
</style>

<!-- Hot Tournaments Title -->
<div class="ht-component container my-2">
    <div class="ht-section-header d-flex justify-content-between align-items-center my-2">
        <h2 class="ht-section-title hotTournamentsTitle">HOT TOURNAMENTS</h2>
        <a href="/${I18n.getUsersPreferredLanguage()}/tournaments" class="btn btn-outline-secondary btn-sm ht-btn-back show-more-tournaments-button" aria-label="See All">
            <i class="bi bi-arrow-left"></i>
        </a>
    </div>

    <div class="row g-4" id="hottournaments-html"></div>
</div>

`;

HotTournamentsComponent.reg('hottournaments-component');