const {defaultLocale, supportedLocales, supportedAiAnalysesLocales, supportedArticlesLocales, translations} = require("./translation-imports");

const Translator = require("@andreasremdt/simple-translator");

// eslint-disable-next-line require-jsdoc
class I18n {
    // eslint-disable-next-line require-jsdoc
    constructor() {
        // Initialize the Translator instance
        this.translator = new Translator({
            defaultLanguage: "en",
            detectLanguage: false,
            selector: "[data-i18n]", // Not used in Node.js but kept for consistency
            debug: false,
            registerGlobally: "__", // Not used in Node.js but kept for consistency
            persist: false,
            persistKey: "preferred_language", // Not used in Node.js but kept for consistency
            filesLocation: "./i18n", // Not used in Node.js but kept for consistency
        });

        // Add translations
        supportedLocales.forEach((supportedLocale) => {
            this.translator.add(supportedLocale, translations[supportedLocale]);
        });
    }

    // eslint-disable-next-line require-jsdoc
    getCurrentlySupportedLanguages() {
        return Array.from(this.translator.languages.keys());
    }

    // eslint-disable-next-line require-jsdoc
    translateString(key, language, replacements = {}) {
        let translation = this.translator.translateForKey(key, language);
        if (!translation) {
            console.log(`No translation found for key: ${key}`);
            return key; // Fallback to the key itself if no translation is found
        }
        // Replace placeholders with actual values
        for (const [placeholder, value] of Object.entries(replacements)) {
            const regex = new RegExp(`{{${placeholder}}}`, "g");
            translation = translation.replace(regex, value);
        }
        return translation;
    }
}

module.exports = {I18n, defaultLocale, supportedLocales, supportedAiAnalysesLocales, supportedArticlesLocales, translations};
