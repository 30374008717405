import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";

const LogoDesktop = require("../../global-assets/Logo-site.png");
const LogoMobile = require("../../utilities/template-assets/assets/icons/any/icon-512x512.png");


export class MainNavbar extends BaseComponent {
  initCallback() {

    const navEntries = [ // add any new menu items here, search for .nav-link in the template and adjust the width if you add/remove items
      { key: "home", translationKey: "navMainMenu.Home" },
      { key: "matches", iconMobile: "bi bi-lightning-fill", translationKey: "navMainMenu.Matches" },
      { key: "predictions", iconMobile: "bi bi-calendar2-check-fill", translationKey: "navMainMenu.Predictions" },
      { key: "tournaments", iconMobile: "bi bi-rocket-fill", translationKey: "navMainMenu.Tournaments" },
      { key: "streamers", iconMobile: "bi bi-broadcast", translationKey: "navMainMenu.Streamers" },
      { key: "news", iconMobile: "bi bi-newspaper", translationKey: "navMainMenu.News" },
    ];

    const { desktopHTML, mobileHTML } = MainNavbar.createMenu(navEntries, LogoDesktop, LogoMobile);
    document.querySelector("#desktopNav ul.list-group").innerHTML = desktopHTML;
    document.querySelector("#mobileNav .row").innerHTML = mobileHTML;

    MainNavbar.translations(navEntries);
    window.addEventListener(
      "resize",
      MainNavbar.performOrientationChangeActions,
    );
    MainNavbar.performOrientationChangeActions();
    MainNavbar.enablePostPageRedirect(navEntries);
  }

  static translations(navEntries) {
    navEntries.forEach(entry => {
      const desktopSelector = `.${entry.key}Element`;
      const mobileSelector = `.${entry.key}ElementM`;
      const translationText = I18n.translateString(entry.translationKey);

      document.querySelectorAll(desktopSelector).forEach(el => {
        el.innerText = translationText;
      });
      document.querySelectorAll(mobileSelector).forEach(el => {
        el.innerText = translationText;
      });
    });
  }

  static performOrientationChangeActions() {
    if (window.innerHeight > window.innerWidth) {
      document.getElementById("mobileNav").style.display = "";
      document.getElementById("desktopNav").style.display = "none";
      document.getElementById('menusWrapper').classList.remove('fixed-top')
      document.getElementById('menusWrapper').classList.add('fixed-bottom')
    } else {
      document.getElementById("mobileNav").style.display = "none";
      document.getElementById("desktopNav").style.display = "";
      document.getElementById('menusWrapper').classList.remove('fixed-bottom')
      document.getElementById('menusWrapper').classList.add('fixed-top')
      document.querySelectorAll('main-navbar .menu-design span').forEach((el) => {
        el.innerText = MainNavbar.customUppercase(el.innerText);
      });
    }
  }

  static checkNavBarState() {
    // Remove active classes
    document.querySelectorAll('#desktopNav .list-group-item').forEach(el => {
      el.classList.remove('active');
    });
    document.querySelectorAll('#mobileNav .nav-link').forEach(el => {
      el.classList.remove('activeMobile');
    });

    // Parse path segments
    const pathSegments = window.location.pathname.split('/').filter(s => s.length > 0);

    // Decide active key: if only one segment or none, default to "home"; otherwise, use the second segment.
    const activeKey = (pathSegments.length < 2)
      ? "home"
      : pathSegments[1].toLowerCase();

    // Build selectors based on activeKey
    const desktopSelector = `#desktopNav .${activeKey}Desktop`;
    const mobileSelector = `#mobileNav .${activeKey}Mobile`;

    // Add active classes
    const desktopActiveEl = document.querySelector(desktopSelector);
    if (desktopActiveEl) desktopActiveEl.classList.add('active');

    const mobileActiveEl = document.querySelector(mobileSelector);
    if (mobileActiveEl) mobileActiveEl.classList.add('activeMobile');
  }

  static enablePostPageRedirect(navEntries) {
    const lang = I18n.getUsersPreferredLanguage();

    // Set brand link
    const brandLink = document.getElementById("navDesktopElement");
    if (brandLink) {
      brandLink.setAttribute("href", `/${lang}`);
    }

    navEntries.forEach(entry => {
      // For home, the path is empty; otherwise, it's the key.
      const path = entry.key === "home" ? "" : entry.key;
      const fullPath = path ? `/${lang}/${path}` : `/${lang}`;

      // Combine mobile and desktop selectors
      const selectors = `.${entry.key}Mobile, .${entry.key}Desktop`;
      document.querySelectorAll(selectors).forEach(el => {
        el.setAttribute("href", fullPath);
      });
    });
  }

  static customUppercase(str) { // this lives due to the Greek accented characters for mobile menu, which are not uppercase, but on desktop they are and we need to remove the accents
    let result = '';
    for (let i = 0; i < str.length; i++) {
      let char = str[i];

      switch (char) {
        case 'Ά':
          result += 'Α';
          break;
        case 'Έ':
          result += 'Ε';
          break;
        case 'Ή':
          result += 'Η';
          break;
        case 'Ί':
          result += 'Ι';
          break;
        case 'Ό':
          result += 'Ο';
          break;
        case 'Ύ':
          result += 'Υ';
          break;
        case 'Ώ':
          result += 'Ω';
          break;
        default:
          result += char.toUpperCase();
      }
    }
    return result;
  }

  static createMenu(navEntries, logoForHomeDesktop, logoForHomeMobile) {

    let desktopHTML = "";
    let mobileHTML = "";
    desktopHTML += /*html*/ `
    <div class="img-container h-100 d-flex align-items-center justify-content-center">
      <a id="navDesktopElement">
        <img class="navbar-brand ms-3" src="${logoForHomeDesktop}" alt="myesports" id="navDesktopImage">
      </a>
    </div>
    `;
    navEntries.forEach(({ key, iconMobile }) => {
      // 1. Generate classes
      const desktopClass = key + "Desktop";
      const mobileClass = key + "Mobile";
      const labelClassDesktop = key + "Element";
      const labelClassMobile = key + "ElementM";

      // 2. Desktop link
      desktopHTML += /*html*/`
      <a class="list-group-item resize-desktop d-flex align-items-center ${desktopClass}">
        <span class="${labelClassDesktop}"></span>
      </a>
    `;

      // 3. Mobile link
      let iconOrLogo = "";
      if (key === "home") {
        iconOrLogo = `<img class="navbar-brand m-0 p-0" id="navMobileElement" src="${logoForHomeMobile}" alt="myesports"/>`;
      } else if (iconMobile) {
        iconOrLogo = `<i class="${iconMobile}"></i>`;
      }

      mobileHTML += /*html*/`
      <a class="nav-link d-flex flex-column align-items-center ${mobileClass}">
        ${iconOrLogo}
        <p class="list-group-itemM resiz-mobile m-0 ${labelClassMobile}"></p>
      </a>
    `;
    });

    return { desktopHTML, mobileHTML };

  }
}

MainNavbar.template = /*html*/ `
<style>
  main-navbar .img-container {
    width: calc(12.5rem + 1.5vw);
    background: #161B22;
    transform: skew(-30deg);
    margin-left: -1rem;
    transition: width 0.5s ease-in-out;
  }

  main-navbar #navDesktopImage {
    height: 2.5rem;
    transform: skew(30deg);
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
  }

  main-navbar .img-container:hover #navDesktopImage {
    transform: skew(30deg) scale(1.1);
  }

  main-navbar .navbar {
    background-color: #1c232c !important;
    flex-wrap: nowrap;
  }

  main-navbar .menu-design {
    height: calc(1rem + 3vw);
    padding: 0;
  }

  main-navbar .list-group-item,
  main-navbar .list-group-itemM {
    color: rgba(196, 196, 196, 0.8) !important;
  }

  main-navbar .list-group-item {
    transform: skew(-30deg);
    background-color: transparent;
    border: transparent;
    font-weight: bold;
    cursor: pointer;
  }

  main-navbar .menu-design span {
    text-transform: uppercase;
    transform: skew(30deg);
  }

  main-navbar .list-group-item:hover {
    background-color: #1f2731;
    color: #c4c4c4 !important;
  }

  main-navbar .active {
    background-color: #161B22 !important;
    border-top: 2px solid #B22A23 !important;
  }

  main-navbar .activeMobile {
    background-color: #ff282859;
    color: #ffffff;
  }

  main-navbar #navMobileElement {
    height: 2.5rem;
    cursor: pointer;
  }

  main-navbar .navbar-nav,
  main-navbar .nav-link {
    color: #fffffe;
  }

  main-navbar .nav-link {
    width: calc(100% / 6) !important; /* 6 is the number of menu mobile items */
  }

  main-navbar #mobileNav a {
    padding-left: 1rem;
    cursor: pointer;
  }

  main-navbar #mobileNav .nav-link {
    padding: 0.4rem !important;
    cursor: pointer;
  }

  main-navbar .list-group-horizontal > .list-group-item:first-child:not(:last-child) {
    border-radius: 0;
  }

  main-navbar i {
    font-size: 1.5rem;
  }

  main-navbar .resiz-mobile {
    font-size: 0.5rem;
  }

  main-navbar .resize-desktop {
    font-size: calc(0.5rem + 0.5vw);
  }
</style>

<nav id="desktopNav" class="navbar menu-design">
  <ul class="list-group list-group-horizontal ms-0 menu-design-ul h-100 mx-auto">
  </ul>
</nav>

<nav id="mobileNav" class="navbar p-0 m-0">
  <div class="d-flex justify-content-around p-0 m-0 w-100">
    <div class="row w-100 text-white-50">
    </div>
  </div>
</nav>
`;

MainNavbar.reg("main-navbar");