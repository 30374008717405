export class GeofencingGuard {
    static async initiate() {
        try {
            const response = await fetch('https://geo-guard-tight-field-792a.savvas-sidiropoulos96.workers.dev/', {
                method: "GET",
                cache: "no-cache",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            });

            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

            const data = await response.json();
            window.geoFencingGuard = data.country || "EN";
        } catch (error) {
            console.error("Error fetching user country:", error);
            window.geoFencingGuard = "EN";
        }
    }

    static getUserCountry(callback) {
        if (window.geoFencingGuard === undefined) {
            setTimeout(() => {
                GeofencingGuard.getUserCountry(callback);
            }, 50);
        } else {
            callback(window.geoFencingGuard);
        }
    }
}