import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { enableStatsDropdown, displayStatsPeriod, populateDropdownFromStats, statisticsTooltips } from "../../utilities/general/tipsterStatistics";
import { extractFirstTextBlock } from "../../utilities/general/extractFirstTextBlock";
import { newsCarouselForAuthors } from "../../utilities/general/newsCarouselForAuthors";
import { zoomIconsListener } from "../../utilities/general/zoomIconsListener";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { theConverter } from "../../utilities/general/showdownConverterMyesports";
import { makeAtagsForAuthorsSocials } from "../../utilities/general/socialIcons";
import { renderPredictions } from "../../utilities/general/predictionsDisplay";

const noAuthorImg = require('../../global-assets/hoodieman.webp');

export class PostAuthorComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('postauthor-component', PostAuthorComponent.initiator)
  }

  static initiator() {
    getDataFrontend('savedAuthors', 'getAuthors', 'fromPostAuthorsComponent', function () { // Callback function
      if (document.querySelectorAll('#author-post').length !== 0) {

        function getAuthorIdFromURL() {
          const match = window.location.pathname.match(/\/([a-z]{2})\/author\/([^/?&]+)/);
          return match ? match[2] : null;
        }
        const authorId = getAuthorIdFromURL();

        console.debug('I am running the authors component call back!');
        if (window.savedNews === undefined) {
          getDataFrontend('savedNews', 'getNews', 'fromPostAuthorsComponent', function () { // Callback function
            console.debug('Got the news cause we didnt have them beforehand');

            PostAuthorComponent.updateAuthor(authorId);

          });
        } else {
          PostAuthorComponent.updateAuthor(authorId);
        }
      } else {
        console.debug('The authors component call back is supposed to run but I am not on the correct page!!!')
      }
    }
    );
  }

  static dynamicTranslations() {
    document.querySelector('.th-date').innerHTML = I18n.translateString("predictionsTranslations.date");
    document.querySelector('.th-auth-match').innerHTML = I18n.translateString("predictionsTranslations.match");
    document.querySelector('.th-auth-tournament').innerHTML = I18n.translateString("predictionsTranslations.tournament");
    document.querySelector('.th-auth-prediction').innerHTML = I18n.translateString("predictionsTranslations.plainPrediction");
    document.querySelector('.translate-auth-stats-avg-odds').innerHTML = I18n.translateString("predictionsTranslations.statsAvgOdds") + " <i class='bi bi-info-circle'></i>";
    document.querySelector('.translate-auth-stats-earn').innerHTML = I18n.translateString("predictionsTranslations.statsEarn") + " <i class='bi bi-info-circle'></i>";
    document.querySelector('.translate-auth-stats-bets').innerHTML = I18n.translateString("predictionsTranslations.statsBets");
    document.querySelector('.translate-auth-period').innerHTML = I18n.translateString("predictionsTranslations.statisticPeriod");
    document.querySelector('.translate-auth-stats-open-bets').innerHTML = I18n.translateString("predictionsTranslations.statsOpenBets");
    document.querySelector('.translate-authpost-statistics').innerHTML = I18n.translateString("predictionsTranslations.authStats");
    document.querySelector('.translate-authpost-last10').innerHTML = I18n.translateString("predictionsTranslations.last10");
    document.querySelector('.translate-authpost-articles').innerHTML = I18n.translateString("predictionsTranslations.articles");
    document.querySelector('.translate-biography').innerHTML = I18n.translateString("predictionsTranslations.authBio");
    if (document.querySelector('.translate-bio-coming')) { document.querySelector('.translate-bio-coming').innerHTML = I18n.translateString("predictionsTranslations.bioComing"); }
    if (document.querySelector('.show-more-predictions')) { document.querySelector('.show-more-predictions').innerHTML = I18n.translateString("predictionsTranslations.showMorePredictions"); }
  }

  static updateAuthor(authorIdentifier) {
    // console.debug(authorIdentifier);
    const authorsContainer = document.getElementById('author-post');
    authorsContainer.innerHTML = '';
    let html = '';

    // Convert authorIdentifier to lowercase
    const lowerCaseAuthorIdentifier = authorIdentifier.toLowerCase();

    // Find the author in a case-insensitive manner
    const authorKey = Object.keys(window.savedAuthors).find(key => key.toLowerCase() === lowerCaseAuthorIdentifier);
    const author = window.savedAuthors[authorKey];

    const selectedBioContent = author.authorBio && author.authorBio[I18n.getUsersPreferredLanguage()] && author.authorBio[I18n.getUsersPreferredLanguage()] !== '' && author.authorBio[I18n.getUsersPreferredLanguage()] !== 'N/A' ?
      author.authorBio[I18n.getUsersPreferredLanguage()] : author.authorBio && author.authorBio[I18n.defaultLocale] && author.authorBio[I18n.defaultLocale] !== '' && author.authorBio[I18n.defaultLocale] !== 'N/A' ?
        author.authorBio[I18n.defaultLocale] : 'N/A';
    let authorBio = theConverter().makeHtml(selectedBioContent);
    authorBio = authorBio.replace(/<h1/g, '<h2').replace(/<\/h1>/g, '</h2>');
    const clickBaitString = extractFirstTextBlock(authorBio, "bio-coming");
    const authorPhoto = !author.photo || (author.photo === "" || author.photo === "N/A") ? noAuthorImg : author.photo;

    const newshtml = newsCarouselForAuthors(author.name, author);

    html += /*html*/ `
             <div class="author-card row w-100 color-lightdark rounded">
              <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                <h5 class="mb-0 titlesSelector me-2">
                  ${author.fullName} (${author.name})
                </h5>
                  ${makeAtagsForAuthorsSocials(author.socials)}
              </div>

              <div class="col-lg-2 col-md-12 col-12 d-flex flex-column align-items-center justify-content-start author-photo-container p-0">
                <div class="img-circle-container-author-photo d-flex align-items-center">
                  <img src="${authorPhoto}" alt="Author Photo" class="img-fluid mx-auto d-block">
                </div>
              </div>

              <div class="col-lg-10 col-md-12 col-12 mt-3">
                <div class="accordion accordion-flush my-3 row" id="accordionFlush">
                  <div class="accordion-item">
                    <div class="accordion-header" id="flush-heading">
                      <button class="accordion-button collapsed rounded-top color-lightdark clickable" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse" aria-expanded="false" aria-controls="flush-collapse">
                        <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                          <span class="text-name-ai-resize text-white bio-title translate-biography">Biography</span>
                          <span class="text-ai-resize mt-3 text-truncate-3 text-white bio-clickbait">
                            <em>"${clickBaitString}"</em>
                          </span>
                        </div>
                      </button>
                    </div>
                    <div id="flush-collapse" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlush">
                      <div class="accordion-body color-dark rounded-bottom">
                        <div class="mt-3 HtmlPlaceholder">
                          ${authorBio}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="author-card row w-100 mt-5 color-lightdark rounded ${author.newsPosts.length === 0 ? 'd-none' : ''}">
              <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                <h2 class="mb-0 ms-3 titlesSelector translate-authpost-articles">Articles</h2>
              </div>

              <div class="col-12 pb-1 p-1 rounded-bottom ${author.newsPosts.length === 0 ? 'd-none' : ''}">
                <div class="position-relative">
                  <div id="articleCarousel-${authorIdentifier}" class="carousel slide mt-3" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      ${newshtml}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="author-card row w-100 mt-5 color-lightdark rounded predictions-stats-card">
              <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                <h2 class="mb-0 ms-3 titlesSelector translate-authpost-statistics">Predictions stats</h2>
              </div>

              <div class="col-12 pb-1 p-1 rounded-bottom">
                <div class="d-inline-flex justify-content-center align-items-center w-100 p-0 mb-3">
                  <select id="statsPeriod">
                    <option value="All-time">All-time</option>
                  </select>
                </div>

                <table class="predictions-table">
                  <thead>
                    <tr>
                      <th class="timeFrame-th translate-auth-period">Period</th>
                      <th class="translate-auth-stats-bets">Bets</th>
                      <th class="translate-auth-stats-earn tooltip-engage" data-tooltip="EarnExplain">Κέρδος</th>
                      <th class="tooltip-engage" data-tooltip="WinrateExplain">(%) <i class="bi bi-info-circle"></i></th>
                      <th class="tooltip-engage" data-tooltip="RoiExplain">ROI <i class="bi bi-info-circle"></i></th>
                      <th class="translate-auth-stats-avg-odds tooltip-engage" data-tooltip="AvgOddsExplain">Μ.Ο. απ.</th>
                      <th class="translate-auth-stats-open-bets">Open bets</th>
                    </tr>
                  </thead>
                  <tbody id="predictions-stats-tbody2">
                    <!-- Predictions stats will be injected here by JavaScript -->
                  </tbody>
                </table>
              </div>

              <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                <h2 class="mb-0 ms-3 titlesSelector translate-authpost-last10">Last 10 predictions</h2>
              </div>

              <div class="col-12 pb-1 p-1 rounded-bottom">
                <table class="predictions-table">
                  <thead>
                    <tr>
                      <th class="th-game"></th>
                      <th class="th-date">Time</th>
                      <th class="th-auth-match">Match</th>
                      <th class="th-auth-tournament">Tournament</th>
                      <th class="th-auth-prediction">Prediction</th>
                      <th class="th-outcome"></th>
                    </tr>
                  </thead>
                  <tbody id="author-predictions-tbody">
                    <!-- Predictions rows will be injected here by JavaScript -->
                  </tbody>
                </table>
              </div>

              <button id="show-more-authorPredictions-button" class="mx-auto mt-2 mb-2 show-more-predictions">
                Show More
              </button>
            </div>
        `;


    document.querySelector('#author-post').innerHTML = html;

    zoomIconsListener(document.querySelectorAll('.author-card-img-top'));

    author.predictions.sort((a, b) => {
      const dateA = a.matchDate.toDate();
      const dateB = b.matchDate.toDate();
      return dateB - dateA;
    });

    renderPredictions(author.predictions, "author-predictions-tbody", {
      showGameLogo: true,
      showAuthor: false,
      dateFormat: "long",
      locale: I18n.getUsersPreferredLanguage(),
    });

    populateDropdownFromStats(author.statistics);
    enableStatsDropdown(author.statistics);
    displayStatsPeriod(author.statistics, {
      periodKey: "All-time",            // or "01-2025"
      showPeriodCell: true,            // We want a column for "All-time" or the month
      showAuthorCell: false,           // Single author page => no author column
      isClickable: false,
      baseUrl: null,
      tableId: "predictions-stats-tbody2"  // Where we want to append
    });

    PostAuthorComponent.dynamicTranslations();
    statisticsTooltips(20, "tooltip-authorpage-stats");

  }

}



PostAuthorComponent.template = /*html*/`
<style>
/* Stats Period Dropdown */
#statsPeriod {
  background-color: #161b22;
  color: #fff;
  border: 1px solid #2c2c2c;
  padding: 8px 12px;
  font-size: calc(0.6rem + 0.3vw);
  border-radius: 6px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

#statsPeriod:hover {
  background-color: rgba(22, 27, 34, 0.7);
}

#statsPeriod:focus {
  box-shadow: 0 0 5px #161b22;
}

/* WebKit Dropdown Arrow */
#statsPeriod::-webkit-dropdown-arrow {
  color: #fff;
}

/* Dropdown Options */
#statsPeriod option {
  background-color: #161b22;
  color: #fff;
  padding: 10px;
}

/* Scrollbar (WebKit) */
#statsPeriod::-webkit-scrollbar {
  width: 8px;
}

#statsPeriod::-webkit-scrollbar-thumb {
  background-color: #5a5a7f;
  border-radius: 4px;
}

#statsPeriod::-webkit-scrollbar-track {
  background-color: #1e1e2f;
}

/* Show More Button */
postauthor-component .show-more-predictions {
  background-color: #58a6ff;
  border: none;
  color: #ffffff;
  font-size: calc(0.6rem + 0.3vw);
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  text-align: center;
  width: auto;
}

postauthor-component .show-more-predictions:hover {
  background-color: rgb(65, 122, 189);
  box-shadow: 0 6px 18px rgb(17, 66, 121);
}

/* Accordion Styles */
postauthor-component .accordion-item {
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
  border-radius: 15px !important;
  padding: 0;
}

postauthor-component .accordion-button:hover,
postauthor-component .accordion-button:focus {
  background-color: #2c2c2c;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
  outline: none;
}

postauthor-component .accordion-button::after {
  display: none; /* Remove default caret */
}

postauthor-component .accordion-button::before {
  content: '';
  display: inline-block;
  margin-right: 15px;
  border: solid rgba(0, 247, 255, 0.6);
  border-width: 0 3px 3px 0;
  padding: 7px;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

postauthor-component .accordion-button.collapsed::before {
  transform: rotate(-135deg);
}

postauthor-component .accordion-body {
  padding: 25px;
  color: #e0e0e0;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 0 0 15px 15px;
}

/* Bio Title & Clickbait */
postauthor-component .bio-title {
  font-size: calc(0.8rem + 0.4vw);
  font-weight: 700;
  display: block;
}

postauthor-component .bio-clickbait {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  transition: opacity 0.3s ease-in-out;
}

/* Predictions Table Styles */
postauthor-component .predictions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #161b22;
  border: 1px solid #30363d;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

postauthor-component .predictions-table th {
  padding: calc(0.3rem + 0.3vw);
  text-align: center;
  background-color: #21262d;
  color: #58a6ff;
  font-size: calc(0.4rem + 0.3vw);
  font-weight: 700;
  position: sticky;
  top: 0;
}

postauthor-component .predictions-table td {
  border-bottom: 1px solid #30363d;
}

postauthor-component .predictions-table a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
  padding: calc(0.3rem + 0.3vw);
  text-align: center;
  vertical-align: middle;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.4rem + 0.4vw);
}

postauthor-component .predictions-table tbody tr:hover {
  background-color: #30363d;
  cursor: pointer;
}

/* Title Selector */
postauthor-component .titlesSelector {
  font-size: calc(0.8rem + 0.3vw);
  font-weight: 800;
  color: #ffffff;
}

/* Author Photo Container */
postauthor-component .img-circle-container-author-photo {
  width: calc(4rem + 3vw);
  height: auto;
}

postauthor-component .img-circle-container-author-photo img {
  max-width: 100%;
  max-height: 100%;
}

/* Responsive Adjustments */
@media only screen and (max-width: 768px) {
  postauthor-component .th-auth-tournament,
  .tournament-name,
  .timeFrame-th,
  .timeFrame-td {
    display: none;
  }
}
</style>

  <div class="container-fluid d-flex flex-column justify-content-center align-content-center align-items-center" id="author-post">
  </div>

`;

PostAuthorComponent.reg('postauthor-component');