import { I18n } from "../../utilities/i18n/i18n";

export function emptyMessage(element, translatedMessage, className) {
    element.insertAdjacentHTML('beforeend', `
                      <div class="mt-3 ${className}">
                      </div>
                      `);
    if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
        document.querySelector(`.${className}`).innerHTML = I18n.translateString(translatedMessage, { game: document.querySelector('.gameNameActive').innerText });
    } else {
        document.querySelector(`.${className}`).innerHTML = I18n.translateString(translatedMessage, { game: '' });
    }
    return;
}