import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { setupBookies } from "../../utilities/general/setupBookies";

export class WidgetBookmakers extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('widget-bookmakers', WidgetBookmakers.initiator);
        WidgetBookmakers.translations();
    }

    static initiator() {
        getDataFrontend('savedBookmakers', 'getBookmakers', 'fromWidgetBookmakersComponent', function () {
            if (document.querySelector('#widgetbookmakers-container')) {
                WidgetBookmakers.updateBookmakers();
            }
        });
    }

    static updateBookmakers() {
        setupBookies('#widgetbookmakers-container', (bookmakerData, language, count) => {
            if (count >= 3) {
                return ``;
            }
            return /*html*/ `
                            <div class="bookmaker-card">
                                <a href="/${I18n.getUsersPreferredLanguage()}/bookmaker/${bookmakerData.id}" class="bookmaker-logo-wrapper clickable" id="bookmaker-${bookmakerData.id}">
                                    <img src="${bookmakerData.bookmaker_logo || ''}" alt="${bookmakerData.name || ''} Logo">
                                </a>
                                <div class="details">
                                    <div class="bonus-text">${(bookmakerData.bookmaker_hot_bonus && bookmakerData.bookmaker_hot_bonus[language]) || (bookmakerData.bookmaker_hot_bonus && bookmakerData.bookmaker_hot_bonus[I18n.defaultLocale]) || ''}</div>
                                </div>
                                <a href="/en/offers/${bookmakerData.id}" class="play-bookmakers-button cta-button" target="_blank" rel="noopener noreferrer"></a>
                            </div>
                            `;
        });

        WidgetBookmakers.dynamicTranslations();
    }

    static translations() {
        document.querySelectorAll('.bookmakers-title').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersList.widgetTitle");
        });
        document.querySelectorAll('#see-all-bookmakers-button').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersList.widgetButton");
        });
    }

    static dynamicTranslations() {
        document.querySelectorAll('.play-bookmakers-button').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersList.playNow");
        });
    }

}

WidgetBookmakers.template = /*html*/ `
<style>
/* Widget Title */
widget-bookmakers .widget-title {
    color: #D3D3D3;
    font-size: calc(0.3rem + 0.8vw);
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Bookmaker Card */
widget-bookmakers .bookmaker-card {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #252E39;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 12px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3), -3px -3px 6px rgba(30, 33, 50, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
widget-bookmakers .bookmaker-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

/* Logo Wrapper */
widget-bookmakers .bookmaker-logo-wrapper {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    padding: 10px;
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
widget-bookmakers .bookmaker-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

/* Details Section */
widget-bookmakers .details {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 3px;
}

/* Bonus Text */
widget-bookmakers .bonus-text {
    font-size: calc(0.3rem + 0.4vw);
    color: #A6A9B3;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

/* CTA Button */
widget-bookmakers .cta-button {
    background-color: #28A745;
    color: white !important;
    font-weight: bold;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: calc(0.4rem + 0.5vw);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 0px 8px rgba(40, 167, 69, 0.5);
}
widget-bookmakers .cta-button:hover {
    background-color: #21a039;
    box-shadow: 0px 0px 15px rgba(40, 167, 69, 0.8);
}

/* See All Button */
widget-bookmakers .see-all-button {
    text-decoration: none;
    text-transform: none;
    background-color: transparent;
    border: 1px solid #D3D3D3 !important;
    color: #D3D3D3 !important;
    padding: calc(0.2rem + 0.2vw) calc(0.4rem + 0.4vw);
    border-radius: 8px;
    font-size: calc(0.3rem + 0.3vw);
    transition: background-color 0.3s ease, color 0.3s ease;
}
widget-bookmakers .see-all-button:hover {
    background-color: #D3D3D3 !important;
    color: #0D1117 !important;
}

/* Responsive Styles */
@media only screen and (max-width: 1024px) {
    widget-bookmakers .widget-title {
        font-size: 1rem;
    }
    widget-bookmakers .see-all-button,
    widget-bookmakers .bonus-text {
        font-size: 0.6rem;
    }
    widget-bookmakers .cta-button {
        padding: 15px 22px;
        font-size: 0.9rem;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1600px) {
    widget-bookmakers .bookmaker-card {
        flex-direction: column;
    }
    widget-bookmakers .bonus-text {
        text-align: center;
    }
}
</style>

<div class="widget-container mt-4">
    <div class="widget-title">
        <span class="bookmakers-title">Bookmakers</span>
        <a href="/${I18n.getUsersPreferredLanguage()}/bookmakers" id="see-all-bookmakers-button" class="see-all-button">
        </a>
    </div>
    <!-- Bookmakers List -->
    <div id="widgetbookmakers-container"></div>
</div>
`;

WidgetBookmakers.reg('widget-bookmakers');
