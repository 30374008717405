import { globalSocials } from "../../utils/globals.js";

export const greekTranslation =
{
    "translationLanguageInLocal": "Ελληνικά",
    "metaTags": {
        "keyword1": "Esports live stream",
        "keyword2": "Τουρνουά eSports",
        "keyword3": "League of Legends esports",
        "keyword4": "CS:GO / Counter-Strike Τουρνουά",
        "keyword5": "Dota 2 TI (The International)",
        "keyword6": "Αναλύσεις AI και ειδικών esports",
        "keyword7": "Esports προβλέψεις και προγνωστικά",
        "keyword8": "Esports νέα και άρθρα",
        "singleMatchTitleLive": "{{team1}} vs {{team2}} | LIVE-Ζωντανά: {{score1}} - {{score2}} | {{game}}: {{tournament}}",
        "singleMatchTitleFinished": "{{team1}} vs {{team2}} | Αποτέλεσμα: {{score1}} - {{score2}} | {{game}}: {{tournament}}",
        "singleMatchTitleUpcoming": "{{team1}} vs {{team2}} | {{game}}: {{tournament}}",
        "singleMatchTitleFallback": "{{team1}} vs {{team2}} | {{game}}: {{tournament}}",
        "singleMatchDescriptionLive": "{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} για το {{tournament}}. Παρακολούθησε live - ζωντανά! Ο αγώνας(match) ξεκίνησε στις {{date}}.  Δες στατιστικά, προγνωστικά και AI ανάλυση στο MyEsports!",
        "singleMatchDescriptionFinished": "{{game}}: {{teamA}} faced {{teamB}} in {{tournament}}. Ο αγώνας(match) ολοκληρώθηκε {{date}} με σκορ {{score1}} - {{score2}}. Δες στατιστικά, προγνωστικά και AI ανάλυση στο MyEsports!",
        "singleMatchDescriptionUpcoming": "{{game}}: {{teamA}} εναντίων {{teamB}} για το {{tournament}}. Ο αγώνας(match) ξεκινάει στις {{date}}. Δες στατιστικά, προγνωστικά και AI ανάλυση στο MyEsports! Παρακολούθησε το εδώ!",
        "singleMatchDescriptionFallback": "{{game}}: {{teamA}} εναντίων {{teamB}} για το {{tournament}}. Ο αγώνας(match) ξεκινάει στις {{date}}. Δες στατιστικά, προγνωστικά και AI ανάλυση στο MyEsports! Παρακολούθησε live - ζωντανά!",
        "singleTournamentTitle": "{{game}} Τουρνουά – {{tournament}} | MyEsports",
        "singleTournamentDescription": "Ανακάλυψε τα τελευταία αποτελέσματα και το πρόγραμμα του {{tournament}} στο MyEsports! Ένα tier {{tier}} {{game}} τουρνουά με ομάδες όπως {{opponents}}!",
        "singleAuthorTitle": "{{author}} - MyEsports Αρθογράφος",
        "matchesTitle": "Esports Αγώνες – Ζωντανά Αποτελέσματα (Live Scores) & Πρόγραμμα | MyEsports",
        "matchesDescription": "Μείνε ενημερωμένος με όλα τα esports αποτελέσματα & επερχόμενους αγώνες στο MyEsports. Δες ζωντανά σκορ, πρόγραμμα, προγνωστικά (tips), αναλύσεις & στατιστικά για CS2, Dota 2, LoL & άλλα!",
        "tournamentsTitle": "Esports Τουρνουά – Επερχόμενα & Ολοκληρωμένα Events | MyEsports",
        "tournamentsDescription": "Ανακάλυψε τα μεγαλύτερα esports τουρνουά στο MyEsports! Δες πρόγραμμα ζωντανά (live), αποτελέσματα, έπαθλα (prize pools) & ομάδες για CS2, Dota 2, LoL, Valorant & άλλα!",
        "newsTitle": "Esports Νέα και Άρθρα – Τελευταία Νέα & Αναλύσεις | MyEsports",
        "newsDescription": "Δες τις τελευταίες ειδήσεις & άρθρα για esports στο MyEsports! Νέα, αναλύσεις & συνεντεύξεις για CS2, Dota 2, LoL, Valorant & άλλα. Μείνε ενημερωμένος!",
        "authorsTitle": "Γνώρισε τους Αρθρογράφους Esports – Νέα, Προγνωστικά & Αναλύσεις | MyEsports",
        "authorsDescription": "Ανακάλυψε τους ειδικούς του MyEsports! Δες τους αρθρογράφους που σου φέρνουν ειδήσεις-νέα, προγνωστικά-tips & αναλύσεις για CS2, Dota 2, LoL & άλλα esports!",
        "bookmakersTitle": "Top 10 Στοιχηματικές Εταιρείες Esports – Καλύτερες Αποδόσεις & Προσφορές | MyEsports",
        "bookmakersDescription": "Ανακάλυψε τις καλύτερες στοιχηματικές εταιρείες για esports στο MyEsports! Δες τις καλύτερες αποδόσεις, προσφορές & bonus για CS2, Dota 2, LoL, Valorant & άλλα!",
        "skinbookmakersTitle": "Esports Skins & Cases – Αξιόπιστοι Συνεργάτες | MyEsports",
        "skinbookmakersDescription": "Βρες αξιόπιστες πλατφόρμες για αγορά, πώληση, bet & trade skins και cases στο MyEsports! Ασφαλές trading για CS2, Dota 2, Rust & άλλα, με τις καλύτερες προσφορές!",
        "predictionsTitle": "Esports Προγνωστικά & Στοιχηματικά Tips – Ανάλυση Αγώνων | MyEsports",
        "predictionsDescription": "Βρες τα καλύτερα esports προγνωστικά & στοιχηματικές συμβουλές στο MyEsports! ΑΙ Ανάλυση αγώνων, αποδόσεις & στρατηγικές νίκης για CS2, Dota 2, LoL & άλλα!",
        "termsOfUseTitle": "Όροι Χρήσης – Κανονισμοί & Συμφωνία Χρηστών | MyEsports",
        "termsOfUseDescription": "Διάβασε τους Όρους Χρήσης του MyEsports. Μάθε για τις πολιτικές, τους κανονισμούς και τη συμφωνία χρήσης της πλατφόρμας για esports, στοιχήματα & συνεργάτες.",
        "privacyPolicyTitle": "Πολιτική Απορρήτου – Προστασία Δεδομένων & Ασφάλεια | MyEsports",
        "privacyPolicyDescription": "Μάθε πώς το MyEsports προστατεύει τα δεδομένα σου. Διάβασε την Πολιτική Απορρήτου μας για πληροφορίες χρηστών, μέτρα ασφαλείας & συμμόρφωση με GDPR.",
        "faqTitle": "Συχνές Ερωτήσεις (FAQ) – Υποστήριξη & Βοήθεια | MyEsports",
        "faqDescription": "Βρες απαντήσεις σε συχνές ερωτήσεις για το MyEsports. Διάβασε το FAQ μας για esports, στοιχηματικούς συνεργάτες, προγνωστικά, αγοραπωλησίες skins & άλλα!",
        "streamersTitle": "Esports Streamers – Ζωντανές Μεταδόσεις & Κανάλια | MyEsports",
        "streamersDescription": "Βρες τους καλύτερους esports streamers στο MyEsports! Δες ζωντανές μεταδόσεις, gameplay, συνεντεύξεις & highlights για CS2, Dota 2, LoL, Valorant & άλλα!",
        "homeTitle": "MyEsports – Esports για Νέα, Αγώνες, Στοιχήματα & Προγνωστικά",
        "homeDescription": "Μπες στον κόσμο των esports με το MyEsports! Δες ειδήσεις, αποτελέσματα αγώνων, live scores, στοιχηματικά προγνωστικά, τουρνουά για CS2, Dota 2, LoL, Valorant & άλλα!.",
    },
    "homePage": {
        "latestAnalyses": 'ΤΕΛΕΥΤΑΙΕΣ ΑΙ ΑΝΑΛΥΣΕΙΣ'
    },
    "networkGuard": {
        "title": "Ώπα!",
        "description": "Η σελίδα που ζητήσατε δεν υπάρχει.",
    },
    "navMainMenu": {
        "Home": "Αρχική",
        "Tournaments": "Τουρνουά",
        "Matches": "Αγώνες",
        "Giveaways": "Κέρδισε",
        "Casinos": "Στοιχηματικές",
        "AboutUs": "Σχετικά με εμάς",
        "Streamers": "Streamers",
        "News": "Νέα",
        "Predictions": "Προγνωστικά",
        "navDesktopTitle": "MyEsports",
    },
    "hotmatchTranslations": {
        "playerNationalities": "Συμμετέχουν παίχτες από {{countries}}",
        "playerAges": "Ο νεότερος παίχτης είναι {{Age}} χρονών",
        "viewers": "{{maxviewers}} Θεατές",
        "alternativeTimeLineHours": "Βλέπετε το {{RGN}}ο παιχνίδι. {{hourz}} ώρα και {{minutez}} λεπτά που ξεκίνησε η σειρά!",
        "alternativeTimeLineMinutes": "Βλέπετε το {{RGN}}ο παιχνίδι. {{minutez}} λεπτά που ξεκίνησε η σειρά!",
        "badgeHot": `<i class="me-2 bi bi-fire"></i>ΚΟΡΥΦΑΙΟ`,
        "noLiveMsg": `<h2>Μείνετε ψύχραιμοι, οι αγώνες είναι προ των πυλών!</h2><p>Φαίνεται ότι η {{game}} σκηνή κάνει μια παύση. Συνεχίστε να τσεκάρετε!</p>`,
    },
    "matchTranslations": {
        "matchMatchUpcoming": `Ξεκινάει σε {{minutes}} λεπτά`,
        "matchMatchLive": `ΖΩΝΤΑΝΑ`,
        "matchMatchFinished": `Τελικό`,
        "matchMatchPostponed": `Αναβολή`,
        "matchMatchCanceled": `Ακυρώθηκε`,
        "matchMatchAboutTo": `Ξεκινάει..`,
        "homeMatchDHM": `{{days}}Μ {{hours}}Ω {{minutes}}Λ`,
        "homeMatchHM": `{{hours}}Ω {{minutes}}Λ`,
        "homeMatchM": `{{minutes}}Λ`,
        "homeMatchLive": `ΖΩΝΤΑΝΑ`,
        "homeMatchAboutTo": `ΞΕΚΙΝΑΕΙ..`,
        "pillLive": "ΖΩΝΤΑΝΑ",
        "pillUpcoming": "ΠΡΟΣΕΧΩΣ",
        "pillFinished": "ΤΕΛΙΚΑ",
        "pillPredictions": "ΠΡΟΒΛΕΨΕΙΣ",
        "pillAll": "ΟΛΑ",
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"></i> ΑΓΑΠΗΜΕΝΑ`,
        "bestofX": "Στις {{gamesNumber}} νίκες",
        "bestof1": "Μονός αγώνας",
        "bestof2": "2 παιχνίδια",
        "allGamesPlayed": "{{gamesNumber}} παιχνίδια",
        "allGamesPlayedMicro": "{{gamesNumber}}Π",
        "firstToX": "Στις {{gamesNumber}} νίκες",
        "showMoreMatches": "Περισσότερα",
        "emptyGameFilterMessage": "Δεν υπάρχουν αγώνες {{game}} προς το παρόν.",
        "emptyStateFilterMessage": "Δεν υπάρχουν αγώνες {{game}} με τα επιλεγμένα φίλτρα.",
        "emptyPredictionFilterMessage": "Δεν υπάρχουν προγνωστικά {{predGame}} για την επιλεγμένη ημερομηνία.",
        "emptyAnalysisFilterMessage": "Δεν υπάρχουν πρόσφατες αναλύσεις {{game}}",
        "searchPlaceholder": "Αναζήτηση για τουρνουά και αγώνες...",
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return Math.floor(numberOfGames / 2) + 1; },
    },
    "postMatchTranslations": {
        "aiTranslationTitle": "Ανάλυση Αγώνων με Τεχνητή Νοημοσύνη",
        "tipTranslationTitle": "Προγνωστικά από τους Ειδικούς",
        "team1players": "Παίκτες",
        "team2players": "Παίκτες",
        "ageLabel": "Ηλικία: ",
        "positionLabel": "Θέση: ",
        "h2hNoRecentMsg": "Δεν υπάρχουν πρόσφατοι αγώνες μεταξύ των δύο ομάδων",
        "matchHistory": "Ιστορικό Αγώνων",
        "h2hPill": "Μεταξύ τους",
        "badgeWon": "Ν",
        "badgeLost": "Η",
        "badgeDraw": "Ι",
        "noVideoMessage": "Δεν υπάρχει διαθέσιμο βίντεο για αυτό τον αγώνα",
        "analysis": "Η τεχνητή νοημοσύνη 'PlayPulse' θα αναλύσει αυτόν τον αγώνα εντός 24 ωρών πριν την έναρξη. Παρακαλούμε επιστρέψτε πιο κοντά στην έναρξη του αγώνα για να δείτε την πλήρη ανάλυση!",
        "whatDoesAiThink": "Ανάλυση με Τεχνητή Νοημοσύνη για Αυτόν τον Αγώνα:",
        "aiAnalysis": "AI ΑΝΑΛΥΣΗ",
        "betNow": "Παίξε τώρα!",
        "oddsDraw": "Ισοπαλία",
        "predictToWin": "για νικητή αγώνα",
        "predictDraw": "Ισοπαλία",
        "predictExactScore": "Ακριβές Σκορ",
        "predictOverUnder": "παιχνίδια",
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>Δεν υπάρχουν πρόσφατα άρθρα</h2><p>Δυστυχώς, δεν υπάρχουν πρόσφατα {{newsGame}} άρθρα.</p>`,
        "newsTitle": "ΑΡΘΡΑ",
        "writtenAt": "Γράφτηκε στις",
        "footerArticle": "🔥  Σου αρεσε το αρθρο; 🔥",
        "footerArticleFollow": "Ακολούθησέ μας για περισσότερα συναρπαστικά νέα!",
        "newsSeeAll": "Περισσότερα",
        "seeMore": "Δείτε περισσότερα...",
        "goBack": "Επιστροφή στα Νέα",
        "tournamentNews": "Τουρνουά",
        "rosterNews": "Ομάδες",
        "gameNews": "Γενικά",
    },
    "footer": {
        "about": "Καλώς ήρθατε στο MyEsports, τον ολοκληρωμένο προορισμό για κάθε φίλο των eSports. Με μια βαθιά αγάπη και πάθος για τον κόσμο του gaming, δημιουργήσαμε έναν χώρο όπου η κοινότητα των eSports θα μπορεί να βρει τα πάντα: από ζωντανές μεταδόσεις αγώνων και εκδηλώσεων μέχρι προγνωστικά, αναλύσεις και άρθρα για όλα τα είδη παιχνιδιών.",
        "followUs": "Ακολουθήστε μας",
        "usefulLinks": "Χρήσιμοι σύνδεσμοι",
        "contactUs": "Επικοινωνία",
        "aboutUs": "Σχετικά με εμάς",
        "eula": "Όροι Χρήσης",
        "privacy": "Πολιτική Απορρήτου",
        "faq": "Συχνές Ερωτήσεις",
        "Authors": "Αρθρογράφοι",
    },
    "tournamentsTranslations": {
        "noDateMsg": "Μη καθορισμένη Ημ. Λήξης",
        "tier": "Κατηγορία",
        "emptyTournamentFilterMessage": "Δεν υπάρχουν διαθέσιμα {{game}} τουρνουά με τα επιλεγμένα φίλτρα, προς το παρόν.",
        "pillOngoing": "ΣΕ ΕΞΕΛΙΞΗ",
        "pillUpcoming": "ΠΡΟΣΕΧΩΣ",
        "pillFinished": "ΟΛΟΚΛΗΡΩΘΗΚΑΝ",
        "pillAll": "ΟΛΑ",
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>ΣΕ ΕΞΕΛΙΞΗ</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">ΟΛΟΚΛΗΡΩΘΗΚΕ</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{days}} ημέρες</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{days}} ημέρα</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{hours}}ω {{minutes}}λ</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Ξεκινάει σε {{minutes}}λ</span>`,
        "noPrizePoolAvailable": "N/A",
        "euroPrizePool": "€",
        "usdPrizePool": "$",
        "teamsCompete": "Ομάδες συμμετέχουν",
        "hotTournamentsTitle": "ΔΗΜΟΦΙΛΗ ΤΟΥΡΝΟΥΑ",
        "hotTournamentFixedLabel": `ΣΕ ΕΞΕΛΙΞΗ`,
        "noHotTournamentsMsg": `<h2>Δεν υπάρχουν τουρνουά σε εξέλιξη</h2><p>Δυστυχώς, δεν υπάρχουν {{game}} τουρνουά σε εξέλιξη προς το παρόν</p>`,
        "noHotTournamentsButton2": "Περισσότερα",
        "clickHotTournaments": "Κάντε κλικ για περισσότερες πληροφορίες.",
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Συμμετέχουν: <span class="text-resize-tournament-info-right text-white">{{teams}}</span> ομάδες`,
        "playersCompetePost": `Συμμετέχουν: <span class="text-resize-tournament-info-right text-white">{{teams}}</span> παίχτες`,
        "noPrizePoolAvailablePost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Χρηματικό έπαθλο: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": "Κατηγορία:",
        "winnerPost": "Νικητής Τουρνουά:",
        "startPost": "Έναρξη Τουρνουά:",
        "endPost": "Λήξη Τουρνουά:",
        "noDateMsgPost": "Μη καθορισμένη ημ/νία λήξης",
        "ladderWins": "Νίκες",
        "ladderLosses": "Ήττες",
        "ladderTies": "Ισοπαλίες",
        "ladderGamesWon": "Νικημένα παιχνίδια",
        "ladderGamesLost": "Χαμένα παιχνίδια",
        "ladderTieBreaker": "Μπρέικς Ισοπαλίας",
        "upperBracket": "Ανώ Bracket",
        "lowerBracket": "Κατώ Bracket",
        "grandFinal": "Τελικός",
        "semiFinal": "Ημιτελικός",
        "quarterFinal": "Προημιτελικός",
        "roundBrackets": "Γύρος",
        "matchBrackets": "Αγώνας",
        "laddersMatchesTitle": "Αγώνες",
        "week": "Εβδομάδα",
        "liveWeek": "ΖΩΝΤΑΝΑ",
    },
    "predictionsTranslations": {
        "predTitle": "ΠΡΟΓΝΩΣΤΙΚΑ",
        "comingSoon": "Σύντομα διαθέσιμα",
        "today": "Σήμερα",
        "title": "Προβλέψεις αγωνιστικής δράσης",
        "subtitle": "Στο MyEsports, μελετάμε προσεκτικά κάθε αγώνα για να σας προσφέρουμε τις καλύτερες συμβουλές στοιχηματισμού στα esports. Οι ειδικοί μας αναλύουν τις ομάδες, τους παίκτες και τις στρατηγικές για να σας βοηθήσουν να πάρετε τις καλύτερες αποφάσεις στοιχηματισμού.",
        "time": "Ώρα",
        "date": "Ημερομηνία",
        "match": "Αγώνας",
        "tournament": "Τουρνουά",
        "tipster": "Tipster",
        "betButton1": "Παίξτο τώρα στην ",
        "odd": "με απόδοση ",
        "noPredictionForThisDate": "Δεν υπάρχουν προγνωστικά για αυτήν την ημερομηνία.",
        "statsAvgOdds": "Μ.Ο. απ.",
        "statsEarn": "Κέρδη",
        "statsBets": "Στοιχήματα",
        "statsOpenBets": "Ανοιχτά Στοιχ.",
        "authStats": "Στατιστικά προβλέψεων",
        "articles": "Άρθρα",
        "last10": "Τελευταίες προβλέψεις",
        "authBio": "Βιογραφία",
        "bioComing": "Η βιογραφία του συντάκτη θα είναι σύντομα διαθέσιμη.",
        "showMorePredictions": "Περισσότερα",
        "EarnExplain": `Τα κέρδη υπολογίζονται με βάση τις προβλέψεις που έχουν κλείσει.Κάθε
                        tipster ξεκινάει από το 0, με κάθε σωστή πρόβλεψη να του προσθέτει
                        την κερδισμένη απόδοση x1 στο συνολικό του κέρδος. Για παράδειγμα, σε
                        σωστή πρόβλεψη με απόδοση 1.80, το κέρδος του tipster αυξάνεται κατά
                        0.80. Σε λάθος πρόβλεψη, αφαιρείται 1.00 από το συνολικό κέρδος.`,
        "WinrateExplain": `Το ποσοστό επιτυχίας υπολογίζεται με βάση τις σωστές προβλέψεις
                        του tipster σε σχέση με τις συνολικές του προβλέψεις. Το ποσοστό
                        επιτυχίας δεν λαμβάνει υπόψη τις αποδόσεις των προβλέψεων.`,
        "RoiExplain": `Το ROI (Return on Investment) υπολογίζεται με βάση τα κέρδη του
                        tipster σε σχέση με τα συνολικά του στοιχήματα. Επι της ουσίας, το ROI
                        είναι το καθαρό κέρδος του tipster, αν για παράδειγμα έχει στοιχηματίσει
                        50 αγώνες με μια μονάδα το καθένα και το ROI είναι 20%, τότε έχει κερδίσει 10 μονάδες.`,
        "AvgOddsExplain": `Η μέση απόδοση υπολογίζεται με βάση τις αποδόσεις των προβλέψεων
                        του tipster. Η μέση απόδοση δεν λαμβάνει υπόψη το αποτέλεσμα των
                        προβλέψεων.`,
        "plainPrediction": "Πρόβλεψη",
        "statisticPeriod": "Περίοδος",
        "alltimeStats": "Γενικά στατιστικά",
    },
    "banners": {
        "desktopLeft": 'el_desktop_left',
        "desktopRight": 'el_desktop_right',
    },
    "streamerTranslations": {
        "cardFollowers": "Ακόλουθοι",
        "cardInfo": `Ο χρήστης {{user}} είναι {{status}}  αυτή τη στιμγή.`,
        "cardViewers": "Παρακολουθούν ζωντανά!",
        "detailsInformation": "Πληροφορίες Streamer",
        "detailsName": "Όνοματεπώνυμο",
        "detailsNationality": "Εθνικότητα",
        "detailsAge": "Ηλικία",
        "detailsGame": "Παιχνίδια",
        "detailsRole": "Ρόλος",
        "detailsTeam": "Ομάδα",
        "detailsSignature": "Χαρακτηριστικοί Ήρωες",
        "accordionHowTo": `<strong>-Πως να εγγραφώ στη λίστα των streamers του myEsports;</strong>`,
        "accordionHowToReply": `Μπορείς να συμπληρώσεις την φόρμα <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">εδώ</a> ή να μας βρεις στον <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">discord server</a> μας και να έρθεις σε επικοινωνία με κάποιον moderator ο οποίος θα σε καθοδηγήσει.`,
        "accordionEarn": `<strong>-Τι θα κερδίσω αν streamάρω με τα banners του myEsports;</strong>`,
        "accordionEarnReply": `Την παρουσία σου στο site μας και διαφήμιση σε ολα μας τα Social Media.`,
        "accordionGraphics": `<strong>-Που θα βρω τα γραφικά για το stream μου;</strong>`,
        "accordionGraphicsReply": `Θα σου τα στείλει η ομάδα μας, μαζί με οδηγίες για το πως θα τα χρησιμοποιήσεις.`,
        "accordionHowToStream": `<strong>-Θέλω να γίνω streamer αλλά δεν έχω ξανά streamαρει, πως ξεκινάω;</strong>`,
        "accordionHowToStreamReply": `Μην ανυσηχείς, θα σε καθοδηγήσει η ομάδα μας για το πως θα ξεκινήσεις, για περισσότερες πληροφορίες μπορείς να μας βρεις στον <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">discord server</a> μας.`,
        "accordionViewers": `<strong>-Πόσους viewers πρέπει να έχω για να με δεχτείτε;</strong>`,
        "accordionViewersReply": `Δεν υπάρχει κάποιος αριθμός viewers που θα σε κάνει να μην είσαι ευπρόσδεκτος στην ομάδα μας. Το μόνο που χρειάζεται να έχεις είναι όρεξη και καθημερινό πρόγραμμα live streaming.`,
    },
    "authorsTranslations": {
        "title": 'Γνωρίστε τους αρθρογράφους του MyEsports',
    },
    "bookmakersPostTranslations": {
        "odds": "Αποδόσεις",
        "varietyOfEsports": "Πληθώρα Esports",
        "liveSupport": "Ζωντανή Υποστήριξη",
        "liveStreaming": "Ζωντανή Μετάδοση",
        "payments": "Πληρωμές",
        "fullReview": "Αναλυτική Ανασκόπηση",
        "esports": "Ηλεκτρονικά Αθλήματα",
        "register": "ΕΓΓΡΑΦΗ"
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": "Αναλυτική Ανασκόπηση",
        "register": "ΕΓΓΡΑΦΗ"
    },
    "bookmakersSkinList": {
        "widgetTitle": "Skin ΠΛΑΤΦΟΡΜΕΣ",
        "widgetButton": "Περισσότερα",
        "playNow": "Πάμε!",
        "introSection": `<h2 class="title-section">Κορυφαία Esports Skin Στοιχηματικά Sites για το 2024: Ανακάλυψε τις καλύτερες πλατφόρμες</h2>
            <p>
            Με την εκρηκτική ανάπτυξη των esports, κορυφαία skins στοιχηματισμού έχουν αναδυθεί για να καλύψουν τη ζήτηση, προσφέροντας εκτεταμένες αγορές για δημοφιλή παιχνίδια όπως το Counter-Strike 2, το Dota 2, το League of Legends, το Valorant, το Mobile Legends και το Call of Duty. Αυτά τα παιχνίδια προσφέρουν μια συνεχή ροή συναρπαστικών τουρνουά και λιγκών, παρέχοντας στους παίκτες στοιχημάτων διάφορες επιλογές – από απλές νίκες αγώνων έως στοιχήματα συγκεκριμένα για κάθε παιχνίδι.           
            </p>
            <p>
            Στο <a href=\"https://myesports.gg\" class=\"text-a-style\">myesports.gg</a>, έχουμε εξετάσει προσεκτικά μια σειρά από κορυφαίες πλατφόρμες στοιχηματισμού σε skins esports για να σε βοηθήσουμε να βρεις το ιδανικό skin για τις στοιχηματικές σου ανάγκες.            
            </p>`,
        "sectionTitle": "Πού να Στοιχηματίσεις στα Esports: Καλύτερα Esports Betting Skins & Μπόνους",
        "pleaseBetResponsibly": "Παρακαλούμε Στοιχηματίστε Υπεύθυνα",
        "reviewMethodology": `
                <h2 class="title-section">Η Μεθοδολογία μας</h2>
        <p>
        Στο <a class=\"text-a-style\" href=\"https://myesports.gg\">myesports.gg</a>, η αποστολή μας είναι απλή – να σε καθοδηγήσουμε στην επιλογή της ιδανικής πλατφόρμας για τα esports στοιχήματά σου. Αξιολογούμε κάθε πλατφόρμα με βάση την ευκολία χρήσης, την ποικιλία αγορών esports και τα χαρακτηριστικά ασφαλείας για να εξασφαλίσουμε την ασφάλεια σου κατά τις καταθέσεις ή αναλήψεις χρημάτων.        
        </p>
        <p>
        Επίσης, αξιολογούμε την αδειοδότηση για να επιβεβαιώσουμε ότι η πλατφόρμα λειτουργεί νόμιμα στην περιοχή σου. Μόνο αυτά που πληρούν τα υψηλά μας πρότυπα λαμβάνουν την έγκρισή μας. Προσφέρουμε αποκλειστικά μπόνους και προσφορές  για να βελτιώσουμε περαιτέρω την εμπειρία σου.        
        </p>
        <p>
        Τα κριτήριά μας καλύπτουν πολλούς τομείς, συμπεριλαμβανομένης της φιλικότητας προς τον χρήστη, των αγορών που προσφέρονται και των μεθόδων πληρωμής. Κάθε bookmaker skin αξιολογείται διεξοδικά σε οκτώ κύριες κατηγορίες, καλύπτοντας τα πάντα από τον σχεδιασμό και τη λειτουργικότητα έως την εξυπηρέτηση πελατών. Δες τις αναλυτικές κριτικές μας για να εξερευνήσεις την απόδοση κάθε bookmaker skin σε αυτούς τους κρίσιμους τομείς.        
        </p>
        `,
        "review": "Ανασκόπηση",
        "betNow": "ΠΑΙΞΕ ΤΩΡΑ",
        "noBookmakerSkinsMsg": `<h2>Δεν υπάρχουν διαθέσιμα skin platforms για την περιοχή και τη γλώσσα σου.</h2>`,
    },
    "bookmakersList": {
        "widgetTitle": "Στοιχηματικες",
        "widgetButton": "Περισσότερα",
        "playNow": "Παίξε!",
        "introSection": `<h2 class="title-section">Κορυφαίες Ιστοσελίδες Στοιχηματισμού Esports για το 2024: Ανακαλύψτε τους Καλύτερους Esports Bookmakers</h2>
            <p>
            Με την εκρηκτική ανάπτυξη των esports, οι κορυφαίοι ιστότοποι στοιχηματισμού έχουν ανταποκριθεί στη ζήτηση, προσφέροντας εκτεταμένες αγορές για δημοφιλή παιχνίδια όπως Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends και Call of Duty. Αυτά τα παιχνίδια φέρνουν μια συνεχόμενη ροή από συναρπαστικά τουρνουά και λίγκες, παρέχοντας στους παίκτες ποικιλία επιλογών – από απλούς νικητές αγώνων έως εξειδικευμένα στοιχήματα ανά παιχνίδι.            </p>
            <p>
            Στο <a href=\"https://myesports.gg\" class=\"text-a-style\">myesports.gg</a>, έχουμε εξετάσει προσεκτικά μια σειρά από κορυφαίες πλατφόρμες στοιχηματισμού esports για να σας βοηθήσουμε να βρείτε την ιδανική ιστοσελίδα για τις στοιχηματικές σας ανάγκες.
            </p>`,
        "sectionTitle": "Πού να Στοιχηματίσετε στα Esports: Καλύτερες Ιστοσελίδες και Μπόνους Esports",
        "pleaseBetResponsibly": "Παρακαλούμε Στοιχηματίστε Υπεύθυνα",
        "reviewMethodology": `
                <h2 class="title-section">Η Μεθοδολογία της Αξιολόγησής μας</h2>
        <p>
            Στο <a class=\"text-a-style\" href=\"https://myesports.gg\">myesports.gg</a>, η αποστολή μας είναι απλή – να σας καθοδηγήσουμε στην επιλογή του ιδανικού bookmaker για το στοίχημα esports. Αξιολογούμε κάθε ιστοσελίδα με βάση την ευκολία χρήσης, την ποικιλία αγορών esports και τα χαρακτηριστικά ασφαλείας, ώστε να διασφαλίσουμε την ασφάλειά σας κατά την κατάθεση ή ανάληψη.
        </p>
        <p>
            Επίσης, εξετάζουμε την αδειοδότηση για να επαληθεύσουμε αν ο bookmaker λειτουργεί νόμιμα στην περιοχή σας. Μόνο όσοι πληρούν τα υψηλά μας πρότυπα λαμβάνουν την έγκρισή μας. Προσφέρουμε αποκλειστικά μπόνους και προσφορές esports για να βελτιώσουμε περαιτέρω την εμπειρία σας.
        </p>
        <p>
            Τα κριτήρια αξιολόγησής μας καλύπτουν πολλούς τομείς, συμπεριλαμβανομένης της φιλικότητας προς τον χρήστη, των επιλογών σε αγορές και των μεθόδων πληρωμής. Κάθε bookmaker αξιολογείται διεξοδικά σε οκτώ βασικές κατηγορίες, καλύπτοντας τα πάντα, από τον σχεδιασμό και τη λειτουργικότητα έως την εξυπηρέτηση πελατών. Εξετάστε τις λεπτομερείς αξιολογήσεις μας για να ανακαλύψετε την απόδοση κάθε bookmaker σε αυτούς τους κρίσιμους τομείς.
        </p>
        `,
        "review": "Ανασκόπηση",
        "betNow": "ΕΓΓΡΑΦΗ",
        "noBookmakersMsg": `<h2>Δεν υπάρχουν διαθέσιμες στοιχηματικές για την περιοχή και τη γλώσσα σας.</h2>`,
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Μισό λεπτό! Φαίνεται ότι χρησιμοποιείτε κάποιον ad blocker.</strong></p>
        <p>Χάνετε περιεχόμενο που έχουμε για εσάς. Δεν είναι διαφημίσεις, αλλά χρήσιμες λειτουργίες. Παρακαλούμε εξετάστε το ενδεχόμενο απενεργοποίησης του ad blocker σας.</p>
        <button id="close-button">Το κατάλαβα!</button>`,
    },
    "discordTemplate": {
        "titleMain": "Χρειάζεσαι βοήθεια;",
        "titleSub": "Μπες στο Discord μας!",
    },
}
