import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { I18n } from "../../utilities/i18n/i18n";
import { translations } from "../../../functions/across-stack/i18n/i18n";
import { emptyMessage } from "../../utilities/general/emptyMessage";
import { getMatches } from "../../utilities/firebase-assistant/frontend-transition";
import { reversegameIdFilter, gameIdImg } from "../../utilities/general/gameIdImgMapping";
import { getMatchName } from '../../../functions/across-stack/utils/globals';
import { startCountdown } from "../../utilities/general/countDownForMatches";
import { findMatchType } from "../../utilities/general/matchType";
import { generateDateSelector } from "../../utilities/general/generateDateSelector";
import { PlaceholderMatches } from "../placeholder-matcheswidget/placeholder-matches";
import { daySelectorTranslatePosition } from "../../utilities/general/generateDateSelector";

const noImageFound = require('../../global-assets/no image found.png');
const noImageFoundPlayer = require('../../global-assets/hoodieman.webp');

export class MatchesComponent extends BaseComponent {

    initCallback() {
        MatchesComponent.addStatusListeners();
        RouterAssistant.checkForSingleContainer('matches-component', MatchesComponent.initiator);
    }

    static favCheckbox;
    static matchesSavedDate = new Date().toISOString().split('T')[0];
    static currentTranslateX = 0;

    static initiator() {

        getMatches('fromMatchesComponent', () => {
            MatchesComponent.checkUrlToActivateStatusFilters(); // this exists for initial run if prompted from a url and not a redirect
            if (document.querySelectorAll('#upcomingmatches-html').length !== 0) {
                console.debug('I am running the matches component call back!');
                if (!document.getElementById('#dateSelector-MatchesComponent')) {
                    generateDateSelector("MatchesComponent");
                }
                if (document.querySelector('.gameFilterNotMsg')) document.querySelector('.gameFilterNotMsg').remove();
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny());
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
                }
            } else {
                console.debug('The matches component call back is supposed to run but I am not on the correct page!!!')
            }
        });
        GameNavbar.subscribeToGameFilterChanges('fromMatchesComponent', (eventId) => {
            if (document.querySelectorAll('#upcomingmatches-html').length !== 0) {
                if (!document.getElementById('#dateSelector-MatchesComponent')) {
                    generateDateSelector("MatchesComponent");
                }
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), MatchesComponent.getCurrentlyActivatedStatusIfAny());
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
                }
            }
        })

    }

    static addStatusListeners() {
        const navItems = document.querySelectorAll('matches-component .status-selector');
        navItems.forEach(item => {
            item.addEventListener('click', (event) => {
                MatchesComponent.changeUrlAccordingToStatusFilters(event);
                const gameId = GameNavbar.getCurrentlyActivatedGameIfAny();
                if (!document.getElementById('#dateSelector-MatchesComponent')) {
                    generateDateSelector("MatchesComponent");
                }
                MatchesComponent.updateMatches(gameId, MatchesComponent.getCurrentlyActivatedStatusIfAny());
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerHTML.trim() || upcomingMatchesDiv.innerHTML === '') {
                    emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyStateFilterMessage', 'gameFilterNotMsg');
                }
            });
        });
    }

    static getCurrentlyActivatedStatusIfAny() {
        return RouterAssistant.getCurrentVariableValue('status')
    }

    static changeUrlAccordingToStatusFilters(event) {
        const statusName = event.target.getAttribute('alt');
        RouterAssistant.addOrUpdateCurrentPageVariable("status", statusName);
        MatchesComponent.checkUrlToActivateStatusFilters();
    }

    static checkUrlToActivateStatusFilters() {
        const currentActiveStatusFilter = RouterAssistant.getCurrentVariableValue('status')
        if (currentActiveStatusFilter) {
            const activeElement = document.querySelector(`[alt="${currentActiveStatusFilter}"]`);
            if (activeElement) {
                const currentlyActiveElement = document.querySelector('.activestatus');
                if (currentlyActiveElement) {
                    currentlyActiveElement.classList.remove('activestatus');
                }
                activeElement.classList.add('activestatus');
            } else {
                // console.debug("No matching status filter found");
            }
        } else {
            // console.debug("No status filter in URL");
        }
    }



    static updateMatches(gameId, statusId) {

        console.debug('running update matches');
        document.querySelector('#upcomingmatches-html').innerHTML = '';

        if (statusId === 'running') {
            MatchesComponent.matchesSavedDate = new Date().toISOString().split('T')[0];
        }

        // update dateSelector active status based on matchesSavedDate set from the user
        document.querySelectorAll('.date-box-MatchesComponent').forEach((box) => {
            const saveDate = box.getAttribute('data-date-MatchesComponent');

            if (saveDate === MatchesComponent.matchesSavedDate) {
                box.classList.add('active');
                document.querySelectorAll('.activestatus').forEach(function (element) {
                    element.classList.remove('activestatus');
                });
            } else {
                box.classList.remove('active');
            }
        });

        //  toogle between statusId based on user input
        let now = new Date().toISOString().split('T')[0];

        const finishedEl = document.querySelector('#finished');
        const notStartedEl = document.querySelector('#not_started');

        if (statusId !== "fav-tab" && statusId !== "running") {
            if (MatchesComponent.matchesSavedDate > now) {
                statusId = "not_started";
                finishedEl.classList.add('disabled');
                notStartedEl.classList.remove('disabled');
            } else if (MatchesComponent.matchesSavedDate < now) {
                statusId = "finished";
                finishedEl.classList.remove('disabled');
                notStartedEl.classList.add('disabled');
            } else {
                statusId = RouterAssistant.getCurrentVariableValue('status');
                finishedEl.classList.remove('disabled');
                notStartedEl.classList.remove('disabled');
            }
        }

        if (!statusId) {
            statusId = 'running';
        }

        RouterAssistant.addOrUpdateCurrentPageVariable("status", statusId)
        MatchesComponent.checkUrlToActivateStatusFilters();

        let favourites = null;
        if (statusId === "fav-tab") {
            const favString = localStorage.getItem("myEsportsfavourites");
            favourites = favString ? JSON.parse(favString) : {};
        }

        const sortedMatchIds = Object.keys(window.savedMatches)
            .filter((id) => {
                const data = window.savedMatches[id];

                if (gameId && reversegameIdFilter[gameId] !== undefined) {
                    if (reversegameIdFilter[gameId] !== data.videogame.id) {
                        return false; // Exclude if game doesn't match
                    }
                }

                if (statusId !== "fav-tab" && statusId !== "running") {
                    const matchDate = new Date(data.begin_at || data.scheduled_at).toISOString().split('T')[0];
                    if (matchDate < MatchesComponent.matchesSavedDate || matchDate > MatchesComponent.matchesSavedDate) {
                        return false; // outside date range
                    }
                }

                // Filter by status
                const gameStatus = data.status;
                if (statusId && statusId !== gameStatus && statusId !== "fav-tab") {
                    return false;
                }

                // If statusId is "fav-tab", keep only matches that are in favourites
                if (statusId === "fav-tab") {
                    if (!favourites || !favourites[`favCheck-${data.id}`]) {
                        return false;
                    }
                }

                return true;
            })
            .sort((a, b) => {
                const matchA = window.savedMatches[a];
                const matchB = window.savedMatches[b];
                const dateA = new Date(matchA.begin_at || matchA.scheduled_at);
                const dateB = new Date(matchB.begin_at || matchB.scheduled_at);
                return dateA - dateB;
            });

        const uniqueTournamentNames = []; // array for search filtering
        const uniqueTeamNames = []; // array for search filtering

        for (let i = 0; i < sortedMatchIds.length; i++) {

            const matchCode = sortedMatchIds[i]
            const data = window.savedMatches[matchCode]

            if (!data.opponents || data.opponents.length < 2) continue; // if no opponents due to tourney format ongoing, skip the match

            const gameStatus = data.status;
            const matchName = getMatchName(data);
            const team1name = data.opponents[0].opponent.name;
            const team1id = data.opponents[0].opponent.id;
            let team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : data.winner_type === "Team" ? noImageFound : noImageFoundPlayer;
            const team2name = data.opponents[1].opponent.name;
            const team2id = data.opponents[1].opponent.id;
            let team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : data.winner_type === "Team" ? noImageFound : noImageFoundPlayer;
            const winnerId = data.winner && data.winner.id ? data.winner.id : data.winner_id;
            const tournamentName = `${data.league.name} ${data.serie.full_name}`;
            const gameLogoHTML = `<img src="${gameIdImg[data.videogame.id]}" alt="tournament logo" class="img-fluid mx-auto d-flex justify-content" >`;
            const matchTypeFinal = findMatchType(data.match_type, data.number_of_games);
            const score = `${data.results[0].score.toString()} - ${data.results[1].score.toString()}`;
            const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
            const date = `${dateRaw.toLocaleTimeString(I18n.getUsersPreferredLanguage(), { hour: '2-digit', minute: '2-digit', hour12: true })}`;
            let team1Odds = data.thunderpickData && data.thunderpickData.team1Odds !== 1 ? data.thunderpickData.team1Odds : data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? data.thunderpickData.team1StartingOdds : "";
            let team2Odds = data.thunderpickData && data.thunderpickData.team2Odds !== 1 ? data.thunderpickData.team2Odds : data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? data.thunderpickData.team2StartingOdds : "";
            if (team1Odds !== "" && team2Odds !== "") {
                team1Odds = parseFloat(team1Odds).toFixed(2);
                team2Odds = parseFloat(team2Odds).toFixed(2);
            }
            const countdownElementIdmatches = `countdownmatches-${matchCode}`;
            const matchElementIdmatches = `matchfrommatches-${matchCode}`;

            if (!uniqueTournamentNames.includes(tournamentName)) {
                uniqueTournamentNames.push(tournamentName);
            }

            if (!uniqueTeamNames.includes(team1name)) {
                uniqueTeamNames.push(team1name);
            }

            if (!uniqueTeamNames.includes(team2name)) {
                uniqueTeamNames.push(team2name);
            }

            const matchHtml = /*html*/`
                        <a href="/${I18n.getUsersPreferredLanguage()}/match/${matchCode}/${matchName}" class="matchItem d-flex align-items-center p-1 mt-1 rounded text-white color-lightdark clickable justify-content-between" id="${matchElementIdmatches}" data-tournament="${tournamentName}" data-team1="${team1name}" data-team2="${team2name}">
                        
                        <!-- Game Info -->
                        <div class="match-game-container ms-1 me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="${data.videogame_title ? data.videogame_title.name : data.videogame.name}" alt="${data.videogame_title ? data.videogame_title.name : data.videogame.name}">
                            <div class="d-flex align-items-center justify-content-center">
                            ${gameLogoHTML}
                            </div>
                            <div class="d-flex justify-content-center">
                            <span class="matchTypeFromMatches">${matchTypeFinal}</span>
                            </div>
                        </div>

                        <!-- Tournament and Date -->
                        <div class="match-tournament-container ms-2 text-light d-none d-xl-block" data-bs-toggle="tooltip" data-bs-placement="top" title="${tournamentName}" alt="${tournamentName}">
                            <div class="text-start text-truncate">
                            <span>${date}</span>
                            </div>
                            <div class="text-start text-truncate">
                            ${tournamentName}
                            </div>
                        </div>

                        <!-- Match Teams and Score -->
                        <div class="match-teams-container d-flex align-items-center justify-content-center text-center">
                            
                            <!-- Team 1 -->
                            <div class="team-related d-flex align-items-center justify-content-end ${winnerId === team1id ? 'winner-Highlight' : ''}" id="match-${matchCode}-team-${team1id}" alt="${team1name}">
                            <span class="flex-fill team-name">${team1name}</span>
                            <div class="img-circle-container d-flex justify-content-center flex-column m-1">
                                <img src="${team1img}" alt="" class="img-fluid mx-auto d-block">
                            </div>
                            <span class="new-odds-badge ms-1 me-1 ${team1Odds === 'No data' || team1Odds === '' || gameStatus === 'canceled' ? 'd-none' : ''}">${team1Odds}</span>
                            <span class="new-odds-badge-placeholder ms-1 me-1 ${team1Odds === 'No data' || team1Odds === '' ? '' : 'd-none'}">1.00</span>
                            </div>

                            <!-- Score -->
                            <div class="score-matches fw-bold" alt="score for ${team1name} - ${team2name}">${score}</div>

                            <!-- Team 2 -->
                            <div class="team-related d-flex align-items-center justify-content-start ${winnerId === team2id ? 'winner-Highlight' : ''}" id="match-${matchCode}-team-${team2id}" alt="${team2name}">
                            <span class="new-odds-badge ms-1 me-1 ${team1Odds === 'No data' || team1Odds === '' || status === 'finished' || status === 'canceled' ? 'd-none' : ''}">${team2Odds}</span>
                            <span class="new-odds-badge-placeholder ms-1 me-1 ${team1Odds === 'No data' || team1Odds === '' || status === 'finished' || status === 'canceled' ? '' : 'd-none'}">1.00</span>
                            <div class="img-circle-container d-flex justify-content-center flex-column m-1">
                                <img src="${team2img}" alt="" class="img-fluid mx-auto d-block">
                            </div>
                            <span class="flex-fill team-name">${team2name}</span>
                            </div>
                        </div>

                        <!-- Countdown -->
                        <div class="match-countdown-or-date-container d-none d-xxl-flex justify-content-center" alt="status">
                            <span id="${countdownElementIdmatches}" class="my-2 badge countdownBadge"></span>
                        </div>

                        <!-- Date (mobile fallback) -->
                        <div class="match-countdown-or-date-container text-start text-truncate d-flex d-xl-none ms-1" alt="date">
                            <span>${date}</span>
                        </div>

                        <!-- Favorites Star -->
                        <label class="star-label" for="favCheck-${matchCode}" data-bs-toggle="tooltip" data-bs-placement="top" title="Add ${team1name} vs ${team2name} to favourites!" alt="Add ${team1name} vs ${team2name} to favourites!">
                            <input class="form-check-input star-checkbox" type="checkbox" value="" id="favCheck-${matchCode}" aria-label="Add to favorites">
                            <i class="bi bi-star-fill star-icon"></i>
                        </label>

                        </a>
                        `;

            document.querySelector('#upcomingmatches-html').insertAdjacentHTML('beforeend', matchHtml);
            MatchesComponent.dynamicTranslations(matchElementIdmatches, data.number_of_games);
            startCountdown('matchespage', dateRaw, countdownElementIdmatches, matchElementIdmatches, gameStatus, data.streams_list);

        }

        daySelectorTranslatePosition("MatchesComponent");
        MatchesComponent.searchBarLogic();
        MatchesComponent.favouritesLogic();

        let element = document.getElementById('placeholder-matches-big');
        if (element) element.style.display = "none"; // deactivate placeholder  matches

    }

    static favouritesCheckboxChangeHandler(event) {
        event.stopPropagation();
        let checkbox = event.target;
        const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites')) || {};
        favourites[checkbox.id] = checkbox.checked;
        localStorage.setItem('myEsportsfavourites', JSON.stringify(favourites));
        checkbox.classList.toggle('checked-style', checkbox.checked);
    }

    static favouritesLogic() {
        document.querySelectorAll('[id^="favCheck"]').forEach(function (checkbox) {
            checkbox.addEventListener('click', MatchesComponent.favouritesCheckboxChangeHandler);
            const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites')) || {};
            checkbox.checked = favourites[checkbox.id] === true;
            checkbox.classList.toggle('checked-style', checkbox.checked);
        });
    }

    static dynamicTranslations(matchElementIdmatches, numberOfGames) {
        const matchTypeElement = document.querySelector(`#${matchElementIdmatches} .matchTypeFromMatches`);
        if (matchTypeElement.innerHTML === 'all_games_played') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayedMicro", { gamesNumber: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'best_of_X') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.boN", { numberOfGames: numberOfGames });
        } else if (matchTypeElement.innerHTML === 'first_to_X') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.boN", { numberOfGames: (2 * numberOfGames - 1) });
        } else if (matchTypeElement.innerHTML === 'best_of_2') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.boN", { numberOfGames: 2 });
        } else if (matchTypeElement.innerHTML === 'single_game') {
            matchTypeElement.innerHTML = I18n.translateString("matchTranslations.boN", { numberOfGames: 1 });
        }

        document.querySelectorAll('.todayClass').forEach(element => {
            element.innerHTML = I18n.translateString("predictionsTranslations.today");
        });
    }

    static handleSearchInputEvent() {
        const searchBar = document.getElementById(`searchBar`);
        // console.debug('Input value changed:');

        const searchValue = searchBar.value.trim().toLowerCase();
        const searchTerms = searchValue.split(/\s+/).map(term => term.toLowerCase());
        const items = document.querySelectorAll('.matchItem');

        items.forEach(item => {
            const name1 = item.getAttribute('data-team1').toLowerCase();
            const name2 = item.getAttribute('data-team2').toLowerCase();
            const category = item.getAttribute('data-tournament').toLowerCase();

            const name1noSpaces = name1.replace(/\s+/g, '');
            const name2noSpaces = name2.replace(/\s+/g, '');
            const categoryNoSpaces = category.replace(/\s+/g, '');

            const matches = searchTerms.every(term =>
                name1.includes(term) || name2.includes(term) || category.includes(term) ||
                name1noSpaces.includes(term) || name2noSpaces.includes(term) || categoryNoSpaces.includes(term)
            );

            if (matches) {
                item.classList.remove('hidden-important');
            } else {
                item.classList.add('hidden-important');
            }
        });
    }

    static handleSearchInputEventMobile() {
        const searchBar = document.getElementById(`searchBarMobile`);

        const searchValue = searchBar.value.trim().toLowerCase();
        const searchTerms = searchValue.split(/\s+/).map(term => term.toLowerCase());
        const items = document.querySelectorAll('.matchItem');

        items.forEach(item => {
            const name1 = item.getAttribute('data-team1').toLowerCase();
            const name2 = item.getAttribute('data-team2').toLowerCase();
            const category = item.getAttribute('data-tournament').toLowerCase();

            const name1noSpaces = name1.replace(/\s+/g, '');
            const name2noSpaces = name2.replace(/\s+/g, '');
            const categoryNoSpaces = category.replace(/\s+/g, '');

            const matches = searchTerms.every(term =>
                name1.includes(term) || name2.includes(term) || category.includes(term) ||
                name1noSpaces.includes(term) || name2noSpaces.includes(term) || categoryNoSpaces.includes(term)
            );

            if (matches) {
                item.classList.remove('hidden-important');
            } else {
                item.classList.add('hidden-important');
            }
        });
    }

    static searchBarLogic() {
        const searchBar = document.getElementById('searchBar');
        const searchBarMobile = document.getElementById('searchBarMobile');

        if (searchBar) {
            // Ensure that event listeners are not added multiple times
            searchBar.removeEventListener('input', MatchesComponent.handleSearchInputEvent);  // Remove previous listeners
            searchBar.addEventListener('input', MatchesComponent.handleSearchInputEvent);     // Add listener
        }
        if (searchBarMobile) {
            // Ensure that event listeners are not added multiple times
            searchBarMobile.removeEventListener('input', MatchesComponent.handleSearchInputEventMobile);  // Remove previous listeners
            searchBarMobile.addEventListener('input', MatchesComponent.handleSearchInputEventMobile);     // Add listener
        }


        if (searchBar && searchBar.value !== '') {
            const inputEvent = new Event('input', { bubbles: true });
            searchBar.dispatchEvent(inputEvent);
        }
        if (searchBarMobile && searchBarMobile.value !== '') {
            const inputEvent = new Event('input', { bubbles: true });
            searchBarMobile.dispatchEvent(inputEvent);
        }

    }

    static theDateListener(element, selectedDate) {
        element.addEventListener('click', function () {
            MatchesComponent.matchesSavedDate = selectedDate;

            if (window.savedDateMatches && window.savedDateMatches[MatchesComponent.matchesSavedDate]) {
                console.debug('update matches from saved windows')
                MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), null);
                const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                    emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
                }
            } else {
                console.debug('update matches from firestore')
                window.savedDateMatches[MatchesComponent.matchesSavedDate] = 'OK';
                document.querySelector('#placeholder-matches-big').style.display = '';
                FirebaseAssistant.getMatches(querySnapshot => {
                    querySnapshot.forEach(single => {
                        window.savedMatches[single.data().id] = single.data();
                    });

                    MatchesComponent.updateMatches(GameNavbar.getCurrentlyActivatedGameIfAny(), null);
                    const upcomingMatchesDiv = document.querySelector('#upcomingmatches-html');
                    if (!upcomingMatchesDiv.innerText.trim() || upcomingMatchesDiv.innerText === '') {
                        emptyMessage(upcomingMatchesDiv, 'matchTranslations.emptyGameFilterMessage', 'gameFilterNotMsg');
                    }
                }, MatchesComponent.matchesSavedDate);
            }
        });
    }

}

MatchesComponent.template =   /*html*/ `
<style>

matches-component    .match-countdown-or-date-container {
        width: 10%;
        font-size: calc(0.3rem + 0.55vw);
        color:rgba(255, 255, 255, 0.7) !important;
    }
matches-component   .match-tournament-container {
        width: 20%;
        font-size: calc(0.3rem + 0.5vw);
        color:rgba(255, 255, 255, 0.7) !important;
    }
matches-component    .match-teams-container {
        width: 61%;
    }
matches-component    .match-game-container {
        width: 4%;
        font-size: calc(0.4rem + 0.45vw);
    }
matches-component    .team-related {
        width: 43%;
    }
matches-component    .team-name {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: calc(0.4rem + 0.45vw);
        max-width: calc(10px + 5vw);
    }
matches-component    .score-matches {
        font-size: calc(0.6rem + 0.3vw);
        width: 14%;
        text-align: center;
    }

matches-component .gameFilterNotMsg {
  text-align: center;
  padding: 20px;
  background-color: #161b22;
  border-color: #f5c6cb;
  border-radius: 0.25rem;
}

#searchBar::-webkit-input-placeholder,
#searchBar:-ms-input-placeholder,
#searchBar::placeholder {
  font-size: calc(0.15rem + 0.45vw);
}

#searchBar.search-input {
  font-size: 10px !important;
  width: calc(1rem + 13vw);
}

#searchBar.search-input:focus {
  border-color: #ff2828;
  box-shadow: 0 0 0 0.2rem rgba(255, 40, 40, 0.25);
}

matches-component .search-input-mobile {
  font-size: 10px !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

matches-component .search-input-mobile2 {
  font-size: 10px !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

matches-component .search-input-mobile:focus {
  font-size: 10px !important;
  border-color: #ff2828;
  box-shadow: 0 0 0 0.2rem rgba(255, 40, 40, 0.25);
}

matches-component .style-pills .status-selector {
  background-color: #181c24;
  border-radius: 30px;
  color: #ffffff !important;
  padding: calc(0.4rem + 0.1vw) calc(0.8rem + 0.2vw);
  margin: 0 5px;
  font-size: calc(0.5rem + 0.25vw);
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  list-style-type: none;
}

matches-component .new-odds-badge {
  background-color: rgba(0, 0, 0, 0.62);
  color: #ffffff;
  font-size: calc(0.4rem + 0.45vw);
  padding: calc(0.05rem + 0.2vw) calc(0.2rem + 0.4vw);
  border-radius: 4px;
  font-weight: 700;
  transition: background-color 0.3s;
  text-align: center;
}

matches-component .new-odds-badge-placeholder {
  color: transparent;
  font-size: calc(0.4rem + 0.45vw);
  padding: calc(0.05rem + 0.2vw) calc(0.2rem + 0.4vw);
  font-weight: 700;
  text-align: center;
}

matches-component .star-from-tab {
  margin-right: 0.3rem;
  color: gold;
  font-size: 0.66rem;
  vertical-align: middle;
}

matches-component .form-check {
  padding-left: 0 !important;
}

matches-component .form-check .form-check-input {
  margin-left: 0 !important;
}

matches-component .star-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  margin-left: 0.5rem;
}

matches-component .star-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  cursor: pointer;
  z-index: 2;
}

matches-component .star-icon {
  font-size: calc(1rem + 0.3vw);
  margin-right: 0.5rem;
  line-height: 1;
  color: gray;
  transition: color 0.3s ease, transform 0.2s ease;
}

matches-component .checked-style + .star-icon {
  color: gold;
}

@media (hover: hover) {
  matches-component .star-label:hover .star-icon {
    transform: scale(1.05);
    color: orange;
  }
}


matches-component .winner-Highlight {
  background-color: #005307;
  filter: drop-shadow(#0d1117 0rem 0.4rem 4.8px);
  border-radius: 10px;
}

matches-component .style-pills .activestatus {
  background-color: rgba(255, 40, 40, 0.59) !important;
  color: #ffffff !important;
}

matches-component .style-pills .disabled {
  color: #707070 !important;
}

matches-component .hidden-important {
  display: none !important;
}

matches-component .countdownBadge {
  font-size: calc(0.2rem + 0.35vw) !important;
  font-weight: 700;
}

matches-component .img-circle-container {
  width: calc(1.3rem + 1.3vw);
  height: calc(1.3rem + 1.3vw);
}

matches-component .img-circle-container img {
  max-width: calc(1.0rem + 1.0vw);
}

@media only screen and (max-width: 1199px) {
  matches-component .style-pills {
    justify-content: space-between !important;
    padding: 0;
  }
    matches-component  .match-teams-container {
        width: 70%;
    }
}
@media only screen and (max-width: 1399px) {
    matches-component .match-tournament-container {
        width: 25%;
    }
}

</style>

<div class="container p-0">
  <div class="row">
    <!-- Custom Scrollable Datepicker with Scroll Arrows -->
    <div class="date-selector-matches-container">
      <button class="scroll-arrow-matches scroll-left-MatchesComponent" aria-label="Scroll Left">
        <i class="bi bi-chevron-left"></i>
      </button>
      <div class="date-selector-matches" id="dateSelector-MatchesComponent">
        <!-- Date boxes will be injected here by JavaScript -->
      </div>
      <button class="scroll-arrow-matches scroll-right-MatchesComponent" aria-label="Scroll Right">
        <i class="bi bi-chevron-right"></i>
      </button>
    </div>
  </div>

  <div class="input-group mb-2 d-flex d-xl-none">
    <span class="input-group-text search-input-mobile">
      <i class="bi bi-search"></i>
    </span>
    <input id="searchBarMobile" type="text" class="text-truncate search-input-mobile2 form-control" placeholder="${I18n.translateString('matchTranslations.searchPlaceholder')}">
  </div>

  <div class="row">
    <ul class="col-12 col-xl-9 style-pills d-flex rounded justify-content-start align-items-center mb-2">
      <li class="status-selector activestatus" id="running" alt="running" data-toggle="pill">
        LIVE
      </li>
      <li class="status-selector" id="not_started" alt="not_started" data-toggle="pill">
        UPCOMING
      </li>
      <li class="status-selector" id="finished" alt="finished" data-toggle="pill">
        FINISHED
      </li>
      <li class="status-selector" id="fav-tab" alt="fav-tab" data-toggle="pill">
          <i class="bi bi-star-fill star-from-tab" alt="i-fav-tab"></i> FAVORITES
        </a>
      </li>
    </ul>

    <div class="col-3 d-flex justify-content-end align-items-center d-none d-xl-flex mb-2">
      <form>
        <input id="searchBar" type="text" class="text-truncate search-input form-control rounded-pill" placeholder="${I18n.translateString('matchTranslations.searchPlaceholder')}">
      </form>
    </div>
  </div>
</div>

<div id="upcomingmatches-html"></div>
`;

MatchesComponent.reg('matches-component');