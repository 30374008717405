
import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";

export class PlaceholderMatches extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('placeholder-matches', PlaceholderMatches.initiator)
  }

  static initiator() {
    let matchesHtml = '';
    for (let i = 0; i < 10; i++) {
      matchesHtml += /*html*/`
        <div class="match-item-placeholder mt-3">
          <div class="game-logo skeleton">
          </div>
          <div class="match-left d-flex flex-row gap-2 align-items-center">
            <div class="team-name skeleton"></div>
            <div class="team-logo skeleton"></div>
          </div>
          <div class="match-center d-flex flex-row gap-2 align-items-center justify-content-center">
            <div class="match-odds skeleton"></div>
            <div class="match-score skeleton"></div>
            <div class="match-odds skeleton"></div>
          </div>
          <div class="match-right d-flex flex-row-reverse gap-2 align-items-center">
            <div class="team-name skeleton"></div>
            <div class="team-logo skeleton"></div>
          </div>
          <div>
            <i class="bi bi-star-fill"></i>
          </div>
        </div>`;
    }
    document.getElementById('the-matches-placeholder-big').innerHTML = matchesHtml;
  }

}

PlaceholderMatches.template = /*html*/`
<style>
placeholder-matches .date-item {
  width: 13%;
  height: calc(2rem + 2vw);
  border-radius: 8px;
}

placeholder-matches .search-bar-placeholder {
  width: 95%;
  height: calc(1rem + 2vw);
  border-radius: 6px;
}

placeholder-matches .status-button {
  width: calc(2rem + 3vw);
  height: calc(1rem + 1vw);
  border-radius: 18px;
}

placeholder-matches .match-item-placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1E252E;
  border-radius: 8px;
  padding: calc(0.5rem + 0.5vw);
}

placeholder-matches .skeleton {
  background: rgb(31, 36, 43);
  position: relative;
  overflow: hidden;
}

placeholder-matches .skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 100%);
  animation: placeholder-matches-shimmer 1.5s infinite;
}

@keyframes placeholder-matches-shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

placeholder-matches .match-left,
placeholder-matches .match-right {
  width: 25%;
}

placeholder-matches .match-center {
  width: 35%;
}

placeholder-matches .game-logo {
  width: 5%;
  height: calc(1rem + 1vw);
  border-radius: 20%;
}

placeholder-matches .team-logo {
  width: calc(1.5rem + 1vw);
  height: calc(1rem + 1.5vw);
  border-radius: 50%;
}

placeholder-matches .team-name {
  width: calc(4rem + 4vw);
  height: calc(0.5rem + 0.5vw);
  border-radius: 4px;
}

placeholder-matches .match-score {
  width: calc(1rem + 1vw);
  height: 15px;
  border-radius: 4px;
}

placeholder-matches .match-odds {
  width: calc(1rem + 1vw);
  height: 20px;
  border-radius: 4px;
}

placeholder-matches .bi-star-fill {
  width: 5%;
}
</style>

      <div id="placeholder-matches-big">
        <div class="top-bar-placeholder d-flex flex-column align-items-center">
          <!-- Date Selector Placeholder -->
          <div class="date-selector-placeholder mt-2 px-4 d-flex flex-row justify-content-between w-100">
            <div class="date-item skeleton"></div>
            <div class="date-item skeleton"></div>
            <div class="date-item skeleton"></div>
            <div class="date-item skeleton"></div>
            <div class="date-item skeleton"></div>
            <div class="date-item skeleton"></div>
          </div>

          <!-- Search Bar Placeholder -->
          <div class="search-bar-placeholder skeleton mt-2"></div>

          <!-- Status Buttons Placeholder -->
          <div class="status-buttons-placeholder mt-2 d-flex flex-row justify-content-between w-100">
            <div class="status-button skeleton"></div>
            <div class="status-button skeleton"></div>
            <div class="status-button skeleton"></div>
            <div class="status-button skeleton"></div>
          </div>
        </div>

        <div id="the-matches-placeholder-big" class="matches-placeholder my-3 d-flex flex-column">
        </div>
`;
PlaceholderMatches.reg('placeholder-matches'); // components should always be 2 words seperated with a dash