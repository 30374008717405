import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { makeAtagsForGlobalSocials } from "../../utilities/general/socialIcons";

const logoImage = require("../../utilities/template-assets/assets/icons/any/icon-512x512.png");
const eeepEnglishBig = require('./assets/eeepEnglisgBig.webp');
const eeepGreekBig = require('./assets/eeepGreekBig.webp');
const eeepSmall = require('./assets/eeepSmall.webp');
const funStops = require('./assets/When_the_Fun_Stops_Stop.webp');
const eighteenPlus = require('./assets/18.webp');
const gambleAware = require('./assets/gamble_aware.webp');

export class FooterContainer extends BaseComponent {

  initCallback() {

    document.querySelector('.footer-some-icons').innerHTML = makeAtagsForGlobalSocials();
    GeofencingGuard.getUserCountry(function (userCurrentCountry) {
      if (userCurrentCountry === 'GR') {
        document.getElementById('eeepRow').style.display = ''
        if (I18n.getUsersPreferredLanguage() === 'el')
          document.getElementById('eeepGreekPart').style.display = ''
        else
          document.getElementById('eeepEnglishPart').style.display = ''
      }
    })
  }
}

FooterContainer.template = /*html*/ `
<style>
  footer-container .text-eeep {
    font-size: calc(0.5rem + 0.7vw);
    color: white;
    font-family: Verdana;
  }

  footer-container .text-resize-footer {
    font-size: calc(0.4rem + 0.5vw);
  }

  footer-container .container {
    max-width: 100%;
    margin: 0;
    background-color: #1c232c;
    border-top: #B22A23 solid;
  }

  footer-container a {
    text-decoration: none;
    color: #adb5bd;
  }

  footer-container a:hover {
    color: #B22A23;
  }

  footer-container .footer-some-icons {
    justify-content: center;
  }

  footer-container .footer-copyright {
    border-top: #adb5bd solid 1px;
  }

  footer-container .footer-title {
    font-size: calc(0.8rem + 0.4vw);
    color: #ffffff;
  }

  footer-container .eep-img-big {
    width: 10rem;
    height: auto;
  }

  footer-container .eep-img-small {
    width: 5rem;
    height: auto;
  }

  footer-container .logo-myp {
    width: calc(3rem + 3vw);
    height: auto;
  }

  footer-container #footerAboutContent {
    text-align: justify;
  }

  footer-container .gamble-aware-logos {
    object-fit: contain;
  }
</style>

<div class="container text-center">
  <div class="footer-top-content row">
    <div class="footer-social-media col-lg-6 col-md-6 col-xl-4 col-sm-12 mt-2 mt-lg-5">
      <p id="footerFollowUs" class="mb-1 footer-title">${I18n.translateString('footer.followUs')}</p>
      <img src="${logoImage}" class="logo-myp img-fluid mb-3 mt-2" alt="myesports logo">
      <div class="footer-some-icons d-flex">
      </div>
    </div>
    <div class="footer-usefull-links col-lg-6 col-md-6 col-xl-4 col-sm-12 mt-2 mt-lg-5">
      <p id="footerUsefulLinks" class="mb-1 footer-title">${I18n.translateString('footer.usefulLinks')}</p>
      <div class="d-flex d-md-block justify-content-between mt-2 mt-md-1 ps-2 pe-2">
        <a href="mailto: contact@myesports.gg">
          <p id="footerContactUs" class="mb-1 mb-md-3 text-resize-footer">${I18n.translateString('footer.contactUs')}</p>
        </a>
        <a href="/${I18n.getUsersPreferredLanguage()}/terms-of-use">
          <p id="footerEULA" class="mb-1 mb-md-3 text-resize-footer">${I18n.translateString('footer.eula')}</p>
        </a>
        <a href="/${I18n.getUsersPreferredLanguage()}/privacy-policy">
          <p id="footerPrivacy" class="mb-1 mb-md-3 text-resize-footer">${I18n.translateString('footer.privacy')}</p>
        </a>
        <a href="/${I18n.getUsersPreferredLanguage()}/frequently-asked-questions">
          <p id="footerFaq" class="mb-1 mb-md-3 text-resize-footer">${I18n.translateString('footer.faq')}</p>
        </a>
        <a href="/${I18n.getUsersPreferredLanguage()}/authors">
          <p id="footerAuthors" class="mb-1 mb-md-3 text-resize-footer">${I18n.translateString("footer.Authors")}</p>
        </a>
      </div>
    </div>
    <div class="footer-aboutus col-lg-12 col-md-12 col-xl-4 col-sm-12 mt-2 mt-lg-5">
      <p id="footerAboutUs" class="footer-title mb-1">${I18n.translateString('footer.aboutUs')}</p>
      <div class="col-10 offset-1 col-md-8 offset-md-2 mt-2">
        <p id="footerAboutContent" class="text-resize-footer">
          ${I18n.translateString('footer.about')}
        </p>
      </div>
    </div>
  </div>
  <div id="eeepRow" class="footer-eeep row d-flex justify-content-center m-3 m-lg-5" style="display: none">
    <div id="eeepEnglishPart" class="col-12 d-flex align-items-center justify-content-center" style="display: none!important">
      <img src="${eeepEnglishBig}" class="eep-img-big img-fluid d-none d-lg-flex" alt="eeep">
      <img src="${eeepSmall}" class="eep-img-small img-fluid d-flex d-lg-none" alt="eeep">
      <span class="ms-2 text-eeep d-none d-lg-flex">
        21+ | RISK OF ADDICTION & LOSS OF PROPERTY | PLAY RESPONSIBLY | <br>
        KETHEA: 2109237777 | PLAY RESPONSIBLY
      </span>
      <span class="ms-2 text-eeep d-flex d-lg-none">
        21+ | RISK OF ADDICTION & LOSS OF PROPERTY | PLAY RESPONSIBLY
      </span>
    </div>
    <div id="eeepGreekPart" class="col-12 d-flex align-items-center justify-content-center" style="display: none!important">
      <img src="${eeepGreekBig}" class="eep-img-big img-fluid d-none d-lg-flex" alt="eeep banner">
      <img src="${eeepSmall}" class="eep-img-small img-fluid d-flex d-lg-none" alt="eeep banner">
      <span class="ms-2 text-eeep d-none d-lg-flex">
        21+ | ΑΡΜΟΔΙΟΣ ΡΥΘΜΙΣΤΗΣ ΕΕΕΠ | ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ | <br>
        ΓΡΑΜΜΗ ΒΟΗΘΕΙΑΣ ΚΕΘΕΑ: 2109237777 | ΠΑΙΞΕ ΥΠΕΥΘΥΝΑ
      </span>
      <span class="ms-2 text-eeep d-flex d-lg-none">
        21+ | ΑΡΜΟΔΙΟΣ ΡΥΘΜΙΣΤΗΣ ΕΕΕΠ | ΚΙΝΔΥΝΟΣ ΕΘΙΣΜΟΥ & ΑΠΩΛΕΙΑΣ ΠΕΡΙΟΥΣΙΑΣ
      </span>
    </div>
  </div>
  <a href="https://www.gambleaware.org/" target="_blank" rel="noopener noreferrer" class="row mt-2 mb-5 d-flex justify-content-between" style="background-color:rgba(0, 0, 0, 0.31)">
    <img src="${gambleAware}" class="gamble-aware-logos col-4 col-md-3 img-fluid mt-2 mb-2 ms-md-5" alt="gamble aware logo">
    <img src="${eighteenPlus}" class="gamble-aware-logos col-4 col-md-3 img-fluid mt-2 mb-2" alt="gamble aware logo">
    <img src="${funStops}" class="gamble-aware-logos col-4 col-md-3 img-fluid mt-2 mb-2 me-md-5" alt="gamble aware logo">
  </a>
  <div class="footer-copyright row">
    <div class="col p-3">
      &copy; MyEsports 2025
    </div>
  </div>
</div>   
`;

FooterContainer.reg('footer-container');
