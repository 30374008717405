import { BaseComponent } from "@symbiotejs/symbiote";
import discLogo from './assets/discord-white-icon.png';
import { I18n } from "../../utilities/i18n/i18n";
import { globalSocials } from "../../../functions/across-stack/utils/globals";

export class DiscordTemplate extends BaseComponent {
  initCallback() {
    document.querySelectorAll(".discord-thingy").forEach((el) => {
      el.insertAdjacentHTML('beforeend', /*html*/ `
      <div class="widget-header">
        <div class="image-wrapper">
          <img src="${discLogo}" alt="Discord Logo">
        </div>
        <div class="titles">
          <div class="title-main"><!-- I18N entry --></div>
          <div class="title-sub"><!-- I18N entry --></div>
        </div>
      </div>`);
      el.querySelector(".title-main").innerText = I18n.translateString("discordTemplate.titleMain");
      el.querySelector(".title-sub").innerText = I18n.translateString("discordTemplate.titleSub");
    });
  }
}

DiscordTemplate.template = /*html*/ `
<style>
  discord-template .widget-container {
    background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
    border-radius: 16px;
    padding: calc(0.5rem + 0.5vw); /* Responsive padding */
    color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    text-decoration: none; /* Ensure link styles don't interfere */
    cursor: pointer;
  }

  discord-template .widget-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.6);
  }

  discord-template .widget-header {
    display: flex;
    align-items: center;
  }

  discord-template .widget-header .image-wrapper {
    width: calc(1.8rem + 1.8vw); /* Responsive size for the wrapper */
    height: calc(1.8rem + 1.8vw);
    border-radius: 50%;
    border: 3px solid #5865F2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 20px;
    background-color: transparent;
    animation: pulse 2s infinite;
  }

  discord-template .widget-header .image-wrapper img {
    width: calc(1.2rem + 1.2vw); /* Responsive size for the image */
    height: calc(1.2rem + 1.2vw);
    object-fit: contain;
    transition: transform 0.5s ease;
  }

  discord-template .widget-header .image-wrapper img:hover {
    transform: rotate(360deg);
  }

  discord-template .widget-header .titles {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  discord-template .widget-header .titles .title-main {
    font-size: calc(0.5rem + 0.5vw); /* Responsive font size */
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  discord-template .widget-header .titles .title-sub {
    font-size: calc(0.2rem + 0.5vw); /* Responsive font size */
    color: #b9bbbe;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }

  discord-template .widget-action {
    font-size: 2rem;
    color: #5865F2;
    transition: color 0.3s ease, transform 0.3s ease;
  }

  discord-template .widget-action:hover {
    color: #7289da;
    transform: translateX(5px);
  }

  /* Animations */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(88, 101, 242, 0.7);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(88, 101, 242, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(88, 101, 242, 0);
    }
  }

  @media only screen and (max-width: 768px) {
    discord-template .widget-header .image-wrapper {
      width: 54px; /* Fixed size for smaller screens */
      height: 54px;
      margin-right: 10px;
    }

    discord-template .widget-header .image-wrapper img {
      width: 36px; /* Further Fixed size */
      height: 36px;
    }

    discord-template .widget-header .titles .title-main,
    discord-template .widget-header .titles .title-sub {
      font-size: 1rem;
    }
  }
</style>

  <a href="${globalSocials.discord}" target="_blank" class="widget-container discord-thingy" aria-label="Join our Discord server">
  </a>
`;

DiscordTemplate.reg('discord-template');