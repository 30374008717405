import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { GameNavbar } from "../game-navbar/game-navbar";
import { gameIdImg, gameIdFilter } from "../../utilities/general/gameIdImgMapping";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { extractFirstTextBlock } from "../../utilities/general/extractFirstTextBlock";
import { emptyMessage } from "../../utilities/general/emptyMessage";

const noImage = require('../../global-assets/no image found.png');

export class AnalysesHomecomponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('analyses-homecomponent', AnalysesHomecomponent.initiator);
    }

    static initiator() {

        getDataFrontend('savedAnalyses', 'getAnalyses', 'fromHomeWidgetComponent', function () {
            if (document.querySelectorAll('#latestAnalysesList').length !== 0) {
                const activeGameFilterId = GameNavbar.getCurrentlyActivatedGameIfAny();
                AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
            }
        });

        AnalysesHomecomponent.translations();

        GameNavbar.subscribeToGameFilterChanges('fromAiAnalyses', (activeGameFilterId) => {
            if (document.querySelectorAll('#latestAnalysesList').length !== 0) { // Ensure the component exists on the page
                AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
            }
        });
    }

    static updateAnalyses(activeGameFilterId) {

        const list = document.getElementById('latestAnalysesList');
        list.innerHTML = '';

        let counter = 0;
        Object.entries(window.savedAnalyses).forEach(([key, value]) => {
            const doc = window.savedAnalyses[key];
            const clickBaitString = extractFirstTextBlock(doc.output, "analysis-unbelievable");
            if (key.split('-')[1] !== I18n.getUsersPreferredLanguage() && key.split('-')[1] !== I18n.defaultLocale) return;
            if (clickBaitString.length === 0) return;
            if (activeGameFilterId !== null && activeGameFilterId !== 'null' && gameIdFilter[doc.gameId] !== activeGameFilterId) return;
            if (counter >= 5) return;

            counter++;
            const image1 = (doc.team1logo !== '') ? doc.team1logo : noImage;
            const image2 = (doc.team2logo !== '') ? doc.team2logo : noImage;
            const team1name = (doc.opName1) ? doc.opName1 : '';
            const team2name = (doc.opName2) ? doc.opName2 : '';
            const team1slug = (doc.opSlug1) ? doc.opSlug1 : '';
            const team2slug = (doc.opSlug2) ? doc.opSlug2 : '';
            const matchName = doc.matchName;
            const gameId = doc.gameId;

            list.insertAdjacentHTML("beforeend", /*html*/`
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 mb-2">
                    <a href="/${I18n.getUsersPreferredLanguage()}/match/${key.split('-')[0]}/${matchName}" id="analysis-${key.split('-')[0]}" class="analysis-card">
                        <div class="analysis-row">
                            <!-- Game Logo on the Left -->
                            <div class="game-logo-container d-flex justify-content-center flex-column m-1">
                                <img src="${gameIdImg[gameId]}" alt="${gameIdFilter[gameId]} Logo" class="img-fluid mx-auto d-block">
                            </div>
                            
                            <!-- Teams Section on the Right -->
                            <div class="teams-container">
                                <!-- Team 1 -->
                                <div class="team-container">
                                    <span class="analysis-team-name">${team1slug}</span>
                                    <div class="team-logo d-flex justify-content-center flex-column ms-1">
                                        <img src="${image1}" alt="${team1name} Logo" class="img-fluid mx-auto d-block">
                                    </div>
                                </div>
                                
                                <!-- VS Separator -->
                                <div class="vs">VS</div>
                                
                                <!-- Team 2 -->
                                <div class="team-container">
                                    <div class="team-logo d-flex justify-content-center flex-column me-1">
                                        <img src="${image2}" alt="${team2name} Logo" class="img-fluid mx-auto d-block">
                                    </div>
                                    <span class="analysis-team-name">${team2slug}</span>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Summary -->
                        <div class="analysis-summary">
                            ${clickBaitString}
                        </div>
                    </a>
                </div>
            `);
        });

        if (list.innerHTML === '') emptyMessage(list, 'matchTranslations.emptyAnalysisFilterMessage', 'analysisFilterNotMsg');

    }

    static translations() {
        const titleElement = document.getElementById('analysesHomeTitle');
        if (titleElement) {
            titleElement.innerHTML = I18n.translateString('homePage.latestAnalyses');
        }
    }
}

AnalysesHomecomponent.template = /*html*/`
<style>
  analyses-homecomponent .analysisFilterNotMsg {
      text-align: center;
      padding: 20px;
      background-color: #161b22;
      border-color: #f5c6cb;
      border-radius: 0.25rem;
  }



  analyses-homecomponent .ht-section-title {
      font-size: calc(0.5rem + 1vw);
      color: #f1f1f1;
      position: relative;
  }

  analyses-homecomponent .ht-section-title::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 90%;
      height: 4px;
      background-color: #B22A23;
      border-radius: 2px;
  }

    @media only screen and (max-width: 1024px) {
  analyses-homecomponent .ht-section-title {
            font-size: 1rem;
        }
    }


  /* Analysis Card Styles */
  analyses-homecomponent .analysis-card {
      background-color: #1E252E;
      border-radius: 12px;
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;
      color: inherit;
  }

  analyses-homecomponent .analysis-card:hover .analysis-summary,
  analyses-homecomponent .analysis-card:hover {
      transform: translateY(-5px);
      cursor: pointer;
      background-color: #3A4B5C;
  }

  /* Combined Row for Game Logo and Teams */
  analyses-homecomponent .analysis-row {
      display: flex;
      align-items: center;
      background-color: #161b22;
      padding: 0.5rem;
      border-bottom: 1px solid #B22A23;
      justify-content: space-between;
      gap: 0.5rem;
      position: relative;
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  }

  /* Game Logo Container */
  analyses-homecomponent .game-logo-container img {
      width: calc(1rem + 1vw);
      object-fit: contain;
  }

  /* Teams Container */
  analyses-homecomponent .teams-container {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      flex-wrap: wrap;
  }

  /* Team Container */
  analyses-homecomponent .team-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
  }

  /* Team Name */
  analyses-homecomponent .analysis-team-name {
      font-size: calc(0.4rem + 0.4vw);
      font-weight: 600;
      color: #f1f1f1;
      text-align: left;
      white-space: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  /* Team Logo */
  analyses-homecomponent .team-logo {
      width: calc(1rem + 1vw);
  }

  analyses-homecomponent .team-logo img {
      max-width: calc(0.7rem + 0.4vw);
  }

  /* VS Separator */
  analyses-homecomponent .vs {
      font-size: calc(0.42rem + 0.42vw);
      margin: 0 10px;
      font-weight: 600;
      color: #f1f1f1;
      flex-shrink: 0;
  }

  /* Summary */
  analyses-homecomponent .analysis-summary {
      font-size: calc(0.4rem + 0.4vw);
      color: #ccc;
      padding: 0;
      margin: 0.75rem;
      background-color: #1e252e;
      border-radius: 0 0 12px 12px;
      text-align: left;

      /* Truncate text to 5 lines */
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  }

  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
      analyses-homecomponent .team-logo {
          width: 1.5rem;
      }

      analyses-homecomponent .team-logo img {
          max-width: 1.2rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.8rem;
          margin: 0;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.75rem;
      }

      analyses-homecomponent .analysis-team-name {
          font-size: 0.8rem;
      }
  }
</style>

<div id="analysesFinalHtml" class="container ah-component mt-4">
    <div class="ht-section-header d-flex justify-content-between align-items-center mb-2">
        <h2 id="analysesHomeTitle" class="ht-section-title">
            RECENT AI ANALYSIS placeholder
        </h2>
    </div>

    <div id="latestAnalysesList" class="row"></div>
</div>
`;

AnalysesHomecomponent.reg('analyses-homecomponent'); 