import { BaseComponent } from "@symbiotejs/symbiote";

export class PlaceholderTeamplayers extends BaseComponent {

    initCallback() {
    }

}

PlaceholderTeamplayers.template = /*html*/`
<style>

placeholder-teamplayers .skeleton {
  background: rgb(31, 36, 43);
  position: relative;
  overflow: hidden;
}

placeholder-teamplayers .skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 100%);
  animation: placeholder-teamplayers-shimmer 1.5s infinite;
}

@keyframes placeholder-teamplayers-shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

placeholder-teamplayers .placeholder-playerlogo {
  width: calc(50px + 6vw);
  height: calc(100px + 7vw);
  border-radius: 5%;
}

placeholder-teamplayers .team-name {
  width: 40%;
  height: calc(1.5rem + 1.5vw);
  border-radius: 4px;
}
</style>
              <div class="my-3 text-center d-flex justify-content-center align-items-center w-100">
                <div class="team-name skeleton"></div>
              </div>

              <div class="container-fluid scrollbar players-grid row row-cols-3 row-cols-lg-4 row-cols-xl-5 g-3">
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
              </div>

              <div class="my-3 text-center d-flex justify-content-center align-items-center w-100">
                <div class="team-name skeleton"></div>
              </div>

              <div class="container-fluid scrollbar players-grid row row-cols-3 row-cols-lg-4 row-cols-xl-5 g-3">
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
                <div class="col">
                    <div class="placeholder-playerlogo skeleton"></div>
                </div>
              </div>

`;
PlaceholderTeamplayers.reg('placeholder-teamplayers');