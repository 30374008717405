// sorts tournaments by tier, currency, prize pool, and date in that order
export function sortTournaments(tournaments) {
    const tierOrder = { 's': 1, 'a': 2, 'b': 3, 'c': 4, 'd': 5 };
    const sortedTournamentIds = tournaments.sort((a, b) => {
        const tournamentA = window.savedTournaments[a];
        const tournamentB = window.savedTournaments[b];

        const tierA = tierOrder[tournamentA.tournaments[0].tier] || 6;
        const tierB = tierOrder[tournamentB.tournaments[0].tier] || 6;

        if (tierA !== tierB) {
            return tierA - tierB;
        }

        const isValidCurrencyA = tournamentA.currency === 'Euro' || tournamentA.currency === 'United States Dollar' ? 1 : 0;
        const isValidCurrencyB = tournamentB.currency === 'Euro' || tournamentB.currency === 'United States Dollar' ? 1 : 0;

        if (isValidCurrencyA !== isValidCurrencyB) {
            return isValidCurrencyB - isValidCurrencyA; // valid currencies come first
        }

        if (tournamentA.totalPrizePool !== tournamentB.totalPrizePool) {
            return tournamentB.totalPrizePool - tournamentA.totalPrizePool; // sort in descending order

        }

        const dateA = new Date(tournamentA.begin_at ? tournamentA.begin_at : tournamentA.scheduled_at);
        const dateB = new Date(tournamentB.begin_at ? tournamentB.begin_at : tournamentB.scheduled_at);

        return dateA.getTime() - dateB.getTime();
    });

    return sortedTournamentIds;
}