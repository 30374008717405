import { MatchesComponent } from "../../components/matches-component/matches-component";
import { PredictionComponent } from "../../components/predictions-component/predictions-component";
import { I18n } from "../i18n/i18n";

export function generateDateSelector(Component) {

    // Function to Generate a Date Range (past + future days)
    function generateDateRange(startDate, numberOfDays) {
        const dates = [];
        const halfDays = Math.floor(numberOfDays / 2);
        // Generate past dates
        for (let i = halfDays; i > 0; i--) {
            const pastDate = new Date(startDate);
            pastDate.setDate(startDate.getDate() - i);
            dates.push(pastDate);
        }
        // Add today
        dates.push(new Date(startDate));
        // Generate future dates
        for (let i = 1; i <= halfDays; i++) {
            const futureDate = new Date(startDate);
            futureDate.setDate(startDate.getDate() + i);
            dates.push(futureDate);
        }
        return dates;
    }

    const dateSelector = document.getElementById(`dateSelector-${Component}`);
    if (!dateSelector) {
        return;
    }
    dateSelector.innerHTML = '';


    const allDates = generateDateRange(new Date(), 10); // Generate 5  days before and after today
    const todayStr = formatDate(new Date());

    // Center datepicker around today with past and future dates
    allDates.forEach((dateObj, index) => {
        const dateStr = formatDate(dateObj);
        const day = dateObj.getDate();
        const dayName = dateObj.toLocaleString(I18n.getUsersPreferredLanguage(), { weekday: 'short' });
        const monthName = dateObj.toLocaleString(I18n.getUsersPreferredLanguage(), { month: 'short' });

        const dateBox = document.createElement('div');
        dateBox.classList.add(`date-box-${Component}`);

        const isTodayDate = (dateStr === todayStr);
        if (isTodayDate) {
            dateBox.innerHTML = `<span class="day-number todayClass">Today</span><span class="day-name">${dayName}</span>`;
        } else {
            dateBox.innerHTML = `<span class="day-number">${day}</span><span class="day-name">${dayName} ${monthName}</span>`;
        }
        dateBox.setAttribute(`data-date-${Component}`, dateStr);
        dateBox.setAttribute('role', 'button');
        dateBox.setAttribute('tabindex', '0');
        dateBox.setAttribute('aria-label', 'Select matches  ' + (isTodayDate ? 'Today' : dayName + ' ' + monthName + ' ' + day));

        const selectedDate = dateBox.getAttribute(`data-date-${Component}`);

        if (Component === 'MatchesComponent') MatchesComponent.theDateListener(dateBox, selectedDate);
        else if (Component === 'PredictionComponent') PredictionComponent.theDateListener(dateBox, selectedDate);

        // Accessibility: Handle keyboard events
        dateBox.addEventListener('keypress', function (e) {
            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                this.click();
            }
        });

        dateSelector.appendChild(dateBox);
    });

    const dateContent = document.createElement('div');
    dateContent.classList.add('date-content');
    while (dateSelector.firstChild) {
        dateContent.appendChild(dateSelector.firstChild);
    }
    dateSelector.appendChild(dateContent);

    dateSelector.insertAdjacentHTML('afterbegin', /*html*/`
                    <style>
                    /* Date Selector Container */
                    predictions-component .date-selector-container,
                    matches-component .date-selector-matches-container {
                    position: relative;
                    margin-bottom: 30px;
                    overflow: hidden; /* Hide overflow to use transform for scrolling */
                    white-space: nowrap; /* Prevent line breaks */
                    }

                    /* Date Content */
                    predictions-component .date-content,
                    matches-component .date-content {
                    display: inline-flex;
                    transition: transform 0.3s ease;
                    }

                    /* Date Selector Scrollable Wrapper */
                    predictions-component .date-selector,
                    matches-component .date-selector-matches {
                    display: flex;
                    justify-content: center;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                    scrollbar-width: none;
                    -ms-overflow-style: none;
                    padding: 10px 40px;
                    background-color: inherit;
                    border-radius: 8px;
                    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
                    align-items: center;
                    }

                    /* Hide Scrollbar */
                    predictions-component .date-selector::-webkit-scrollbar,
                    matches-component .date-selector-matches::-webkit-scrollbar {
                    display: none;
                    }

                    /* Date Boxes */
                    predictions-component .date-box-PredictionComponent,
                    matches-component .date-box-MatchesComponent {
                    background-color: #21262d;
                    border-radius: 15px;
                    padding: calc(0.2rem + 0.4vw) calc(0.4rem + 0.8vw);
                    margin: 0 10px;
                    cursor: pointer;
                    transition: background-color 0.3s, transform 0.3s, color 0.3s;
                    text-align: center;
                    flex-shrink: 0;
                    user-select: none;
                    white-space: nowrap;
                    overflow: auto;
                    text-overflow: ellipsis;
                    }

                    /* Date Boxes Hover */
                    predictions-component .date-box-PredictionComponent:hover,
                    matches-component .date-box-MatchesComponent:hover {
                    background-color: #30363d;
                    transform: translateY(-3px);
                    }

                    /* Active Date Box */
                    predictions-component .date-box-PredictionComponent.active,
                    matches-component .date-box-MatchesComponent.active {
                    background-color: #00d1b2;
                    color: #1e1e1e;
                    transform: translateY(calc(-3px - 0.2vw));
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
                    }

                    /* Date Box Day Number */
                    predictions-component .date-box-PredictionComponent .day-number,
                    matches-component .date-box-MatchesComponent .day-number {
                    font-size: calc(0.6rem + 0.45vw);
                    font-weight: 600;
                    display: block;
                    }

                    /* Date Box Day Name */
                    predictions-component .date-box-PredictionComponent .day-name,
                    matches-component .date-box-MatchesComponent .day-name {
                    font-size: calc(0.4rem + 0.45vw);
                    display: block;
                    }
                    </style>
        `);

}

function formatDate(date) {
    const y = date.getFullYear();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    return `${y}-${m}-${d}`;
}

export function daySelectorTranslatePosition(Component) {
    const elements = document.querySelectorAll(`.date-box-${Component}`);
    const activeElement = Array.from(elements).find(el => el.classList.contains('active'));

    let dateContent;
    let currentTranslateRef;

    switch (Component) {
        case "MatchesComponent":
            dateContent = document.querySelector('matches-component .date-content');
            currentTranslateRef = MatchesComponent;
            break;
        case "PredictionComponent":
            dateContent = document.querySelector('predictions-component .date-content');
            currentTranslateRef = PredictionComponent;
            break;
        default:
            console.warn(`Unknown Component: ${Component}`);
            return;
    }

    const dateSelector = document.getElementById(`dateSelector-${Component}`);
    if (dateSelector) {
        if (dateContent) {
            dateContent.style.transform = `translateX(${currentTranslateRef.currentTranslateX}px)`;
        }

        // Get the position of the active element relative to the parent container
        const activeElementRect = activeElement.getBoundingClientRect();
        const containerRect = dateContent.getBoundingClientRect();

        // Calculate the offset of the active element within the container
        const elementOffsetLeft = activeElementRect.left - containerRect.left;

        function getScrollLimit() {
            return dateContent.clientWidth / 2;
        }
        const scrollLimit = getScrollLimit();

        if (activeElement) {
            // Calculate the necessary translateX value to bring the element into view
            const targetTranslateX = ((elementOffsetLeft / dateContent.clientWidth) * (2 * -scrollLimit)) + scrollLimit;
            // Set the new translateX and update the currentTranslateX
            currentTranslateRef.currentTranslateX = targetTranslateX;
            dateContent.style.transform = `translateX(${currentTranslateRef.currentTranslateX}px)`;
        } else {
            console.warn('No active element found in the list.');
        }

        // Scroll Arrow Functionality
        let scrollLeftBtn = document.querySelector(`.scroll-left-${Component}`);
        let scrollRightBtn = document.querySelector(`.scroll-right-${Component}`);
        if (scrollLeftBtn && scrollRightBtn) {
            // Remove all listeners from the scroll buttons
            scrollLeftBtn = removeAllEventListeners(scrollLeftBtn);
            scrollRightBtn = removeAllEventListeners(scrollRightBtn);

            scrollLeftBtn.addEventListener('click', () => {
                currentTranslateRef.currentTranslateX = Math.min(currentTranslateRef.currentTranslateX + dateSelector.clientWidth / 4, scrollLimit); // Ensure it does not go beyond the left limit
                dateContent.style.transform = `translateX(${currentTranslateRef.currentTranslateX}px)`;
            });

            scrollRightBtn.addEventListener('click', () => {
                currentTranslateRef.currentTranslateX = Math.max(currentTranslateRef.currentTranslateX - dateSelector.clientWidth / 4, -scrollLimit); // Ensure it does not go beyond the right limit
                dateContent.style.transform = `translateX(${currentTranslateRef.currentTranslateX}px)`;
            });

            dateContent.addEventListener('touchmove', (e) => {
                e.preventDefault(); // Prevent swiping or dragging
            }, { passive: false });
            // Prevent scrolling on the target element
            dateContent.addEventListener('wheel', (e) => {
                e.preventDefault();
            }, { passive: false });

        }

        dateSelector.insertAdjacentHTML('afterbegin', /*html*/`
                        <style>
                        /* Scroll Arrows */
                        matches-component .scroll-arrow-matches,
                        predictions-component .scroll-arrow {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: rgba(24, 28, 33, 0.7);
                        border: none;
                        color: #c9d1d9;
                        padding: 10px;
                        border-radius: 50%;
                        cursor: pointer;
                        z-index: 3;
                        transition: background-color 0.3s, color 0.3s;
                        }

                        matches-component .scroll-arrow-matches:hover,
                        predictions-component .scroll-arrow:hover {
                        background-color: rgba(24, 28, 33, 0.9);
                        color: #58a6ff;
                        }

                        /* Scroll Left Positioning */
                        matches-component .scroll-left-MatchesComponent,
                        predictions-component .scroll-left-PredictionComponent {
                        left: 9px;
                        }

                        /* Scroll Right Positioning */
                        matches-component .scroll-right-MatchesComponent,
                        predictions-component .scroll-right-PredictionComponent {
                        right: 9px;
                        }
                        </style>
        `);
    }
}

function removeAllEventListeners(element) {
    const newElement = element.cloneNode(true); // Create a clone of the element
    element.parentNode.replaceChild(newElement, element); // Replace the old element with the new one
    return newElement; // Return the new element
}