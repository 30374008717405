import { I18n } from "../../utilities/i18n/i18n";
import { reversegameIdFilter } from "./gameIdImgMapping";
import { gameIdImg } from "./gameIdImgMapping";

const noImageFound = require('../../global-assets/no image found.png');
const noImageFoundPlayer = require('../../global-assets/hoodieman.webp');

export function showcasePrediction(prediction, predictionDisplay) {

  const team1Acronym = prediction.teams[0].acronym ? prediction.teams[0].acronym : prediction.teams[0].name;
  const team2Acronym = prediction.teams[1].acronym ? prediction.teams[1].acronym : prediction.teams[1].name;

  if (/^\d+-\d+$/.test(prediction.predictionValue)) {
    predictionDisplay += ` ${I18n.translateString("postMatchTranslations.predictExactScore")}`;
  } else if (/^(Over|Under)(\d+)/.test(prediction.predictionValue)) {
    predictionDisplay = prediction.predictionValue.replace(
      /(Over|Under)(\d+)/,
      "$1 $2"
    ) + ` ${I18n.translateString("postMatchTranslations.predictOverUnder")}`;

  } else if (["Opponent1Win", "1"].includes(prediction.predictionValue)) {
    predictionDisplay = prediction.teams[0].name;
  } else if (["Opponent2Win", "2"].includes(prediction.predictionValue)) {
    predictionDisplay = prediction.teams[1].name;
  } else if (["Draw", "X"].includes(prediction.predictionValue)) {
    predictionDisplay = I18n.translateString("postMatchTranslations.predictDraw");
  } else if (/^(Home|Away)\s([+-]\d+(\.\d+)?)$/.test(prediction.predictionValue)) {
    // E.g. "Home -1.5" => "TEAM1NAME -1.5"
    predictionDisplay = prediction.predictionValue.replace(
      /(Home|Away)\s([+-]\d+(\.\d+)?)/,
      (m, team, handicap) => {
        return `${team === "Home" ? team1Acronym : team2Acronym} ${handicap}`;
      }
    );
  }
  return predictionDisplay;
}

export function showcasePredictionForPostmatch(prediction, team1name, team2name, team1img, team2img) {

  let predictionChoice = ["Opponent1Win", "1"].includes(prediction.predictionValue) ?
    `${team1name && team1img ? `<img src="${team1img}" class="img-fluid mx-auto predImage"> ${team1name} ${I18n.translateString("postMatchTranslations.predictToWin")}</img>` : ''}` :
    ["Opponent2Win", "2"].includes(prediction.predictionValue) ?
      `${team2name && team2img ? `<img src="${team2img}" class="img-fluid mx-auto predImage"> ${team2name} ${I18n.translateString("postMatchTranslations.predictToWin")}</img>` : ''}` :
      ["Draw", "X"].includes(prediction.predictionValue) ?
        `<svg class="predImage2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M488 192H336v56c0 39.7-32.3 72-72 72s-72-32.3-72-72V126.4l-64.9 39C107.8 176.9 96 197.8 96 220.2v47.3l-80 46.2C.7 322.5-4.6 342.1 4.3 357.4l80 138.6c8.8 15.3 28.4 20.5 43.7 11.7L231.4 448H368c35.3 0 64-28.7 64-64h16c17.7 0 32-14.3 32-32v-64h8c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24zm147.7-37.4L555.7 16C546.9 .7 527.3-4.5 512 4.3L408.6 64H306.4c-12 0-23.7 3.4-33.9 9.7L239 94.6c-9.4 5.8-15 16.1-15 27.1V248c0 22.1 17.9 40 40 40s40-17.9 40-40v-88h184c30.9 0 56 25.1 56 56v28.5l80-46.2c15.3-8.9 20.5-28.4 11.7-43.7z"/></svg> ${I18n.translateString("postMatchTranslations.predictDraw")}` :
        /^\d+-\d+$/.test(prediction.predictionValue) ?
          `<i class="predImage2 bi bi-hand-thumbs-up-fill"></i> ${prediction.predictionValue} ${I18n.translateString("postMatchTranslations.predictExactScore")}` :
          /^Over\d+\.\d+$/.test(prediction.predictionValue) ?
            `<i class="predImage2 bi bi-chevron-double-up"></i> Over ${prediction.predictionValue.match(/^Over(\d+\.\d+)/)[1]} ${I18n.translateString("postMatchTranslations.predictOverUnder")}` :
            /^Under\d+\.\d+$/.test(prediction.predictionValue) ?
              `<i class="predImage2 bi bi-chevron-double-down"></i> Under ${prediction.predictionValue.match(/^Under(\d+\.\d+)/)[1]} ${I18n.translateString("postMatchTranslations.predictOverUnder")}` :
              /^(Home|Away)\s([+-]\d+(\.\d+)?)$/.test(prediction.predictionValue) ?
                prediction.predictionValue.replace(/(Home|Away)\s([+-]\d+(\.\d+)?)/, (m, team, handicap) => {
                  let teamName = team === "Home" ? team1name : team2name;
                  let teamImg = team === "Home" ? team1img : team2img;
                  return teamName && teamImg
                    ? `<img src="${teamImg}" class="img-fluid mx-auto predImage"> ${teamName} ${handicap}`
                    : `${teamName} ${handicap}`;
                })
                : prediction.predictionValue;

  return predictionChoice;
}

export function buildRowHtml(item, options = {}) {
  const {
    showGameLogo = false,
    showAuthor = false,
    dateFormat = "short",
    locale = "en",
  } = options;

  // 1) Figure out the match URL (common in your code)
  const matchUrl = `/${locale}/match/${item.id}/${item.matchName}`;

  // 2) Convert item.matchDate to a real JS Date
  let matchDate = null;
  if (typeof item.matchDate?.toDate === "function") {
    matchDate = item.matchDate.toDate();
  } else {
    matchDate = new Date(item.matchDate);
  }
  if (isNaN(matchDate.getTime())) {
    // fallback or skip
    matchDate = new Date();
  }

  // 3) Format the date/time text
  let dateText = "";
  if (dateFormat === "short") {
    dateText =
      matchDate.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
  } else {
    dateText = matchDate.toLocaleDateString(locale, {
      weekday: "short",
      day: "numeric",
      month: "short",
    }) +
      " " +
      matchDate.toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
  }
  // 4) Check if match is in the past
  const now = new Date();
  const isPast = matchDate < now;

  // 5) Build your columns:

  // A) (Optional) Game logo
  let gameLogoTd = "";
  if (showGameLogo) {
    const gameLogoSrc = gameIdImg[item.gameId] || null;
    gameLogoTd = /*html*/ `
      <td class="game-logo-cell">
        <a href="${matchUrl}">
          <img src="${gameLogoSrc}" alt="${item.gameId} Logo"
               class="game-logo" style="object-fit: contain; width: calc(1.3rem + 0.8vw);">
        </a>
      </td>
    `;
  }

  // B) Time cell
  const timeTd = /*html*/ `
    <td class="time-cell">
      <a href="${matchUrl}">${dateText}</a>
    </td>
  `;

  // C) Teams cell
  const team1 = item.teams?.[0] || {};
  const team2 = item.teams?.[1] || {};
  const team1Logo = team1.logo === "noImageFound" ? noImageFound : team1.logo === "noImageFoundPlayer" ? noImageFoundPlayer : team1.logo;
  const team2Logo = team2.logo === "noImageFound" ? noImageFound : team2.logo === "noImageFoundPlayer" ? noImageFoundPlayer : team2.logo;
  const team1Odds = item.odds?.["1"] && item.odds["1"] !== "-" ? parseFloat(item.odds["1"]).toFixed(2) : "";
  const team2Odds = item.odds?.["2"] && item.odds["2"] !== "-" ? parseFloat(item.odds["2"]).toFixed(2) : "";

  const matchCell = /*html*/ `
    <td class="match-cell">
      <a href="${matchUrl}">
        <div class="team-container">
          <img src="${team1Logo}" alt="${team1.name || ""} Logo" class="team-logo" title="${team1.name || ""}">
          <span class="team-name text-truncate">${team1.name || ""}</span>
          <span class="odds-value d-none d-md-flex" style="margin-bottom:3px;">${team1Odds}</span>
        </div>
        <div class="team-container">
          <img src="${team2Logo}" alt="${team2.name || ""} Logo" class="team-logo" title="${team2.name || ""}">
          <span class="team-name text-truncate">${team2.name || ""}</span>
          <span class="odds-value d-none d-md-flex" style="margin-top:3px;">${team2Odds}</span>
        </div>
      </a>
    </td>
  `;

  // D) Tournament cell
  const tName = item.tournamentName || "";
  const tournamentTd = /*html*/ `
      <td class="tournament-name">
        <a href="${matchUrl}">
          ${tName}
        </a>
      </td>
    `;

  // E) (Optional) Author cell
  let authorTd = "";
  if (showAuthor) {
    const aName = item.authorName || "";
    const aPhoto = item.authorPhoto || noImageFoundPlayer;
    authorTd = /*html*/ `
      <td>
        <a href="${matchUrl}">
          <div class="author-container d-flex align-items-center">
            <img src="${aPhoto}" alt="${aName} Logo" class="author-logo">
            <span class="author-name">${aName}</span>
          </div>
        </a>
      </td>
    `;
  }

  // F) Prediction cell
  let displayPredVal = showcasePrediction(item, item.predictionValue || "");
  // The main odd for the entire prediction
  let mainOddValue =
    item.oddValue && item.oddValue !== "-"
      ? parseFloat(item.oddValue).toFixed(2)
      : "";

  const predictionTd = /*html*/ `
    <td>
      <a href="${matchUrl}">
        <div class="prediction-value">${displayPredVal}</div>
        <span class="prediction-odds-value blue-animation">${mainOddValue}</span>
      </a>
    </td>
  `;

  // G) Outcome cell
  let outcomeHtml = "";
  if (item.state && item.state !== "Upcoming") {
    if (isPast && item.state === "W") {
      outcomeHtml = /*html*/`<span class="text-success checkPredictStateClass"><i class="bi bi-check-circle-fill"></i></span>`;
    } else if (item.state === "Canceled") {
      outcomeHtml = /*html*/`<span class="checkPredictStateClass"><i class="bi bi-dash-circle-fill"></i></span>`;
    } else if (isPast && item.state === "L") {
      outcomeHtml = /*html*/`<span class="text-danger checkPredictStateClass"><i class="bi bi-x-circle-fill"></i></span>`;
    }
    // else leave blank
  }
  const outcomeTd = /*html*/ `
      <td>
        <a href="${matchUrl}">
          ${outcomeHtml}
        </a>
      </td>
    `;

  // 6) Put it all together in one <tr>:
  const rowHtml = /*html*/ `
    <tr id="predictionMatch-${item.id}-${item.authorName || ""}">
      ${gameLogoTd}
      ${timeTd}
      ${matchCell}
      ${tournamentTd}
      ${authorTd}
      ${predictionTd}
      ${outcomeTd}
    </tr>
  `;
  return rowHtml;
}

export function renderPredictions(items, tableBodyId, options) {
  const tbody = document.getElementById(tableBodyId);
  if (!tbody) {
    console.error("No <tbody> found with id:", tableBodyId);
    return;
  }

  if (tableBodyId === "predictions-tbody") {

    items.forEach(item => {
      if (options.gameId && reversegameIdFilter[options.gameId] !== undefined) {
        if (Array.isArray(reversegameIdFilter[options.gameId])) {
          if (!reversegameIdFilter[options.gameId].includes(item.gameId)) {
            return;
          }
        } else {
          if (reversegameIdFilter[options.gameId] !== item.gameId) {
            return;
          }
        }
      }
      const rowHtml = buildRowHtml(item, options);
      // Append to table
      tbody.innerHTML += rowHtml;
    });
  } else if (tableBodyId === "author-predictions-tbody") {

    const itemsPerPage = 5;
    let currentIndex = 0;

    function renderNextBatch() {
      const slice = items.slice(currentIndex, currentIndex + itemsPerPage);
      slice.forEach(item => {
        const rowHtml = buildRowHtml(item, options);
        tbody.innerHTML += rowHtml;
      });
      currentIndex += slice.length;

      // Hide the button if we've rendered all
      if (currentIndex >= items.length) {
        document.getElementById("show-more-authorPredictions-button").style.display = "none";
      }
    }

    // Initially render the first batch
    renderNextBatch();

    // Attach the "Show More" click
    const showMoreBtn = document.getElementById("show-more-authorPredictions-button");
    if (showMoreBtn) {
      showMoreBtn.addEventListener("click", renderNextBatch);
    }
  }

  tbody.innerHTML += /*html*/ `
                <style>
              /* Prediction Styles Shared Between postauthor-component & predictions-component */
              postauthor-component .checkPredictStateClass,
              predictions-component .checkPredictStateClass {
                font-size: calc(0.6rem + 0.9vw);
              }

              postauthor-component .prediction-odds-value,
              predictions-component .prediction-odds-value {
                font-size: calc(0.5rem + 0.5vw);
                font-weight: 750;
                color: rgb(255, 255, 255);
                background-color: transparent;
                padding: calc(0.1rem + 0.2vw) calc(0.2rem + 0.4vw);
                border-radius: 20px;
              }

              postauthor-component .prediction-value,
              predictions-component .prediction-value {
                font-weight: 700;
                color: rgb(209, 217, 224);
                margin-bottom: 5px;
              }

              postauthor-component .odds-value,
              predictions-component .odds-value {
                font-weight: 700;
                color: #58a6ff;
                margin-left: auto;
              }

              postauthor-component .time-cell,
              predictions-component .time-cell {
                font-weight: 600;
                color: #58a6ff;
              }

              postauthor-component .team-container,
              predictions-component .team-container {
                display: flex;
                flex-direction: row;
                align-items: center;
              }

              postauthor-component .team-logo,
              predictions-component .team-logo {
                width: calc(1.2rem + 1vw);
                height: calc(1.2rem + 1vw);
                object-fit: contain;
                border-radius: 50%;
                border: 2px solid #21262d;
                background-color: #2f363d;
              }

              postauthor-component .team-name,
              predictions-component .team-name {
                font-weight: 600;
                color: #c9d1d9;
                margin-left: 10px;
                max-width: calc(3rem + 4vw);
              }

              postauthor-component .tournament-name,
              predictions-component .tournament-name {
                text-align: left;
                font-weight: 600;
                color: #adbac7;
              }
              </style>
    `
}

