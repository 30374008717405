import { BaseComponent } from "@symbiotejs/symbiote";

export class PlaceholderNewsCarousel extends BaseComponent {
    initCallback() {
    }
}

PlaceholderNewsCarousel.template = /*html*/`

<div id="placeholder-news-carousel">
                <div class="row flex-nowrap overflow-hidden w-100" style="height: calc(6rem + 4vw);">
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                </div>
                <div class="row flex-nowrap overflow-hidden w-100 mt-3" style="height: calc(6rem + 4vw);" >
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                    <div class="col-6 col-xxl-4">
                      <article class="placeholder-wave color-dark rounded d-flex h-100 align-items-end p-3">
                      <span class="placeholder col-12 rounded"></span>
                       </article>
                    </div>
                </div>

</div>         
      `;
PlaceholderNewsCarousel.reg('placeholder-newscarousel'); // components should always be 2 words seperated with a dash