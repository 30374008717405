/**
 * Sanitizes a string to create a URL-friendly slug.
 *
 * @param {string} str - The string to sanitize.
 * @return {string} A sanitized slug string.
 */
function sanitizeSlug(str) {
    return str
        .normalize("NFD") // Decompose accents
        .replace(/[\u0300-\u036f]/g, "") // Remove accent marks
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[&%$@!~`^*()=+{}[\]|\\:;"'<>,.?#/]/g, "") // Remove special characters
        .replace(/-+/g, "-") // Replace multiple hyphens with single
        .replace(/^-+|-+$/g, ""); // Trim leading/trailing hyphens
}

/**
 * Generates a match name based on the provided object.
 *
 * @param {Object} object - The match object containing opponent details.
 * @return {string} A sanitized match name string.
 */
function getMatchName(object) {
    return (object.opponents &&
        object.opponents[0] && object.opponents[0].opponent && object.opponents[0].opponent.name &&
        object.opponents[1] && object.opponents[1].opponent && object.opponents[1].opponent.name) ?
        `${sanitizeSlug(object.opponents[0].opponent.name)}-vs-${sanitizeSlug(object.opponents[1].opponent.name)}` :
        (object.name) ? sanitizeSlug(object.name.replace(/match/gi, "")) :
            "unknown";
}

/**
 * Generates a tournament name based on the provided object.
 *
 * @param {Object} object - The tournament object containing league details.
 * @return {string} A sanitized tournament name string.
 */
function getTournamentName(object) {
    if (!object || !object.league || !object.full_name) return "unknown";

    const seriesName = `${object.league.name}-${object.full_name}`;
    return sanitizeSlug(seriesName);
}

/**
 * Generates a tournament name based on the provided object.
 *
 * @param {Object} object - The tournament object containing league details.
 * @return {string} A sanitized tournament name string.
 */
function getTournamentNameFromMatch(object) {
    if (!object || !object.league || !object.serie.full_name) return "unknown";

    const seriesName = `${object.league.name}-${object.serie.full_name}`;
    return sanitizeSlug(seriesName);
}

const globalSocials = {
    discord: "https://discord.gg/3sU4u59qze",
    twitter: "https://x.com/myesportsgg",
    facebook: "https://www.facebook.com/myesportsgg",
    twitch: "https://www.twitch.tv/myesports_gg",
    instagram: "https://www.instagram.com/myesportsgg/",
    youtube: "https://www.youtube.com/@myesportsgg",
    tiktok: "https://www.tiktok.com/@myesports.gg",
};

module.exports = {
    getMatchName,
    getTournamentName,
    getTournamentNameFromMatch,
    globalSocials,
};
