export function extractFirstTextBlock(markup, fromWhere) {
    try {
        // Split the string into lines
        const lines = markup.split('\n');

        // Initialize an array to hold the text block lines
        let textBlock = [];
        let blockStarted = false;

        // Iterate over each line
        for (let line of lines) {
            // Check if the line does not start with special characters and is not empty
            if (!line.startsWith('<h') && !line.startsWith('##') && !line.startsWith('#') && !line.startsWith('**') && !line.startsWith('* **') && !line.startsWith('*') && !line.startsWith('-') && line.trim() !== '') {
                let refactoredLine = line.replace(/<[^>]*>?/gm, '');
                textBlock.push(refactoredLine.trim());
                blockStarted = true;
            } else if (blockStarted) {
                // If the block has started and we encounter a line with special characters or an empty line, break the loop
                break;
            }
        }
        const textToBeReturned = textBlock.join(' ').replace('<p>', '').replace('</p>', '');
        return (textToBeReturned.length < 5) ? `<span class="translate-${fromWhere}">You won't believe it<span>` : textToBeReturned;
    } catch (e) {
        console.log('ERROR')
        return ''
    }

}