import { globalSocials } from "../../utils/globals.js";

export const chineseMandarinTranslation =
{
    "translationLanguageInLocal": `中文`,
    "metaTags": {
        "keyword1": `电竞直播`,
        "keyword2": `电竞赛事`,
        "keyword3": `英雄联盟电竞`,
        "keyword4": `反恐精英：全球攻势（CS:GO）赛事`,
        "keyword5": `Dota 2 国际邀请赛（TI）`,
        "keyword6": `电竞比赛人工智能与专家分析`,
        "keyword7": `电竞比赛预测与投注技巧`,
        "keyword8": `电竞新闻与文章`,
        "singleMatchTitleLive": `{{team1}} 对阵 {{team2}} | 直播：{{score1}} - {{score2}} | {{game}}：{{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} 对阵 {{team2}} | 结果：{{score1}} - {{score2}} | {{game}}：{{tournament}}`,
        "singleMatchTitleUpcoming": `即将开始：{{team1}} 对阵 {{team2}} | {{game}}：{{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} 对阵 {{team2}} | {{game}}：{{tournament}}`,
        "singleMatchDescriptionLive": `{{game}}：{{teamA}} {{score1}}-{{score2}} {{teamB}} 在 {{tournament}} 中。立即观看直播！比赛开始于 {{date}}。在MyEsports上获取统计数据、预测和人工智能分析！`,
        "singleMatchDescriptionFinished": `{{game}}：{{teamA}} 在 {{tournament}} 中对阵 {{teamB}}。比赛结束于 {{date}}，结果为 {{score1}} - {{score2}}。在MyEsports上获取统计数据、预测和人工智能分析！`,
        "singleMatchDescriptionUpcoming": `{{game}}：{{teamA}} 将在 {{tournament}} 中对阵 {{teamB}}。比赛开始于 {{date}}。在MyEsports上获取统计数据、预测和人工智能分析！在此观看即将到来的比赛直播！`,
        "singleMatchDescriptionFallback": `{{game}}：{{teamA}} 将在 {{tournament}} 中对阵 {{teamB}}。比赛开始于 {{date}}。在MyEsports上获取统计数据、预测和人工智能分析！观看直播！`,
        "singleTournamentTitle": `{{game}} 赛事 – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `在MyEsports上探索{{tournament}}的最新比赛和结果！{{tier}}级{{game}}赛事，包含{{opponents}}等队伍！`,
        "singleAuthorTitle": `{{author}} - MyEsports 作者`,
        "matchesTitle": `电竞比赛 – 实时比分与结果 | MyEsports`,
        "matchesDescription": `在MyEsports上了解最新的电竞比赛结果和即将到来的比赛。获取反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟等游戏的实时比分、赛程、分析、技巧和队伍统计数据！`,
        "tournamentsTitle": `电竞赛事 – 即将到来与过往活动 | MyEsports`,
        "tournamentsDescription": `在MyEsports上发现最大的电竞赛事！获取反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟、无畏契约等游戏的赛程、结果、奖金池和队伍排名！`,
        "newsTitle": `电竞新闻与文章 – 最新动态与见解 | MyEsports`,
        "newsDescription": `在MyEsports上了解最新的电竞新闻、文章和见解！获取反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟、无畏契约等游戏的更新，以及专家分析和采访！`,
        "authorsTitle": `认识我们的电竞作者 – 新闻、预测与技巧 | MyEsports`,
        "authorsDescription": `认识MyEsports背后的专家！我们的电竞作者为您带来反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟等游戏的新闻、深度文章、预测和技巧！`,
        "bookmakersTitle": `顶级电竞博彩平台 – 受信任的合作伙伴与优惠 | MyEsports`,
        "bookmakersDescription": `探索MyEsports受信任的博彩合作伙伴！寻找提供独家优惠、奖金和安全平台的顶级电竞博彩平台，适用于反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟等游戏！`,
        "skinbookmakersTitle": `电竞皮肤交易平台 – 受信任的皮肤与箱子合作伙伴 | MyEsports`,
        "skinbookmakersDescription": `探索MyEsports受信任的皮肤交易合作伙伴！安全地购买、出售、交易和投注反恐精英：全球攻势（CS:GO）、Dota 2、Rust等游戏的皮肤和箱子。寻找最佳市场和独家优惠！`,
        "predictionsTitle": `电竞预测与投注技巧 – 专家分析 | MyEsports`,
        "predictionsDescription": `在MyEsports上获取专家电竞投注预测和技巧！从我们的专家和人工智能那里获得准确的比赛分析、赔率见解和制胜策略，适用于反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟等游戏！`,
        "termsOfUseTitle": `使用条款 – 规则与用户协议 | MyEsports`,
        "termsOfUseDescription": `阅读MyEsports的使用条款。了解用户协议、规则以及使用我们平台的政策，包括电竞内容、博彩合作伙伴等。`,
        "privacyPolicyTitle": `隐私政策 – 数据保护与安全 | MyEsports`,
        "privacyPolicyDescription": `了解MyEsports如何保护您的数据。阅读我们的隐私政策，了解用户信息、安全措施以及符合GDPR的合规性，以获得安全体验。`,
        "faqTitle": `常见问题解答 – 常见问题与支持 | MyEsports`,
        "faqDescription": `查找有关MyEsports的常见问题解答。阅读我们的常见问题解答，了解电竞报道、博彩合作伙伴、预测、皮肤交易等支持主题。`,
        "streamersTitle": `电竞主播 – 直播频道与游戏内容 | MyEsports`,
        "streamersDescription": `在MyEsports上发现来自世界各地的顶级电竞主播！观看直播游戏、职业选手直播以及我们合作创作者的独家内容。`,
        "homeTitle": `MyEsports – 您的终极电竞中心，提供新闻、比赛和投注技巧`,
        "homeDescription": `通过MyEsports在电竞世界中保持领先！获取反恐精英：全球攻势（CS:GO）、Dota 2、英雄联盟等游戏的最新新闻、比赛结果、实时比分、投注技巧、预测和赛事更新！`
    },
    "homePage": {
        "latestAnalyses": `最新人工智能分析`
    },
    "networkGuard": {
        "title": `糟糕！`,
        "description": `您要查找的页面不存在！`
    },
    "navMainMenu": {
        "Home": `首页`,
        "Tournaments": `赛事`,
        "Matches": `比赛`,
        "Giveaways": `赠品`,
        "Casinos": `博彩平台`,
        "AboutUs": `关于我们`,
        "Streamers": `主播`,
        "News": `新闻`,
        "Predictions": `预测`,
        "navDesktopTitle": `MyEsports`
    },
    "hotmatchTranslations": {
        "playerNationalities": `{{countries}} 选手参赛`,
        "playerAges": `最年轻的选手 {{Age}} 岁`,
        "viewers": `{{maxviewers}} 人正在观看！`,
        "alternativeTimeLineHours": `第 {{RGN}} 局进行中。开始于 {{hourz}} 小时 {{minutez}} 分钟前`,
        "alternativeTimeLineMinutes": `第 {{RGN}} 局进行中。开始于 {{minutez}} 分钟前`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>热门`,
        "noLiveMsg": `<h2>请稍等，精彩即将到来！</h2><p>看来 {{game}} 的比赛暂时休息。</p>`
    },
    "matchTranslations": {
        "title": `{{team1}} 对阵 {{team2}} | {{tournament}}`,
        "description": `在MyEsports上观看 {{teamA}} 对阵 {{teamB}} 的 {{tournament}} | 查看人工智能分析和我们的专家对这场比赛的看法！`,
        "h2": `这是比赛的副标题`,
        "body": `这是比赛的主要内容`,
        "matchMatchUpcoming": `剩余 {{minutes}} 分钟`,
        "matchMatchLive": `直播`,
        "matchMatchFinished": `已结束`,
        "matchMatchPostponed": `已推迟`,
        "matchMatchCanceled": `已推迟`,
        "matchMatchAboutTo": `即将开始`,
        "homeMatchDHM": `{{days}}天 {{hours}}小时 {{minutes}}分`,
        "homeMatchHM": `{{hours}}小时 {{minutes}}分`,
        "homeMatchM": `{{minutes}}分`,
        "homeMatchLive": `直播`,
        "homeMatchAboutTo": `即将开始`,
        "pillLive": `直播`,
        "pillUpcoming": `即将到来`,
        "pillFinished": `已结束`,
        "pillPredictions": `预测`,
        "pillAll": `全部`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> 收藏`,
        "bestofX": `{{gamesNumber}} 局最佳`,
        "bestof1": `1 局最佳`,
        "bestof2": `2 局最佳`,
        "allGamesPlayed": `{{gamesNumber}} 场比赛`,
        "allGamesPlayedMicro": `共{{gamesNumber}}场`,
        "firstToX": `先到 {{gamesNumber}} 局`,
        "showMoreMatches": `查看更多`,
        "emptyGameFilterMessage": `目前没有可用的 {{game}} 比赛。`,
        "emptyStateFilterMessage": `没有符合所选过滤条件的 {{game}} 比赛。`,
        "emptyPredictionFilterMessage": `没有所选日期可用的 {{predGame}} 预测。`,
        "emptyAnalysisFilterMessage": `没有最近的分析 {{game}}`,
        "searchPlaceholder": `搜索特定赛事或队伍...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames; }
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `人工智能比赛分析`,
        "tipTranslationTitle": `专家提示`,
        "team1players": `选手`,
        "team2players": `选手`,
        "ageLabel": `年龄：`,
        "positionLabel": `位置：`,
        "h2hNoRecentMsg": `这两支队伍之间没有近期比赛`,
        "matchHistory": `比赛历史`,
        "h2hPill": `交锋记录`,
        "badgeWon": `胜`,
        "badgeLost": `负`,
        "badgeDraw": `平`,
        "noVideoMessage": `这场比赛没有可用的视频`,
        "analysis": `我们的'PlayPulse'人工智能将在比赛开始前24小时内进行分析。请在比赛临近时再次访问，以查看完整的分析。`,
        "whatDoesAiThink": `人工智能对这场比赛的见解：`,
        "aiAnalysis": `人工智能分析`,
        "betNow": `立即投注`,
        "oddsDraw": `平局`,
        "predictToWin": `胜出`,
        "predictDraw": `平局`,
        "predictExactScore": `准确比分`,
        "predictOverUnder": `局数`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>暂无最新文章</h2><p>很遗憾，目前没有关于{{newsGame}}的最新文章。</p>`,
        "newsTitle": `文章`,
        "writtenAt": `发表于`,
        "footerArticle": `🔥 喜欢这篇文章吗？ 🔥`,
        "footerArticleFollow": `不要错过！在社交媒体上关注我们，获取更多精彩更新！`,
        "newsSeeAll": `查看全部`,
        "seeMore": `查看更多...`,
        "goBack": `返回新闻`,
        "tournamentNews": `赛事新闻`,
        "rosterNews": `战队阵容新闻`,
        "gameNews": `综合新闻`
    },
    "footer": {
        "about": `欢迎来到MyEsports，您电竞爱好者的综合目的地。我们源于对游戏世界的深爱与热情，创建了一个电竞社区可以找到一切的空间：从比赛和赛事的直播到各种游戏的预测、分析和文章。`,
        "followUs": `关注我们`,
        "usefulLinks": `实用链接`,
        "contactUs": `联系我们`,
        "aboutUs": `关于我们`,
        "eula": `使用条款`,
        "privacy": `隐私政策`,
        "faq": `常见问题`,
        "Authors": `作者`
    },
    "tournamentsTranslations": {
        "noDateMsg": `结束日期待定`,
        "tier": `级别`,
        "emptyTournamentFilterMessage": `没有符合所选状态筛选条件的{{game}}赛事。`,
        "pillOngoing": `进行中`,
        "pillUpcoming": `即将开始`,
        "pillFinished": `已结束`,
        "pillAll": `全部`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>直播中</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">已结束</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">{{days}}天后开始</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">{{days}}天后开始</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">{{hours}}小时{{minutes}}分钟后开始</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">{{minutes}}分钟后开始</span>`,
        "noPrizePoolAvailable": `暂无`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `参赛队伍`,
        "hotTournamentsTitle": `热门赛事`,
        "hotTournamentFixedLabel": `进行中`,
        "noHotTournamentsMsg": `<h2>当前没有直播赛事</h2><p>很遗憾，目前没有{{game}}直播赛事。</p>`,
        "noHotTournamentsButton2": `查看全部`,
        "clickHotTournaments": `点击查看更多信息。`
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `参赛队伍：<span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `参赛选手：<span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `奖金池：<span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `奖金池：<span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `奖金池：<span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `级别：`,
        "winnerPost": `赛事冠军：`,
        "startPost": `开始日期：`,
        "endPost": `结束日期：`,
        "noDateMsgPost": `结束日期待定`,
        "ladderWins": `胜场`,
        "ladderLosses": `败场`,
        "ladderTies": `平局`,
        "ladderGamesWon": `胜局`,
        "ladderGamesLost": `败局`,
        "ladderTieBreaker": `加赛`,
        "upperBracket": `胜者组`,
        "lowerBracket": `败者组`,
        "grandFinal": `总决赛`,
        "semiFinal": `半决赛`,
        "quarterFinal": `四分之一决赛`,
        "roundBrackets": `轮次`,
        "matchBrackets": `场次`,
        "laddersMatchesTitle": `比赛`,
        "week": `周`,
        "liveWeek": `直播`
    },
    "predictionsTranslations": {
        "predTitle": `预测`,
        "comingSoon": `敬请期待`,
        "today": `今天`,
        "title": `电竞预测`,
        "subtitle": `在MyEsports，我们仔细研究每场比赛，为您带来最佳的电竞博彩建议。我们的专家分析队伍、选手和策略，帮助您做出最佳的投注决策。`,
        "time": `时间`,
        "date": `日期`,
        "match": `比赛`,
        "tournament": `赛事`,
        "tipster": `预测者`,
        "betButton1": `立即在上下注`,
        "odd": `@`,
        "noPredictionForThisDate": `此日期暂无预测，请稍后回来查看。`,
        "statsAvgOdds": `平均赔率`,
        "statsEarn": `盈利`,
        "statsBets": `投注`,
        "statsOpenBets": `未结算投注`,
        "authStats": `预测统计`,
        "articles": `文章`,
        "last10": `近期预测`,
        "authBio": `简介`,
        "bioComing": `简介敬请期待`,
        "showMorePredictions": `显示更多`,
        "EarnExplain": `利润根据已结算的预测计算。每个预测者从0开始，每次正确的预测都会将获得的赔率x1添加到他们的总利润中。例如，在赔率为1.80的正确预测中，预测者的利润增加0.80。在不正确的预测中，从总利润中减去1.00。`,
        "WinrateExplain": `胜率根据已结算的预测计算。它是正确预测占总预测数的百分比。`,
        "RoiExplain": `投资回报率（ROI）根据预测者的利润与他们的总投注额计算。本质上，投资回报率代表预测者的净利润。例如，如果他们进行了50次每次一单位的投注，并且投资回报率为20%，那么他们赢得了10个单位。`,
        "AvgOddsExplain": `平均赔率根据已结算预测的赔率计算。它是赔率的总和除以预测总数。`,
        "plainPrediction": `预测`,
        "statisticPeriod": `周期`,
        "alltimeStats": `全部`
    },
    "banners": {
        "desktopLeft": `en_desktop_left`,
        "desktopRight": `en_desktop_right`
    },
    "streamerTranslations": {
        "cardFollowers": `粉丝数`,
        "cardInfo": `{{user}} 目前{{status}}。`,
        "cardViewers": `观看人数！`,
        "detailsInformation": `主播信息`,
        "detailsName": `全名`,
        "detailsNationality": `国籍`,
        "detailsAge": `年龄`,
        "detailsGame": `游戏`,
        "detailsRole": `角色`,
        "detailsTeam": `战队`,
        "detailsSignature": `招牌英雄`,
        "accordionHowTo": `<strong>-如何在MyEsports上成为主播？</strong>`,
        "accordionHowToReply": `您可以通过加入我们的<a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord服务器</a>并联系我们的团队，或者填写此<a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">表格</a>来申请。`,
        "accordionEarn": `<strong>-在MyEsports上成为主播有什么收益？</strong>`,
        "accordionEarnReply": `在MyEsports上展示将为您提供增加观众和发展频道的机会。您还有机会在我们的文章和社交媒体中被推荐。`,
        "accordionGraphics": `<strong>-我会收到频道图形吗？</strong>`,
        "accordionGraphicsReply": `是的，我们将为您提供频道图形，以及如何使用它们和改善直播体验的支持和指导。`,
        "accordionHowToStream": `<strong>-我是直播新手，还能加入吗？我该如何开始？</strong>`,
        "accordionHowToStreamReply": `当然！我们欢迎所有级别的主播。您可以通过加入我们的<a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord服务器</a>并联系我们的团队获取更多信息。`,
        "accordionViewers": `<strong>-我需要有多少观众才能加入？</strong>`,
        "accordionViewersReply": `我们欢迎各种规模的主播。无论您有1个还是10,000个观众，都欢迎加入MyEsports！`
    },
    "authorsTranslations": {
        "title": `探索MyEsports作者团队`
    },
    "bookmakersPostTranslations": {
        "odds": `赔率`,
        "varietyOfEsports": `电竞种类`,
        "liveSupport": `在线客服`,
        "liveStreaming": `直播`,
        "payments": `支付方式`,
        "fullReview": `完整评测`,
        "esports": `电竞`,
        "register": `立即投注`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `完整评测`,
        "register": `立即投注`
    },
    "bookmakersSkinList": {
        "widgetTitle": `皮肤平台`,
        "widgetButton": `查看全部`,
        "playNow": `玩!`,
        "introSection": `<h2 class="title-section">2024年顶级电竞博彩网站：发现最佳电竞博彩皮肤</h2><p>随着电子竞技的爆炸性增长，领先的博彩皮肤应运而生，满足市场需求，为《反恐精英2》、《Dota 2》、《英雄联盟》、《Valorant》、《Mobile Legends》和《使命召唤》等热门游戏提供广泛的市场。这些游戏带来了持续不断的激动人心的锦标赛和联赛，为投注者提供了多样化的选择——从简单的比赛获胜者到特定游戏的投注。</p><p>在<a href="https://myesports.gg" class="text-a-style">myesports.gg</a>，我们仔细审查了一系列顶级电竞博彩皮肤平台，以帮助您找到满足您投注需求的完美皮肤。</p>`,
        "sectionTitle": `在哪里投注电竞：最佳电竞博彩皮肤和奖金`,
        "pleaseBetResponsibly": `请理性投注`,
        "reviewMethodology": `<h2 class="title-section">我们的评测方法</h2><p>在<a class="text-a-style" href="https://myesports.gg">myesports.gg</a>，我们的使命很简单——指导您选择理想的电竞博彩博彩皮肤。我们根据易用性、电竞市场种类和安全功能评估每个皮肤，以确保您在存款或取款时的安全。</p><p>我们还评估许可证，以验证博彩皮肤是否在您所在地区合法运营。只有符合我们高标准的博彩皮肤才能获得我们的认可。我们提供独家电竞奖金和促销活动，以进一步提升您的投注体验。</p><p>我们的评测标准涵盖多个领域，包括用户友好性、市场产品和支付方式。每个博彩皮肤都在八个主要类别中进行全面评估，涵盖从设计和功能到客户服务的所有内容。查看我们的深入评测，了解每个博彩皮肤在这些关键领域的表现。</p>`,
        "review": `评测`,
        "betNow": `立即投注`,
        "noBookmakerSkinsMsg": `<h2>您所在地区和语言没有可用的皮肤平台。</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `博彩公司`,
        "widgetButton": `查看全部`,
        "playNow": `玩!`,
        "introSection": `<h2 class="title-section">2024年顶级电竞博彩网站：发现最佳电竞博彩公司</h2><p>随着电子竞技的爆炸性增长，领先的博彩网站应运而生，满足市场需求，为《反恐精英2》、《Dota 2》、《英雄联盟》、《Valorant》、《Mobile Legends》和《使命召唤》等热门游戏提供广泛的市场。这些游戏带来了持续不断的激动人心的锦标赛和联赛，为投注者提供了多样化的选择——从简单的比赛获胜者到特定游戏的投注。</p><p>在<a href="https://myesports.gg" class="text-a-style">myesports.gg</a>，我们仔细审查了一系列顶级电竞博彩平台，以帮助您找到满足您投注需求的完美网站。</p>`,
        "sectionTitle": `在哪里投注电竞：最佳电竞博彩网站和奖金`,
        "pleaseBetResponsibly": `请理性投注`,
        "reviewMethodology": `<h2 class="title-section">我们的评测方法</h2><p>在<a class="text-a-style" href="https://myesports.gg">myesports.gg</a>，我们的使命很简单——指导您选择理想的电竞博彩博彩公司。我们根据易用性、电竞市场种类和安全功能评估每个网站，以确保您在存款或取款时的安全。</p><p>我们还评估许可证，以验证博彩公司是否在您所在地区合法运营。只有符合我们高标准的博彩公司才能获得我们的认可。我们提供独家电竞奖金和促销活动，以进一步提升您的投注体验。</p><p>我们的评测标准涵盖多个领域，包括用户友好性、市场产品和支付方式。每个博彩公司都在八个主要类别中进行全面评估，涵盖从设计和功能到客户服务的所有内容。查看我们的深入评测，了解每个博彩公司在这些关键领域的表现。</p>`,
        "review": `评测`,
        "betNow": `立即投注`,
        "noBookmakersMsg": `<h2>您所在地区和语言没有可用的博彩公司。</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>糟糕！您似乎正在使用广告拦截器。</strong></p><p>您错过了一些我们为您准备的精彩内容。这不是广告，而是一些有用的功能。请考虑禁用您的广告拦截器。</p><button id="close-button">知道了！</button>`
    },
    "discordTemplate": {
        "titleMain": `需要帮助吗？`,
        "titleSub": `立即加入我们的Discord！`
    }
}
