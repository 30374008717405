import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { getMatchName, getTournamentNameFromMatch } from '../../../functions/across-stack/utils/globals';
import { translations } from "../../../functions/across-stack/i18n/i18n";
import { extractFirstTextBlock } from "../../utilities/general/extractFirstTextBlock";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { resizetoTwitchHeight } from "../../utilities/general/resizeToTwitchHeight";
import { startCountdown } from "../../utilities/general/countDownForMatches";
import { gameIdImg } from "../../utilities/general/gameIdImgMapping";
import { theConverter } from "../../utilities/general/showdownConverterMyesports";
import { showcasePredictionForPostmatch } from "../../utilities/general/predictionsDisplay";
import { findMatchType } from "../../utilities/general/matchType";
import { I18n } from "../../utilities/i18n/i18n";
import { PlaceholderPostmatch } from "../placeholder-matcheswidget/placeholder-postmatch";
import { PlaceholderTeamplayers } from "../placeholder-matcheswidget/placeholder-teamplayers";


const noPlayerImg = require('../../global-assets/hoodieman.webp');
const noImageFound = require('../../global-assets/no image found.png');
const aiAvatar = require('../../global-assets/ai.jpeg');

export class PostMatchesComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('postmatches-component', PostMatchesComponent.initiator);
    window.addEventListener('resize', () => resizetoTwitchHeight(document.querySelector('[id^="twitch-iframe"]'), true));

  }

  static getMatchId() { // TOBECHECKED
    return window.location.href.match(/\/match\/(\d+)(?:\/[^\/]+)?$/)[1];
  }

  static initiator() {
    const matchcodeid = PostMatchesComponent.getMatchId();
    let data = null;

    // Check all caches for the match data.
    if (window.savedMatches && window.savedMatches[matchcodeid] !== undefined) {
      console.debug('The match is found in the saved matches');
      data = window.savedMatches[matchcodeid];
    } else if (window.savedMatchesFromTournaments && window.savedMatchesFromTournaments[matchcodeid] !== undefined) {
      console.debug('The match is found in the saved matches from tournaments');
      data = window.savedMatchesFromTournaments[matchcodeid];
    } else if (window.savedPastMatches) {
      for (let key in window.savedPastMatches) {
        if (window.savedPastMatches[key] !== undefined) {
          for (let i = 0; i < window.savedPastMatches[key].length; i++) {
            if (
              window.savedPastMatches[key][i] &&
              window.savedPastMatches[key][i].id &&
              window.savedPastMatches[key][i].id.toString() === matchcodeid
            ) {
              console.debug('The match is found in the saved past matches');
              data = window.savedPastMatches[key][i];
              break;
            }
          }
        }
      }
    }

    // If cached data exists, update the UI immediately.
    if (data) {
      if (document.querySelectorAll('postmatches-component').length !== 0) {
        console.debug('Running post matches component callback with cached data');
        PostMatchesComponent.updateTheMatch(window.savedMatches[matchcodeid]);
      } else {
        console.debug('Post matches component callback should run, but we are not on the right page');
      }
    } else {
      console.debug('Match not found in cache, fetching the data');
    }

    // Always attach the real-time listener from firebase assistant to ensure auto-updates.
    setTimeout(() => { //timeout exists to ensure it's not appending the new data while live data is doing so
      FirebaseAssistant.getSingleMatch(matchcodeid, querySnapshot => {
        if (!querySnapshot.empty) {
          const single = querySnapshot.docs[0];
          const data = single.data();
          // Merge new data with any existing saved data.
          if (window.savedMatches === undefined) {
            window.savedMatches = { [matchcodeid]: data };
          } else {
            window.savedMatches[matchcodeid] = {
              ...(window.savedMatches[matchcodeid] || {}), // existing data if any
              ...data // merge new properties
            };
          }
          // Update the UI with the latest data from Firestore.
          if (document.querySelectorAll('postmatches-component').length !== 0) {
            console.debug('Running post matches component callback with updated data');
            PostMatchesComponent.updateTheMatch(window.savedMatches[matchcodeid]);
          } else {
            console.debug('Post matches component callback should run, but we are not on the right page');
          }
        }
      });
    }, 50);
  }

  static async updateTheMatch(data) {

    PostMatchesComponent.generateTipsterAccordions(data);
    PostMatchesComponent.generateAiAccordion(data);
    PostMatchesComponent.getPastData(data);
    const oddsHtml = PostMatchesComponent.generateOddsSection(data);
    const promoStreamUserLogin = PostMatchesComponent.PromoVideo(false, data.id);

    const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
    const countdownElementId = `countdown-${data.id}`;
    const team1id = data.opponents[0].opponent.id;
    const team2id = data.opponents[1].opponent.id;
    const team1name = data.opponents[0].opponent.name;
    const team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
    const team2name = data.opponents[1].opponent.name;
    const team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound
    const winnerId = data.winner?.id || data.winner_id;
    const tournamentName = `${data.league.name} ${data.serie.full_name}`;
    const tournamentWithDashes = getTournamentNameFromMatch(data);
    const tournamentLogoHTML = data.league.image_url ? `<img src="${data.league.image_url}" alt="${data.league.name} logo" class="tournament-logo">` : '';

    const matchTypeFinal = findMatchType(data.match_type, data.number_of_games);
    const score = `<span class="${winnerId === team1id ? 'text-success' : ''}">${data.results[0].score.toString()}</span> - <span class="${winnerId === team2id ? 'text-success' : ''}">${data.results[1].score.toString()}</span>`;
    const date = `${dateRaw.toLocaleDateString(I18n.getUsersPreferredLanguage(), {
      weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
      day: 'numeric',   // numeric day of the month
      month: 'short'    // "short" for abbreviated month name (e.g., Feb)
    })} ${dateRaw.toLocaleTimeString(I18n.getUsersPreferredLanguage(), {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    })}`;

    const { videoPlayerSection, chatSection } = PostMatchesComponent.setUpVideoPlayersAndChats(data);

    if (document.querySelectorAll('[id^="postmatch"]').length !== 0) {

      console.debug('an addition arrived from snapshot, so we are not going to fetch the data again, but we are going to update the page!!');

      const score = `<span class="${winnerId === team1id ? 'text-success' : ''}">${data.results[0].score.toString()}</span> - <span class="${winnerId === team2id ? 'text-success' : ''}">${data.results[1].score.toString()}</span>`;
      document.querySelector(`.score-postmatch`).innerHTML = `${score}`;

      if (document.querySelector(`.thePlayerContainer`).innerHTML.trim() === '' || document.querySelector('.thePlayerContainer .noVideoMessage')) {
        console.debug('The player container is empty or it has no video yet, so we are going to add the video player if on time window');

        document.querySelector(`.thePlayerContainer`).innerHTML = videoPlayerSection;
        document.querySelector('#chatforvideo').innerHTML = chatSection;
        PostMatchesComponent.videoPillListeners(data);

      }

      startCountdown("postMatchPage", dateRaw, countdownElementId, null, data.status);
      resizetoTwitchHeight(document.querySelector('[id^="twitch-iframe"]'));

    } else {

      if (data.opponents[0].type === 'Team') PostMatchesComponent.getRostersData(data);
      else document.querySelector('.team-players-container').style.display = 'none';


      if (promoStreamUserLogin !== undefined) {
        data?.frontendData?.videoLinks.unshift(`https://player.twitch.tv/?channel=${promoStreamUserLogin}&parent=${window.location.hostname}`);
        data?.frontendData?.chatLinks.unshift(`https://www.twitch.tv/embed/${promoStreamUserLogin}/chat?darkpopout&parent=${window.location.hostname}`);
        data?.frontendData?.htmlTags.unshift(`https://www.twitch.tv/${promoStreamUserLogin}---promo`);
        data?.frontendData?.consoleIcons.unshift('twitch');
      }

      const matchHtml = /*html*/ `
                      <!-- Video Player Section -->
                      <div class="row mb-3 rounded thePlayerContainer"></div>

                      <!-- Main Content Area -->

                        <!-- Match Section -->
                        <div id="postmatch-${data.id}" class="match-section rounded mb-3 p-3">

                          <!-- Tournament Logo -->
                          <div class="row">
                          <div class="d-flex justify-content-center align-items-center col-1 p-0">
                            ${tournamentLogoHTML}
                          </div>

                          <!-- Match Header -->
                          <div class="d-flex align-items-center col-11 p-0">

                            <div class="d-flex justify-content-between align-items-center w-100">
                              <a href="/${I18n.getUsersPreferredLanguage()}/tournament/${data.serie_id}/${tournamentWithDashes}"
                                class="header-info text-truncate clickable"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="${tournamentName}">
                                ${tournamentName}
                              </a>

                              <div class="d-flex align-items-center gap-2 me-1">
                                <span class="match-status badge bg-secondary matchTypeFromPostMatch me-2 d-none d-xxl-flex">${matchTypeFinal}</span>
                                <span id="${countdownElementId}" class="match-status my-2 badge countdownBadge"></span>
                                <img src="${gameIdImg[data.videogame.id]}" alt="game logo" class="game-logo me-1">
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="match-date d-flex align-items-center col-11 offset-1 p-0 gap-1">
                              <i class="bi bi-clock"></i> ${date}
                          </div>
                          </div>

                          <!-- Team vs Score Section -->
                          <div class="teams-container row d-flex align-items-center justify-content-center mt-3">
                            <!-- Team 1 -->
                            <div class="col-5 team-container flex-fill d-flex flex-column justify-content-center align-content-center align-items-center gap-1" id="team-${team1id}">
                                <img src="${team1img}" onerror="this.onerror=null; this.src='${noImageFound}';" alt="${team1name}" class="img-fluid mx-auto d-block" loading="lazy">
                                <div class="team-name text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" title="${team1name}">
                                  ${team1name}
                                </div>
                                <div class="win-loss-badges-home"></div>
                            </div>

                            <!-- Score -->
                            <div class="col-2 score-postmatch d-flex justify-content-center align-items-center gap-2">
                            ${score}
                            </div>

                            <!-- Team 2 -->
                            <div class="col-5 team-container flex-fill d-flex flex-column justify-content-center align-content-center align-items-center gap-1" id="team-${team2id}">
                                <img src="${team2img}" alt="${team2name}" onerror="this.onerror=null; this.src='${noImageFound}';" class="img-fluid mx-auto d-block" loading="lazy">
                                <div class="team-name text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" title="${team2name}">
                                  ${team2name}
                                </div>
                                <div class="win-loss-badges-away"></div>
                            </div>
                          </div>

                          <!-- Match Status on Mobile -->
                          <div class="d-flex mb-3 align-items-center justify-content-center d-xxl-none">
                            <span class="match-status badge bg-secondary matchTypeFromPostMatch">${matchTypeFinal}</span>
                          </div>

                          <!-- Odds Section -->
                          <div class="odds-section-parent">
                            ${oddsHtml}
                          </div>

                        </div>
                        `;

      document.getElementById('placeholder-chat').style.display = "none";
      document.querySelector('placeholder-postmatch').style.display = "none";

      document.querySelector('#matchpost-html').insertAdjacentHTML('beforeend', matchHtml);
      document.querySelector('#chatforvideo').innerHTML = chatSection;
      document.querySelector(`.thePlayerContainer`).innerHTML = videoPlayerSection;
      if (document.querySelector('.odds-section-parent').innerHTML.trim() === '') {
        document.querySelector('.odds-section-parent').style.display = 'none';
      }

      const matchTypeElements = document.querySelectorAll('.matchTypeFromPostMatch');
      matchTypeElements.forEach(matchTypeElement => {
        if (matchTypeElement.innerHTML === 'all_games_played') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.allGamesPlayed", { gamesNumber: data.number_of_games });
        } else if (matchTypeElement.innerHTML === 'best_of_X') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestofX", { gamesNumber: translations[I18n.getLanguageFromUrl()]["matchTranslations"]["boNCalculation"](data.number_of_games) });
        } else if (matchTypeElement.innerHTML === 'best_of_2') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof2");
        } else if (matchTypeElement.innerHTML === 'first_to_X') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.firstToX", { gamesNumber: data.number_of_games });
        } else if (matchTypeElement.innerHTML === 'single_game') {
          matchTypeElement.innerHTML = I18n.translateString("matchTranslations.bestof1");
        }
      });
      document.querySelectorAll('.noVideoMessage').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.noVideoMessage"));

      if (document.querySelector('.translate-draw')) document.querySelectorAll('.translate-draw').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.oddsDraw"));

      PostMatchesComponent.videoPillListeners(data);
      startCountdown("postMatchPage", dateRaw, countdownElementId, null, data.status);
      resizetoTwitchHeight(document.querySelector('[id^="twitch-iframe"]'));

    }

  }

  static setUpVideoPlayersAndChats(data) {

    const dateRaw = new Date(data.begin_at ? data.begin_at : data.scheduled_at);
    const endDateRaw = data.end_at ? new Date(data.end_at) : null;
    let startDif = new Date().getTime() - dateRaw.getTime(); // calculate the difference between the current time and the start time of the match
    let endDif = data.status === 'finished' ? new Date().getTime() - endDateRaw.getTime() : null;

    let videoPlayers = data?.frontendData?.videoLinks.map((videoLink, index) => {

      return /*html*/ `<div class="twitch-container pt-3 px-0 color-dark rounded-top">
                            ${videoLink ? /*html*/ `<div id="videosid">
                                            <iframe id="twitch-iframe-${index}"
                                                src="${!videoLink.includes("twitch") ? videoLink : `${videoLink}${window.location.hostname}`}" 
                                                height="100%" 
                                                width="100%" 
                                                frameborder="0" 
                                                scrolling="no" 
                                                allowfullscreen="true">
                                            </iframe>
                                        </div>`
          : '<span class="d-flex justify-content-center noVideoMessage">No video available<span>'}
                        </div>`;
    });

    let chats = data?.frontendData?.chatLinks.map((chatLink, index) => {
      return /*html*/ `
                            ${chatLink ? /*html*/ `<div id="chats">
                                            <iframe id="twitch-chat-${index}"
                                                        src="${`${chatLink}${window.location.hostname}`}"
                                                        height="700px"
                                                        width="100%"
                                                        frameborder="0"
                                                        scrolling="no"
                                                        allowfullscreen="true">
                                                    </iframe>
                                                    </div>`
          : ''}`;

    })

    let navPills = videoPlayers?.map((videoPlayer, index) => {

      if (videoPlayer.includes('No video available')) {
        return '';
      } else {
        let userLanguage = I18n.getLanguageFromUrl();
        let languageCode = data?.frontendData?.htmlTags[index].split('---')[1];
        let activeClass = index === 0 ? 'active' : '';
        if (languageCode === 'promo') {
          return `<li class="nav-item text-stream-pill">
                                    <a class="nav-link ${activeClass}" id="${data?.frontendData?.htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${data?.frontendData?.consoleIcons[index]}"></i>MyEsports</a>
                                </li>`;
        } else {
          let languageName = new Intl.DisplayNames([userLanguage], { type: 'language' }).of(languageCode);
          return `<li class="nav-item text-stream-pill">
                                    <a class="nav-link ${activeClass}" id="${data?.frontendData?.htmlTags[index]}" data-toggle="pill"><i class="me-2 bi bi-${data?.frontendData?.consoleIcons[index]}"></i>${languageName}</a>
                                </li>`;
        }
      }
    }).join('');

    let videoPlayerSection = '';
    let chatSection = '';
    const shouldShowVideoPlayer =
      (data.status !== 'finished' && data.status !== 'canceled' && startDif >= -20 * 60 * 1000) ||
      (data.status !== 'canceled' && endDif !== null && endDif <= 20 * 60 * 1000) ||
      data.status === 'running';

    if (shouldShowVideoPlayer) {
      videoPlayerSection = `
                              ${videoPlayers[0]}
                              <ul class="nav stream-navpills">
                                ${navPills}
                              </ul>
                            `;
      chatSection = chats[0];
    } else {
      console.debug('the match is not live, so no video player or chat will be shown');
    }

    return { videoPlayerSection, chatSection };

  }

  static generateOddsSection(data) {
    const getValidOdds = (primary, fallback) =>
      (primary && primary !== 1 ? parseFloat(primary).toFixed(2) : (fallback && fallback !== 1 ? parseFloat(fallback).toFixed(2) : null));

    if (data.status === 'finished') return '';

    const thunderpickMatchId = data?.thunderpickData?.matchUrl?.split('match/').pop() || null;

    const bookmakers = [
      {
        key: 'boomerang',
        team1Odds: getValidOdds(data?.boomerangData?.team1Odds, data?.boomerangData?.team1StartingOdds),
        team2Odds: getValidOdds(data?.boomerangData?.team2Odds, data?.boomerangData?.team2StartingOdds),
        drawOdds: getValidOdds(data?.boomerangData?.drawOdds, data?.boomerangData?.drawStartingOdds),
        url: `/${I18n.getUsersPreferredLanguage()}/betting/Boomerang-bet/${data.id}`,
        logoHtml: `<img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/bookmakers%2Fbp_logo_white.webp?alt=media&token=81d367a8-fba0-4167-8cd3-07ef74c45343" alt="Boomerang Logo">`
      },
      {
        key: 'thunderpick',
        team1Odds: getValidOdds(data?.thunderpickData?.team1Odds, data?.thunderpickData?.team1StartingOdds),
        team2Odds: getValidOdds(data?.thunderpickData?.team2Odds, data?.thunderpickData?.team2StartingOdds),
        drawOdds: getValidOdds(data?.thunderpickData?.drawOdds, data?.thunderpickData?.drawStartingOdds),
        url: `/${I18n.getUsersPreferredLanguage()}/betting-single/Thunderpick/${data.id}/${thunderpickMatchId}`,
        logoHtml: `<img src="data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='180.33'%20height='24.83'%20viewBox='0%200%20180.33%2024.83'%3e%3cg%20data-name='Group%2016899'%3e%3cpath%20fill='%23fff'%20d='m126.72%2011.75%204.55-.03L140.78.04h-16.04l-4.06%2016.43h4.9Zm2.38-9.62h5.1l-7.34%209.03Z'%20data-name='Path%20776'/%3e%3cpath%20fill='%23fff'%20d='M116.13%208.81%20123.3.04h-16.1l-3.98%2016.43h4.9l3.53-14.34h5.04l-5.42%206.65%202.9%207.7h4.9Z'%20data-name='Path%20777'/%3e%3cpath%20fill='%23fff'%20d='M180.33.04h-4.9l-6.68%207.51%201.9-7.51h-4.9l-4.06%2016.43h4.9l1.78-7.17%204.2%207.17h4.9l-4.68-8.04Z'%20data-name='Path%20778'/%3e%3cpath%20fill='%23fff'%20d='M88.33%200%2073.82.04l-4.23%2016.43h6.5l13.6-14.2ZM75.67%2011.71l2.49-9.58h6.64v.07Z'%20data-name='Path%20779'/%3e%3cpath%20fill='%23fff'%20d='M61.44%2012.52%2059.48.04h-6.15l-4.1%2016.43h4.9l2.84-11.33%201.5%2011.33h6.85L69.38.04h-4.9Z'%20data-name='Path%20780'/%3e%3cpath%20fill='%23fff'%20d='M23.74%207.38h-4.68L20.9.04H16l-4.09%2016.43h4.9l1.54-6.3h4.69l-1.58%206.3h4.9L30.5.04h-4.9Z'%20data-name='Path%20781'/%3e%3cpath%20fill='%23fff'%20d='M14.27.04H.52L0%201.79h4.79l-3.6%2014.68h4.9L9.68%201.8h4.05Z'%20data-name='Path%20782'/%3e%3cpath%20fill='%23fff'%20d='M155.85%202.48h6.02l.59-2.45h-10.9l-4.03%2016.44h10.91l.53-2.1h-6.02Z'%20data-name='Path%20783'/%3e%3cpath%20fill='%23fff'%20d='M40.6%209.51h-4.2L39.79.04h-4.9l-3.46%2013.88%201.5%202.48.14.07H44.8v-.07L48.92.04h-4.9Z'%20data-name='Path%20784'/%3e%3cpath%20fill='%23fff'%20d='M147.15.04h-4.96V0c-1.4%205.46-4.02%2016.47-4.02%2016.47h4.9Z'%20data-name='Path%20785'/%3e%3cpath%20fill='%2316bdf9'%20d='m101.9%209.51.38-1.5h-8.6l10.6-7.97h-6.36L85.4%2010.6l.21.42%207.94-.03-9.34%205.03.17.35%207.94.03-13.39%208.01.25.42%2021.12-8.88.66-2.62h-6.08Z'%20data-name='Path%20786'/%3e%3c/g%3e%3c/svg%3e" alt="Thunderpick Logo">
`,
      }
    ];

    let oddsHtml = '';

    for (const bookmaker of bookmakers) {
      if (!bookmaker.team1Odds || !bookmaker.team2Odds) continue;

      oddsHtml += `<div class="odds-section">`;

      // Full-width logo on top if draw is available
      if (bookmaker.drawOdds) {
        oddsHtml += `
      <div class="bet-now col-12 text-center mb-2">
        <a href="${bookmaker.url}" target="_blank" aria-label="Visit ${bookmaker.key}">
          ${bookmaker.logoHtml}
        </a>
      </div>`;
      }

      // Team 1
      oddsHtml += `
      <div class="team-odds">
        <a class="odds-button" href="${bookmaker.url}" target="_blank" aria-label="Bet on ${data.opponents[0].opponent.name} at odds ${bookmaker.team1Odds}">
          <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${bookmaker.team1Odds}
        </a>
      </div>`;

      // Draw OR Logo if no draw odds
      if (bookmaker.drawOdds) {
        oddsHtml += `
        <div class="team-odds" data-bs-toggle="tooltip" title="Draw">
          <a class="odds-button" href="${bookmaker.url}" target="_blank" aria-label="Bet on draw at odds ${bookmaker.drawOdds}">
            <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${bookmaker.drawOdds}
          </a>
        </div>`;
      } else {
        oddsHtml += `
        <div class="bet-now">
          <a href="${bookmaker.url}" target="_blank" aria-label="Visit ${bookmaker.key}" class"d-block">
            ${bookmaker.logoHtml}
          </a>
        </div>`;
      }

      // Team 2
      oddsHtml += `
      <div class="team-odds">
        <a class="odds-button" href="${bookmaker.url}" target="_blank" aria-label="Bet on ${data.opponents[1].opponent.name} at odds ${bookmaker.team2Odds}">
          <i class="bi bi-cash-stack me-2 d-none d-lg-inline"></i>${bookmaker.team2Odds}
        </a>
      </div>`;

      oddsHtml += `</div>`;
    }

    return oddsHtml;
  }

  static async generateTipsterAccordions(data) {

    function generatePredictionHtml(data) {
      if (data.predictions && data.predictions.length > 0) {

        const thunderpickMatchId = data.thunderpickData && data.thunderpickData.matchUrl ? data.thunderpickData.matchUrl.split('match/').pop() : null;
        const team1name = data.opponents[0].opponent.name;
        const team1img = data.opponents[0].opponent.image_url ? data.opponents[0].opponent.image_url : noImageFound
        const team2name = data.opponents[1].opponent.name;
        const team2img = data.opponents[1].opponent.image_url ? data.opponents[1].opponent.image_url : noImageFound

        document.getElementById('tipsHtml').innerHTML = '';
        document.querySelector('.tipTranslationTitle').innerHTML = '';

        data.predictions.forEach((prediction, index) => {
          let predictionHtml = '';
          const authorImage = prediction.authorData.photo ? prediction.authorData.photo : noImageFound;
          const authorName = prediction.authorData.name ? prediction.authorData.name : `Team's`;
          // dynamically generate the betting link based on the provider
          const providerMapping = {
            'Thunderpick': {
              betLink: data.thunderpickData && data.thunderpickData.matchUrl ?
                `/${I18n.getUsersPreferredLanguage()}/betting-prediction-single/Thunderpick/${authorName}/${data.id}/${thunderpickMatchId}` : `/${I18n.getUsersPreferredLanguage()}/betting-prediction/Thunderpick/${authorName}/${data.id}`,
            },
            'Sportaza': { betLink: `/${I18n.getUsersPreferredLanguage()}/betting-prediction/Sportaza/${authorName}/${data.id}` },
            'Boomerang': { betLink: `/${I18n.getUsersPreferredLanguage()}/betting-prediction/Boomerang-bet/${authorName}/${data.id}` },
          };

          let predictionOdd = prediction.predictionValue === "1" ?
            data.thunderpickData && data.thunderpickData.team1StartingOdds && data.thunderpickData.team1StartingOdds !== 1 ? parseFloat(data.thunderpickData.team1StartingOdds).toFixed(2) : prediction.oddValue :
            prediction.predictionValue === "2" ?
              data.thunderpickData && data.thunderpickData.team2StartingOdds && data.thunderpickData.team2StartingOdds !== 1 ? parseFloat(data.thunderpickData.team2StartingOdds).toFixed(2) : prediction.oddValue :
              prediction.predictionValue === "X" ?
                data.thunderpickData && data.thunderpickData.drawStartingOdds && data.thunderpickData.drawStartingOdds !== 1 ? parseFloat(data.thunderpickData.drawStartingOdds).toFixed(2) : prediction.oddValue :
                parseFloat(prediction.oddValue).toFixed(2);

          const preMarkdownPred = prediction.thePrediction[I18n.getUsersPreferredLanguage()] || prediction.thePrediction[I18n.defaultLocale];
          let thePrediction = theConverter().makeHtml(preMarkdownPred);

          let predictionChoice = showcasePredictionForPostmatch(prediction, team1name, team2name, team1img, team2img);

          let placeBetHtml = '';
          if (!prediction.oddBoolean && !predictionOdd) {
            placeBetHtml = /*html*/ `
                                    <a href="${providerMapping[prediction.provider].betLink}" target="_blank" class="betting-link blue-animation">
                                        <span class="translatePredictionButton"></span>${prediction.provider}!
                                    </a>
                                `
          } else {
            placeBetHtml = /*html*/ `
                                    <a href="${providerMapping[prediction.provider].betLink}" target="_blank" class="betting-link blue-animation">
                                        <span class="translatePredictionButton"></span>${prediction.provider} <span class="translateWithOdd"></span>${predictionOdd}!
                                    </a>
                `;
          }

          const tipClickBaitString = extractFirstTextBlock(thePrediction, "tip-postmatch");
          predictionHtml += /*html*/ `
                      <!-- TIP Accordion -->
                      <div class="accordion accordion-flush row mb-1" id="accordionFlushExample${index}">
                        <div class="accordion-item">
                          <div class="accordion-header" id="flush-heading${index}">
                            <button class="accordion-button collapsed rounded-top color-lightdark clickable"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapse${index}"
                                    aria-expanded="false"
                                    aria-controls="flush-collapse${index}">

                              <div class="col-auto mb-3">
                                <div class="img-circle-container-aiAndTipster d-flex justify-content-center flex-column">
                                  <img src="${authorImage}" alt="${authorName}'s tip on esports match" class="img-fluid mx-auto d-block">
                                  <i class="bi bi-coin tipster-icon-overlay" aria-hidden="true"></i>
                                </div>
                              </div>

                              <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                                <span id="mainTipTitle${index}" class="header-text-accordion text-white">
                                  ${authorName}: ${predictionChoice}</span>
                                </span>
                                <span id="tipClickBaitSpan${index}" class="sub-text-accordion mt-3 pe-2 text-truncate-3 text-white">
                                  <em>"${tipClickBaitString}"</em>
                                </span>
                              </div>

                            </button>
                          </div>

                          <div id="flush-collapse${index}" class="accordion-collapse collapse"
                              aria-labelledby="flush-heading${index}"
                              data-bs-parent="#accordionFlushExample${index}">
                            <div class="accordion-body color-dark rounded-bottom text-center">
                                  <div class="HtmlPlaceholder">
                                    ${thePrediction}
                                  </div>
                                  <span class="${data.status === 'finished' ? 'd-none' : ''}">
                                    ${placeBetHtml}
                                  </span>
                            </div>
                          </div>
                        </div>
                      </div>

            `;

          document.getElementById('tipsHtml').insertAdjacentHTML('beforeend', predictionHtml);
          document.querySelectorAll('.translateWithOdd').forEach(el => el.innerHTML = I18n.translateString("predictionsTranslations.odd"));
          document.querySelectorAll('.translatePredictionButton').forEach(el => el.innerHTML = I18n.translateString("predictionsTranslations.betButton1"));
          document.querySelectorAll('.tipTranslationTitle').forEach(el => {
            el.innerHTML = '';
            el.insertAdjacentHTML(
              'beforeend',
              `<i class="bi bi-lightbulb me-2"></i>` + I18n.translateString("postMatchTranslations.tipTranslationTitle")
            );
          });
          document.querySelector('.tipTranslationTitle').style.display = "";
        });

        document.querySelectorAll('.accordion-collapse').forEach((collapsible, index) => {
          collapsible.addEventListener('hidden.bs.collapse', event => {
            const clickBaitSpan = document.getElementById(`tipClickBaitSpan${index}`);
            if (clickBaitSpan) {
              clickBaitSpan.style.opacity = '1';
            }
          });

          collapsible.addEventListener('show.bs.collapse', event => {
            const clickBaitSpan = document.getElementById(`tipClickBaitSpan${index}`);
            if (clickBaitSpan) {
              clickBaitSpan.style.opacity = '0';
            }
          });
        });
      }
    }

    if (data.predictions === undefined) {
      // Get the predictions for the match if they are not already saved from other instances
      FirebaseAssistant.getSinglePrediction(data.id, querySnapshot => {

        if (!querySnapshot.empty) {
          data.predictions = [];
          querySnapshot.forEach(single => {
            const dataPred = single.data();
            data.predictions.push(dataPred);
          });

          data.predictions.forEach(prediction => {
            let authorKey = window.savedAuthors ? Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase()) : null;
            if (!authorKey) {
              getDataFrontend('savedAuthors', 'getAuthors', 'fromPostMatchPageComp', function () { // Callback function
                authorKey = Object.keys(window.savedAuthors).find(key => key.toLowerCase() === prediction.tipster.toLowerCase());
                prediction.authorData = window.savedAuthors[authorKey];
              }
              );
            } else {
              prediction.authorData = window.savedAuthors[authorKey];
            }
          });
        } else {
          data.predictions = [];
        }
        generatePredictionHtml(data);
      });
    } else {
      generatePredictionHtml(data);
    }
  }

  static async generateAiAccordion(data) {

    function generateAiHtml(data) {
      const clickBaitString = extractFirstTextBlock(data.analysis, "ai-postmatch")
      const htmlAnalysisContent = theConverter().makeHtml(data.analysis);
      document.getElementById('clickBaitString').innerHTML = ``;
      document.getElementById('clickBaitString').innerHTML = `<em>"${clickBaitString}"</em>`;
      document.getElementById('htmlAnalysisContent').innerHTML = ``;
      document.getElementById('htmlAnalysisContent').innerHTML = htmlAnalysisContent;
      if (data.status !== 'finished') {
        document.querySelector('.aiTranslationTitle').style.display = '';
        document.getElementById("accordionFlushExample").style.display = '';

        document.querySelectorAll('.aiTranslationTitle').forEach(el => {
          el.innerHTML = '';
          el.insertAdjacentHTML(
            'beforeend',
            `<i class="bi bi-graph-up me-2"></i>` + I18n.translateString("postMatchTranslations.aiTranslationTitle")
          );
        });
      }

      const myCollapsible = document.getElementById('flush-collapseOne')
      // Check if the collapsible element exists
      if (myCollapsible) {
        // Add event listeners for collapse events
        const clickBaitSpan = document.getElementById('clickBaitString');
        myCollapsible.addEventListener('hidden.bs.collapse', event => {
          if (clickBaitSpan) {
            clickBaitSpan.style.opacity = '1';
          }
        });
        myCollapsible.addEventListener('show.bs.collapse', event => {
          if (clickBaitSpan) {
            clickBaitSpan.style.opacity = '0';
          }
        });

        // Handle the data.analysis length
        if (data.analysis.length < 200) {
          // Remove the collapsible content
          myCollapsible.remove();

          // Hide the clickBaitSpan
          const clickBaitSpan = document.getElementById('clickBaitString');
          if (clickBaitSpan) {
            clickBaitSpan.style.display = 'none';
          }

          // Update the mainAiTitle
          const mainAiTitle = document.getElementById('mainAiTitle');
          if (mainAiTitle) {
            mainAiTitle.innerText = data.analysis;
          }
        }
      }
    }

    if (data.analysis === undefined) {
      FirebaseAssistant.getAnalysis(data.id, querySnapshot => {
        if (querySnapshot && !querySnapshot.empty && querySnapshot.status.state === 'COMPLETED') {
          data.analysis = querySnapshot.output;
        } else {
          data.analysis = I18n.translateString("postMatchTranslations.analysis");
        }
        generateAiHtml(data);
      });
    } else {
      generateAiHtml(data);
    }
  }

  static async getPastData(data) {

    function processQuerySnapshot(docs, primaryTeamId, secondaryTeamId, currentMatchId, caller = null) {
      let counter = 0;
      if (docs.length > 0) {
        for (let doc of docs) {
          if (counter >= 10) return;
          let pastData = doc.data();
          // Skip current match or unfinished games
          if (pastData.id === currentMatchId || pastData.status !== 'finished') continue;

          // Get the opponent ids from the past match
          const oppIds = [pastData.opponents[0].opponent.id, pastData.opponents[1].opponent.id];

          // If the match is between the two teams, add this to h2h matches also
          if (oppIds.includes(primaryTeamId) && oppIds.includes(secondaryTeamId)) {
            addMatch(primaryTeamId + "_" + secondaryTeamId, pastData);
          }

          // Add match data keyed by the primary team id, unless calling from h2h function
          if (caller !== "h2h") addMatch(primaryTeamId, pastData);
          counter++;
        };
      } else {
        // In case the snapshot is empty, initialize the key with an empty value if not already defined
        if (!window.savedPastMatches) {
          window.savedPastMatches = {};
        }
        if (!window.savedPastMatches[primaryTeamId]) {
          window.savedPastMatches[primaryTeamId] = [];
        }
        if (!window.savedPastMatches[primaryTeamId + "_" + secondaryTeamId]) {
          window.savedPastMatches[primaryTeamId + "_" + secondaryTeamId] = [];
        }
      }
    }

    function addMatch(key, pastData) { //function to help add match to the savedPastMatches object, for whatever scenario object is
      if (!window.savedPastMatches) window.savedPastMatches = {};
      if (!window.savedPastMatches[key]) window.savedPastMatches[key] = [];
      if (!window.savedPastMatches[key].some(match => match.id === pastData.id)) window.savedPastMatches[key].push(pastData);
    }

    const team1idF = data.opponents[0].opponent.id;
    const team2idF = data.opponents[1].opponent.id;

    let result1Circles = [];
    let result2Circles = [];

    if (window.savedPastMatches && window.savedPastMatches[team1idF] && window.savedPastMatches[team2idF] && (window.savedPastMatches[team1idF + '_' + team2idF] || window.savedPastMatches[team2idF + '_' + team1idF])) {
      console.debug('Past matches already saved for these teams');
    } else {

      let promise1 = new Promise((resolve, reject) => {
        FirebaseAssistant.getPastMatches(team1idF, team2idF, (docs1, docs2) => {

          processQuerySnapshot(docs1, team1idF, team2idF, data.id);
          processQuerySnapshot(docs2, team2idF, team1idF, data.id);

          resolve();
        });
      });
      let promise2 = new Promise((resolve, reject) => {
        FirebaseAssistant.getH2hPastMatches(team1idF, team2idF, (docs) => {

          processQuerySnapshot(docs, team1idF, team2idF, data.id, 'h2h');

          resolve();
        });
      });

      await Promise.allSettled([promise1, promise2]);
    }

    const groups = [
      {
        key: team1idF,
        parentHtml: 'pastMatchesforTeam1',
        config: { parentHtmlType: 'team1', primaryTeamId: team1idF },
      },
      {
        key: team2idF,
        parentHtml: 'pastMatchesforTeam2',
        config: { parentHtmlType: 'team2', primaryTeamId: team2idF },
      },
      {
        key: team1idF + '_' + team2idF,
        parentHtml: 'h2hMatches',
        config: { parentHtmlType: 'h2h', primaryTeamId: team1idF },
      },
    ];

    // Store the HTML in an object so we can update each container easily.
    let containers = {
      pastMatchesforTeam1: `<div class="tab-pane fade show" id="pills-gen-${team1idF}" role="tabpanel" aria-labelledby="pills-${team1idF}-tab">`,
      pastMatchesforTeam2: `<div class="tab-pane fade show" id="pills-gen-${team2idF}" role="tabpanel" aria-labelledby="pills-${team2idF}-tab">`,
      h2hMatches: `<div class="tab-pane fade show active" id="pills-h2h-${team1idF}" role="tabpanel" aria-labelledby="pills-h2h-${team1idF}-tab">`,
    };

    // Loop through each group and append generated HTML if matches exist
    groups.forEach(group => {
      const matches = window.savedPastMatches[group.key];
      if (matches && matches[0] !== '') {
        matches.forEach(matchData => {
          containers[group.parentHtml] += buildMatchHtml(matchData, group.config);
        });
      }
    });

    function buildMatchHtml(matchData, config) {
      // Destructure the configuration parameters
      const { parentHtmlType, primaryTeamId } = config;

      // Extract common data from matchData
      const matchName = getMatchName(matchData);
      const team1name = matchData.opponents[0].opponent.name;
      const team1id = matchData.opponents[0].opponent.id;
      const team2name = matchData.opponents[1].opponent.name;
      const team2id = matchData.opponents[1].opponent.id;

      // Set up team images
      const team1img = `<img src="${matchData.opponents[1].type === 'Player' ? matchData.opponents[0].opponent.image_url || noPlayerImg : matchData.opponents[0].opponent.image_url || noImageFound}" 
          onerror="this.onerror=null; this.src='${noImageFound}';" alt="${team1name} logo" class="img-fluid mx-auto d-block">`;
      const team2img = `<img src="${matchData.opponents[1].type === 'Player' ? matchData.opponents[1].opponent.image_url || noPlayerImg : matchData.opponents[1].opponent.image_url || noImageFound}"
          onerror="this.onerror=null; this.src='${noImageFound}';" alt="${team2name} logo" class="img-fluid mx-auto d-block">`;


      // Tournament details
      const tournamentName = `${matchData.league.name} ${matchData.serie.full_name}`;
      const tournamentLogoHTML = matchData.league.image_url ? `<img src="${matchData.league.image_url}" alt="${tournamentName} logo" class="img-fluid mx-auto d-block" onerror="this.onerror=null; this.remove();">` : '';

      // Score and date
      const score = `${matchData.results[0].score} - ${matchData.results[1].score}`;
      const dateRaw = new Date(matchData.begin_at || matchData.scheduled_at);
      const date = `${dateRaw.toLocaleDateString(I18n.getUsersPreferredLanguage(), {
        weekday: 'short', day: 'numeric', month: 'short', year: 'numeric'
      })} ${dateRaw.toLocaleTimeString(I18n.getUsersPreferredLanguage(), {
        hour: '2-digit', minute: '2-digit', hour12: false
      })}`;

      // Determine winner and apply conditional classes based on container type
      const winnerId = matchData.winner?.id || matchData.winner_id;
      let leftClass = '', rightClass = '';

      if (parentHtmlType === 'team1' || parentHtmlType === 'team2') {
        // mark the team that is the primary team, winner or loser
        if (winnerId === primaryTeamId) {
          leftClass = team1id === primaryTeamId ? 'winner-team' : '';
          rightClass = team2id === primaryTeamId ? 'winner-team' : '';
        } else if (winnerId && winnerId !== primaryTeamId) {
          leftClass = team1id === primaryTeamId ? 'loser-team' : '';
          rightClass = team2id === primaryTeamId ? 'loser-team' : '';
        }
      } else if (parentHtmlType === 'h2h') {
        // For head-to-head, simplier, only mark the winner
        leftClass = winnerId === team1id ? 'winner-team' : '';
        rightClass = winnerId === team2id ? 'winner-team' : '';
      }

      if (winnerId) { // Setting the badges for the win/loss/draw of the teams
        if (winnerId === primaryTeamId) {
          config.parentHtmlType === "team1" && result1Circles.length < 5 ?
            result1Circles.unshift('<span class="badge bg-success fixed-size-badge-wld">W</span>') :
            config.parentHtmlType === "team2" && result2Circles.length < 5 ?
              result2Circles.push('<span class="badge bg-success fixed-size-badge-wld">W</span>') : '';
        } else {
          config.parentHtmlType === "team1" && result1Circles.length < 5 ?
            result1Circles.unshift('<span class="badge bg-danger fixed-size-badge-wld">L</span>') :
            config.parentHtmlType === "team2" && result2Circles.length < 5 ?
              result2Circles.push('<span class="badge bg-danger fixed-size-badge-wld">L</span>') : '';
        }
      } else {
        config.parentHtmlType === "team1" && result1Circles.length < 5 ?
          result1Circles.unshift('<span class="badge bg-warning  fixed-size-badge-wld">D</span>') :
          config.parentHtmlType === "team2" && result2Circles.length < 5 ?
            result2Circles.push('<span class="badge bg-warning  fixed-size-badge-wld">D</span>') : '';
      }

      let html = /*html*/ `
          <a href="/${I18n.getUsersPreferredLanguage()}/match/${matchData.id}/${matchName}" 
            class="row mt-2 rounded text-white color-lightdark p-0 clickable">
            <div class="col-12 p-0 d-flex justify-content-between rounded-top color-dark mb-2 p-1">
              <div class="text-start d-flex">
                <span class="mx-1 d-flex align-items-center pastmatch-tournament">${tournamentName}</span>
                <div class="pastmatch-tournamentimgcontainer d-flex align-items-center">
                  ${tournamentLogoHTML}
                </div>
              </div>
              <div class="text-end text-white pastmatch-date me-1 d-flex justify-content-center align-items-center">
                <i class="bi bi-clock"></i><span class="ms-1"> ${date}</span>
              </div>
            </div>
            
            <div class="col-4 offset-1 d-flex justify-content-end align-items-center text-end ${leftClass}">
              <span class="pastmatch-teamname mx-2 my-0">${team1name}</span>
              <div class="pastmatch-teamimgcontainer d-flex flex-column justify-content-center m-2">
                ${team1img}
              </div>
            </div>
            
            <div class="col-2 d-flex flex-column align-items-center justify-content-around">
              <p class="pastmatch-score m-0">${score}</p>
            </div>
            
            <div class="col-4 d-flex justify-content-start align-items-center text-start ${rightClass}">
              <div class="pastmatch-teamimgcontainer d-flex flex-column justify-content-center m-2">
                ${team2img}
              </div>
              <span class="pastmatch-teamname mx-2">${team2name}</span>
            </div>

            <div class="row mb-2"></div>

          </a>
        `;

      return html;
    }

    // Close each container div
    containers.pastMatchesforTeam1 += `</div>`;
    containers.pastMatchesforTeam2 += `</div>`;
    containers.h2hMatches += `</div>`;

    let pastPills1 = /*html*/ `
                         <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team1idF}" 
                            type="button" role="tab" aria-controls="pills-gen-${team1idF}" aria-selected="false">${data.opponents[0].opponent.name}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active h2hpillClass" id="generalpast1-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-h2h-${team1idF}" 
                            type="button" role="tab" aria-controls="pills-h2h-${team1idF}" aria-selected="false">Head to head</button>
                        </li>                      
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="generalpast2-pills-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-${team2idF}" 
                            type="button" role="tab" aria-controls="pills-gen-${team2idF}" aria-selected="false">${data.opponents[1].opponent.name}</button>
                        </li>
                                `;
    document.getElementById('past1-pills-tab').innerHTML = pastPills1;



    while (result1Circles.length < 5) {
      result1Circles.unshift('<span class="badge bg-secondary fixed-size-badge-wld">-</span>');
    }

    while (result2Circles.length < 5) {
      result2Circles.push('<span class="badge bg-secondary fixed-size-badge-wld">-</span>');
    }
    const resultCircles1HTML = result1Circles.join(' ');
    const resultCircles2HTML = result2Circles.join(' ');
    // setTimeout(() => {
    //   document.querySelector(".win-loss-badges-home").innerHTML = resultCircles1HTML;
    //   document.querySelector(".win-loss-badges-away").innerHTML = resultCircles2HTML;
    //   document.querySelectorAll('.fixed-size-badge-wld').forEach(function (el) {
    //     if (el.innerHTML === 'W') {
    //       el.innerHTML = I18n.translateString("postMatchTranslations.badgeWon");
    //     } else if (el.innerHTML === 'L') {
    //       el.innerHTML = I18n.translateString("postMatchTranslations.badgeLost");
    //     } else if (el.innerHTML === 'D') {
    //       el.innerHTML = I18n.translateString("postMatchTranslations.badgeDraw");
    //     }
    //   });
    // }, 5555);


    document.getElementById('past1-pills-tabContent').innerHTML = `${containers.h2hMatches}${containers.pastMatchesforTeam1}${containers.pastMatchesforTeam2}`;

    let pillElements = document.querySelectorAll('#past1-pills-tab .nav-link');
    for (let i = 0; i < pillElements.length; i++) {
      new bootstrap.Tab(pillElements[i]);
    }

    const tabPanes = document.querySelectorAll('#past1-pills-tabContent .tab-pane');
    for (let i = 0; i < tabPanes.length; i++) {
      if (tabPanes[i].innerHTML.trim() === '') {
        tabPanes[i].innerHTML = /*html*/ `
                                <div class="row rounded text-white color-dark">
                                  <span class="mt-4 mb-4 h2hNoRecentMsg" style="justify-content: center; text-align: center;"></span>
                                </div>
                                `;
      }
    }

    document.querySelectorAll('.matchHistory').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.matchHistory"));
    document.querySelectorAll('.h2hpillClass').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hPill"));
    document.querySelectorAll('.h2hNoRecentMsg').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.h2hNoRecentMsg"));

  }

  static async getRostersData(data) {

    let teamData1Html = ``
    let teamData2Html = ``
    const currentGameId = data.videogame.id;

    try {
      FirebaseAssistant.callGetRostersFunctions({ tournamentId: data.tournament_id.toString() || data.tournament.id.toString(), rosterId1: data.opponents[0].opponent.id, rosterId2: data.opponents[1].opponent.id }, (response) => {
        const teamData1 = response.rosterId1;
        const teamData2 = response.rosterId2;

        const rolesOrder = ['top', 'jun', 'mid', 'adc', 'sup'];
        const rolesOrderDota = ['carry/mid', 'carry', 'mid', 'offlane', 'support'];

        function sortPlayersByLoLRole(players) {
          return players.sort((a, b) => {
            const roleA = a.role ? rolesOrder.indexOf(a.role.toLowerCase()) : rolesOrder.length;
            const roleB = b.role ? rolesOrder.indexOf(b.role.toLowerCase()) : rolesOrder.length;
            return roleA - roleB;
          });
        }
        function sortPlayersByDotaRole(players) {
          return players.sort((a, b) => {
            const roleA = a.role ? rolesOrderDota.indexOf(a.role.toLowerCase()) : rolesOrderDota.length;
            const roleB = b.role ? rolesOrderDota.indexOf(b.role.toLowerCase()) : rolesOrderDota.length;
            const indexA = roleA === -1 ? rolesOrderDota.length : roleA;
            const indexB = roleB === -1 ? rolesOrderDota.length : roleB;
            return indexA - indexB;
          });
        }
        if (currentGameId === 1) {
          teamData1.players = sortPlayersByLoLRole(teamData1.players);
          teamData2.players = sortPlayersByLoLRole(teamData2.players);
        }
        if (currentGameId === 4) {
          teamData1.players = sortPlayersByDotaRole(teamData1.players);
          teamData2.players = sortPlayersByDotaRole(teamData2.players);
        }

        let team1Count = 0;
        for (let i = 0; i < 19; i++) { // setting this up to grab as many players as possible
          if (teamData1.players[i]) {
            // console.debug(teamData1.players[i])
            let countryCode = teamData1.players[i].nationality ? teamData1.players[i].nationality.toLowerCase() : false;
            const flagIconHtml = countryCode ?
              `<span class="fi fi-${countryCode}" aria-label="${countryCode} flag" data-bs-toggle="tooltip" data-bs-placement="top" title="${countryCode}" ></span>
                          `
              : '';
            teamData1Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" title="${teamData1.players[i].name}">
                            ${flagIconHtml}
                              <div class="text-truncate ms-1">
                            ${teamData1.players[i].name}
                            </div>
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${teamData1.players[i].image_url ? teamData1.players[i].image_url : noPlayerImg}" 
                                 class="player-image" alt="${teamData1.players[i].name}" loading="lazy"
                                 onerror="this.onerror=null; this.src='${noPlayerImg}';">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> ${teamData1.players[i].role ? teamData1.players[i].role : 'N/A'}<br>
                                <strong class="age-label"></strong> ${teamData1.players[i].age ? teamData1.players[i].age : 'N/A'}
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team1Count++;
          }
          while (team1Count < 5 && teamData1.players[i] === undefined && currentGameId !== 22 && currentGameId !== 23) {
            teamData1Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name">
                            Uknown
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> N/A<br>
                                <strong class="age-label"></strong> N/A
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team1Count++;
          }
          while (team1Count < 4 && teamData1.players[i] === undefined && currentGameId === 23) {
            teamData1Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name">
                            Uknown
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> N/A<br>
                                <strong class="age-label"></strong> N/A
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team1Count++;
          }
        }



        let team2Count = 0;
        for (let i = 0; i < 19; i++) { // Loop through up to 19 players
          if (teamData2.players[i]) {
            let countryCode = teamData2.players[i].nationality ? teamData2.players[i].nationality.toLowerCase() : false;
            const flagIconHtml = countryCode ?
              `<span class="fi fi-${countryCode}" aria-label="${countryCode} flag" data-bs-toggle="tooltip" data-bs-placement="top" title="${countryCode}" ></span>`
              : '';
            teamData2Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name text-truncate" data-bs-toggle="tooltip" data-bs-placement="top" title="${teamData2.players[i].name}">

                            ${flagIconHtml}
                            <div class="text-truncate ms-1">
                            ${teamData2.players[i].name}
                            </div>
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${teamData2.players[i].image_url ? teamData2.players[i].image_url : noPlayerImg}" 
                                 class="player-image" alt="${teamData2.players[i].name}" loading="lazy"
                                 onerror="this.onerror=null; this.src='${noPlayerImg}';">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> ${teamData2.players[i].role ? teamData2.players[i].role : 'N/A'}<br>
                                <strong class="age-label"></strong> ${teamData2.players[i].age ? teamData2.players[i].age : 'N/A'}
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team2Count++;
          }
          while (team2Count < 5 && teamData2.players[i] === undefined && currentGameId !== 22 && currentGameId !== 23) {
            teamData2Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name">
                            Uknown
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> N/A<br>
                                <strong class="age-label"></strong> N/A
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team2Count++;
          }
          while (team2Count < 4 && teamData2.players[i] === undefined && currentGameId === 23) {
            teamData2Html += /*html*/`
                <div class="col">
                    <div class="player-card">
                        <div class="player-name">
                            Uknown
                        </div>
                        <div class="player-image-wrapper">
                            <img src="${noPlayerImg}" alt="N/A" class="player-image" loading="lazy">
                        </div>
                        <div class="card-body p-2">
                            <p class="player-info">
                                <strong class="position-label"></strong> N/A<br>
                                <strong class="age-label"></strong> N/A
                            </p>
                        </div>
                    </div>
                </div>
            `;
            team2Count++;
          }
        }
        document.querySelector('placeholder-teamplayers').style.display = "none";
        document.querySelector('.teamData1Html').innerHTML = teamData1Html;
        document.querySelector('.teamData2Html').innerHTML = teamData2Html;
        document.querySelector('.team1nameForPlayers').insertAdjacentHTML('afterbegin', teamData1.name);
        document.querySelector('.team2nameForPlayers').insertAdjacentHTML('afterbegin', teamData2.name);
        document.querySelectorAll('.age-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.ageLabel"));
        document.querySelectorAll('.position-label').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.positionLabel"));
        document.querySelectorAll('.team1players').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.team1players"));
        document.querySelectorAll('.team2players').forEach(el => el.innerHTML = I18n.translateString("postMatchTranslations.team2players"));
      });
    } catch (error) {
      console.error('Error fetching rosters data:', error);

    }

  }

  static PromoVideo(fromHotmatch, promomatchid) {
    if (window.savedStreamers) {
      for (const [key, streamer] of Object.entries(window.savedStreamers)) {

        if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.promo.promoMatchId === promomatchid && streamer.status === 'Online' && fromHotmatch === false) {
          return streamer.userLogin;
        } else if (streamer.promo && streamer.promo.check && streamer.promo.promoMatchId && streamer.promo.check === true && streamer.status === 'Online' && fromHotmatch === true) {
          return {
            promoMatchId: streamer.promo.promoMatchId,
            userLogin: streamer.userLogin,
            promoViewers: streamer.viewerCount
          };
        }
      }
    }
  }

  static videoPillListeners(data) {
    data?.frontendData?.htmlTags.forEach((tag, index) => {
      let pill = document.getElementById(tag);
      if (pill) {
        pill.addEventListener('click', () => {
          // Remove 'active' class from all pills
          data?.frontendData?.htmlTags.forEach((tag) => {
            let p = document.getElementById(tag);
            if (p) p.classList.remove('active');
          });

          // Add 'active' class to the clicked pill
          pill.classList.add('active');

          // Replace the current video player with the one associated with the clicked pill
          let videoContainer = document.querySelector('#videosid iframe');
          if (videoContainer) {
            videoContainer.src = !data.frontendData.videoLinks[index].includes("twitch") ? data.frontendData.videoLinks[index] : `${data.frontendData.videoLinks[index]}${window.location.hostname}`; // Use data?.frontendData?.videoLinks instead of videoPlayers
          }

          // Replace the current chat with the one associated with the clicked pill
          let chatIframe = document.querySelector('#chats iframe');
          if (chatIframe) {
            chatIframe.src = `${data.frontendData.chatLinks[index]}${window.location.hostname}`;
          }
        });
      }
    });
  }
}


PostMatchesComponent.template = /*html*/ `
<style>
  /* Font Sizes */
  postmatches-component .matchHistory,
  postmatches-component .section-title,
  postmatches-component .team-title,
  postmatches-component .score-postmatch {
    font-size: calc(0.8rem + 1vw);
    font-weight: 800;
    color: white;
  }
  postmatches-component .twitch-container {
    border-bottom-right-radius: var(--bs-border-radius) !important;
  }
  postmatches-component .header-info,
  postmatches-component .header-text-accordion,
  postmatches-component .pastmatch-score {
    font-size: calc(0.5rem + 0.8vw);
    font-weight: 700;
  }

  postmatches-component .player-name,
  postmatches-component .team-odds,
  postmatches-component .betting-link,
  postmatches-component .text-stream-pill {
    font-size: calc(0.6rem + 0.6vw);
    font-weight: 600;
  }

  postmatches-component .win-loss-badges-home span,
  postmatches-component .win-loss-badges-away span,
  postmatches-component .team-name,
  postmatches-component .player-info,
  postmatches-component .match-status,
  postmatches-component .match-date,
  postmatches-component .pastmatch-historypills,
  postmatches-component .h2hNoRecentMsg,
  postmatches-component .pastmatch-teamname {
    font-size: calc(0.5rem + 0.4vw);
  }

  postmatches-component .sub-text-accordion,
  postmatches-component .pastmatch-tournament,
  postmatches-component .pastmatch-date {
    font-size: calc(0.4rem + 0.3vw);
  }

  /* Match Section Styling */
  postmatches-component .match-section {
    background-color: #151d27;
    border: 1px solid #2a3340;
  }

  postmatches-component .HtmlPlaceholder h1,
  postmatches-component .HtmlPlaceholder h2,
  postmatches-component .HtmlPlaceholder h3,
  postmatches-component .HtmlPlaceholder h4 {
    color: rgb(168, 255, 249);
  }

  postmatches-component .matchHistory,
  postmatches-component .section-title,
  postmatches-component .team-title {
    text-align: center;
    color: rgb(168, 255, 249);
    margin-bottom: calc(0.5rem + 0.5vw);
  }

  postmatches-component .matchHistory {
    color: #ffffff !important;
  }

  /* Player Cards */
  postmatches-component .player-card {
    background-color: #1e252e;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    margin: 0;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  postmatches-component .player-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgb(0, 255, 238);
  }

  postmatches-component .player-image-wrapper {
    background-color: #151d27;
    width: 100%;
    height: calc(50px + 5vw);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  postmatches-component .player-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: transform 0.3s;
  }

  postmatches-component .player-card:hover .player-image {
    transform: scale(1.05);
  }

  postmatches-component .player-name {
    font-weight: 700;
    color: #ffffff;
    margin: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  postmatches-component .player-info {
    margin: 5px;
    color: #b2b2b2;
  }

  postmatches-component .header-info {
    font-weight: 700;
    color: #ffffff !important;
  }

  postmatches-component .game-logo,
  postmatches-component .tournament-logo,
  postmatches-component .pastmatch-teamimgcontainer,
  postmatches-component .pastmatch-tournamentimgcontainer img,
  postmatches-component .predImage,
  postmatches-component .predImage2 {
    width: calc(15px + 1vw);
    height: calc(15px + 1vw);
    object-fit: contain;
  }

  postmatches-component .match-date {
    color: #adb5bd;
  }

  postmatches-component .team-container img {
    width: calc(50px + 5vw);
    height: calc(50px + 5vw);
    object-fit: contain;
    transition: transform 0.3s;
  }

  postmatches-component .team-container img:hover {
    transform: scale(1.05);
  }

  postmatches-component .team-name {
    font-weight: 700;
    color: #ffffff;
    max-width: calc(50px + 15vw);
  }

  postmatches-component .win-loss-badges-home span,
  postmatches-component .win-loss-badges-away span {
    margin: 0 calc(0.01rem + 0.1vw);
    font-weight: 800;
  }

  /* Odds Section */
  postmatches-component .odds-section-parent {
    padding: 10px 0.5vw;
  }

  postmatches-component .odds-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #2a3340;
    border-radius: 10px;
    background-color: #090f14;
    padding: 0.5vw;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden;
  }

  postmatches-component .team-odds {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    padding: 10px 2vw;
  }

  postmatches-component .odds-button {
    background-color: #2a3340;
    border: none;
    color: #ffffff !important;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  postmatches-component .odds-button:hover {
    background-color: #d73648;
    box-shadow: 0 4px 15px rgba(215, 54, 72, 0.4);
  }

  postmatches-component .bet-now img,
  postmatches-component .bet-now svg {
    width: calc(50px + 5vw);
    object-fit: contain;
  }

  postmatches-component .betting-link {
    display: inline-block;
    background-color: rgba(73, 150, 145, 0.8);
    color: #ffffff !important;
    padding: 12px 20px;
    border-radius: 50px;
    font-weight: 700;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  postmatches-component .betting-link:hover {
    background-color: rgba(28, 214, 202, 0.6);
    box-shadow: 0 4px 15px rgba(28, 214, 202, 0.6);
  }

  /* Accordion Styles */
  postmatches-component .accordion-item {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    border-radius: 15px !important;
    overflow: hidden;
    padding: 0;
    transition: background-color 0.3s, box-shadow 0.3s;
  }

  postmatches-component .accordion-button:hover,
  postmatches-component .accordion-button:focus {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
    outline: none;
  }

  postmatches-component .accordion-button::after {
    display: none;
  }

  postmatches-component .accordion-button::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    border: solid #00ffee;
    border-width: 0 3px 3px 0;
    padding: 7px;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }

  postmatches-component .accordion-button.collapsed::before {
    transform: rotate(-135deg);
  }

  postmatches-component .accordion-body {
    padding: 25px;
  }

  postmatches-component .img-circle-container-aiAndTipster,
  postmatches-component .img-circle-container-aiAndTipster {
    width: calc(60px + 2vw);
    height: calc(60px + 2vw);
    flex-shrink: 0;
    position: relative;
  }

  postmatches-component .img-circle-container-aiAndTipster img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #00ffee;
    box-shadow: 0 0 10px rgba(0, 255, 234, 0.5);
    transition: transform 0.3s ease-in-out;
  }

  postmatches-component .img-circle-container-aiAndTipster:hover img {
    transform: scale(1.05);
  }

  postmatches-component .ai-icon-overlay,
  postmatches-component .tipster-icon-overlay {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: #121212;
    border: 2px solid #00ffee;
    border-radius: 50%;
    font-size: calc(0.8rem + 0.5vw);
    color: #00ffee;
    box-shadow: 0 0 5px rgba(0, 255, 234, 0.5);
    width: 40%;
    height: 40%;
    text-align: center;
    transition: color 0.3s ease-in-out;
  }

  postmatches-component .players-grid {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }



  postmatches-component .pastmatch-historypills > li > button.active {
    background-color: rgba(0, 255, 234, 0.3);
    color: #fff;
  }

  postmatches-component .pastmatch-historypills > li > button:hover {
    background-color: rgba(0, 255, 234, 0.5);
    cursor: pointer;
    filter: drop-shadow(0 0 4px rgba(0, 255, 234, 0.5));
    transition: all ease-in-out 0.4s;
    color: #fff;
  }

  postmatches-component .pastmatch-historypills > li > button {
    color: #ffffff !important;
    border-radius: 5px;
  }

    postmatches-component .stream-navpills > li > a.active {
    background-color: #ff282859;
    color: #fff;
  }

  postmatches-component .stream-navpills > li > a:hover {
    background-color: #b22a23;
    cursor: pointer;
    filter: drop-shadow(0 0 4px #b22a23);
    transition: all ease-in-out 0.4s;
    color: #fff;
  }

    postmatches-component .stream-navpills > li > a {
    color: #ffffff !important;
    border-radius: 0 0 8px 8px;
  }

  postmatches-component .winner-team {
    background-color: #52a35e;
    filter: drop-shadow(#0d1117 0rem 0.4rem 4.8px);
    border-radius: 15px;
  }

  postmatches-component .loser-team {
    background-color: #ff4646;
    border-radius: 15px;
  }
</style>

        <placeholder-postmatch></placeholder-postmatch>

        <!-- Match Post Content -->
        <div id="matchpost-html" class="container"></div>

        <!-- Predictions Section -->
        <div class="container my-4 p-0">
          <h2 class="section-title tipTranslationTitle" style="display: none;">

          </h2>
          <div id="tipsHtml"></div>
        </div>

        <!-- AI Analysis Section -->
        <div class="container my-4 p-0">
          <h2 class="section-title aiTranslationTitle" style="display: none;">

          </h2>

          <div class="accordion accordion-flush row" id="accordionFlushExample" style="display: none;">
            <div class="accordion-item">
              <div class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed rounded-top color-lightdark clickable"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne">

                  <div class="col-auto mb-3">
                    <div class="img-circle-container-aiAndTipster d-flex justify-content-center flex-column">
                      <img src="${aiAvatar}" alt="AI Avatar analyzing esports match" class="img-fluid mx-auto d-block">
                      <i class="bi bi-activity ai-icon-overlay" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                    <span id="mainAiTitle" class="header-text-accordion text-white">
                      ${I18n.translateString("postMatchTranslations.whatDoesAiThink")}
                    </span>
                    <span id="clickBaitString" class="sub-text-accordion mt-3 pe-2 text-truncate-3 text-white">
                      <!-- JS injected -->
                    </span>
                  </div>

                </button>
              </div>

              <div id="flush-collapseOne" class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample">
                <div class="accordion-body color-dark rounded-bottom">
                  <div id="htmlAnalysisContent" class="HtmlPlaceholder">
                    <!-- JS injected -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Teams and Player Cards -->
        <placeholder-teamplayers></placeholder-teamplayers>
        <div class="team-players-container row my-4 p-0 rounded">
          <div class="col-12 mb-1">
            <div class="team-section">
              <div class="team-title team1nameForPlayers">
                <span class="team1players"></span>
              </div>
              <div class="teamData1Html scrollbar players-grid row row-cols-3 row-cols-lg-4 row-cols-xl-5 g-3">
              <!-- JS injected -->
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="team-section">
              <div class="team-title team2nameForPlayers">
                <span class="team2players"></span>
              </div>
              <div class="teamData2Html scrollbar players-grid row row-cols-3 row-cols-lg-4 row-cols-xl-5 g-3">
              <!-- JS injected -->
              </div>
            </div>
          </div>
        </div>

        <!-- Match History Section -->
        <div class="row my-4">
          <h2 class="matchHistory text-center"></h2>
          <ul class="nav mb-1 pastmatch-historypills p-0 d-flex text-center justify-content-center gap-1" id="past1-pills-tab" role="tablist">
            <!-- JS injected -->
          </ul>
          <div class="col-12">
            <div class="tab-content" id="past1-pills-tabContent">
              <!-- JS injected -->
            </div>
          </div>
        </div>

`;

PostMatchesComponent.reg('postmatches-component');