const {englishTranslation} = require("./translations/en");
const {greekTranslation} = require("./translations/el");
const {spanishTranslation} = require("./translations/es");
const {russianTranslation} = require("./translations/ru");
const {frenchTranslation} = require("./translations/fr");
const {portugueseTranslation} = require("./translations/pt");
const {koreanTranslation} = require("./translations/ko");
const {chineseMandarinTranslation} = require("./translations/zh");

const defaultLocale = "en";
const supportedLocales = ["en", "el", "ru", "es", "pt", "zh", "ko", "fr"];
const supportedAiAnalysesLocales = ["en"];
const supportedArticlesLocales = ["en", "el"];

const translations = { // this is to be used from the Front End
    en: englishTranslation,
    el: greekTranslation,
    es: spanishTranslation,
    ru: russianTranslation,
    fr: frenchTranslation,
    pt: portugueseTranslation,
    ko: koreanTranslation,
    zh: chineseMandarinTranslation,
};

// CommonJS export for Node.js
if (typeof module !== "undefined" && typeof module.exports !== "undefined") {
    module.exports = {
        defaultLocale,
        supportedLocales,
        supportedAiAnalysesLocales,
        supportedArticlesLocales,
        translations,
    };
}
