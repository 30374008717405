import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MatchesComponent } from "../matches-component/matches-component";
import { GameNavbar } from "../game-navbar/game-navbar";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { reversegameIdFilter } from "../../utilities/general/gameIdImgMapping";
import { displayStatsPeriod, statisticsTooltips } from "../../utilities/general/tipsterStatistics";
import { renderPredictions } from "../../utilities/general/predictionsDisplay";
import { generateDateSelector } from "../../utilities/general/generateDateSelector";
import { daySelectorTranslatePosition } from "../../utilities/general/generateDateSelector";

export class PredictionComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('predictions-component', PredictionComponent.initiator);
        window.PredictionComponent = PredictionComponent;
    }

    static currTranslateX = 0;
    static predictionsSavedDate = new Date().toISOString().split('T')[0];

    static initiator() {

        console.debug('I am running the matches component call back for predictions page!');
        if (document.querySelectorAll('#predictions-tbody').length !== 0) {
            console.debug('I am running the authors component call back for predictions page!');

            getDataFrontend('savedAuthors', 'getAuthors', 'fromPredictionComponent', function () { // Callback function
                PredictionComponent.appendPredictionsToWindow();
                if (!document.getElementById('#dateSelector-PredictionComponent')) {
                    generateDateSelector("PredictionComponent");
                }
                PredictionComponent.updatePredictionPage(GameNavbar.getCurrentlyActivatedGameIfAny());
            }
            );

        } else {
            console.debug('The authors component call back is supposed to run for predictions but I am not on the correct page!!!')
        }



        GameNavbar.subscribeToGameFilterChanges('fromPredictionsPageComponent', (eventId) => {
            if (document.querySelectorAll('#predictions-tbody').length !== 0) {
                console.debug('I am running the predictions component call back for the filter changes!');
                PredictionComponent.updatePredictionPage(eventId);
            } else {
                console.debug('I am not running the predictions component call back!');
            }
        });

        PredictionComponent.translations();

    }

    static appendPredictionsToWindow() {
        if (window.predictionMatches === undefined) {
            window.predictionMatches = [];
        }
        Object.keys(window.savedAuthors).forEach(key => {
            for (let i = 0; i < window.savedAuthors[key].predictions.length; i++) {
                const prediction = window.savedAuthors[key].predictions[i];
                const author = window.savedAuthors[key];
                const matchExists = window.predictionMatches.some(existingMatch => existingMatch.id === prediction.id && existingMatch.authorName.toLowerCase() === key.toLowerCase());
                if (!matchExists) {
                    window.predictionMatches.push({ ...prediction, authorName: author.name, authorPhoto: author.photo });
                }
            }
        });
    }

    static renderTipsterStats() {
        const tbody = document.getElementById('predictions-stats-tbody');
        if (!tbody) {
            console.error("Element with id 'predictions-tbody' not found.");
            return;
        }
        tbody.innerHTML = '';

        // Convert savedAuthors object to an array and sort by the number of predictions
        const sortedAuthors = Object.keys(window.savedAuthors)
            .map(key => window.savedAuthors[key])
            .sort((a, b) => b.predictions.length - a.predictions.length);

        sortedAuthors.forEach(author => {
            // We want: NO period cell, YES author cell, clickable cells => link to /author/<authorName>
            displayStatsPeriod(author.statistics, {
                periodKey: "All-time",
                showPeriodCell: false,
                showAuthorCell: true,
                authorName: author.name,
                authorPhoto: author.photo,
                isClickable: true,
                baseUrl: `/${I18n.getUsersPreferredLanguage()}/author/${author.name}`,
                tableId: "predictions-stats-tbody",
            });
        });


    }

    static sortTable() {
        const sortButtons = document.querySelectorAll('.sort-icons'); // Select all buttons
        sortButtons.forEach(button => {
            const th = button.closest('th'); // Find the closest <th> containing the button
            if (th && !th.dataset.listenerAdded) {
                const handleClick = () => {
                    const table = th.closest('table'); // Get the table
                    const rows = Array.from(table.rows).slice(1); // Exclude header row
                    const column = th.getAttribute('data-column') || button.getAttribute('data-column'); // Use <th>'s data-column or button's
                    const isAscending = button.classList.contains('bi-sort-up');

                    // console.debug('rows', rows);

                    rows.sort((a, b) => {
                        const cellA = a.cells[column].innerText;
                        const cellB = b.cells[column].innerText;

                        const compareValues = (a, b) => {
                            if (column === "3" || column === "4") { // (%) and ROI columns
                                return parseFloat(a.replace('%', '')) - parseFloat(b.replace('%', ''));
                            } else {
                                return parseFloat(a) > parseFloat(b) ? 1 : (parseFloat(a) < parseFloat(b) ? -1 : 0);
                            }
                        };

                        if (isAscending) {
                            return compareValues(cellA, cellB);
                        } else {
                            return compareValues(cellB, cellA);
                        }
                    });

                    // Clear existing rows and append sorted rows
                    const tbody = table.tBodies[0];
                    tbody.innerHTML = '';
                    tbody.append(...rows);

                    // Toggle sort direction on button
                    button.classList.toggle('bi-sort-up');
                    button.classList.toggle('bi-sort-down');
                };

                th.addEventListener('click', handleClick);
                th.dataset.listenerAdded = 'true'; // Set flag to indicate listener has been added
            }
        });
    }

    static renderAllPredictionsForDateSelectorCounters(gameId) {
        // Filter matches based on selected date if provided
        const elements = document.querySelectorAll('[data-date-PredictionComponent]');

        elements.forEach(element => {
            // ✅ Remove any previous match count elements
            const oldCount = element.querySelector('.match-count');
            if (oldCount) {
                oldCount.remove();
            }

            const selectedDate = element.getAttribute('data-date-PredictionComponent');
            const filteredMatches = window.predictionMatches.filter(match => {
                const matchDate = new Date(match.matchDate.toDate());
                const selected = new Date(selectedDate);
                // Compare year, month, and day to ensure accurate filtering
                return matchDate.getFullYear() === selected.getFullYear() &&
                    matchDate.getMonth() === selected.getMonth() &&
                    matchDate.getDate() === selected.getDate();
            });


            let matchCount = 0;

            filteredMatches.forEach(match => {
                // If a gameId is provided and it doesn't match the current game's id, skip this match
                if (gameId && reversegameIdFilter[gameId] !== undefined) {
                    if (Array.isArray(reversegameIdFilter[gameId])) {
                        if (!reversegameIdFilter[gameId].includes(match.gameId)) {
                            return;
                        }
                    } else {
                        if (reversegameIdFilter[gameId] !== match.gameId) {
                            return;
                        }
                    }
                }
                matchCount++;
            });

            if (matchCount !== 0) {
                const matchCountElement = document.createElement('span');
                matchCountElement.classList.add('match-count');
                matchCountElement.classList.add('blue-animation');
                matchCountElement.textContent = matchCount;

                element.insertAdjacentElement('beforeend', matchCountElement);
            }
        });
    }

    static updatePredictionsForDate(gameId, selectedDate = null) {

        // update dateSelector active status based on predictionsSavedDate set from the user
        document.querySelectorAll('.date-box-PredictionComponent').forEach((box) => {
            const savedDate = box.getAttribute('data-date-PredictionComponent');

            if (savedDate === PredictionComponent.predictionsSavedDate) {
                box.classList.add('active');
            } else {
                box.classList.remove('active');
            }
        });

        // Function to Render Predictions Table based on selected date
        const tbody = document.getElementById('predictions-tbody');
        if (!tbody) {
            console.error("Element with id 'predictions-tbody' not found.");
            return;
        }
        tbody.innerHTML = ''; // Clear existing rows

        const dateContent = document.querySelector('predictions-component .date-content');
        dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;

        // Filter matches based on selected date if provided
        const filteredMatches = window.predictionMatches.filter(match => {
            const matchDate = new Date(match.matchDate.toDate());
            const selected = new Date(selectedDate);
            // Compare year, month, and day to ensure accurate filtering
            return matchDate.getFullYear() === selected.getFullYear() &&
                matchDate.getMonth() === selected.getMonth() &&
                matchDate.getDate() === selected.getDate();
        });

        const matchesWithPredictions = filteredMatches.filter(match => match.predictionValue !== null && match.predictionValue !== undefined);

        // Sort matchesWithPredictions by match time
        matchesWithPredictions.sort((a, b) => {
            const dateA = new Date(a.matchDate.toDate());
            const dateB = new Date(b.matchDate.toDate());
            return dateA - dateB;
        });

        renderPredictions(matchesWithPredictions, "predictions-tbody", {
            showGameLogo: false,
            showAuthor: true,
            dateFormat: "short",
            locale: I18n.getUsersPreferredLanguage(),
            gameId: gameId,
        });

        if (document.querySelectorAll('#predictions-tbody tr').length === 0) {
            const noPredictionTr = document.createElement('tr');
            const noPredictionTd = document.createElement('td');
            const noPredictionA = document.createElement('a');
            noPredictionTd.colSpan = 8;
            noPredictionA.textContent = "No prediction for this date yet, come back later.";
            noPredictionA.classList.add('translateNoPredictions');
            noPredictionA.style.fontStyle = "italic";
            noPredictionA.style.color = "#8b949e";
            noPredictionTd.appendChild(noPredictionA);
            noPredictionTr.appendChild(noPredictionTd);
            tbody.appendChild(noPredictionTr);
        }

        daySelectorTranslatePosition("PredictionComponent");
    }

    static dynamicTranslations() {
        document.querySelectorAll('.todayClass').forEach(element => {
            element.innerHTML = I18n.translateString("predictionsTranslations.today");
        });
        document.querySelectorAll('.translateNoPredictions').forEach(element => {
            element.innerHTML = I18n.translateString("predictionsTranslations.noPredictionForThisDate");
        });
    }

    static translations() {
        document.querySelector('.translateTitle').innerHTML = I18n.translateString("predictionsTranslations.title");
        document.querySelector('.translateSubtitle').innerHTML = I18n.translateString("predictionsTranslations.subtitle");
        document.querySelector('.th-time').innerHTML = I18n.translateString("predictionsTranslations.time");
        document.querySelector('.th-match').innerHTML = I18n.translateString("predictionsTranslations.match");
        document.querySelector('.th-tipster').innerHTML = I18n.translateString("predictionsTranslations.tipster");
        document.querySelector('.th-tournament').innerHTML = I18n.translateString("predictionsTranslations.tournament");
        document.querySelector('.translate-stats-avg-odds').innerHTML = I18n.translateString("predictionsTranslations.statsAvgOdds");
        document.querySelector('.translate-stats-earn').innerHTML = I18n.translateString("predictionsTranslations.statsEarn");
        document.querySelector('.translate-stats-bets').innerHTML = I18n.translateString("predictionsTranslations.statsBets");
        document.querySelector('.translate-stats-open-bets').innerHTML = I18n.translateString("predictionsTranslations.statsOpenBets");
        // document.querySelector('.translate-stats-tipster').innerHTML = I18n.translateString("predictionsTranslations.tipster");
    }

    static updatePredictionPage(gameId) {

        // Render predictions for today by default
        PredictionComponent.updatePredictionsForDate(gameId, PredictionComponent.predictionsSavedDate);
        PredictionComponent.renderAllPredictionsForDateSelectorCounters(gameId);
        PredictionComponent.renderTipsterStats();
        PredictionComponent.dynamicTranslations();
        PredictionComponent.sortTable();
        statisticsTooltips(-45, "tooltip-predictionspage-stats");
    }

    static theDateListener(element, selectedDate) {
        element.addEventListener('click', function () {
            PredictionComponent.predictionsSavedDate = selectedDate;
            PredictionComponent.updatePredictionsForDate(GameNavbar.getCurrentlyActivatedGameIfAny(), selectedDate);
            PredictionComponent.dynamicTranslations();

        });
    }
}


PredictionComponent.template = /*html*/ `
<style>
/* Header Styles */
predictions-component .header {
  text-align: center;
  margin-bottom: 15px;
}

predictions-component .header h2 {
  font-size: calc(0.5rem + 2vw);
  font-weight: 700;
  color: #58a6ff;
  margin-bottom: 10px;
}

predictions-component .header p {
  font-size: calc(0.55rem + 0.6vw);
  color: #8b949e;
}

/* Predictions Table Styles */
predictions-component .predictions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #161b22;
  border: 1px solid #30363d;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

predictions-component .predictions-table th {
  padding: calc(0.3rem + 0.3vw);
  text-align: center;
  background-color: #21262d;
  color: #58a6ff;
  font-size: calc(0.4rem + 0.3vw);
  font-weight: 700;
  position: sticky;
  top: 0;
}

predictions-component .predictions-table td {
  border-bottom: 1px solid #30363d;
}

predictions-component .predictions-table a {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: calc(0.3rem + 0.3vw);
  text-align: center;
  vertical-align: middle;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.4rem + 0.4vw);
}

predictions-component .predictions-table tbody tr:hover {
  background-color: #30363d;
  cursor: pointer;
}

/* Match Counter Badge */
predictions-component .match-count {
  font-size: calc(0.5rem + 0.5vw);
  font-weight: 600;
  padding: calc(0.2rem + 0.1vw) calc(0.4rem + 0.2vw);
  background-color: #58a6ff;
  color: #ffffff;
  min-width: 30px;
  border-radius: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

/* Author Logo for predictionsDisplay.js and tisterStatistics.js */
predictions-component .author-logo {
  width: calc(1.2rem + 1vw);
  height: calc(1.2rem + 1vw);
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #21262d;
  background-color: #2f363d;
  margin-right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 724px) {
  predictions-component .stats-bets-td,
  predictions-component .stats-bets-th,
  predictions-component .stats-open-bets-td,
  predictions-component .stats-open-bets-th,
  predictions-component .tournament-name,
  predictions-component .th-tournament {
    display: none;
  }
}
</style>

<div class="container-fluid py-2">
        <!-- Header Section -->
        <div class="header">
            <h2 class="translateTitle">Esports Predictions</h2>
            <p class="translateSubtitle d-none d-lg-flex">At MyEsports, we study each match closely to bring you the best betting tips in esports.</p>
        </div>

        <!-- Custom Scrollable Datepicker with Scroll Arrows -->
        <div class="date-selector-container">
            <button class="scroll-arrow scroll-left-PredictionComponent" aria-label="Scroll Left"><i class="bi bi-chevron-left"></i></button>
            <div class="date-selector" id="dateSelector-PredictionComponent">
                <!-- Date boxes will be injected here by JavaScript -->
            </div>
            <button class="scroll-arrow scroll-right-PredictionComponent" aria-label="Scroll Right"><i class="bi bi-chevron-right"></i></button>
        </div>

        <!-- Predictions Table -->
            <table class="predictions-table">
                <thead>
                    <tr>
                        <th class="th-time">Time</th>
                        <th class="th-match">Match</th>
                        <!--<th>1</th>
                        <th>X</th>
                        <th>2</th>-->
                        <th class='th-tournament'>Tournament</th>
                        <th class="th-tipster">Tipster</th>
                        <th class="th-prediction">Prediction</th>
                        <th class="th-outcome"></th>
                    </tr>
                </thead>
                <tbody id="predictions-tbody">
                    <!-- Predictions rows will be injected here by JavaScript -->
                </tbody>
            </table>
        <!-- Predictions Stats -->
            <table class="predictions-table mt-3">
                <thead>
                    <tr>
                    <th class="translate-stats-tipster">Tipster</th>
                    <th class="clickable stats-bets-th"><span class="translate-stats-bets">Bets</span> <i class="bi bi-sort-up sort-icons" data-column="1"></i></th>
                    <th class="clickable tooltip-engage stats-earn-th" data-tooltip="EarnExplain"><span class="translate-stats-earn">Κέρδος</span> <i class=" bi bi-sort-up sort-icons" data-column="2"></i></th>
                    <th class="clickable tooltip-engage stats-wr-th" data-tooltip="WinrateExplain"><span class="translate-stats-wr">(%)</span> <i class=" bi bi-sort-up sort-icons" data-column="3"></i></th>
                    <th class="clickable tooltip-engage stats-roi-th" data-tooltip="RoiExplain"><span class="translate-stats-roi">ROI</span> <i class=" bi bi-sort-up sort-icons" data-column="4"></i></th>
                    <th class="clickable tooltip-engage stats-avg-odds-th" data-tooltip="AvgOddsExplain"><span class="translate-stats-avg-odds">Μ.Ο. απ.</span> <i class=" bi bi-sort-up sort-icons" data-column="5"></i></th>
                    <th class="stats-open-bets-th"><span class="translate-stats-open-bets">Open bets</span></th>
                    </tr>
                </thead>
                <tbody id="predictions-stats-tbody">
                    <!-- Predictions rows will be injected here by JavaScript -->
                </tbody>
            </table>
    </div>

`;

PredictionComponent.reg('predictions-component');