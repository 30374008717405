import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { theConverter } from "../../utilities/general/showdownConverterMyesports";
import { makeAtagsForAuthorsSocials, makeAtagsForGlobalSocials } from "../../utilities/general/socialIcons";

export class NewsPostComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('newspost-component', NewsPostComponent.initiator);
    }

    static initiator() {

        function getArticleNameFromURL() { // gets the article name from the URL
            const match = window.location.pathname.match(/^\/[a-z]{2}(?:\/article)?\/([^/?&]+)/);
            return match ? match[1] : null;
        }
        const articleName = getArticleNameFromURL();

        getDataFrontend('savedNews', 'getNews', 'fromNewsPostComponent', function () { // Callback function
            getDataFrontend('savedAuthors', 'getAuthors', 'fromNewsPostComponent', function () { // Callback function
                let newsPostId = null;
                Object.entries(window.savedNews).forEach(([key, news]) => {
                    if (news.public_url_greeklish === articleName) {
                        newsPostId = key;
                    }
                });
                NewsPostComponent.updateNewsPostComponent(newsPostId);
            }
            );

            window.twttr = (function (d, s, id) {  // Twitter shared content script
                var js, fjs = d.getElementsByTagName(s)[0],
                    t = window.twttr || {};
                if (d.getElementById(id)) return t;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://platform.twitter.com/widgets.js";
                fjs.parentNode.insertBefore(js, fjs);

                t._e = [];
                t.ready = function (f) {
                    t._e.push(f);
                };
                return t;
            })(document, "script", "twitter-wjs");

            window.twttr.ready(function (twttr) {
                twttr.widgets.load();
            });
        });

    }

    static updateNewsPostComponent(newsPostId) {

        let tempAuthorData = {};
        Object.entries(window.savedAuthors).forEach(([authorName, authorData]) => {
            if (authorName.toLowerCase() === window.savedNews[newsPostId].linkedAuthor.toLowerCase()) {
                tempAuthorData = authorData;
            }
        });

        const chosenContent = window.savedNews[newsPostId].content[I18n.getUsersPreferredLanguage()] || window.savedNews[newsPostId].content[I18n.defaultLocale];
        console.debug(newsPostId);
        let htmlContent = window.savedNews[newsPostId].public_url_greeklish !== "frequently-asked-questions" ? theConverter().makeHtml(chosenContent) : chosenContent.replace(/```/g, "");
        const wrappedHtmlContent = `<div class="HtmlPlaceholder">${htmlContent}</div>`;
        const writtenAtTimestamp = window.savedNews[newsPostId].writtenAt;
        const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
        const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });

        document.querySelector('.the-article-container').insertAdjacentHTML('afterbegin', wrappedHtmlContent)
        document.getElementById('newsArticleCoverPhoto').src = window.savedNews[newsPostId].article_photo;
        document.querySelector('.author-info h5').textContent = tempAuthorData.fullName + ' "' + tempAuthorData.name + '" ';
        document.querySelector('.author-info p').textContent = ` ${I18n.translateString("newsTranslations.writtenAt")} ${formattedDate}`;
        document.querySelector('.author-photo').src = tempAuthorData.photo;
        document.querySelector('.theSocials').insertAdjacentHTML('beforeend', `${makeAtagsForAuthorsSocials(tempAuthorData.socials)}`);

        const followUsHtml = /*html*/`
                <div class="container">
                    <h2 class="mb-4"></h2>
                    <h5 class="mb-4"></h5>
                    <!-- Social Media Icons (using Bootstrap Icons) -->
                    <div class="d-flex justify-content-center align-items-center">
                        ${makeAtagsForGlobalSocials()}
                    </div>
                </div>
                `;

        document.querySelector('.follow-us').innerHTML = followUsHtml;
        document.querySelector('.follow-us h2').textContent = ` ${I18n.translateString("newsTranslations.footerArticle")} `;
        document.querySelector('.follow-us h5').textContent = ` ${I18n.translateString("newsTranslations.footerArticleFollow")} `;
        if (window.savedNews[newsPostId].is_page) {
            document.querySelector('.the-article-info').style.display = 'none';
            document.querySelector('.follow-us').style.display = 'none';
        }
    }
}

NewsPostComponent.template = /*html*/`
<style>

/* according for FAQ */
newspost-component .accordion-button {

    color: #ffffff;
    padding: 20px;
    border-radius: 15px !important;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  newspost-component .accordion-button:hover,
  newspost-component  .accordion-button:focus {
    background-color: #2c2c2c;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
    outline: none;
  }

  newspost-component  .accordion-button::after {
    display: none; /* Remove default caret */
  }

  newspost-component  .accordion-button::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    border: solid #00ffee; /* Neon color for caret */
    border-width: 0 3px 3px 0;
    padding: 7px;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }

  newspost-component  .accordion-button.collapsed::before {
    transform: rotate(-135deg);
  }

  newspost-component .accordion-body {
    padding: 15px;
    color: #e0e0e0;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0 0 15px 15px;
  }

newspost-component .follow-us h2 {
    color: #4CAF50; /* Secondary color red */
    font-size: calc(0.5rem + 1.5vw);
    font-weight: bold;
    text-transform: uppercase;
    animation: bounceIn 1s ease-in-out;
    letter-spacing: 2px;
}

newspost-component .follow-us h5 {
    font-size: calc(0.5rem + 0.5vw);
    color: #eaeaea;
    margin-bottom: 2rem;
}

/* Keyframes for bounce-in effect */
@keyframes bounceIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    80% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

/* Responsive grid system for media */
newspost-component .container {
    background-color: #252E39;
}

newspost-component .newspost-component-img {
width: 100%;
height: auto;
object-fit: cover;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
newspost-component .author-info {
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
newspost-component .theSocials a {
color: #e0e0e0;
}

newspost-component .img-circle-container-author{
    width: calc(2rem + 4vw);
    height: calc(2rem + 4vw);
}
newspost-component .img-circle-container-author img{
    max-width: calc(1rem + 4vw);
    max-height: calc(1rem + 4vw);
    align-self: center;
}
newspost-component .text-resize-name{
font-size: calc(0.5rem + 0.5vw);
font-weight: 700;
color: #ffffff;
}
newspost-component .text-resize-date{
font-size: calc(0.4rem + 0.5vw);
font-weight: 500;
}

</style>

<div class="container rounded">
  <div class="row color-dark p-1 rounded the-article-info">
    <!-- Author Info Section -->
    <div class="col-6 p-0 color-dark rounded-start h-100 position-relative">
      <div class="ratio ratio-16x9">
        <div class="author-info d-flex flex-column justify-content-center align-items-center color-lightdark">
          <div class="img-circle-container-author d-flex justify-content-center border border-2 border-success bg-dark bg-gradient rounded-circle overflow-hidden">
            <img src="" alt="Author Photo" class="author-photo img-fluid rounded-circle mx-auto d-block">
          </div>
          <h5 class="mt-2 text-resize-name"></h5>
          <p class="text-resize-date"></p>
          <div class="theSocials"></div>
        </div>
      </div>
    </div>

    <!-- Article Cover Image -->
    <div class="col-6 p-0 rounded-end">
      <img id="newsArticleCoverPhoto" src="" alt="Article Image" class="newspost-component-img">
    </div>
  </div>

  <!-- Article Body Container -->
  <div class="the-article-container mt-3 px-2">
    <!-- Follow Us Section -->
    <div class="follow-us text-center py-5 mb-3"></div>
  </div>
</div>
`;

NewsPostComponent.reg('newspost-component');