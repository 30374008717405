import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { theConverter } from "../../utilities/general/showdownConverterMyesports";

export class PostBookmakersComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('postbookmakers-component', PostBookmakersComponent.initiator);
  }

  static initiator() {
    getDataFrontend('savedBookmakers', 'getBookmakers', 'fromPostBookmakersComponent', function () {
      if (document.querySelector('#s_bookmaker')) {
        console.debug('PostBookmakersSkinComponent callback is running.');
        PostBookmakersComponent.updateBookmakers();
      } else {
        console.debug('PostBookmakersSkinComponent callback is supposed to run but I am not on the correct page!!!');
      }
    });

  }

  static updateBookmakers() {

    function getBookmakerIdFromURL() {
      const match = window.location.pathname.match(/\/bookmaker\/([^/?&]+)/);
      return match ? match[1] : null;
    }
    const bookmakerid = getBookmakerIdFromURL();

    let data = null;
    // console.debug('Bookmaker ID:', bookmakerid);
    if (window.savedBookmakers !== undefined && window.savedBookmakers[bookmakerid]) {
      data = window.savedBookmakers[bookmakerid];
    } else {
      console.error('Bookmaker data not found for ID:', bookmakerid);
      return;
    }

    // console.debug('Retrieved Bookmaker Data:', data);

    const userLanguage = I18n.getUsersPreferredLanguage();
    const getLocalizedField = (field) => {
      if (field && typeof field === 'object') {
        return field[userLanguage] || field[I18n.defaultLocale] || '';
      }
      return field || '';
    };

    const bookmakerName = getLocalizedField(data.name);
    const bookmakerAbout = getLocalizedField(data.bookmaker_about);
    const bookmakerLogo = data.bookmaker_logo || '';
    const bookmakerColor = data.bookmaker_color || '#f5c518';
    const bookmakerBonusUrl = `/en/offers/` + bookmakerid;
    const bookmakerBonusText = getLocalizedField(data.bonus_text);
    const bookmakerBonusDetails = getLocalizedField(data.bonus_details);

    const bookmakersEsportsRaw = data.bookmakers_esports;
    let bookmakersEsports = [];
    if (Array.isArray(bookmakersEsportsRaw)) {
      bookmakersEsports = bookmakersEsportsRaw;
    } else {
      console.warn('bookmakers_esports field is missing or format unexpected.');
    }

    const bookmakerRatingApodoseis = data.bookmaker_rating?.apodoseis || 0;
    const bookmakerRatingPlithwra = data.bookmaker_rating?.plithwra_esports || 0;
    const bookmakerRatingLiveSupport = data.bookmaker_rating?.live_support || 0;
    const bookmakerRatingPlhrwmes = data.bookmaker_rating?.plhrwmes || 0;
    const bookmakerRatingLiveSteaming = data.bookmaker_rating?.live_streaming || 0;
    const ratingsSum = bookmakerRatingApodoseis + bookmakerRatingPlithwra + bookmakerRatingLiveSupport + bookmakerRatingPlhrwmes + bookmakerRatingLiveSteaming;
    const overallRating = (ratingsSum / 100).toFixed(1);

    let bookmakerAboutContent = theConverter().makeHtml(bookmakerAbout);

    function populateEsports(esportsArray) {
      const container = document.getElementById('esports-bookmakers-list');
      if (!container) return;

      container.innerHTML = '';
      if (!Array.isArray(esportsArray)) return;

      if (esportsArray.length === 0) {
        container.innerHTML = '<p>No Esports data available.</p>';
        return;
      }

      esportsArray.forEach((esport) => {
        if (esport) {
          const esportDiv = document.createElement('div');
          esportDiv.className = "col-auto m-1 p-0 rounded";
          const esportSpan = document.createElement('span');
          esportSpan.className = "text-resize-esports";
          esportSpan.textContent = esport;
          esportDiv.appendChild(esportSpan);
          container.appendChild(esportDiv);
        }
      });
    }


    const bkmkrHtml =  /*html*/`
        <div class="container">
          <div class="row top rounded-top" style="background-color: ${bookmakerColor}">
          <a href="${bookmakerBonusUrl}" target="_blank" class="image-wrapper">
              <img src="${bookmakerLogo}" alt="${bookmakerName} Logo">
            </a>
            <div class="col-12 text-center my-2">
              <span class="text-rating-resize">
                <span>${overallRating} / 5</span>
                <i class="bi bi-star-fill"></i>
              </span>
            </div>
            <div class="col-12 text-center my-2 cta-col-action">
              <a href="${bookmakerBonusUrl}" class="btn" target="_blank">${I18n.translateString('bookmakersPostTranslations.register')}</a>
            </div>
          </div>

          <!-- Rating Section -->
          <div class="row d-flex justify-content-center mt-1 mt-lg-2">
            <div class="col-12 scrollable-section d-flex flex-column" id="rating">
              <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.odds')}</span>
              <div class="progress mb-2" style="height: 20px;">
                <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                    style="width: ${bookmakerRatingApodoseis}%; background-color: ${bookmakerColor};">
                  ${bookmakerRatingApodoseis}%
                </div>
              </div>
              <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.varietyOfEsports')}</span>
              <div class="progress mb-2" style="height: 20px;">
                <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" style="width: ${bookmakerRatingPlithwra}%; background-color: ${bookmakerColor};">
                  ${bookmakerRatingPlithwra}%
                </div>
              </div>
              <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.liveSupport')}</span>
              <div class="progress mb-2" style="height: 20px;">
                <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" style="width: ${bookmakerRatingLiveSupport}%; background-color: ${bookmakerColor};">
                  ${bookmakerRatingLiveSupport}%
                </div>
              </div>
              <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.liveStreaming')}</span>
              <div class="progress mb-2" style="height: 20px;">
                <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" style="width: ${bookmakerRatingLiveSteaming}%; background-color: ${bookmakerColor};">
                  ${bookmakerRatingLiveSteaming}%
                </div>
              </div>
              <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.payments')}</span>
              <div class="progress mb-2" style="height: 20px;">
                <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
                    role="progressbar" style="width: ${bookmakerRatingPlhrwmes}%; background-color: ${bookmakerColor};">
                  ${bookmakerRatingPlhrwmes}%
                </div>
              </div>
            </div>
          </div>
            
          <div class="intro-section mb-5 text-center">
            <h2 class="title-section mt-5">${bookmakerName} - ${I18n.translateString('bookmakersPostTranslations.fullReview')}</h2>
            <div class="content-section HtmlPlaceholder">
              ${bookmakerAboutContent}
            </div>
          </div>

          <!-- Esports Section -->
          <div class="row mt-1 mt-lg-2 scrollable-section" id="bookmakers_esports-scroll">
            <h2 class="title-section" style="color:#4CAF50; margin-bottom:1.5rem;">${I18n.translateString('bookmakersPostTranslations.esports')}</h2>
            <div id="esports-bookmakers-list" class="row d-flex flex-row"></div>
          </div>
        </div>

        <!-- Banner Section -->
        <div class="banner-section my-5">
          <a href="${bookmakerBonusUrl}" target="_blank" class="image-wrapper">
            <img src="${bookmakerLogo}" alt="${bookmakerName}" >
          </a>
          <span class="banner-text">${bookmakerBonusText}</span>
          <span class="banner-details">${bookmakerBonusDetails}</span>
            <div class="col-12 text-center my-2 cta-col-action">
              <a href="${bookmakerBonusUrl}" class="btn" target="_blank">${I18n.translateString('bookmakersPostTranslations.register')}</a>
            </div>
        </div>
`;

    const containerElement = document.querySelector('#s_bookmaker');
    if (containerElement) {
      containerElement.insertAdjacentHTML('beforeend', bkmkrHtml);
      // console.debug('Bookmaker HTML inserted into #s_bookmaker.');
    } else {
      console.error('Container element #s_bookmaker not found in the DOM.');
      return;
    }

    populateEsports(bookmakersEsports);

  }
}

PostBookmakersComponent.template = /*html*/`
<style>
/* Top container: lighter gradient for contrast */
postbookmakers-component .top {
    background: linear-gradient(135deg, #1b222a 0%, #0f1219 100%);
    border-radius: 10px;
    padding: 20px 15px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

/* Image wrapper for the top section */
postbookmakers-component .image-wrapper {
    margin-bottom: 15px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    padding: 10px;
    transition: background 0.3s ease;
    border-bottom: none;
}
postbookmakers-component .image-wrapper:hover {
    background: rgba(255,255,255,0.91);
}

/* Logo inside image wrapper */
postbookmakers-component .image-wrapper img {
    width: 180px;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}
postbookmakers-component .image-wrapper img:hover {
    transform: scale(1.05);

}

postbookmakers-component .text-rating-resize {
    background-color: #f5c518;
    color: #000000;
    padding: 0.3rem 0.6rem;
    border-radius: 0.25rem;
    font-size: calc(0.8rem + 0.5vw);
    font-weight: 800;
    display: inline-block;
    margin-bottom: 15px;
}
postbookmakers-component .text-rating-resize i {
    color: #000000;
    margin-left: 5px;
}

/* CTA Button */
postbookmakers-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: #ffffff !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none !important;
    display: inline-block;
}
postbookmakers-component .cta-col-action .btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* Intro / Analysis Section */
postbookmakers-component .intro-section .title-section {
    font-size: calc(0.5rem + 1.5vw);
    color: #4CAF50;
    font-weight: 700;
    letter-spacing: 1px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(76,175,80,0.3);
    margin-bottom: 2rem;
}

/* Content Section */
postbookmakers-component .content-section {
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    line-height: 1.7;
    background: #1e252e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(76,175,80,0.05);
}
postbookmakers-component a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px dashed #4CAF50;
    transition: color 0.3s ease, border-bottom-color 0.3s ease;
}
postbookmakers-component a:hover {
    color: #66FF99 !important;
    border-bottom-color: #66FF99;
}
postbookmakers-component .progress-bar {
    text-align: start;
    padding-inline-start: 49%;
}
postbookmakers-component .content-section li {
  position: relative;
  list-style: none;
}
postbookmakers-component .content-section li::before {
    content: "★" !important;
    color: #4CAF50;
    font-weight: bold;
    position: absolute;
    left: calc(-1rem - 0.5vw);
    top: calc(-0.1rem + -0.1vw);
}

/* Esports Tag */
postbookmakers-component .text-resize-esports {
    color: #4CAF50;
    font-weight: 700;
    font-size: calc(0.5rem + 0.5vw);
    padding: 5px 10px;
    border: 1px dashed #4CAF50;
    border-radius: 5px;
    background: rgba(76,175,80,0.1);
}

/* Banner Section (CTA) */
postbookmakers-component .banner-section {
    position: relative;
    margin-top: 40px;
    background: linear-gradient(135deg, #1e282e 0%, #0f1219 100%);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(255,255,255,0.3);
}

postbookmakers-component .banner-section .image-wrapper img {
    max-width: 100px;
}

postbookmakers-component .banner-text {
    display: block;
    color: #4CAF50;
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}
postbookmakers-component .banner-details {
    display: block;
    color: #dcdcdc !important;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}
</style>

  <div id="s_bookmaker"></div>
`;

PostBookmakersComponent.reg('postbookmakers-component'); // components should always be 2 words separated with a dash