import { I18n } from '../i18n/i18n';

export function startCountdown(caller, gameStartTime, countdownElement, matchElementId, gameStatus, counterORstreams_list = false) {
    const now = new Date();
    const diffInMilliseconds = gameStartTime - now;
    const diffInMinutes = Math.round(diffInMilliseconds / 1000 / 60);

    const element = document.getElementById(countdownElement);
    const matchElement = document.getElementById(matchElementId);

    if (!element) return;
    element.innerHTML = '';
    element.classList.remove('bg-primary', 'bg-danger', 'bg-secondary', 'bg-warning', 'live-animation', 'live-badge-widget');

    if (caller === 'widget') {
        if (element && diffInMinutes > 0) {
            if (diffInMinutes > 1440) {
                const days = Math.floor(diffInMinutes / 1440);
                const hours = Math.floor((diffInMinutes % 1440) / 60);
                const minutes = diffInMinutes % 60;
                element.innerHTML = I18n.translateString("matchTranslations.homeMatchDHM", { days: days, hours: hours, minutes: minutes });
            } else if (diffInMinutes > 60) {
                const hours = Math.floor(diffInMinutes / 60);
                const minutes = diffInMinutes % 60;
                element.innerHTML = I18n.translateString("matchTranslations.homeMatchHM", { hours: hours, minutes: minutes });
            } else if (diffInMinutes < 60) {
                element.innerHTML = I18n.translateString("matchTranslations.homeMatchM", { minutes: diffInMinutes });
            }

        } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
            element.insertAdjacentHTML('afterbegin', '<i class="me-1 bi bi-broadcast"></i>');
            element.insertAdjacentHTML('beforeend', I18n.translateString("matchTranslations.homeMatchLive"));
            element.classList.add('live-badge-widget');
        } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -30 && gameStatus === 'not_started')) {
            element.innerHTML = I18n.translateString("matchTranslations.homeMatchAboutTo");
        } else if (element && (diffInMinutes < 30 && gameStatus === 'not_started')) {
            matchElement.classList.add('hidden-important');
            counterORstreams_list--;
        }
        else if (element && ((gameStatus === 'finished') || (gameStatus === 'postponed') || (gameStatus === 'canceled'))) {
            matchElement.classList.add('hidden-important');
            counterORstreams_list--;
        }

        return counterORstreams_list;
    }
    else {
        if (element && diffInMinutes <= 60 && diffInMinutes > 0) {
            element.classList.add('bg-primary');
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchUpcoming", { minutes: diffInMinutes });
            element.style.display = caller === 'matchespage' ? 'none' : '';
        } else if (element && (diffInMinutes <= 0 && gameStatus === 'running')) {
            element.classList.add('bg-danger');
            element.classList.add('live-animation');
            element.insertAdjacentHTML('afterbegin', '<i class="me-1 bi bi-broadcast"></i>');
            element.insertAdjacentHTML('beforeend', I18n.translateString("matchTranslations.matchMatchLive"));
        } else if (element && (diffInMinutes <= 0 && diffInMinutes >= -180 && gameStatus === 'not_started')) {
            element.classList.add('bg-primary');
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchAboutTo");
        } else if (element && (gameStatus === 'finished')) {
            element.classList.add('bg-secondary');
            element.style.display = caller === 'matchespage' ? 'none' : '';
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchFinished");
        } else if (element && (gameStatus === 'postponed')) {
            element.classList.add('bg-warning');
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchPostponed");
        } else if (element && (gameStatus === 'canceled')) {
            element.classList.add('bg-warning');
            element.innerHTML = I18n.translateString("matchTranslations.matchMatchCanceled");
        } else if (matchElement && diffInMinutes < -180) {
            matchElement.style.display = 'none';
        }

        if (caller === 'matchespage' && element && matchElement.querySelector('.countdownBadge') && counterORstreams_list && counterORstreams_list.length > 0 && gameStatus === 'running') {
            matchElement.querySelector('.countdownBadge').innerHTML = '<i class="me-1 bi bi-cast"></i>' + matchElement.querySelector('.countdownBadge').innerHTML;
        }
    }
}
