import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { theConverter } from "../../utilities/general/showdownConverterMyesports";

export class PostBookmakersSkinComponent extends BaseComponent {

    initCallback() {
        RouterAssistant.checkForSingleContainer('postbookmakersskin-component', PostBookmakersSkinComponent.initiator);
    }

    static initiator() {
        getDataFrontend('savedSkinBookmakers', 'getSkinBookmakers', 'fromPostBookmakersSkinComponent', function () {
            if (document.querySelector('#s_bookmaker_skin')) {
                console.debug('PostBookmakersSkinComponent callback is running.');
                PostBookmakersSkinComponent.updateSkinBookmakers();
            } else {
                console.debug('PostBookmakersSkinComponent callback is supposed to run but I am not on the correct page!!!');
            }
        });
    }

    static updateSkinBookmakers() {

        function getBookmakerIdFromURL() {
            const match = window.location.pathname.match(/\/skin-bookmaker\/([^/?&]+)/);
            return match ? match[1] : null;
        }
        const bookmakerSkinId = getBookmakerIdFromURL();

        let data = null;

        if (window.savedSkinBookmakers !== undefined && window.savedSkinBookmakers[bookmakerSkinId]) {
            data = window.savedSkinBookmakers[bookmakerSkinId];
        } else {
            console.error('Bookmaker Skin data not found for ID:', bookmakerSkinId);
            return;
        }

        const userLanguage = I18n.getUsersPreferredLanguage();

        const getLocalizedField = (field) => {
            if (field && typeof field === 'object') {
                return field[userLanguage] || field[I18n.defaultLocale] || '';
            }
            return field || '';
        };

        const bookmakerSkinName = getLocalizedField(data.bookmaker_skin_name);
        const bookmakerSkinAbout = getLocalizedField(data.bookmaker_skin_about);
        const bookmakerSkinLogo = data.bookmaker_skin_logo || '';
        const bookmakerSkinColor = data.bookmaker_skin_color || '#f5c518';
        const bookmakerSkinBonusUrl = `/en/cases/` + bookmakerSkinId;
        const bookmakerSkinBonusText = getLocalizedField(data.bonus_text);
        const bookmakerSkinBonusDetails = getLocalizedField(data.bonus_details);
        const bookmakerSkinRating = data.bookmaker_skin_rating || '0';

        let bookmakerSkinAboutContent = theConverter().makeHtml(bookmakerSkinAbout);

        const fullReviewText = I18n.translateString('bookmakersSkinPostTranslations.fullReview');
        const registerText = I18n.translateString('bookmakersSkinPostTranslations.register');
        const bkskinHtml = /*html*/`
         <div class="container">
            <div class="top" style="background-color: ${bookmakerSkinColor}">
              <div class="image-wrapper mt-2">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer">
                  <img src="${bookmakerSkinLogo}" alt="${bookmakerSkinName} Logo" >
                </a>
              </div>
              <div class="mt-2">
                <span class="text-rating-resize">
                  <span>${bookmakerSkinRating} / 5</span>
                  <i class="bi bi-star-fill"></i>
                </span>
              </div>
              <div class="my-2 cta-col-action">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer" class="btn">${registerText}</a>
              </div>
            </div>

            <!-- Intro Section -->
            <div class="intro-section">
              <h2 class="title-section">${bookmakerSkinName} - ${fullReviewText}</h2>
              <div class="content-section HtmlPlaceholder">
                ${bookmakerSkinAboutContent}
              </div>
            </div>

            <!-- Banner Section -->
            <div class="banner-section">
              <div class="image-wrapper">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer">
                  <img src="${bookmakerSkinLogo}" alt="${bookmakerSkinName}">
                </a>
              </div>
              <span class="banner-text">${bookmakerSkinBonusText}</span>
              <span class="banner-text">${bookmakerSkinBonusDetails}</span>
              <div class="my-2 cta-col-action">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer" class="btn">${registerText}</a>
              </div>
            </div>
          </div>
        `;

        const containerElement = document.querySelector('#s_bookmaker_skin');
        if (containerElement) {
            containerElement.insertAdjacentHTML('beforeend', bkskinHtml);
            // console.debug('Bookmaker Skin HTML inserted into #s_bookmaker_skin.');
        } else {
            console.error('Container element #s_bookmaker_skin not found in the DOM.');
            return;
        }
    }
}

PostBookmakersSkinComponent.template = /*html*/`
<style>
/* Top container: lighter gradient for contrast */
postbookmakersskin-component .top {
    background: linear-gradient(135deg, #1b222a 0%, #0f1219 100%);
    border-radius: 10px;
    padding: 20px 15px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

/* Image wrapper for the top section */
postbookmakersskin-component .image-wrapper {
    margin-bottom: 15px;
    border-radius: 10px;
    padding: 10px;
    transition: background 0.3s ease;
    border-bottom: none;
}

/* Logo inside image wrapper */
postbookmakersskin-component .image-wrapper img {
    width: 180px;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}
postbookmakersskin-component .image-wrapper img:hover {
    transform: scale(1.05);

}

postbookmakersskin-component .text-rating-resize {
    background-color: #f5c518;
    color: #000000;
    padding: 0.3rem 0.6rem;
    border-radius: 0.25rem;
    font-size: calc(0.8rem + 0.5vw);
    font-weight: 800;
    display: inline-block;
    margin-bottom: 15px;
}
postbookmakersskin-component .text-rating-resize i {
    color: #000000;
    margin-left: 5px;
}

/* CTA Button */
postbookmakersskin-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: #ffffff !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none !important;
    display: inline-block;
}
postbookmakersskin-component .cta-col-action .btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* Intro / Analysis Section */
postbookmakersskin-component .intro-section .title-section {
    font-size: calc(0.5rem + 1.5vw);
    color: #4CAF50;
    font-weight: 700;
    letter-spacing: 1px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(76,175,80,0.3);
    margin-bottom: 2rem;
}

/* Content Section */
postbookmakersskin-component .content-section {
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    line-height: 1.7;
    background: #1e252e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(76,175,80,0.05);
}
postbookmakersskin-component .progress-bar {
    text-align: start;
    padding-inline-start: 49%;
}
postbookmakersskin-component .content-section li {
  position: relative;
  list-style: none;
}
postbookmakersskin-component .content-section li::before {
    content: "★" !important;
    color: #4CAF50;
    font-weight: bold;
    position: absolute;
    left: calc(-1rem - 0.5vw);
    top: calc(-0.1rem + -0.1vw);
}

/* Esports Tag */
postbookmakersskin-component .text-resize-esports {
    color: #4CAF50;
    font-weight: 700;
    font-size: calc(0.5rem + 0.5vw);
    padding: 5px 10px;
    border: 1px dashed #4CAF50;
    border-radius: 5px;
    background: rgba(76,175,80,0.1);
}

/* Banner Section (CTA) */
postbookmakersskin-component .banner-section {
    position: relative;
    margin-top: 40px;
    background: linear-gradient(135deg, #1e282e 0%, #0f1219 100%);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(255,255,255,0.3);
}

postbookmakersskin-component .banner-section .image-wrapper img {
    max-width: 100px;
}

postbookmakersskin-component .banner-text {
    display: block;
    color: #4CAF50;
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}
postbookmakersskin-component .banner-details {
    display: block;
    color: #dcdcdc !important;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}
</style>
  <div id="s_bookmaker_skin"></div>
`;

PostBookmakersSkinComponent.reg('postbookmakersskin-component'); // components should always be 2 words separated with a dash
