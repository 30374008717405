import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { setupSkinBookies } from "../../utilities/general/setupSkinBookies";

export class BookmakersSkinComponent extends BaseComponent {

  initCallback() {
    RouterAssistant.checkForSingleContainer('bookmakers-skin-component', BookmakersSkinComponent.initiator);
    BookmakersSkinComponent.mainTranslations();
  }

  static initiator() {
    getDataFrontend('savedSkinBookmakers', 'getSkinBookmakers', 'fromBookmakersSkinComponent', function () {
      if (document.querySelector('#all-bookmakers-skin')) {
        console.debug('Bookmakers Skin Component is ready');
        BookmakersSkinComponent.updateBookmakersSkin();
      }
    });
  }

  static updateBookmakersSkin() {
    setupSkinBookies('#all-bookmakers-skin', (bookmakerData, language, count) => {
      const listItemsHTML = ((bookmakerData.bookmaker_skin_list_items && bookmakerData.bookmaker_skin_list_items[language]) ||
        (bookmakerData.bookmaker_skin_list_items && bookmakerData.bookmaker_skin_list_items[I18n.defaultLocale]) || []).map(item => `<div>★ ${item}</div>`).join('');

      return /*html*/ `
                <div id="bookmaker-skin-${bookmakerData.id}" class="cta-row row g-3 my-3">
                    <a href="/${I18n.getUsersPreferredLanguage()}/skin-bookmaker/${bookmakerData.id}" class="image-wrapper col-12 col-md-2 order-1 order-md-2">
                        <img src="${bookmakerData.bookmaker_skin_logo || ''}" alt="${bookmakerData.bookmaker_skin_name || ''} Logo" loading="lazy">
                    </a>
                    <div class="cta-col-rank col-12 col-md-1 order-1">${count + 1}</div>
                    <div class="cta-col cta-col-bonus col-12 col-md-3 order-3 order-md-3">
                        <div class="bonus-text">${(bookmakerData.bookmaker_skin_hot_bonus && bookmakerData.bookmaker_skin_hot_bonus[language]) || (bookmakerData.bookmaker_skin_hot_bonus && bookmakerData.bookmaker_skin_hot_bonus[I18n.defaultLocale]) || ''}</div>
                        <div class="bonus-details">${(bookmakerData.bonus_details && bookmakerData.bonus_details[language]) || (bookmakerData.bonus_details && bookmakerData.bonus_details[I18n.defaultLocale]) || ''}</div>
                    </div>
                    <div class="cta-col-info col-12 col-md-4 order-4 order-md-4">
                        <a href="/${I18n.getUsersPreferredLanguage()}/skin-bookmaker/${bookmakerData.id}" class="cta-review-title">${bookmakerData.bookmaker_skin_name || ''} <span class="raw-review">a</span></a>
                        <div class="cta-list-items">${listItemsHTML}</div>
                    </div>
                    <div class="cta-col-action col-12 col-md-2 order-5 order-md-5">
                        <a href="/en/cases/${bookmakerData.id}" class="btn" target="_blank" rel="noopener noreferrer">
                            <span class="raw-betNow"></span>
                        </a>
                    </div>
                </div>
            `;
    });

    BookmakersSkinComponent.dynamicTranslations();

  }

  static mainTranslations() {
    document.querySelector('.main-intro').innerHTML = I18n.translateString("bookmakersSkinList.introSection");
    document.querySelector('.where-to').innerHTML = I18n.translateString("bookmakersSkinList.sectionTitle");
    document.querySelector('.safe-bettting').innerHTML = I18n.translateString("bookmakersSkinList.pleaseBetResponsibly");
    document.querySelector('.review-methodology').innerHTML = I18n.translateString("bookmakersSkinList.reviewMethodology");
  }

  static dynamicTranslations() {
    document.querySelectorAll('.raw-review').forEach(element => { element.innerHTML = I18n.translateString("bookmakersSkinList.review"); });
    document.querySelectorAll('.raw-betNow').forEach(element => { element.innerHTML = I18n.translateString("bookmakersSkinList.betNow"); });
  }
}

BookmakersSkinComponent.template = /*html*/ `
<style>
  /* Intro Section */
  bookmakers-skin-component .intro-section p {
    color: #A0A3A7;
    font-size: calc(0.6rem + 0.4vw);
    line-height: 1.5;
  }

  /* CTA Row */
  bookmakers-skin-component .cta-row {
    display: flex;
    align-items: center;
    align-content: center;
    background-color: #2B2F38;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  bookmakers-skin-component .cta-row:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
  }

  /* CTA Columns */
  bookmakers-skin-component .cta-col {
    flex: 1;
    padding: 10px;
  }

  bookmakers-skin-component .cta-col-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(1rem + 1vw);
    height: calc(1rem + 1vw);
    background-color: #1E232D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 15px;
    font-size: calc(0.6rem + 0.6vw);
  }

  /* Image Wrapper */
  bookmakers-skin-component .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
    background-color: #1B222A;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 10px rgb(55, 69, 85);
    margin-right: 15px;
    cursor: pointer;
  }

  bookmakers-skin-component .image-wrapper img {
    max-width: 100%;
    max-height: 100%;
  }

  /* Bonus Section */
  bookmakers-skin-component .cta-col-bonus .bonus-text {
    color: #4CAF50;
    font-weight: bold;
    font-size: calc(0.8rem + 0.4vw);
  }

  bookmakers-skin-component .cta-col-bonus .bonus-details {
    color: #b5b5b5;
    font-size: calc(0.6rem + 0.4vw);
  }

  /* CTA Info */
  bookmakers-skin-component .cta-col-info {
    text-align: left;
    color: #A0A3A7;
    padding-left: 15px;
  }

  bookmakers-skin-component .cta-col-info a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    font-size: calc(1rem + 0.2vw);
  }

  bookmakers-skin-component .cta-col-info .cta-list-items {
    font-size: calc(0.6rem + 0.4vw);
    line-height: 1.5;
  }

  /* CTA Action Button */
  bookmakers-skin-component .cta-col-action {
    text-align: center;
  }

  bookmakers-skin-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: white !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: calc(0.7rem + 0.7vw);
    text-align: center;
  }

  bookmakers-skin-component .cta-col-action .btn:hover {
    background-color: #45a049;
  }

  /* Title Section */
  bookmakers-skin-component .title-section {
    font-weight: bold;
    font-size: calc(0.8rem + 0.7vw);
    color: #ffffff;
  }

  /* Responsive Styles */
  @media only screen and (max-width: 768px) {
    bookmakers-skin-component .cta-row {
      align-items: stretch;
      align-content: stretch;
    }

    bookmakers-skin-component .cta-col-rank {
      display: none !important;
    }

    bookmakers-skin-component .image-wrapper {
      width: 100%;
      height: 50px;
      margin-bottom: 0;
    }

    bookmakers-skin-component .image-wrapper img {
      width: 100px;
      height: auto;
      margin-bottom: 0;
    }

    bookmakers-skin-component .cta-col-action .btn {
      width: 50%;
      text-align: center;
      padding: 5px 10px;
    }

    bookmakers-skin-component .cta-col-info .cta-list-items {
      display: none;
    }

    bookmakers-skin-component .cta-col,
    bookmakers-skin-component .cta-review-title {
      text-align: center;
    }
  }
</style>

<div class="container-fluid my-4">
    <!-- Intro Section -->
    <div class="main-intro intro-section">
    <!-- I18N -->
    </div>

    <h2 class="where-to title-section"><!-- I18N --></h2>

    <!-- Bookmakers Skins List -->
    <div id="all-bookmakers-skin" class=" m-1">
        <!-- Dynamically inserted bookmaker skin entries will go here -->
    </div>

    <h2 class="safe-bettting title-section removal-title"><!-- I18N --></h2>
</div>

<!-- Methodology Section -->
<div class="container-fluid my-4 removal-class">
    <div class="review-methodology intro-section">
    <!-- Dynamically I18N review methodology content will go here -->
    </div>
</div>
`;

BookmakersSkinComponent.reg('bookmakers-skin-component'); 