import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";

const noImage = require("../../global-assets/hoodieman.webp");

export class LivestreamerComponent extends BaseComponent {

  initCallback() {

    getDataFrontend('savedStreamers', 'getStreamers', 'fromStreamerWidget', function () {

      let highestViewerCount = 0; // To track the highest viewer count
      let highestViewerStreamer = null; // To track the streamer with the highest viewer count

      // Iterate through streamers to find the one with the highest viewer count
      for (const [streamerId, streamerData] of Object.entries(window.savedStreamers)) {
        if (streamerData.status === 'Online' && streamerData.viewerCount >= highestViewerCount) {
          highestViewerCount = streamerData.viewerCount;
          highestViewerStreamer = { streamerId, streamerData };
        }
      }

      // Execute the block only for the streamer with the highest viewer count
      if (highestViewerStreamer) {
        const { streamerId, streamerData } = highestViewerStreamer;

        console.log(streamerId, streamerData);
        document.getElementById("streamer-container").style.display = "";
        document.getElementById("streamerImg").setAttribute("src", streamerData.streamerAvatar || noImage);

        function getRandomGame(games) { // Get a random game from the streamer's games we have stored
          const randomIndex = Math.floor(Math.random() * games.length);
          return games[randomIndex];
        }

        const gameGrabbedFromTwitch = streamerData.twitchGame !== "N/A" ? streamerData.twitchGame : getRandomGame(streamerData.games); // If the streamer is live, use the game they are saying they are playing on Twitch, otherwise use a random game from the games we have stored

        document.getElementById("streamerText").innerText = `${streamerData.streamerName} is playing ${gameGrabbedFromTwitch}`; // todo translations
        document.getElementById("streamer-container").setAttribute('href', `/${I18n.getUsersPreferredLanguage()}/streamers?streamerName=${streamerData.userLogin}`);

        // Function to adjust bottom padding based on scrollToTop visibility
        LivestreamerComponent.adjustStreamerContainerBottom();
        window.addEventListener('scroll', LivestreamerComponent.adjustStreamerContainerBottom, true);

      }
    });
  }

  static adjustStreamerContainerBottom() {
    const scrollToTopElement = document.getElementById("scrollToTop");
    const streamerContainer = document.getElementById("circle-streamer-container");

    if (getComputedStyle(scrollToTopElement).display === "none") {
      streamerContainer.style.bottom = getComputedStyle(scrollToTopElement).bottom;
    } else {
      streamerContainer.style.bottom = ""; // Reset to the value defined in CSS
    }
  }
}

LivestreamerComponent.template = /*html*/`
<style>
  livestreamer-component .container-livestreamer {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
  }

  livestreamer-component .circle {
    width: calc(35px + 1vw);
    height: calc(35px + 1vw);
    background-color: #2f363d;
    border-radius: 50%;
    border: 1px solid rgba(46, 204, 46, 0.5);
    position: fixed;
    right: calc(0.2rem + 4vw);
    bottom: calc(7rem - 2vw);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1057;
    filter: drop-shadow(0 0 0.5rem #101010);
  }

  livestreamer-component .circle .logo {
    transition: all 0.3s ease;
    position: relative;
    z-index: 20;
  }

  livestreamer-component .circle .content {
    display: none;
    font-size: calc(0.5rem + 0.5vw);
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    position: absolute;
    left: 4rem;
    z-index: 15;
    opacity: 0;
    transform: translateY(20px);
  }

  @keyframes slideInFadeIn {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  livestreamer-component .img-circle-container-streamer {
    width: calc(3rem + 1vw);
    height: calc(3rem + 1vw);
  }

  livestreamer-component .img-circle-container-streamer img {
    max-width: calc(2.5rem + 1vw);
    max-height: calc(2.5rem + 1vw);
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    livestreamer-component .circle:hover {
      width: 350px;
      height: 75px;
      border-radius: 10px;
      justify-content: flex-start;
    }

    livestreamer-component .circle:hover .logo {
      width: 40px;
      height: 40px;
    }

    livestreamer-component .circle:hover .content {
      display: flex;
      animation: slideInFadeIn 2s forwards;
    }
  }
</style>

<a class="container-livestreamer" id="streamer-container" style="display: none">
  <div class="circle clickable dark-animation" id="circle-streamer-container">
    <div class="img-circle-container-streamer d-flex justify-content-center flex-column m-2">
      <img id="streamerImg" src="" alt="" class="img-fluid mx-auto d-block">
    </div>
    <div class="content">
      <span id="streamerText"></span>
    </div>
  </div>
</a>
`;

LivestreamerComponent.reg('livestreamer-component'); 