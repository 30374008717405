import "./utilities/i18n/i18n";
import { RouterAssistant } from "./utilities/router-assistant/router-assistant";
import './utilities/template-assets/template-assets'
// import {GeofencingGuard} from "./utilities/geofencing-guard/geofencing-guard";
import { BootstrapAssistant } from "./utilities/bootstrap-assistant/bootstrap-assistant";
import "./utilities/firebase-assistant/firebase-assistant";
import "./components/grid-orchestrator/grid-orchestrator";
import { GridOrchestrator } from "./components/grid-orchestrator/grid-orchestrator";
import { GeofencingGuard } from "./utilities/geofencing-guard/geofencing-guard";

class KickStarter {
    static initiate() {
        GeofencingGuard.initiate();
        // I18n.initiate();
        BootstrapAssistant.initiate();
        // FirebaseAssistant.initiate();
        GridOrchestrator.initiate();
        RouterAssistant.initiate();
    }
}

KickStarter.initiate();
