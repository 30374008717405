import { I18n } from '../i18n/i18n';

export function info1swapper(viewers, topMatch, selector, twitchBoolean) { // swaps between viewers and time played with games remaining, because we know users for twitch only
    if (twitchBoolean) {
        document.querySelectorAll(selector).forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.viewers", { maxviewers: viewers }));
    } else {
        let beginAt = new Date(topMatch.begin_at);
        let now = new Date();
        let diffMs = now - beginAt; // difference in milliseconds
        let diffSecs = Math.floor(diffMs / 1000); // convert to seconds
        let diffMins = Math.floor(diffSecs / 60); // convert to minutes
        let diffHrs = Math.floor(diffMins / 60); // convert to hours
        let runningGameNumber = topMatch.games.findIndex(game => game.status === "running") + 1;
        if (runningGameNumber === 0) { runningGameNumber = topMatch.games.findIndex(game => game.status === "not_started") + 1; }

        if (diffHrs > 0) {
            // if it's been more than an hour, display hours and minutes
            let remainingMins = diffMins % 60;
            document.querySelectorAll(selector).forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineHours", { RGN: runningGameNumber, hourz: diffHrs, minutez: remainingMins }));
        } else {
            let remainingMins = diffMins % 60;
            // console.debug('I am running the else statement')
            document.querySelectorAll(selector).forEach(el => el.innerText = I18n.translateString("hotmatchTranslations.alternativeTimeLineMinutes", { RGN: runningGameNumber, minutez: remainingMins }));
        }
    }
}

export function info2swapper(players) { // swaps the object between team vs team matches and player vs player matches
    let nationalities = []
    let maxAge = 9999999

    let regionNames = new Intl.DisplayNames([I18n.getLanguageFromUrl()], { type: 'region' });


    players.forEach(player => {
        if (player.age !== null && player.age < maxAge) {
            maxAge = player.age;
        }
        if (player.nationality !== null) {
            const countryName = regionNames.of(player.nationality);
            if (!nationalities.includes(countryName)) {
                nationalities.push(countryName);
            }
        }
    });

    return { nationalities, maxAge };
}

export function manipulateInfos(nationalities, maxAge) { // lives outside of main component just because it's repetitive and we want to keep the main component clean
    document.getElementById('spicyInfoNumberTwo').innerText = I18n.translateString("hotmatchTranslations.playerNationalities", { countries: nationalities.join(', ') });
    if (maxAge !== 9999999) {
        document.getElementById('spicyInfoNumberThree').innerText = I18n.translateString("hotmatchTranslations.playerAges", { Age: maxAge });
        document.getElementById('hotMatchInfoThirdsectionPart1').style.display = ''
        document.getElementById('hotMatchInfoThirdsectionPart2').style.display = ''
    }
    else {
        document.getElementById('hotMatchInfoThirdsectionPart1').setAttribute('style', 'display: none !important');
        document.getElementById('hotMatchInfoThirdsectionPart2').setAttribute('style', 'display: none !important');
    }
}

export function removeHotMatchFromMatches(matchId) {
    const stringMatchId = matchId.toString();

    if (document.querySelector('#hotmatch-component')) {
        const observer = new MutationObserver(() => {
            const matchElement = document.getElementById(`matchfromhome-${stringMatchId}`);
            const showMoreButton = document.getElementById('show-more-button');

            if (showMoreButton && matchElement) {
                matchElement.classList.add('hidden-important');
            }
        });

        observer.observe(document.body, {
            childList: true, // Listen for child additions or removals
            subtree: true,  // Include all descendants
        });
    }
}