import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";

export class CasinosContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters()
    }
}

CasinosContainer.template = /*html*/ `
    <div class="main-content col-12 g-0 mt-2">
        <div class="row m-2">
            <bookmakershome-component></bookmakershome-component>
        </div>
    </div>
`;

CasinosContainer.reg('casinos-container');
