// postbookmakersskin-container.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";
import { PostBookmakersSkinComponent } from "../postbookmakersskin-component/postbookmakersskin-component";
import { PredictionsHomeComponent } from "../predictions-homecomponent/predictions-homecomponent";
import { DiscordTemplate } from "../discord-template/discord-template";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { WidgetBookmakersSkin } from "../widget-bookmakers-skin/widget-bookmakers-skin";

export class PostBookmakersSkinContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }

}

PostBookmakersSkinContainer.template = /*html*/ `
    <div class="main-content col-12 col-lg-8 g-0 mt-2">
                <div class="row m-2">
                        <postbookmakersskin-component></postbookmakersskin-component>
                </div>
              </div>

           <div class="sidebar col-12 col-lg-4 mt-2">
                    <div class="row m-2 d-none d-lg-flex">
                        <widget-matches></widget-matches>
                    </div>
                    <div class="row m-2 d-none d-lg-flex">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="row m-2">
                            <discord-template></discord-template>
                    </div>
                    <div class="row m-2">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                    </div>
                </div>
`;

PostBookmakersSkinContainer.reg('postbookmakersskin-container'); // Register the component
