import { globalSocials } from "../../utils/globals.js";

export const spanishTranslation =
{
    "metaTags": {
        "keyword1": `Esports en vivo`,
        "keyword2": `Torneos de esports`,
        "keyword3": `Esports de League of Legends`,
        "keyword4": `Torneos de CS:GO / Counter-Strike`,
        "keyword5": `Dota 2 TI (The International)`,
        "keyword6": `Análisis de partidos de esports con IA y expertos`,
        "keyword7": `Predicciones y consejos de apuestas en esports`,
        "keyword8": `Noticias y artículos de esports`,
        "singleMatchTitleLive": `{{team1}} vs {{team2}} | En Vivo: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFinished": `{{team1}} vs {{team2}} | Resultado: {{score1}} - {{score2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleUpcoming": `Próximo: {{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchTitleFallback": `{{team1}} vs {{team2}} | {{game}}: {{tournament}}`,
        "singleMatchDescriptionLive": `{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} en {{tournament}}. ¡Míralo en vivo ahora! El partido comenzó el {{date}}. Obtén estadísticas, predicciones y análisis de IA en MyEsports.`,
        "singleMatchDescriptionFinished": `{{game}}: {{teamA}} se enfrentó a {{teamB}} en {{tournament}}. El partido terminó el {{date}} con un resultado de {{score1}} - {{score2}}. Obtén estadísticas, predicciones y análisis de IA en MyEsports.`,
        "singleMatchDescriptionUpcoming": `{{game}}: {{teamA}} se enfrentará a {{teamB}} en {{tournament}}. El partido comienza el {{date}}. Obtén estadísticas, predicciones y análisis de IA en MyEsports. ¡Mira el partido en vivo aquí!`,
        "singleMatchDescriptionFallback": `{{game}}: {{teamA}} se enfrentará a {{teamB}} en {{tournament}}. El partido comienza el {{date}}. Obtén estadísticas, predicciones y análisis de IA en MyEsports. ¡Míralo en vivo!`,
        "singleTournamentTitle": `Torneo de {{game}} – {{tournament}} | MyEsports`,
        "singleTournamentDescription": `Explora los últimos partidos y resultados de {{tournament}} en MyEsports. Torneo de {{game}} de nivel {{tier}} con la participación de {{opponents}} y más.`,
        "singleAuthorTitle": `{{author}} - Autor en MyEsports`,
        "matchesTitle": `Partidos de Esports – Resultados y Marcadores en Vivo | MyEsports`,
        "matchesDescription": `Mantente actualizado con los últimos resultados de esports y próximos partidos en MyEsports. Obtén marcadores en vivo, horarios, análisis, consejos y estadísticas de equipos en CS2, Dota 2, LoL y más.`,
        "tournamentsTitle": `Torneos de Esports – Próximos y Pasados | MyEsports`,
        "tournamentsDescription": `Descubre los torneos de esports más importantes en MyEsports. Encuentra horarios, resultados, premios y clasificaciones de equipos en CS2, Dota 2, LoL, Valorant y más.`,
        "newsTitle": `Noticias y Artículos de Esports – Últimas Actualizaciones y Análisis | MyEsports`,
        "newsDescription": `Mantente al día con las últimas noticias, artículos y análisis de esports en MyEsports. Encuentra actualizaciones sobre CS2, Dota 2, LoL, Valorant y más, junto con análisis de expertos y entrevistas.`,
        "authorsTitle": `Conoce a Nuestros Autores de Esports – Noticias, Predicciones y Consejos | MyEsports`,
        "authorsDescription": `Descubre a los expertos detrás de MyEsports. Conoce a nuestros escritores de esports que traen noticias, análisis en profundidad, predicciones y consejos sobre CS2, Dota 2, LoL y más.`,
        "bookmakersTitle": `Top 10 Casas de Apuestas de Esports – Afiliados de Confianza y Ofertas | MyEsports`,
        "bookmakersDescription": `Explora los socios de apuestas de confianza de MyEsports. Encuentra las mejores casas de apuestas de esports con ofertas exclusivas, bonos y plataformas seguras para CS2, Dota 2, LoL y más.`,
        "skinbookmakersTitle": `Intercambio de Skins en Esports – Socios de Confianza para Skins y Cajas | MyEsports`,
        "skinbookmakersDescription": `Descubre los socios de confianza de MyEsports para el intercambio de skins. Compra, vende, intercambia y apuesta skins y cajas de manera segura para CS2, Dota 2, Rust y más. Encuentra los mejores mercados y ofertas exclusivas.`,
        "predictionsTitle": `Predicciones y Consejos de Apuestas en Esports – Análisis de Expertos | MyEsports`,
        "predictionsDescription": `Obtén predicciones y consejos de apuestas en esports con análisis experto en MyEsports. Encuentra análisis precisos de partidos hechos por nuestros expertos y IA, junto con probabilidades y estrategias ganadoras para CS2, Dota 2, LoL y más.`,
        "termsOfUseTitle": `Términos de Uso – Reglas y Acuerdo de Usuario | MyEsports`,
        "termsOfUseDescription": `Lee los Términos de Uso de MyEsports. Conoce los acuerdos de usuario, reglas y políticas de nuestra plataforma, incluyendo contenido de esports, afiliados de apuestas y más.`,
        "privacyPolicyTitle": `Política de Privacidad – Protección de Datos y Seguridad | MyEsports`,
        "privacyPolicyDescription": `Descubre cómo MyEsports protege tu información. Lee nuestra Política de Privacidad sobre datos de usuario, medidas de seguridad y cumplimiento con GDPR para una experiencia segura.`,
        "faqTitle": `FAQ – Preguntas Frecuentes y Soporte | MyEsports`,
        "faqDescription": `Encuentra respuestas a las preguntas más comunes sobre MyEsports. Lee nuestro FAQ para información sobre cobertura de esports, afiliados de apuestas, predicciones, intercambio de skins y más.`,
        "streamersTitle": `Streamers de Esports – Canales en Vivo y Contenido Gaming | MyEsports`,
        "streamersDescription": `Descubre a los mejores streamers de esports en MyEsports. Mira transmisiones en vivo de gameplay, streams de jugadores profesionales y contenido exclusivo de nuestros creadores asociados.`,
        "homeTitle": `MyEsports – Tu Portal Definitivo de Esports para Noticias, Partidos y Apuestas`,
        "homeDescription": `Mantente a la vanguardia del mundo de los esports con MyEsports. Encuentra las últimas noticias, resultados de partidos, marcadores en vivo, consejos de apuestas, predicciones y actualizaciones de torneos de CS2, Dota 2, LoL y más.`,
    },
    "homePage": {
        "latestAnalyses": `ÚLTIMOS ANÁLISIS DE IA`
    },

    "networkGuard": {
        "title": `¡Oops!`,
        "description": `¡La página que buscas no existe!`,
    },

    "navMainMenu": {
        "Home": `Inicio`,
        "Tournaments": `Torneos`,
        "Matches": `Partidos`,
        "Giveaways": `Sorteos`,
        "Casinos": `Casas de Apuestas`,
        "AboutUs": `Sobre Nosotros`,
        "Streamers": `Streamers`,
        "News": `Noticias`,
        "Predictions": `Predicciones`,
        "navDesktopTitle": `MyEsports`,
    },
    "hotmatchTranslations": {
        "playerNationalities": `Jugadores de {{countries}} compiten`,
        "playerAges": `El jugador más joven tiene {{Age}} años`,
        "viewers": `{{maxviewers}} espectadores ahora mismo!`,
        "alternativeTimeLineHours": `Juego {{RGN}} en curso. Comenzó hace {{hourz}} hora(s) y {{minutez}} minuto(s)`,
        "alternativeTimeLineMinutes": `Juego {{RGN}} en curso. Comenzó hace {{minutez}} minuto(s)`,
        "badgeHot": `<i class="me-2 bi bi-fire"></i>CALIENTE`,
        "noLiveMsg": `<h2>¡Espera, la acción está a punto de comenzar!</h2><p>Parece que la acción de {{game}} está tomando un descanso.</p>`
    },
    "matchTranslations": {
        "title": `{{team1}} vs {{team2}} | {{tournament}}`,
        "description": `Mira a {{teamA}} enfrentarse a {{teamB}} en {{tournament}} en MyEsports | ¡Revisa el análisis de IA y lo que nuestros expertos tienen que decir sobre este partido!`,
        "h2": `Este es el subtítulo de un partido`,
        "body": `Este es el contenido principal de un partido`,
        "matchMatchUpcoming": `{{minutes}} min restantes`,
        "matchMatchLive": `EN VIVO`,
        "matchMatchFinished": `Terminado`,
        "matchMatchPostponed": `Pospuesto`,
        "matchMatchCanceled": `Cancelado`,
        "matchMatchAboutTo": `A punto de comenzar`,
        "homeMatchDHM": `{{days}}d {{hours}}h {{minutes}}m`,
        "homeMatchHM": `{{hours}}h {{minutes}}m`,
        "homeMatchM": `{{minutes}}m`,
        "homeMatchLive": `EN VIVO`,
        "homeMatchAboutTo": `Comenzando`,
        "pillLive": `EN VIVO`,
        "pillUpcoming": `PRÓXIMO`,
        "pillFinished": `TERMINADO`,
        "pillPredictions": `PREDICCIONES`,
        "pillAll": `TODO`,
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"></i> FAVORITOS`,
        "bestofX": `Mejor de {{gamesNumber}}`,
        "bestof1": `Mejor de 1`,
        "bestof2": `Mejor de 2`,
        "allGamesPlayed": `{{gamesNumber}} partidos`,
        "allGamesPlayedMicro": `GP{{gamesNumber}}`,
        "firstToX": `Primero a {{gamesNumber}}`,
        "showMoreMatches": `Ver más`,
        "emptyGameFilterMessage": `No hay partidos de {{game}} disponibles en este momento.`,
        "emptyStateFilterMessage": `No hay partidos de {{game}} disponibles con los filtros seleccionados.`,
        "emptyPredictionFilterMessage": `No hay predicciones de {{predGame}} disponibles para la fecha seleccionada.`,
        "emptyAnalysisFilterMessage": `No hay análisis recientes {{game}}`,
        "searchPlaceholder": `Buscar torneos o equipos específicos...`,
        "boN": `BO{{numberOfGames}}`,
        boNCalculation: function (numberOfGames) { return numberOfGames }
    },
    "postMatchTranslations": {
        "aiTranslationTitle": `Análisis de Partido Impulsado por IA`,
        "tipTranslationTitle": `Consejos de los Expertos`,
        "team1players": `Jugadores`,
        "team2players": `Jugadores`,
        "ageLabel": `Edad: `,
        "positionLabel": `Posición: `,
        "h2hNoRecentMsg": `No hay partidos recientes entre estos equipos`,
        "matchHistory": `Historial de Partidos`,
        "h2hPill": `Cara a Cara`,
        "badgeWon": `G`,
        "badgeLost": `P`,
        "badgeDraw": `E`,
        "noVideoMessage": `No hay video disponible para este partido`,
        "analysis": `Nuestra IA 'PlayPulse' analizará este partido en las 24 horas previas al inicio. Por favor, regresa más cerca del comienzo del partido para ver el análisis completo!`,
        "whatDoesAiThink": `Perspectivas Impulsadas por IA sobre Este Partido:`,
        "aiAnalysis": `ANÁLISIS DE IA`,
        "betNow": `Apostar Ahora`,
        "oddsDraw": `Empate`,
        "predictToWin": `para ganar`,
        "predictDraw": `Empate`,
        "predictExactScore": `Resultado exacto`,
        "predictOverUnder": `partidos`
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>No hay Artículos Recientes</h2>
      <p>Desafortunadamente, no hay artículos recientes de {{newsGame}}.</p>`,
        "newsTitle": `ARTÍCULOS`,
        "writtenAt": `Escrito en`,
        "footerArticle": `🔥 ¿Te ha gustado el Artículo? 🔥`,
        "footerArticleFollow": `¡No te lo pierdas! ¡Síguenos en las redes sociales para más actualizaciones emocionantes!`,
        "newsSeeAll": `Ver Todo`,
        "seeMore": `Ver Más...`,
        "goBack": `Volver a Noticias`,
        "tournamentNews": `Torneos`,
        "rosterNews": `Plantillas`,
        "gameNews": `General`
    },
    "footer": {
        "about": `Bienvenido a MyEsports, tu destino integral para todos los entusiastas de los eSports. Nacidos de un profundo amor y pasión por el mundo de los videojuegos, hemos creado un espacio donde la comunidad de eSports puede encontrar todo: desde transmisiones en vivo de partidos y eventos hasta predicciones, análisis y artículos sobre todo tipo de juegos.`,
        "followUs": `Síguenos`,
        "usefulLinks": `Enlaces Útiles`,
        "contactUs": `Contáctanos`,
        "aboutUs": `Acerca de Nosotros`,
        "eula": `Términos de Uso`,
        "privacy": `Política de Privacidad`,
        "faq": `Preguntas Frecuentes`,
        "Authors": `Autores`
    },
    "tournamentsTranslations": {
        "noDateMsg": `Fecha de finalización por determinar`,
        "tier": `Nivel`,
        "emptyTournamentFilterMessage": `No hay torneos de {{game}} disponibles con los filtros de estado seleccionados.`,
        "pillOngoing": `EN CURSO`,
        "pillUpcoming": `PRÓXIMOS`,
        "pillFinished": `FINALIZADOS`,
        "pillAll": `TODOS`,
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>En Curso</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Finalizado</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Comienza en {{days}} días</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Comienza en {{days}} día</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Comienza en {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Comienza en {{minutes}}m</span>`,
        "noPrizePoolAvailable": `N/D`,
        "euroPrizePool": `€`,
        "usdPrizePool": `$`,
        "teamsCompete": `Equipos compiten`,
        "hotTournamentsTitle": `TORNEOS CALIENTES`,
        "hotTournamentFixedLabel": `EN CURSO`,
        "noHotTournamentsMsg": `<h2>No hay Torneos en Vivo Actualmente</h2><p>Desafortunadamente, no hay torneos en vivo de {{game}} actualmente.</p>`,
        "noHotTournamentsButton2": `Ver Todos`,
        "clickHotTournaments": `Haz clic para ver más información.`
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Equipos Compiten: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Jugadores Compiten: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Premio: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Premio: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Premio: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": `Nivel:`,
        "winnerPost": `Ganador del Torneo:`,
        "startPost": `Fecha de Inicio:`,
        "endPost": `Fecha de Finalización:`,
        "noDateMsgPost": `Fecha de finalización por determinar`,
        "ladderWins": `Victorias`,
        "ladderLosses": `Derrotas`,
        "ladderTies": `Empates`,
        "ladderGamesWon": `Partidos Ganados`,
        "ladderGamesLost": `Partidos Perdidos`,
        "ladderTieBreaker": `Desempates`,
        "upperBracket": `Cuadro Superior`,
        "lowerBracket": `Cuadro Inferior`,
        "grandFinal": `Gran Final`,
        "semiFinal": `Semifinal`,
        "quarterFinal": `Cuartos de Final`,
        "roundBrackets": `Ronda`,
        "matchBrackets": `Partido`,
        "laddersMatchesTitle": `Partidos`,
        "week": `Semana`,
        "liveWeek": `EN VIVO`
    },
    "predictionsTranslations": {
        "predTitle": `PREDICCIONES`,
        "comingSoon": `Próximamente`,
        "today": `Hoy`,
        "title": `Predicciones de Esports`,
        "subtitle": `En MyEsports, estudiamos cada partido de cerca para brindarte los mejores consejos de apuestas en esports. Nuestros expertos analizan los equipos, jugadores y estrategias para ayudarte a tomar las mejores decisiones de apuestas.`,
        "time": `Hora`,
        "date": `Fecha`,
        "match": `Partido`,
        "tournament": `Torneo`,
        "tipster": `Apostador`,
        "betButton1": `Coloca tu apuesta ahora en `,
        "odd": `@`,
        "noPredictionForThisDate": `No hay predicción para esta fecha aún, vuelve más tarde.`,
        "statsAvgOdds": `Prom. Cuotas`,
        "statsEarn": `Ganado`,
        "statsBets": `Apuestas`,
        "statsOpenBets": `Apuestas Abiertas`,
        "authStats": `Estadísticas de Predicción`,
        "articles": `Artículos`,
        "last10": `Predicciones recientes`,
        "authBio": `Biografía`,
        "bioComing": `Biografía próximamente`,
        "showMorePredictions": `Mostrar Más`,
        "EarnExplain": `Las ganancias se calculan en función de las predicciones que han sido cerradas. Cada apostador comienza desde 0, con cada predicción correcta sumando las cuotas ganadas x1 a su ganancia total. Por ejemplo, en una predicción correcta con cuotas de 1.80, la ganancia del apostador aumenta en 0.80. En el caso de una predicción incorrecta, se resta 1.00 de la ganancia total.`,
        "WinrateExplain": `La tasa de aciertos se calcula en función de las predicciones que han sido cerradas. Es el porcentaje de predicciones correctas sobre el total de predicciones realizadas.`,
        "RoiExplain": `El ROI (Retorno sobre la Inversión) se calcula en función de las ganancias del apostador en relación con sus apuestas totales. Básicamente, el ROI representa la ganancia neta del apostador. Por ejemplo, si ha realizado 50 apuestas con una unidad cada una y el ROI es del 20%, entonces ha ganado 10 unidades.`,
        "AvgOddsExplain": `Las cuotas promedio se calculan en función de las cuotas de las predicciones que han sido cerradas. Es la suma de las cuotas dividida por el total de predicciones.`,
        "plainPrediction": `Predicción`,
        "statisticPeriod": `Período`,
        "alltimeStats": `Todo el Tiempo`
    },
    "banners": {
        "desktopLeft": 'es_desktop_left',
        "desktopRight": 'es_desktop_right'
    },
    "streamerTranslations": {
        "cardFollowers": `Seguidores`,
        "cardInfo": `{{user}} está {{status}} en este momento.`,
        "cardViewers": `¡Espectadores!`,
        "detailsInformation": `Información del Streamer`,
        "detailsName": `Nombre Completo`,
        "detailsNationality": `Nacionalidad`,
        "detailsAge": `Edad`,
        "detailsGame": `Juegos`,
        "detailsRole": `Rol`,
        "detailsTeam": `Equipo`,
        "detailsSignature": `Héroe de Firma`,
        "accordionHowTo": `<strong>-¿Cómo puedo convertirme en streamer en MyEsports?</strong>`,
        "accordionHowToReply": `Para convertirte en streamer en MyEsports, puedes aplicar uniéndote a nuestro <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">servidor de Discord</a> y contactar con nuestro equipo o llenar este <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="formulario de google" target="_blank" rel="noopener noreferrer">formulario</a>.`,
        "accordionEarn": `<strong>-¿Qué gano al convertirme en streamer en MyEsports?</strong>`,
        "accordionEarnReply": `Ser presentado en MyEsports te dará la oportunidad de aumentar tu audiencia y hacer crecer tu canal. También tendrás la oportunidad de ser destacado en nuestros artículos y redes sociales.`,
        "accordionGraphics": `<strong>-¿Recibiré gráficos para mi canal?</strong>`,
        "accordionGraphicsReply": `Sí, te proporcionaremos gráficos para tu canal, así como soporte y orientación sobre cómo usarlos y mejorar tu experiencia de streaming.`,
        "accordionHowToStream": `<strong>-Soy nuevo en el streaming, ¿puedo unirme aún? ¿Cómo empiezo?</strong>`,
        "accordionHowToStreamReply": `¡Por supuesto! Damos la bienvenida a streamers de todos los niveles. Puedes comenzar uniéndote a nuestro <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">servidor de Discord</a> y contactando con nuestro equipo para más información.`,
        "accordionViewers": `<strong>-¿Cuántos espectadores necesito tener para unirme?</strong>`,
        "accordionViewersReply": `¡Damos la bienvenida a streamers de todos los tamaños! Ya sea que tengas 1 o 10,000 espectadores, ¡eres bienvenido a unirte a MyEsports!`
    },
    "authorsTranslations": {
        "title": 'Descubre al Equipo de Autores de MyEsports'
    },

    "bookmakersPostTranslations": {
        "odds": `Cuotas`,
        "varietyOfEsports": `Variedad de Esports`,
        "liveSupport": `Soporte en Vivo`,
        "liveStreaming": `Transmisión en Vivo`,
        "payments": `Pagos`,
        "fullReview": `Reseña Completa`,
        "esports": `Esports`,
        "register": `APUESTA AHORA`
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": `Reseña Completa`,
        "register": `APUESTA AHORA`
    },
    "bookmakersSkinList": {
        "widgetTitle": `Plataformas de Skins`,
        "widgetButton": `Más`,
        "playNow": `Jugar!`,
        "introSection": `<h2 class="title-section">Principales Sitios de Apuestas en Esports para Unirse en 2024: Descubre las Mejores Skins de Casas de Apuestas en Esports</h2>
            <p>
            Con el crecimiento explosivo de los esports, las casas de apuestas líderes han surgido para satisfacer la demanda, ofreciendo amplios mercados para juegos populares como Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends y Call of Duty. Estos juegos traen un flujo continuo de emocionantes torneos y ligas, proporcionando a los apostadores opciones diversas, desde ganadores de partidos hasta apuestas específicas de juegos.            
            </p>
            <p>
            En <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, hemos revisado cuidadosamente una gama de plataformas de skins de apuestas en esports de primer nivel para ayudarte a encontrar la skin perfecta para tus necesidades de apuestas.            
            </p>`,
        "sectionTitle": `Dónde Apostar en Esports: Las Mejores Skins de Casas de Apuestas en Esports y Bonificaciones`,
        "pleaseBetResponsibly": `Por favor, apuesta con responsabilidad`,
        "reviewMethodology": `
                <h2 class="title-section">Nuestra Metodología de Reseña</h2>
        <p>
        En <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, nuestra misión es sencilla: guiarte en la selección de la skin de casa de apuestas ideal para tus apuestas en esports. Evaluamos cada skin basándonos en la facilidad de uso, variedad de mercados de esports y características de seguridad para asegurar tu seguridad al depositar o retirar fondos.
        </p>
        <p>
        También evaluamos las licencias para verificar si la skin de casa de apuestas opera legalmente en tu región. Solo aquellas que cumplan con nuestros altos estándares reciben nuestro sello de aprobación. Presentamos bonificaciones y promociones exclusivas de esports para mejorar aún más tu experiencia de apuestas.        
        </p>
        <p>
        Nuestros criterios de reseña abarcan múltiples áreas, incluyendo facilidad de uso, oferta de mercados y métodos de pago. Cada skin de casa de apuestas es evaluada exhaustivamente en ocho categorías principales, cubriendo todo, desde diseño y funcionalidad hasta servicio al cliente. Consulta nuestras reseñas detalladas para explorar el desempeño de cada skin en estas áreas críticas.        
        </p>
        `,
        "review": `Reseña`,
        "betNow": `APUESTA AHORA`,
        "noBookmakerSkinsMsg": `<h2>No hay plataformas de skins disponibles para tu región e idioma.</h2>`
    },
    "bookmakersList": {
        "widgetTitle": `Casas de Apuestas`,
        "widgetButton": `Más`,
        "playNow": `Jugar!`,
        "introSection": `<h2 class="title-section">Principales Sitios de Apuestas en Esports para Unirse en 2024: Descubre las Mejores Casas de Apuestas en Esports</h2>
            <p>
            Con el crecimiento explosivo de los esports, los sitios de apuestas líderes han surgido para satisfacer la demanda, ofreciendo amplios mercados para juegos populares como Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends y Call of Duty. Estos juegos traen un flujo continuo de emocionantes torneos y ligas, proporcionando a los apostadores opciones diversas, desde ganadores de partidos hasta apuestas específicas de juegos.
            </p>
            <p>
            En <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, hemos revisado cuidadosamente una gama de plataformas de apuestas en esports de primer nivel para ayudarte a encontrar el sitio perfecto para tus necesidades de apuestas.
            </p>`,
        "sectionTitle": `Dónde Apostar en Esports: Los Mejores Sitios de Apuestas en Esports y Bonificaciones`,
        "pleaseBetResponsibly": `Por favor, apuesta con responsabilidad`,
        "reviewMethodology": `
                <h2 class="title-section">Nuestra Metodología de Reseña</h2>
        <p>
            En <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, nuestra misión es sencilla: guiarte en la selección de la casa de apuestas ideal para tus apuestas en esports. Evaluamos cada sitio basándonos en la facilidad de uso, variedad de mercados de esports y características de seguridad para asegurar tu seguridad al depositar o retirar fondos.
        </p>
        <p>
            También evaluamos las licencias para verificar si la casa de apuestas opera legalmente en tu región. Solo aquellas que cumplan con nuestros altos estándares reciben nuestro sello de aprobación. Presentamos bonificaciones y promociones exclusivas de esports para mejorar aún más tu experiencia de apuestas.
        </p>
        <p>
            Nuestros criterios de reseña abarcan múltiples áreas, incluyendo facilidad de uso, oferta de mercados y métodos de pago. Cada casa de apuestas es evaluada exhaustivamente en ocho categorías principales, cubriendo todo, desde diseño y funcionalidad hasta servicio al cliente. Consulta nuestras reseñas detalladas para explorar el desempeño de cada casa de apuestas en estas áreas críticas.
        </p>
        `,
        "review": `Reseña`,
        "betNow": `APUESTA AHORA`,
        "noBookmakersMsg": `<h2>No hay casas de apuestas disponibles para tu región e idioma.</h2>`
    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>¡Vaya! Parece que estás usando un bloqueador de anuncios.</strong></p>
        <p>Te estás perdiendo de un contenido excelente que tenemos para ti. No son anuncios, son características útiles. Por favor, considera desactivar tu bloqueador de anuncios.</p>
        <button id="close-button">¡Entendido!</button>`
    },
    "discordTemplate": {
        "titleMain": `Necesitas ayuda?`,
        "titleSub": `Únete a nuestro Discord ahora!`,
    },
}

