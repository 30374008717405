import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { PostMatchesComponent } from "../../components/postmatches-component/postmatches-component";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";

export class PostMatchesContainer extends BaseComponent {
    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }
}

PostMatchesContainer.template = /*html*/ `
    <div class="main-content col-lg-8 col-md-12 col-sm-12 col-xs-12 g-0 mt-2 ">
        <div class="home-matches home-components row m-2">
            <div class="home-upcoming-matches">
                <postmatches-component></postmatches-component>
            </div>
        </div>

        <div class="home-latest-news home-components row m-2 d-none d-lg-flex">
            <news-carousel></news-carousel>
        </div>

        <div class="home-tournaments home-components row m-2 d-none d-lg-flex">
            <div class="home-carousel-slider">
                <hottournaments-component></hottournaments-component>
            </div>
        </div>

        <!--
            <div class="home-bookmakers home-components row m-2">
                <bookmakershome-component></bookmakershome-component>
            </div>
        -->
    </div>

    <div class="sidebar col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2 ">
        <div class="sidebar-predictions home-components row m-2 ">
            <div id="placeholder-chat" class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-12 col-xxl-12 placeholder-glow rounded w-100 color-dark">
                <div class="placeholder bg-secondary w-100 rounded my-3" style="height: 700px;">
                </div>
            </div>

            <div class="col-12 d-none rounded d-xl-block mb-3" id="chatforvideo">
            </div>

            <widget-matches class="d-none d-lg-flex"></widget-matches>
            <predictions-homecomponent class="d-none d-lg-flex"></predictions-homecomponent>
        </div>

        <div class="sidebar-discord home-components row m-2 ">
            <div class="home-discord-template">
                <discord-template></discord-template>
            </div>
        </div>

        <div class="sidebar-bonus home-components row m-2 ">
            <div class="home-widget-bookmakers">
                <widget-bookmakers></widget-bookmakers>
                <widget-bookmakers-skin></widget-bookmakers-skin>
            </div>
        </div>
    </div>
`;

PostMatchesContainer.reg('postmatches-container');
