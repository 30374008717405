import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { DiscordTemplate } from "../discord-template/discord-template";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { AnalysesHomecomponent } from "../analyses-homecomponent/analyses-homecomponent";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { AuthorsComponent } from "../author-component/author-component";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";
import { WidgetMatches } from "../widget-matches/widget-matches";
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers";
import { HotmatchComponent } from "../hotmatch-component/hotmatch-component";
import { HottournamentsComponent } from "../hottournaments-component/hottournaments-component";
import { WidgetBookmakersSkin } from "../widget-bookmakers-skin/widget-bookmakers-skin";
import { AdblockComponent } from "../adblockerdetect-component/adblockerdetect-component";

import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";

export class HomeContainer extends BaseComponent {

    initCallback() {

        window.addEventListener('resize', function () {
            // if no resize event is fired within 250ms of the lastly fired one, call the performOrientationChangeActions function :)
            MiscAssistant.debounce(HomeContainer.performOrientationChangeActions, 250)
        }, true)

        RouterAssistant.checkForSingleContainer('home-container', HomeContainer.initiator)
    }

    static initiator() {
        HomeContainer.performOrientationChangeActions();
        GridOrchestrator.activateGameFilters()
    }

    static loadDesktopLayout() {
        let ahrefopenOption = HomeContainer.decideAdsLayer();
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')
            container.removeAttribute('class')
            container.classList.add("main-content", "col-6", "g-0", "mt-2")
            const analysesComponent = document.createElement("analyses-homecomponent");
            analysesComponent.classList.add("row", "m-2");
            container.appendChild(analysesComponent);
            document.querySelector('.sidebar-matches').remove();
            document.querySelector('.sidebar-predictions').remove();
            document.querySelector('.sidebar-discord').remove();
            document.querySelector('.sidebar-bonus').remove();
            // const sidebar1 = document.querySelector('.sidebar1')
            container.insertAdjacentHTML("beforebegin", /*html*/`
                <div class="sidebar1 col-3 mt-2">
                    <div class="sidebar-predictions row m-2">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <!-- our affiliate ad -->
                    <a href="/en/offers/E1RPEMDMgZncpy28STZD" Target="${ahrefopenOption}" class="d-flex justify-content-center mt-4"><img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/affiliateAds%2FScreenshot%202025-01-23%20193225.png?alt=media&token=1b20272a-e91b-469b-b2c6-5835eafb0fe4" width="85%" height="auto" style="object-fit:contain"></a>
                    <div class="sidebar-bonus row m-2">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                    </div>
                    <div class="sidebar-discord row m-2">
                            <discord-template></discord-template>
                    </div>
                </div>
                `)
            container.insertAdjacentHTML("afterend", /*html*/`

                <div class="sidebar2 col-3 mt-2">
                    <div class="sidebar-matches row m-2">
                        <widget-matches></widget-matches>
                    </div>        
                </div>
            `)
        } else {
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`
            <div class="sidebar1 col-3 mt-2">
                <div class="sidebar-predictions row m-2">
                    <predictions-homecomponent></predictions-homecomponent>
                </div>
                <a href="/en/offers/E1RPEMDMgZncpy28STZD" Target="${ahrefopenOption}" class="d-flex justify-content-center mt-4"><img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/affiliateAds%2FScreenshot%202025-01-23%20193225.png?alt=media&token=1b20272a-e91b-469b-b2c6-5835eafb0fe4" width="85%" height="auto" style="object-fit:contain"></a>
                <div class="sidebar-bonus row m-2">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin> </widget-bookmakers-skin>
                </div>                
                <div class="sidebar-discord row m-2">
                        <discord-template></discord-template>
                </div>
            </div>
            <div id="homeContainerInnerContainer" class="main-content col-6 g-0 mt-2">
                <div class="home-matches row m-2">
                    <hotmatch-component></hotmatch-component>
                </div>
                <div class="row m-2">
                    <news-carousel></news-carousel>
                </div>

                <div class="home-tournaments row m-2">
                    <hottournaments-component></hottournaments-component>
                </div>

                <analyses-homecomponent class=" row m-2">
                </analyses-homecomponent>

            </div>
            <div class="sidebar2 col-3 mt-2">               
                <div class="sidebar-matches row m-2">
                    <widget-matches></widget-matches>
                </div>                 
            </div>  
        `)
        }
    }

    static loadMobileLayout() {
        if (document.querySelectorAll('hotmatch-component').length !== 0) {
            const container = document.getElementById('homeContainerInnerContainer')
            container.removeAttribute('class')
            container.removeChild(container.querySelector('analyses-homecomponent'));
            container.classList.add("main-content", "col-12")
            document.querySelector('.sidebar1').remove();
            document.querySelector('.sidebar2').remove();
            document.querySelector('.home-matches').insertAdjacentHTML('afterend',/*html*/ `
                <div class="sidebar-matches row m-2">
                    <widget-matches></widget-matches>
                </div>
                <div class="sidebar-predictions row m-2">
                    <predictions-homecomponent></predictions-homecomponent>
                   <analyses-homecomponent></analyses-homecomponent>
                </div>                
            `)
            document.querySelector('.home-tournaments').insertAdjacentHTML('afterend', /*html*/`
                <div class="sidebar-discord row m-2">
                        <discord-template></discord-template>
                </div>
                <div class="sidebar-bonus row m-2">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin></widget-bookmakers-skin>
                </div>
            `)
        } else {
            document.querySelector('home-container').insertAdjacentHTML('afterbegin', /*html*/`
            <div  id="homeContainerInnerContainer" class="main-content col-12 ">
                <div class="home-matches row m-2">
                        <hotmatch-component></hotmatch-component>
                </div>
                 <div class="sidebar-matches row m-2">
                    <widget-matches></widget-matches>
                </div>
                <div class="sidebar-predictions row m-2">
                    <predictions-homecomponent></predictions-homecomponent>
                    <analyses-homecomponent></analyses-homecomponent>
                </div>                
                <div class="row m-2">
                    <news-carousel></news-carousel>
                </div>
                <div class="home-tournaments row m-2">
                        <hottournaments-component></hottournaments-component>
                </div>
                <div class="sidebar-discord row m-2" >
                        <discord-template></discord-template>
                </div>
                <div class="sidebar-bonus row m-2">
                        <widget-bookmakers></widget-bookmakers>
                        <widget-bookmakers-skin></widget-bookmakers-skin>
                </div>
            </div>
        `)
        }
    }

    static decideAdsLayer() {
        let ahrefopenOption = ''
        if (AdblockComponent.adblockCheck) {
            ahrefopenOption = '_Top'
        } else {
            ahrefopenOption = '_blank'
        }
        return ahrefopenOption
    }

    static performOrientationChangeActions() {
        const isHome = document.querySelector('home-container') !== null;

        if (isHome) {
            const currentState = (window.innerHeight > window.innerWidth) || window.innerWidth < 1024 ? 'mobile' : 'desktop';
            const thereIsContentInside = document.querySelectorAll('hotmatch-component').length !== 0;
            if (!thereIsContentInside && window.homePagePreviousOrientationState !== undefined) {
                window.homePagePreviousOrientationState = undefined
                HomeContainer.performOrientationChangeActions()
            } else {
                if (currentState === 'mobile' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'desktop')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadMobileLayout()
                    window.homePagePreviousOrientationState = currentState
                } else if (currentState === 'desktop' && (window.homePagePreviousOrientationState === undefined || window.homePagePreviousOrientationState === 'mobile')) {
                    console.debug('changing home page state layout')
                    HomeContainer.loadDesktopLayout()
                    window.homePagePreviousOrientationState = currentState
                }
            }
        }
    }
}

HomeContainer.template = /*html*/ `

`;

HomeContainer.reg('home-container');
