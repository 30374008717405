import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { NewsComponent } from "../news-component/news-component";

export class NewsContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }

}

NewsContainer.template = /*html*/ `
<div class="main-content col-12 g-0 mt-2">
  <div class="row m-2">
    <news-component></news-component>
  </div>
</div>
`;

NewsContainer.reg('news-container');