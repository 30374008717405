
import { BaseComponent } from "@symbiotejs/symbiote";

export class PlaceholderMatchesWidget extends BaseComponent {

  initCallback() {
    let matchesHtml = '';
    for (let i = 0; i < 10; i++) {
      matchesHtml += /*html*/`

        <div class="tournament-name skeleton w-100 ${i > 0 ? "mt-3" : ""}"></div>   

        <div class="match-item-placeholder">
          <div class="match-left d-flex flex-column gap-2 align-items-center">
            <div class="team-logo skeleton"></div>
            <div class="team-name skeleton"></div>
            <div class="match-odds skeleton"></div>
          </div>
          <div class="match-center d-flex flex-column gap-2 align-items-center justify-content-center">
            <div class="match-score skeleton"></div>
          </div>
          <div class="match-right d-flex flex-column gap-2 align-items-center">
            <div class="team-logo skeleton"></div>
            <div class="team-name skeleton"></div>
            <div class="match-odds skeleton"></div>
          </div>
        </div>
        `;
    }
    document.getElementById('the-matches-placeholder').innerHTML = matchesHtml;
  }

}

PlaceholderMatchesWidget.template = /*html*/`
<style>

/* Each placeholder row simulates a match line */
placeholder-matcheswidget .match-item-placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1E252E;
  padding: calc(0.5rem + 0.5vw);
  border-radius: 0 0 8px 8px;
}

/* Skeleton base class */
placeholder-matcheswidget .skeleton {
  background:rgb(31, 36, 43); /* Fallback color */
  position: relative;
  overflow: hidden;
}

/* Shimmer effect (animated gradient) */
placeholder-matcheswidget .skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150px; /* Start gradient well off to the left */
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.1) 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

placeholder-matcheswidget .match-left, .match-right {
  width: 25%;
}
placeholder-matcheswidget .match-center {
  width: 35%;
}

placeholder-matcheswidget .team-logo {
  width: calc(1rem + 1vw);
  height: calc(1rem + 1vw);
  border-radius: 50%;
}

placeholder-matcheswidget .team-name {
  width: calc(2rem + 2vw);
  height: calc(0.5rem + 0.5vw);
  border-radius: 4px;
}

placeholder-matcheswidget .tournament-name {
     border-radius: 8px 8px 0 0;
  height: calc(1rem + 0.5vw);
}

placeholder-matcheswidget .match-score {
  width: calc(1rem + 1vw);
  height: 15px;
  border-radius: 4px;
}

placeholder-matcheswidget .match-odds {
  width: calc(1rem + 1vw);
  height: 20px;
  border-radius: 4px;
}

</style>

<div id="placeholder-matches-widget">
    <div id="the-matches-placeholder" class="matches-placeholder d-flex flex-column">
</div>
`;
PlaceholderMatchesWidget.reg('placeholder-matcheswidget');