import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { PredictionComponent } from "../predictions-component/predictions-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
<style>
    
</style>
`

export class PredictionContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.activateGameFilters();
    }
}

PredictionContainer.template = /*html*/ `

<div id="predictionsInnerContainerHeader" class="main-content col-lg-8 col-md-12 col-sm-12 col-xs-12 g-0 mt-2 ">

     <!-- here -->
     <predictions-component></predictions-component>


</div>

 <div class="sidebar col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2 ">

                    <div class="sidebar-matches home-components row m-2 d-none d-lg-flex">
                        <widget-matches></widget-matches>
                    </div>

                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                        </div>
                    </div>
                </div>
`;

PredictionContainer.reg('predictions-container');