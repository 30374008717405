import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { GameNavbar } from "../game-navbar/game-navbar";
import { getDataFrontend } from "../../utilities/firebase-assistant/frontend-transition";
import { gameIdImg, reversegameIdFilter } from "../../utilities/general/gameIdImgMapping";
import { zoomIconsListener } from "../../utilities/general/zoomIconsListener";
import { PlaceholderNewsCarousel } from "../placeholder-matcheswidget/placeholder-newscarousel";

export class NewsCarousel extends BaseComponent {


  initCallback() {
    RouterAssistant.checkForSingleContainer('news-carousel', NewsCarousel.initiator);
    document.querySelectorAll('.newsTitle').forEach((el) => {
      el.innerHTML = I18n.translateString('newsTranslations.newsTitle');
    });
    document.querySelector('.show-more-news-button').insertAdjacentHTML('beforeend', I18n.translateString('newsTranslations.newsSeeAll'));
  }

  static initiator() {
    getDataFrontend('savedNews', 'getNews', 'fromNewsCarousel', function () { // Callback function
      if (document.querySelectorAll('news-carousel').length !== 0)
        NewsCarousel.populateNewsCarousel(GameNavbar.getCurrentlyActivatedGameIfAny());
    });
    GameNavbar.subscribeToGameFilterChanges('fromNewsCarousel', (activeGameFilterId) => {
      if (document.querySelectorAll('news-carousel').length !== 0)
        NewsCarousel.populateNewsCarousel(activeGameFilterId);
    });
  }


  static populateNewsCarousel(activeGameFilterId) {
    const container = document.getElementById('NewsCarouselItemsContainer');
    container.innerHTML = '';

    let count = 0;

    for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {

      if (activeGameFilterId && activeGameFilterId !== 'null' && NewsPostData.game_tag !== activeGameFilterId && NewsPostData.game_tag) continue;
      if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue;
      if (count >= 6) break; // Exit the loop after 6 articles

      const slugUrl = NewsPostData.public_url_greeklish;
      const writtenAtTimestamp = NewsPostData.writtenAt;
      const writtenAtDate = new Date(writtenAtTimestamp.seconds * 1000 + writtenAtTimestamp.nanoseconds / 1000000);
      const formattedDate = writtenAtDate.toLocaleString(I18n.getUsersPreferredLanguage(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });

      container.insertAdjacentHTML('beforeend', /*html*/`
                        <article class="col-6 col-xxl-4">
                            <a href="/${I18n.getUsersPreferredLanguage()}/article/${slugUrl}" class="card mb-2" id="${NewsPostId}">
                                
                                <!-- Game Logo -->
                                <img class="img-game-logo ${gameIdImg[reversegameIdFilter[NewsPostData.game_tag]] ? '' : 'd-none'}" 
                                    src="${gameIdImg[reversegameIdFilter[NewsPostData.game_tag]]}" 
                                    alt="${NewsPostData.game_tag} Logo">
                                
                                <!-- Image Wrapper -->
                                <div class="image-wrapper">
                                <img src="${NewsPostData.article_photo}" 
                                    class="image-for-zoom unzoom" 
                                    alt="${NewsPostData.titles[I18n.getUsersPreferredLanguage()] || NewsPostData.titles[I18n.defaultLocale]}">
                                </div>
                                
                                <!-- Card Body -->
                                <div class="card-body">
                                <div rel="author" class="fw-bold author-link card-text mb-1 d-none d-sm-flex">
                                    ${NewsPostData.linkedAuthor ? NewsPostData.linkedAuthor.charAt(0).toUpperCase() + NewsPostData.linkedAuthor.slice(1) : 'Unknown'}
                                    <time class="news-date ms-1" datetime="${formattedDate}">${formattedDate}</time>
                                </div>
                                <h2 class="card-title h6">
                                    ${NewsPostData.titles[I18n.getUsersPreferredLanguage()] || NewsPostData.titles[I18n.defaultLocale]}
                                </h2>
                                </div>

                            </a>
                            </article>
                        `);

      count++;
      zoomIconsListener(document.querySelectorAll(`[id="${NewsPostId}"]`), ".image-for-zoom");

    }
    document.getElementById('placeholder-news-carousel').style.display = "none"; // Hide the placeholder news carousel
  }
}

NewsCarousel.template = /*html*/`
<style>
news-carousel .container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}



news-carousel .ht-section-title {
  font-size: calc(0.5rem + 1vw);
  color: #f1f1f1;
  position: relative;
}

news-carousel .ht-section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 90%;
  height: 4px;
  background-color: #B22A23;
  border-radius: 2px;
}

news-carousel .ht-btn-back {
        text-decoration: none;
        text-transform: none;
        background-color: transparent;
        border: 1px solid #D3D3D3 !important;
        color: #D3D3D3 !important;
        padding: calc(0.2rem + 0.2vw) calc(0.4rem + 0.4vw);
        border-radius: 8px;
        font-size: calc(0.3rem + 0.3vw);
        transition: background-color 0.3s ease, color 0.3s ease;
}

news-carousel .ht-btn-back:hover {
        background-color: #D3D3D3 !important;
        color: #0D1117 !important;
}

        @media only screen and (max-width: 1024px) {
  news-carousel .ht-section-title {
            font-size: 1rem;
        }
  news-carousel .ht-btn-back {
            font-size: 0.6rem;
        }
    }

news-carousel .card {
  border: none;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

news-carousel .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.6);
}

news-carousel .image-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

news-carousel .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

news-carousel .card .img-game-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(1.5rem + 0.5vw);
  height: calc(1.5rem + 0.5vw);
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
}

news-carousel .card-body {
  padding: calc(0.5rem + 0.2vw) calc(0.75rem + 0.3vw);
  background-color: #1E252E;
}

news-carousel .author-link {
  color: rgba(178, 42, 35, 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

news-carousel .news-date {
  color: #ccc;
  font-size: calc(0.3rem + 0.3vw);
}

news-carousel .card:hover .card-title {
  color: #B22A23;
}

news-carousel .card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc(0.5rem + 0.3vw);
}
</style>

<div class="ht-component container">
  <div class="ht-section-header d-flex justify-content-between align-items-center my-2">
    <h2 class="ht-section-title newsTitle"></h2>
    <a href="/${I18n.getUsersPreferredLanguage()}/news" class="btn btn-outline-secondary btn-sm ht-btn-back show-more-news-button" aria-label="See All">
      <i class="bi bi-arrow-left"></i>
    </a>
  </div>

  <placeholder-newscarousel></placeholder-newscarousel>

  <div id="NewsCarouselItemsContainer" class="row"></div>
</div>
`;

NewsCarousel.reg('news-carousel'); // components should always be 2 words seperated with a dash