import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";
import { PredictionsHomeComponent } from "../predictions-homecomponent/predictions-homecomponent";
import { DiscordTemplateComponent } from "../discord-template/discord-template";
import { WidgetBookmakersComponent } from "../widget-bookmakers/widget-bookmakers";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";

export class PostBookmakersContainer extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }
}

PostBookmakersContainer.template = /*html*/ `

    <div class="main-content col-12 col-lg-8 g-0 mt-2">
                <div class="row m-2">
                        <postbookmakers-component></postbookmakers-component>
                </div>
              </div>

           <div class="sidebar col-12 col-lg-4 mt-2">
                    <div class="row m-2 d-none d-lg-flex">
                        <widget-matches></widget-matches>
                    </div>
                    <div class="row m-2 d-none d-lg-flex">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="row m-2">
                            <discord-template></discord-template>
                    </div>
                    <div class="row m-2">
                            <widget-bookmakers></widget-bookmakers>
                    </div>
                </div>
            
`;

PostBookmakersContainer.reg('postbookmakers-container');
