export function resizetoTwitchHeight(element, fromHotmatch = false) {
    if (element) {
        const width = element.offsetWidth;
        const height = width * (9 / 16);
        element.style.height = `${height}px`;

        // Now, apply this height to the timeline if hotmatch calls
        const timelineHome = document.getElementById('timeline-home');
        if (timelineHome && fromHotmatch) {
            timelineHome.style.height = `${height}px`; // Set the computed height
            timelineHome.style.overflow = 'hidden';
        }
    }
}