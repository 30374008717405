import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { NewsPostComponent } from "../newspost-component/newspost-component";
import { WidgetMatchesComponent } from "../widget-matches/widget-matches";
import { PredictionsHomeComponent } from "../predictions-homecomponent/predictions-homecomponent";
import { WidgetBookmakersComponent } from "../widget-bookmakers/widget-bookmakers";
import { WidgetBookmakersSkinComponent } from "../widget-bookmakers-skin/widget-bookmakers-skin";

export class NewsPost extends BaseComponent {

    initCallback() {
        GridOrchestrator.deactivateGameFilters();
    }

}

NewsPost.template = /*html*/ `
<div class="main-content col-12 col-lg-8 g-0 mt-2">
    <div class="row m-2">
        <newspost-component></newspost-component>
        </div>
    </div>
    <div class="sidebar col-12 col-lg-4 mt-2">
        <div class="row m-2 d-none d-lg-flex">
            <widget-matches></widget-matches>
        </div>

         <div class="row m-2 d-none d-lg-flex">
            <predictions-homecomponent></predictions-homecomponent>
        </div>
        <div class="row m-2">
            <widget-bookmakers></widget-bookmakers>
            <widget-bookmakers-skin></widget-bookmakers-skin>
        </div>
    </div>
`;

NewsPost.reg('news-post');