import { BaseComponent } from "@symbiotejs/symbiote";

export class PlaceholderPostmatch extends BaseComponent {

  initCallback() {
  }

}

PlaceholderPostmatch.template = /*html*/`
<style>
placeholder-postmatch .date-item {
  width: 13%;
  height: calc(2rem + 2vw);
  border-radius: 8px;
}

placeholder-postmatch .search-bar-placeholder {
  width: 95%;
  height: calc(1rem + 2vw);
  border-radius: 6px;
}

placeholder-postmatch .status-button {
  width: calc(2rem + 3vw);
  height: calc(1rem + 1vw);
  border-radius: 18px;
}

placeholder-postmatch .match-item-placeholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1E252E;
  border-radius: 8px;
  padding: calc(0.5rem + 0.5vw);
}

placeholder-postmatch .skeleton {
  background: rgb(31, 36, 43);
  position: relative;
  overflow: hidden;
}

placeholder-postmatch .skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 100%);
  animation: placeholder-postmatch-shimmer 1.5s infinite;
}

@keyframes placeholder-postmatch-shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

placeholder-postmatch .team-logo {
  width: calc(50px + 5vw);
  height: calc(50px + 5vw);
  border-radius: 50%;
}

placeholder-postmatch .team-name {
  width: calc(4rem + 4vw);
  height: calc(0.5rem + 0.5vw);
  border-radius: 4px;
}

placeholder-postmatch .match-score {
  width: calc(2rem + 2vw);
  height: calc(1rem + 1vw);
  border-radius: 4px;
}

</style>

        <!-- Twitch Stream Placeholder -->
        <div class="twitch-container p-3 bg-dark text-white rounded placeholder-glow container mb-4">
          <div class="ratio ratio-16x9 placeholder"></div>
        </div>
        <!-- Match Result Placeholder -->
        <div class="container match-item-placeholder p-5">
          <div class="col-5 d-flex flex-column-reverse gap-2 align-items-center">
            <div class="team-name skeleton"></div>
            <div class="team-logo skeleton"></div>
          </div>
          <div class="col-2 d-flex flex-row gap-2 align-items-center justify-content-center">
            <div class="match-score skeleton"></div>
          </div>
          <div class="col-5 d-flex flex-column-reverse gap-2 align-items-center">
            <div class="team-name skeleton"></div>
            <div class="team-logo skeleton"></div>
          </div>

`;
PlaceholderPostmatch.reg('placeholder-postmatch');