import { globalSocials } from "../../utils/globals.js";

export const englishTranslation =
{
    "translationLanguageInLocal": "English",
    "metaTags": {
        "keyword1": "Esports live stream",
        "keyword2": "Esports tournaments",
        "keyword3": "League of Legends esports",
        "keyword4": "CS:GO / Counter-Strike tournaments",
        "keyword5": "Dota 2 TI (The International)",
        "keyword6": "AI and expert match analysis esports",
        "keyword7": "Esports match predictions and betting tips",
        "keyword8": "Esports news and articles",
        "singleMatchTitleLive": "{{team1}} vs {{team2}} | Live: {{score1}} - {{score2}} | {{game}}: {{tournament}}",
        "singleMatchTitleFinished": "{{team1}} vs {{team2}} | Results: {{score1}} - {{score2}} | {{game}}: {{tournament}}",
        "singleMatchTitleUpcoming": "Upcoming: {{team1}} vs {{team2}} | {{game}}: {{tournament}}",
        "singleMatchTitleFallback": "{{team1}} vs {{team2}} | {{game}}: {{tournament}}",
        "singleMatchDescriptionLive": "{{game}}: {{teamA}} {{score1}}-{{score2}} {{teamB}} in {{tournament}}. Watch live now! Match started on {{date}}.  Get stats, predictions and AI analysis on MyEsports!",
        "singleMatchDescriptionFinished": "{{game}}: {{teamA}} faced {{teamB}} in {{tournament}}. Match ended on {{date}} with {{score1}} - {{score2}} result. Get stats, predictions and AI analysis on MyEsports!",
        "singleMatchDescriptionUpcoming": "{{game}}: {{teamA}} faces {{teamB}} in {{tournament}}. Match starts on {{date}}. Get stats, predictions and AI analysis on MyEsports! Watch the upcoming match live here!",
        "singleMatchDescriptionFallback": "{{game}}: {{teamA}} faces {{teamB}} in {{tournament}}. Match starts on {{date}}. Get stats, predictions and AI analysis on MyEsports! Watch live!",
        "singleTournamentTitle": "{{game}} Tournament – {{tournament}} | MyEsports",
        "singleTournamentDescription": "Explore the latest matches and results of the {{tournament}} on MyEsports! Tier {{tier}} {{game}} tournament featuring {{opponents}} and more!",
        "singleAuthorTitle": "{{author}} - MyEsports Author",
        "matchesTitle": "Esports Matches – Live Scores & Results | MyEsports",
        "matchesDescription": "Stay updated with the latest esports match results & upcoming games on MyEsports. Get live scores, schedules, analyses, tips & team stats across CS2, Dota 2, LoL & more!",
        "tournamentsTitle": "Esports Tournaments – Upcoming & Past Events | MyEsports",
        "tournamentsDescription": "Discover the biggest esports tournaments on MyEsports! Get schedules, results, prize pools & team standings for CS2, Dota 2, LoL, Valorant & more!",
        "newsTitle": "Esports News & Articles – Latest Updates & Insights | MyEsports",
        "newsDescription": "Stay up to date with the latest esports news, articles & insights on MyEsports! Get updates on CS2, Dota 2, LoL, Valorant & more, plus expert analysis & interviews!",
        "authorsTitle": "Meet Our Esports Authors – News, Predictions & Tips | MyEsports",
        "authorsDescription": "Discover the experts behind MyEsports! Meet our esports writers bringing you news, in-depth articles & news, predictions & tips for CS2, Dota 2, LoL & more!",
        "bookmakersTitle": "Top 10 Esports Betting Sites(Casinos) – Trusted Affiliates & Offers | MyEsports",
        "bookmakersDescription": "Explore MyEsports' trusted betting partners! Find top esports betting sites with exclusive offers, bonuses & secure platforms for CS2, Dota 2, LoL & more!",
        "skinbookmakersTitle": "Esports Skin Trading – Trusted Partners for Skins & Cases | MyEsports",
        "skinbookmakersDescription": "Discover MyEsports' trusted skin trading partners! Buy, sell, trade & bet skins and cases safely for CS2, Dota 2, Rust & more. Find the best marketplaces & exclusive deals!",
        "predictionsTitle": "Esports Predictions & Betting Tips – Expert Analysis | MyEsports",
        "predictionsDescription": "Get expert esports betting predictions & tips on MyEsports! Find accurate match analysis from our experts and AI, odds insights & winning strategies for CS2, Dota 2, LoL & more!",
        "termsOfUseTitle": "Terms of Use – Rules & User Agreement | MyEsports",
        "termsOfUseDescription": "Read the Terms of Use for MyEsports. Learn about user agreements, rules, and policies for using our platform, including esports content, betting affiliates & more.",
        "privacyPolicyTitle": "Privacy Policy – Data Protection & Security | MyEsports",
        "privacyPolicyDescription": "Learn how MyEsports protects your data. Read our Privacy Policy on user information, security measures & compliance with GDPR for a safe experience.",
        "faqTitle": "FAQ – Frequently Asked Questions & Support | MyEsports",
        "faqDescription": "Find answers to common questions about MyEsports. Read our FAQ for esports coverage, betting affiliates, predictions, skin trading & more support topics.",
        "streamersTitle": "Esports Streamers – Live Channels & Gaming Content | MyEsports",
        "streamersDescription": "Discover top esports streamers from around the world on MyEsports! Watch live gameplay, pro player streams & exclusive content from our partnered creators.",
        "homeTitle": "MyEsports – Your Ultimate Esports Hub for News, Matches & Betting tips",
        "homeDescription": "Stay ahead in the esports world with MyEsports! Get the latest news, match results, live scores, betting tips, predictions, tournament updates for CS2, Dota 2, LoL & more!",
    },
    "homePage": {
        "latestAnalyses": 'LATEST ΑΙ ANALYSES'
    },

    "networkGuard": {
        "title": "Oops!",
        "description": "The page you are looking for does not exist!",
    },
    "navMainMenu": {
        "Home": "Home",
        "Tournaments": "Tournaments",
        "Matches": "Matches",
        "Giveaways": "Giveaways",
        "Casinos": "Bookmakers",
        "AboutUs": "About us",
        "Streamers": "Streamers",
        "News": "News",
        "Predictions": "Predictions",
        "navDesktopTitle": "MyEsports",
    },
    "hotmatchTranslations": {
        "playerNationalities": "Players from {{countries}} compete",
        "playerAges": "The youngest player is {{Age}} years old",
        "viewers": "{{maxviewers}} watching now!",
        "alternativeTimeLineHours": "Game {{RGN}} ongoing. Started {{hourz}} hour(s) and {{minutez}} minute(s) ago",
        "alternativeTimeLineMinutes": "Game {{RGN}} ongoing. Started {{minutez}} minute(s) ago",
        "badgeHot": `<i class="me-2 bi bi-fire"></i>HOT`,
        "noLiveMsg": `<h2>Hang tight, action is just around the corner!</h2><p>Looks like the {{game}} action is taking a breather.</p>`,
    },
    "matchTranslations": {
        "title": "{{team1}} vs {{team2}} | {{tournament}}",
        "description": "Watch {{teamA}} take on {{teamB}} for the {{tournament}} on MyEsports | Check the AI analysis and what our tipsters have to say for this match!",
        "h2": "This is the sub-paragraph of a match",
        "body": "This is the main content of a match",
        "matchMatchUpcoming": `{{minutes}} mins left`,
        "matchMatchLive": `LIVE`,
        "matchMatchFinished": `Finished`,
        "matchMatchPostponed": `Postponed`,
        "matchMatchCanceled": `Canceled`,
        "matchMatchAboutTo": `About to start`,
        "homeMatchDHM": `{{days}}d {{hours}}h {{minutes}}m`,
        "homeMatchHM": `{{hours}}h {{minutes}}m`,
        "homeMatchM": `{{minutes}}m`,
        "homeMatchLive": `LIVE`,
        "homeMatchAboutTo": `Starting`,
        "pillLive": "LIVE",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillPredictions": "PREDICTIONS",
        "pillAll": "ALL",
        "pillFavorites": `<i class="bi bi-star-fill" alt="fav-tab"d></i> FAVORITES`,
        "bestofX": "Best of {{gamesNumber}}",
        "bestof1": "Best of 1",
        "bestof2": "Best of 2",
        "allGamesPlayed": "{{gamesNumber}} games",
        "allGamesPlayedMicro": "GP{{gamesNumber}}",
        "firstToX": "First to {{gamesNumber}}",
        "showMoreMatches": "View More",
        "emptyGameFilterMessage": "Νo available {{game}} matches at the moment.",
        "emptyStateFilterMessage": "Νo available {{game}} matches with the selected filters.",
        "emptyPredictionFilterMessage": "No available {{predGame}} predictions for the selected date.",
        "emptyAnalysisFilterMessage": "No recent analyses {{game}}",
        "searchPlaceholder": "Search for specific tournaments or teams...",
        "boN": "BO{{numberOfGames}}",
        boNCalculation: function (numberOfGames) { return numberOfGames },
    },
    "postMatchTranslations": {
        "aiTranslationTitle": "AI-Powered Match Analysis",
        "tipTranslationTitle": "Tips from the Experts",
        "team1players": "Players",
        "team2players": "Players",
        "ageLabel": "Age: ",
        "positionLabel": "Position: ",
        "h2hNoRecentMsg": "No recent matches between these teams",
        "matchHistory": "Match History",
        "h2hPill": "Head to Head",
        "badgeWon": "W",
        "badgeLost": "L",
        "badgeDraw": "D",
        "noVideoMessage": "No video available for this match",
        "analysis": "Our 'PlayPulse' AI will analyse this match within 24 hours before kickoff. Please check back closer to the match start time for the full analysis!",
        "whatDoesAiThink": "AI-Powered Insights on This Match:",
        "aiAnalysis": "AI ANALYSIS",
        "betNow": "Bet Now",
        "oddsDraw": "Draw",
        "predictToWin": "to win",
        "predictDraw": "Draw",
        "predictExactScore": "Exact score",
        "predictOverUnder": "games",
    },
    "newsTranslations": {
        "noRecentNewsMsg": `<h2>No Recent Articles</h2>
      <p>Unfortunately, there are no {{newsGame}} recent articles.</p>`, // archive
        "newsTitle": "ARTICLES",
        "writtenAt": "Written at",
        "footerArticle": "🔥 Enjoyed the Article? 🔥",
        "footerArticleFollow": "Don't miss out! Follow us on social media for more exciting updates!",
        "newsSeeAll": "View All",
        "seeMore": "See More...",
        "goBack": "Go back to News",
        "tournamentNews": "Tournaments",
        "rosterNews": "Rosters",
        "gameNews": "General",
    },
    "footer": {
        "about": "Welcome to MyEsports, your comprehensive destination for all eSports enthusiasts. Born from a deep love and passion for the gaming world, we've created a space where the eSports community can find everything: from live broadcasts of matches and events to predictions, analyses, and articles for all kinds of games.",
        "followUs": "Follow Us",
        "usefulLinks": "Useful Links",
        "contactUs": "Contact Us",
        "aboutUs": "About Us",
        "eula": "Terms of Use",
        "privacy": "Privacy Policy",
        "faq": "Frequently Asked Questions",
        "Authors": "Authors",
    },
    "tournamentsTranslations": {
        "noDateMsg": "Ending date TBD",
        "tier": "Tier",
        "emptyTournamentFilterMessage": "No available {{game}} tournaments with the selected status filters.",
        "pillOngoing": "ONGOING",
        "pillUpcoming": "UPCOMING",
        "pillFinished": "FINISHED",
        "pillAll": "ALL",
        "tournamentLive": `<span class="my-2 badge bg-danger text-resize-badge"><i class="me-1 me-md-2 bi bi-broadcast"></i>Ongoing</span>`,
        "tournamentFinished": `<span class="my-2 badge bg-secondary text-resize-badge">Finished</span>`,
        "tournamentUpDHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} days</span>`,
        "tournamentUp1D": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{days}} day</span>`,
        "tournamentUpHM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{hours}}h {{minutes}}m</span>`,
        "tournamentUpM": `<span class="my-2 badge bg-primary text-resize-badge">Starts in {{minutes}}m</span>`,
        "noPrizePoolAvailable": "N/A",
        "euroPrizePool": "€",
        "usdPrizePool": "$",
        "teamsCompete": "Τeams compete",
        "hotTournamentsTitle": "HOT TOURNAMENTS",
        "hotTournamentFixedLabel": `ONGOING`,
        "noHotTournamentsMsg": `<h2>No Live Tournaments Currently</h2><p>Unfortunately, there are no {{game}} live tournaments currently.</p>`,
        "noHotTournamentsButton2": "View All",
        "clickHotTournaments": "Click to view more information.",
    },
    "postTournamentTranslations": {
        "teamsCompetePost": `Teams Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "playersCompetePost": `Players Compete: <span class="text-resize-tournament-info-right text-white">{{teams}}</span>`,
        "noPrizePoolAvailablePost": `Prizepool: <span class="text-resize-tournament-info-right text-white">-</span>`,
        "euroPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}€</span>`,
        "usdPrizePoolPost": `Prizepool: <span class="text-resize-tournament-info-right text-white">{{money}}$</span>`,
        "tierPost": "Tier:",
        "winnerPost": "Tournament Winner:",
        "startPost": "Start Date:",
        "endPost": "End Date:",
        "noDateMsgPost": "Ending date TBD",
        "ladderWins": "Wins",
        "ladderLosses": "Losses",
        "ladderTies": "Ties",
        "ladderGamesWon": "Games Won",
        "ladderGamesLost": "Games Lost",
        "ladderTieBreaker": "Tiebreakers",
        "upperBracket": "Upper Bracket",
        "lowerBracket": "Lower Bracket",
        "grandFinal": "Grand Final",
        "semiFinal": "Semi Final",
        "quarterFinal": "Quarter Final",
        "roundBrackets": "Round",
        "matchBrackets": "Match",
        "laddersMatchesTitle": "Matches",
        "week": "Week",
        "liveWeek": "LIVE",

    },
    "predictionsTranslations": {
        "predTitle": "PREDICTIONS",
        "comingSoon": "Coming soon",
        "today": "Today",
        "title": "Esports Predictions",
        "subtitle": "At MyEsports, we study each match closely to bring you the best betting tips in esports. Our experts analyze the teams, players, and strategies to help you make the best betting decisions.",
        "time": "Time",
        "date": "Date",
        "match": "Match",
        "tournament": "Tournament",
        "tipster": "Tipster",
        "betButton1": "Place your bet now on ",
        "odd": "@",
        "noPredictionForThisDate": "No prediction for this date yet, come back later.",
        "statsAvgOdds": "AVG Odds",
        "statsEarn": "Earned",
        "statsBets": "Bets",
        "statsOpenBets": "Open Bets",
        "authStats": "Prediction Statistics",
        "articles": "Articles",
        "last10": "Recent predictions",
        "authBio": "Biography",
        "bioComing": "Biography coming soon",
        "showMorePredictions": "Show More",
        "EarnExplain": "The profits are calculated based on the predictions that have been closed. Each tipster starts from 0, with each correct prediction adding the earned odds x1 to their total profit. For example, in a correct prediction with odds of 1.80, the tipster's profit increases by 0.80. In the case of an incorrect prediction, 1.00 is subtracted from the total profit.",
        "WinrateExplain": "The win rate is calculated based on the predictions that have been closed. It is the percentage of correct predictions out of the total number of predictions made.",
        "RoiExplain": "The ROI (Return on Investment) is calculated based on the tipster's profits in relation to their total bets. Essentially, the ROI represents the tipster's net profit. For example, if they have placed 50 bets with one unit each and the ROI is 20%, then they have won 10 units.",
        "AvgOddsExplain": "The average odds are calculated based on the odds of the predictions that have been closed. It is the sum of the odds divided by the total number of predictions.",
        "plainPrediction": "Prediction",
        "statisticPeriod": "Period",
        "alltimeStats": "All-Time",
    },
    "banners": {
        "desktopLeft": 'en_desktop_left',
        "desktopRight": 'en_desktop_right',
    },
    "streamerTranslations": {
        "cardFollowers": "Followers",
        "cardInfo": `{{user}} is {{status}} at the moment.`,
        "cardViewers": "Viewers!",
        "detailsInformation": "Streamer Information",
        "detailsName": "Full Name",
        "detailsNationality": "Nationality",
        "detailsAge": "Age",
        "detailsGame": "Games",
        "detailsRole": "Role",
        "detailsTeam": "Team",
        "detailsSignature": "Signature Hero",
        "accordionHowTo": "<strong>-How can I become a streamer on MyEsports?</strong>",
        "accordionHowToReply": `To become a streamer on MyEsports, you can apply by joining our <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team or fill this <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_Rzx7ZW1S-wdS4WKkp5bk2lNoaR-6WGDHGQeAIL_ZECol6g/viewform?usp=sf_link" alt="google form" target="_blank" rel="noopener noreferrer">form</a>.`,
        "accordionEarn": `<strong>-What do I earn by becoming a streamer on MyEsports?</strong>`,
        "accordionEarnReply": `Being presented on MyEsports will give you the opportunity to increase your audience and grow your channel. You will also have the chance to be featured in our articles and social media.`,
        "accordionGraphics": `<strong>-Will I receive graphics for my channel?</strong>`,
        "accordionGraphicsReply": `Yes, we will provide you with graphics for your channel, as well as support and guidance on how to use them and improve your streaming experience.`,
        "accordionHowToStream": `<strong>-I am new to streaming, can I still join? How do i start?</strong>`,
        "accordionHowToStreamReply": `Of course! We welcome streamers of all levels. You can start by joining our <a href="${globalSocials.discord}" target="_blank" rel="noopener noreferrer">Discord server</a> and contacting our team for more information.`,
        "accordionViewers": `<strong>-How many viewers do I need to have to join?</strong>`,
        "accordionViewersReply": `We welcome streamers of all sizes. Whether you have 1 or 10,000 viewers, you are welcome to join MyEsports!`,
    },
    "authorsTranslations": {
        "title": 'Discover the MyEsports Author Team',
    },

    "bookmakersPostTranslations": {
        "odds": "Odds",
        "varietyOfEsports": "Variety of Esports",
        "liveSupport": "Live Support",
        "liveStreaming": "Live Streaming",
        "payments": "Payments",
        "fullReview": "Full Review",
        "esports": "Esports",
        "register": "BET NOW"
    },
    "bookmakersSkinPostTranslations": {
        "fullReview": "Full Review",
        "register": "BET NOW"
    },
    "bookmakersSkinList": {
        "widgetTitle": "Skin Platforms",
        "widgetButton": "More",
        "playNow": "Play!",
        "introSection": `<h2 class="title-section">Top Esports Betting Sites to Join in 2024: Discover the Best Esports Bookmaker Skins</h2>
            <p>
            With the explosive growth of esports, leading betting skins have risen to meet demand, offering extensive markets for popular games like Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, and Call of Duty. These games bring a continuous stream of thrilling tournaments and leagues, providing bettors with diverse options – from straightforward match winners to game-specific bets.            </p>
            <p>
            At <a href=\"https://myesports.gg\" class=\"text-a-style\">myesports.gg</a>, we’ve carefully reviewed a range of top esports betting skin platforms to help you find the perfect skin for your betting needs.            </p>`,
        "sectionTitle": "Where to Bet on Esports: Best Esports Betting Skins & Bonuses",
        "pleaseBetResponsibly": "Please Bet Responsibly",
        "reviewMethodology": `
                <h2 class="title-section">Our Review Methodology</h2>
        <p>
        At <a class=\"text-a-style\" href=\"https://myesports.gg\">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker skin for your esports betting. We evaluate each skin based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.
        </p>
        <p>
        We also assess licensing to verify if the bookmaker skin operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.        </p>
        <p>
        Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker skin is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker skin’s performance in these critical areas.        </p>
        `,
        "review": "Review",
        "betNow": "BET NOW",
        "noBookmakerSkinsMsg": `<h2>No skins platforms available for your region and language.</h2>`,
    },
    "bookmakersList": {
        "widgetTitle": "Βοοkmakers",
        "widgetButton": "More",
        "playNow": "Play!",
        "introSection": `<h2 class="title-section">Top Esports Betting Sites to Join in 2024: Discover the Best Esports Bookmakers</h2>
            <p>
            With the explosive growth of esports, leading betting sites have risen to meet demand, offering extensive markets for popular games like Counter- Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, and Call of Duty.These games bring a continuous stream of thrilling tournaments and leagues, providing bettors with diverse options – from straightforward match winners to game - specific bets.
            </p>
            <p>
            At <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, we’ve carefully reviewed a range of top esports betting platforms to help you find the perfect site for your betting needs.
            </p>`,
        "sectionTitle": "Where to Bet on Esports: Best Esports Betting Sites & Bonuses",
        "pleaseBetResponsibly": "Please Bet Responsibly",
        "reviewMethodology": `
                <h2 class="title-section">Our Review Methodology</h2>
        <p>
            At <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker for your esports betting. We evaluate each site based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.
        </p>
        <p>
            We also assess licensing to verify if the bookmaker operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.
        </p>
        <p>
            Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker’s performance in these critical areas.
        </p>
        `,
        "review": "Review",
        "betNow": "BET NOW",
        "noBookmakersMsg": `<h2>No bookmakers available for your region and language.</h2>`,

    },
    "adblockDetector": {
        "adblockParagraph": `<p><strong>Oops! It looks like you're using an ad blocker.</strong></p>
        <p>You're missing out on some great content we have for you. It's not ads, but helpful features. Please consider disabling your ad blocker.</p>
        <button id="close-button">Got it!</button>`,
    },
    "discordTemplate": {
        "titleMain": "Need Help?",
        "titleSub": "Join our Discord now!",
    },
}

