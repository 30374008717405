import barba from '@barba/core';
// import barbaPrefetch from '@barba/prefetch';
import gsap from "gsap";

import { LoadingSplasher } from "../../components/loading-splasher/loading-splasher.js";
import { GameNavbar } from "../../components/game-navbar/game-navbar";
import { MainNavbar } from "../../components/main-navbar/main-navbar";
import { I18n } from "../i18n/i18n";



// this crap (curtainElement) is to handle safari bs
const curtainElement = `<div id="curtain" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: #212529; opacity: 0;display: none"></div>`;

export class RouterAssistant {
    static initiate() {
        this.startBarba();
        GameNavbar.checkUrlToActivateFilters()
        MainNavbar.checkNavBarState()
        RouterAssistant.checkForHomePageRedirect()
    }

    static startBarba() {
        document.body.insertAdjacentHTML("beforeend", curtainElement)
        // barba.use(barbaPrefetch);
        barba.init({
            preventRunning: true,
            prefetchIgnore: true,
            transitions: [
                {
                    name: 'default',
                    leave(data) {
                        console.log('running leave')
                        RouterAssistant.activateCurtain(1) // this crap is to handle safari bs
                        return gsap.to(data.current.container, {
                            opacity: 0,
                            display: 'none',
                            duration: 0.3,
                        });
                    },
                    enter(data) {
                        GameNavbar.checkUrlToActivateFilters()
                        MainNavbar.checkNavBarState()
                        return gsap.from(data.next.container, {
                            opacity: 0,
                            duration: 0.3,
                        });
                    },
                    after(data) {
                        document.querySelector('h1').innerText = document.title;
                        document.querySelector('[data-barba="container"]').classList.add('row')
                        document.querySelector('[data-barba="container"]').classList.add('g-0')
                        document.getElementById('gameFiltersContainer').scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
                        RouterAssistant.deactivateCurtain() // this crap is to handle safari bs
                    }
                },
            ],
            prevent: ({ el, href, event }) => {
                // Allow Barba to handle internal links only
                const isSelf = href === window.location.href // Same as current URL
                const isExternal = href.startsWith('http') && !href.includes(window.location.host); // External links
                const isHash = href.startsWith('#') || href.startsWith('mailto'); // Same-page anchor links
                const currentUrlParams = new URLSearchParams(window.location.search); // Current URL parameters
                const nextUrl = new URL(href, window.location.origin); // Parse next URL to manipulate parameters
                const nextUrlParams = new URLSearchParams(nextUrl.search); // Next URL parameters

                // If there are parameters in the current URL and a click event is triggered
                if (currentUrlParams.toString() && event.type === 'click') {
                    event.preventDefault();

                    // Merge current parameters with next parameters (nextUrlParams)
                    currentUrlParams.forEach((value, key) => {
                        if (!nextUrlParams.has(key) && key === "game") {  // Add only if the key doesn't exist
                            nextUrlParams.append(key, value);
                        }
                    });

                    // Update the next URL with merged parameters and navigate using Barba
                    nextUrl.search = nextUrlParams.toString();
                    barba.go(nextUrl.toString());
                }

                if (isSelf) event.preventDefault(); // Prevent same URL from reloading 

                return isExternal || isHash || currentUrlParams.toString() || isSelf;
            },
        });
        window.router = barba
    }

    static activateCurtain(activationOpacity, zIndexOverride = 1054) {
        LoadingSplasher.activateSplasher('');

        document.getElementById('curtain').style.zIndex = `${zIndexOverride}`;
        gsap.to(document.getElementById('curtain'), {
            opacity: activationOpacity,
            display: ''
        });
    }

    static deactivateCurtain() {
        LoadingSplasher.deactivateSplasher();
        gsap.to(document.getElementById('curtain'), {
            opacity: 0,
            display: 'none'
        });
    }

    static addOrUpdateCurrentPageVariable(key, value) {
        let current = window.location.href.replace(window.location.origin, '');

        // Check if there are any existing query parameters in the URL
        const hasQueryParams = current.includes('?');

        if (current.includes(key)) {
            const afterSplit = current.split(key);
            const afterKey = afterSplit[afterSplit.length - 1];
            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0];
                window.history.replaceState(null, null, current.replace(`${key}${afterKey2}`, `${key}=${value}`));
            } else {
                window.history.replaceState(null, null, current.replace(`${key}${afterKey}`, `${key}=${value}`));
            }
        } else {
            // If there are query parameters, use '&', otherwise use '?'
            const separator = hasQueryParams ? '&' : '?';
            window.history.replaceState(null, null, `${current}${separator}${key}=${value}`);
        }
    }

    static removeCurrentPageVariable(key) {
        let current = window.location.href.replace(window.location.origin, '')

        if (current.includes(key)) {
            const afterSplit = current.split(key)
            const afterKey = afterSplit[afterSplit.length - 1]

            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0]
                if (!current.includes(`?${key}`)) { // if the key is first after the '?', there is no '&' after the reload
                    window.history.replaceState(null, null, current.replace(`&${key}${afterKey2}`, ''));
                }
                else if (current.includes(`${afterKey2}&`)) {
                    window.history.replaceState(null, null, current.replace(`${key}${afterKey2}&`, ''))
                }
                else {
                    window.history.replaceState(null, null, current.replace(`${key}${afterKey2}`, ''));
                }
            } else {
                if (current.includes(`&${key}`)) {
                    window.history.replaceState(null, null, current.replace(`&${key}${afterKey}`, ''));
                } else {
                    const newUrl = current.replace(`${key}${afterKey}`, '');
                    const cleanUrl = newUrl.includes('?') ? newUrl.replace('?', '') : newUrl;
                    window.history.replaceState(null, null, cleanUrl);
                }
            }
        }
    }

    static getCurrentVariableValue(key) {
        let current = window.location.href.replace(window.location.origin, '')
        if (current.includes(key)) {
            const afterSplit = current.split(key)
            const afterKey = afterSplit[afterSplit.length - 1]
            if (afterKey.includes('&')) {
                const afterKey2 = afterKey.split('&')[0]
                return afterKey2.replace('=', '')
            } else {
                return afterKey.replace('=', '')
            }
        } else return null
    }

    static checkForSingleContainer(element, callback) {
        // console.debug(document.querySelectorAll(element).length)
        if (document.querySelectorAll(element).length === 2) {
            setTimeout(function () {
                RouterAssistant.checkForSingleContainer(element, callback)
            }, 10)
        } else {
            callback()
        }
    }

    static checkForHomePageRedirect(element, callback) {
        if (window.location.pathname === '/') {
            window.router.go(`${I18n.routerGetsLanguage()}`)
        }
    }
}






