/*eslint no-undef: "off"*/
import Translator from '@andreasremdt/simple-translator';
const { translations, defaultLocale } =
    require("../../../functions/across-stack/i18n/translation-imports");

window.i18n = new Translator({
    defaultLanguage: defaultLocale,
    detectLanguage: false,
    selector: "[data-i18n]",
    debug: false,
    registerGlobally: "__",
    persist: false,
    persistKey: "preferred_language",
    filesLocation: "../../../functions/web-server/i18n/translations"
});

Object.entries(translations).forEach(([langCode, translation]) => {
    window.i18n.add(langCode, translation);
});

export class I18n {

    static defaultLocale = defaultLocale;

    static getCurrentlySupportedLanguages() {
        return Array.from(window.i18n.languages.keys())
    }

    static translateString(key, replacements) {
        let translation = window.i18n.translateForKey(key, I18n.getLanguageFromUrl());
        if (!translation) {
            console.log(`No translation found for key: ${key}`);
        }
        if (replacements) {
            for (let placeholder in replacements) {
                translation = translation.replace(`{{${placeholder}}}`, replacements[placeholder]);
            }
        }
        return translation;
    }

    static getLanguageFromUrl() {
        // e.g. "/en/match/foo"
        const path = window.location.pathname;

        // Trim any leading/trailing slashes: "en/match/foo"
        const trimmedPath = path.replace(/^\/+|\/+$/g, '');

        // Split on "/": ["en", "match", "foo"]
        const segments = trimmedPath.split('/');

        // The first segment is your language
        return segments[0] || I18n.routerGetsLanguage();
    }

    static routerGetsLanguage() {
        const userLang = window.navigator.language.split("-")[0]
        return I18n.getCurrentlySupportedLanguages().includes(userLang) ? userLang : window.i18n.defaultLanguage;
    }

    static getUsersPreferredLanguage() {
        return I18n.getLanguageFromUrl();
    }

}


