import { BaseComponent } from "@symbiotejs/symbiote";
import { MainNavbar } from "../main-navbar/main-navbar";
import { GameNavbar } from "../game-navbar/game-navbar";
import { FooterContainer } from "../footer-container/footer-container";
import { LivestreamerComponent } from "../livestreamer-component/livestreamer-component";
import { ScrollToTop } from "../scrolltotop-component/scrolltotop-component";
import { I18n } from "../../utilities/i18n/i18n";
import { AdblockComponent } from "../../components/adblockerdetect-component/adblockerdetect-component.js";
import { HomeContainer } from "../all-containers/home-container.js";
import { PostMatchesContainer } from "../all-containers/postmatches-container";
import { MatchesContainer } from "../all-containers/matches-container.js";
import { PredictionContainer } from "../all-containers/predictions-container.js";
import { TournamentsContainer } from "../all-containers/tournaments-container.js";
import { StreamerContainer } from "../all-containers/streamers-container.js";
import { NewsContainer } from "../all-containers/news-container.js";
import { AuthorsContainer } from "../all-containers/authors-container.js";
import { CasinosContainer } from "../all-containers/casinos-container.js";
import { BookmakersSkinContainer } from "../all-containers/bookmakers-skin-container.js";
import { NewsPost } from "../all-containers/news-post.js";
import { PostTournamentsContainer } from "../all-containers/posttournaments-container.js";
import { PostAuthorContainer } from "../all-containers/postauthor-container.js";
import { PostBookmakersContainer } from "../all-containers/postbookmakers-container.js";
import { PostBookmakersSkinContainer } from "../all-containers/postbookmakersskin-container.js";
import { NetworkGuardContainer } from "../all-containers/notfound-page.js";
import { globalSocials } from "../../../functions/across-stack/utils/globals";


const desktopBannersPerLanguage = {}
const desktopBannersRedirectionsPerLanguage = {}

desktopBannersPerLanguage['el_desktop_left'] = require(`./assets/elLeft.webp`)
desktopBannersPerLanguage['el_desktop_right'] = require(`./assets/elRight.webp`)
desktopBannersPerLanguage['en_desktop_left'] = require(`./assets/enLeft.webp`)
desktopBannersPerLanguage['en_desktop_right'] = require(`./assets/enRight.webp`)

desktopBannersRedirectionsPerLanguage['el_desktop_left'] = `${globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['el_desktop_right'] = `${globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['en_desktop_left'] = `${globalSocials.discord}`
desktopBannersRedirectionsPerLanguage['en_desktop_right'] = `${globalSocials.discord}`

export class GridOrchestrator extends BaseComponent {

    // for the grid orchestrator to work, the router has been edited so that the barba-container get
    // the class 'row g-0' appended to it every time it changes because it is not feasible otherwise

    initCallback() {
        window.addEventListener('resize', GridOrchestrator.performOrientationChangeActions);
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('container')
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('m-0')
        document.getElementsByTagName('grid-orchestrator')[0].classList.add('p-0')
        // addEventListener("scroll", GridOrchestrator.checkForFooterMagic);
        // GridOrchestrator.performOrientationChangeActions();
        // GridOrchestrator.setInitialMaxHeightToGameFiltersContainer()
        // GridOrchestrator.showAppropriateBannersBasedOnLanguage()
    }

    static initiate() {
        document.body.insertAdjacentHTML('afterbegin', '<grid-orchestrator></grid-orchestrator>');
        // GridOrchestrator.attachEventListeners();
    }

    static attachEventListeners() {
        document.querySelectorAll('.side-advertisement').forEach(el => {
            el.addEventListener('click', function (evt) {
                if (evt.target.getAttribute('data-advert') === 'left')
                    window.open(desktopBannersRedirectionsPerLanguage[I18n.translateString('banners.desktopLeft')], 'leftFromMyEsports').focus()
                else
                    window.open(desktopBannersRedirectionsPerLanguage[I18n.translateString('banners.desktopRight')], 'rightFromMyEsports').focus()
            })
        })
    }

    static showAppropriateBannersBasedOnLanguage() {
        document.querySelector('[data-advert="left"]').style.backgroundImage = 'url("' + desktopBannersPerLanguage[I18n.translateString('banners.desktopLeft')] + '")';
        document.querySelector('[data-advert="right"]').style.backgroundImage = 'url("' + desktopBannersPerLanguage[I18n.translateString('banners.desktopRight')] + '")';
    }

    static performOrientationChangeActions() {
        document.querySelectorAll('.side-advertisement').forEach(el => {
            el.style.top = `${document.getElementById('menusWrapper').offsetHeight}px`
            el.style.maxHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight - 10}px`
            el.style.minHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight - 10}px`
        })
        if (window.innerHeight > window.innerWidth) {
            document.getElementById('contentColumn').classList.remove('col-10')
            document.getElementById('contentColumn').classList.remove('offset-1')
            document.getElementById('contentColumn').classList.add('col-12')
            document.querySelectorAll('side-advertisement').forEach(el => {
                el.classList.add('d-none')
            })
            document.getElementById('outsideMenus').style.marginTop = `0px`
            document.getElementById('outsideMenus').style.marginBottom = ``
            document.getElementById('outsideMenus').style.maxHeight = `${window.innerHeight - document.getElementById('menusWrapper').offsetHeight}px`
            document.getElementById('outsideMenus').style.overflowY = `scroll`
        } else {
            document.getElementById('contentColumn').classList.add('col-10')
            document.getElementById('contentColumn').classList.add('offset-1')
            document.getElementById('contentColumn').classList.remove('col-12')
            document.querySelectorAll('side-advertisement').forEach(el => {
                el.style.display = ''
            })
            document.getElementById('outsideMenus').style.marginTop = `${document.getElementById('menusWrapper').offsetHeight}px`
            document.getElementById('outsideMenus').style.marginBottom = `0px`
            document.getElementById('outsideMenus').style.maxHeight = ``
            document.getElementById('outsideMenus').style.overflowY = ``

        }
    }

    static setInitialMaxHeightToGameFiltersContainer() {
        document.getElementById('gameFiltersContainer').style.maxHeight = `${document.getElementById('gameFiltersContainer').scrollHeight}px`
    }

    static checkForFooterMagic(evt) {
        const footerIsVisible = document.getElementById('footerContainer').getBoundingClientRect().y < window.innerHeight
        const advertMarginFromTop = document.getElementById('outsideMenus').getBoundingClientRect().height - document.querySelector('.side-advertisement').getBoundingClientRect().height - document.getElementById('footerContainer').getBoundingClientRect().height - 10

        if (footerIsVisible) {
            document.querySelectorAll('.side-advertisement').forEach(el => {
                el.style.position = 'absolute'
                el.style.marginTop = `${advertMarginFromTop}px`
            })
        } else {
            document.querySelectorAll('.side-advertisement').forEach(el => {
                el.style.position = 'fixed'
                el.style.marginTop = ''
            })
        }
    }

    static activateGameFilters() {
        document.getElementById('gameFiltersContainer').style.maxHeight = ''
        GridOrchestrator.performOrientationChangeActions()
    }

    static deactivateGameFilters() {
        document.getElementById('gameFiltersContainer').style.maxHeight = '0px'
        GridOrchestrator.performOrientationChangeActions()
    }

    static getInitiatorPage = state => ({
        'home': '<home-container data-barba="container" data-barba-namespace="home-page" class="row g-0 g-0"></home-container>',
        'authors': '<authors-container data-barba="container" data-barba-namespace="authors-page" class="row g-0 g-0"></authors-container>',
        'bookmakers': '<casinos-container data-barba="container" data-barba-namespace="casinos-page" class="row g-0 g-0"></casinos-container>',
        'matches': '<matches-container data-barba="container" data-barba-namespace="matches-page" class="row g-0 g-0"></matches-container>',
        'news': '<news-container data-barba="container" data-barba-namespace="news-page" class="row g-0 g-0"></news-container>',
        'predictions': '<predictions-container data-barba="container" data-barba-namespace="predictions-page" class="row g-0 g-0"></predictions-container>',
        'author': '<postauthor-container data-barba="container" data-barba-namespace="postauthor-page" class="row g-0 g-0"></postauthor-container>',
        'bookmaker': '<postbookmakers-container data-barba="container" data-barba-namespace="postbookmakers-page" class="row g-0 g-0"></postbookmakers-container>',
        'skin-bookmaker': '<postbookmakersskin-container data-barba="container" data-barba-namespace="postbookmakersskin-page" class="row g-0 g-0"></postbookmakersskin-container>',
        'match': '<postmatches-container data-barba="container" data-barba-namespace="postmatches-page" class="row g-0 g-0"></postmatches-container>',
        'article': '<news-post data-barba="container" data-barba-namespace="article-page" class="row g-0 g-0"></news-post>',
        'tournament': '<posttournaments-container data-barba="container" data-barba-namespace="posttournaments-page" class="row g-0 g-0"></posttournaments-container>',
        'skin-bookmakers': '<bookmakers-skin-container data-barba="container" data-barba-namespace="bookmakers-skin-page" class="row g-0 g-0"></bookmakers-skin-container>',
        'streamers': '<streamers-container data-barba="container" data-barba-namespace="streamers-page" class="row g-0 g-0"></streamers-container>',
        'tournaments': '<tournaments-container data-barba="container" data-barba-namespace="tournaments-page" class="row g-0 g-0"></tournaments-container>',
        '404': '<notfound-page data-barba="container" data-barba-namespace="404" class="row g-0 g-0"></notfound-page>'
    }[state]);
}

GridOrchestrator.template = /*html*/ `
<style>
  grid-orchestrator #gameFiltersContainer {
    overflow: hidden;
  }

  grid-orchestrator .side-advertisement {
    position: fixed;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  grid-orchestrator .side-advertisement:hover {
    cursor: pointer;
  }

  @media screen and (orientation: portrait) {
    grid-orchestrator .side-advertisement {
      display: none;
    }
  }
</style>

<adblockerdetect-component></adblockerdetect-component>
<scrolltotop-component></scrolltotop-component>
<livestreamer-component></livestreamer-component>

<div id="menusWrapper" class="row g-0 fixed-top" style="z-index: 1056;">
    <main-navbar class="col-12"></main-navbar>
</div>

<div id="outsideMenus" class="row g-0">
    <!-- Left Advertisement -->
    <div data-advert="left" class="col-1 side-advertisement"
         style="overflow: hidden; border-radius: 10px; z-index: 1055;">
    </div>

    <!-- Main Content -->
    <div id="contentColumn" class="col-10 offset-1">
        <div id="gameFiltersContainer" class="mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-1 mt-xxl-1">
            <game-navbar></game-navbar>
        </div>
        ${GridOrchestrator.getInitiatorPage(document.body.getAttribute('data-entry'))}
    </div>

    <!-- Right Advertisement -->
    <div data-advert="right" class="col-1 side-advertisement" 
         style="right: 0; overflow: hidden; border-radius: 10px; z-index: 1055;">
    </div>

    <!-- Footer -->
    <div id="footerContainer" class="col-12 text-center">
        <footer-container></footer-container>
    </div>
</div>

`;

GridOrchestrator.reg('grid-orchestrator');