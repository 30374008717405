import { I18n } from "../../utilities/i18n/i18n";
const noAuthorImg = require('../../global-assets/hoodieman.webp');

export function displayStatsPeriod(statsObj, options) {
    const {
        periodKey,
        showPeriodCell,
        showAuthorCell,
        authorName,
        authorPhoto,
        isClickable,
        baseUrl,
        tableId,
    } = options;

    // 1) Get the stats data for this periodKey
    const data = statsObj[periodKey];

    if (!data) {
        if (document.querySelector(".predictions-stats-card")) document.querySelector(".predictions-stats-card").style.display = "none";
        console.warn(`No stats found for period "${periodKey}"`);
        return;
    }

    // 2) Locate the <tbody> we will append to
    const tbody = document.getElementById(tableId);
    if (!tbody) {
        console.error(`No <tbody> found with id="${tableId}"`);
        return;
    }

    // Clear existing rows if on authors
    if (tableId === "predictions-stats-tbody2") tbody.innerHTML = "";


    // 3) Decide how to localize the period name
    let localizedPeriodName = periodKey; // fallback
    if (periodKey === "All-time") {
        localizedPeriodName = I18n.translateString("predictionsTranslations.alltimeStats");
    } else {
        // If the key is "MM-YYYY"
        const [monthString, yearString] = periodKey.split("-");
        if (monthString && yearString) {
            const month = parseInt(monthString, 10) - 1; // zero-based
            const year = parseInt(yearString, 10);
            const dateObj = new Date(year, month, 1);
            localizedPeriodName = dateObj.toLocaleString(I18n.getLanguageFromUrl(), {
                month: "long",
                year: "numeric",
            });
        }
    }

    // 4) Build the <tr> using a unified helper
    const row = buildStatsRow(data, {
        periodKey,
        localizedPeriodName,
        showPeriodCell,
        showAuthorCell,
        authorName,
        authorPhoto,
        isClickable,
        baseUrl,
    });

    // 5) Append the row
    tbody.appendChild(row);
}

export function buildStatsRow(stats, options) {
    // Create the <tr>
    const tr = document.createElement("tr");
    tr.id = `predictionStats-${options.periodKey.replace(/\s+/g, '-')}`;

    // Helper to build a <td> with or without <a> inside
    function makeTd(content, className = "") {
        const td = document.createElement("td");
        if (className) td.classList.add(className);

        if (options.isClickable && options.baseUrl) {
            const link = document.createElement("a");
            link.href = options.baseUrl;
            link.textContent = content;
            td.appendChild(link);
        } else {
            td.textContent = content;
        }
        return td;
    }

    // 1) Optionally create the Period cell
    if (options.showPeriodCell) {
        const periodTd = makeTd(options.localizedPeriodName, "timeFrame-td");
        tr.appendChild(periodTd);
    }

    // 2) Optionally create the Author cell
    if (options.showAuthorCell) {
        const td = document.createElement("td");
        td.classList.add("stats-author-td");

        const authorContainer = document.createElement("div");
        authorContainer.classList.add("author-container", "d-flex", "align-items-center");

        const img = document.createElement("img");
        img.src = options.authorPhoto || noAuthorImg;
        img.alt = options.authorName;
        img.classList.add("author-logo");

        const span = document.createElement("span");
        span.classList.add("author-name", "text-truncate");
        span.textContent = options.authorName;

        authorContainer.appendChild(img);
        authorContainer.appendChild(span);

        if (options.isClickable && options.baseUrl) {
            const link = document.createElement("a");
            link.href = options.baseUrl;
            link.appendChild(authorContainer);
            td.appendChild(link);
        } else {
            td.appendChild(authorContainer);
        }

        tr.appendChild(td);
    }

    // 3) betsCounter
    tr.appendChild(makeTd(String(stats.betsCounter ?? 0), "stats-bets-td"));

    // 4) totalWinnings
    tr.appendChild(makeTd(String(stats.totalWinnings ?? "0.00"), "stats-earn-td"));

    // 5) winPercentage
    tr.appendChild(makeTd(stats.winPercentage ?? "0.00%", "stats-win-td"));

    // 6) roi
    tr.appendChild(makeTd(stats.roi ?? "0.00%", "stats-roi-td"));

    // 7) averageOdds
    tr.appendChild(makeTd(stats.averageOdds ?? "0.00", "stats-avg-odds-td"));

    // 8) openBetsCounter
    tr.appendChild(makeTd(String(stats.openBetsCounter ?? 0), "stats-open-bets-td"));

    const style = document.createElement("style");
    style.innerHTML = /*css*/ `
  postauthor-component [id^="predictionStats"] td {
    border-bottom: 1px solid #30363d;
    padding: calc(0.5rem + 0.3vw);
    font-size: calc(0.5rem + 0.3vw);
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }`;
    tr.appendChild(style);

    return tr;
}

export function populateDropdownFromStats(statsObj) {
    const dropdown = document.getElementById("statsPeriod");
    if (!dropdown) return;

    dropdown.innerHTML = "";

    // The object keys might look like ["01-2025", "02-2025", "All-time"] as we've set them up on our backend
    const keys = Object.keys(statsObj);

    // Check if "All-time" is present
    const indexAllTime = keys.indexOf("All-time");
    const hasAllTime = indexAllTime !== -1;
    if (hasAllTime) {
        // Remove it from the array so we can sort only the MM-YYYY keys
        keys.splice(indexAllTime, 1);
    }

    // Sort descending by year, then by month
    // "02-2025" should come before "01-2025", etc.
    keys.sort((a, b) => {
        const [am, ay] = a.split("-");
        const [bm, by] = b.split("-");
        const aMonth = parseInt(am, 10);
        const aYear = parseInt(ay, 10);
        const bMonth = parseInt(bm, 10);
        const bYear = parseInt(by, 10);

        // Compare years descending
        if (bYear !== aYear) {
            return bYear - aYear;
        }
        // If same year, compare months descending
        return bMonth - aMonth;
    });

    // Put "All-time" back at the front
    if (hasAllTime) {
        keys.unshift("All-time");
    }

    keys.forEach((key) => {
        const option = document.createElement("option");
        option.value = key; // e.g. "01-2025" or "All-time"

        if (key === "All-time") {
            option.textContent = I18n.translateString("predictionsTranslations.alltimeStats");
        } else {
            // key is something like "01-2025"
            const [monthString, yearString] = key.split("-");
            const month = parseInt(monthString, 10) - 1; // JavaScript months are 0-based
            const year = parseInt(yearString, 10);

            // Construct a Date using day=1, so it’s always valid
            const dateObj = new Date(year, month, 1);

            const localized = dateObj.toLocaleString(I18n.getLanguageFromUrl(), {
                month: "long",
                year: "numeric",
            });

            option.textContent = localized; // e.g. "February 2025"
        }

        dropdown.appendChild(option);
    });

}


export function enableStatsDropdown(statsObj) {
    const statsDropdown = document.getElementById("statsPeriod");
    if (!statsDropdown) return;

    statsDropdown.addEventListener("change", function () {
        const selectedPeriod = statsDropdown.value; // e.g. "01-2025" or "All-time"
        displayStatsPeriod(statsObj, {
            periodKey: selectedPeriod,            // given by the dropdown
            showPeriodCell: true,            // We want a column for "All-time" or the month
            showAuthorCell: false,           // Single author page => no author column
            isClickable: false,
            baseUrl: null,
            tableId: "predictions-stats-tbody2"  // Where we want to append
        });
    });
}

export function statisticsTooltips(offset, Classname) {

    let tooltipElements = document.querySelectorAll('.tooltip-engage');

    const style = document.createElement("style");
    style.innerHTML = /*css*/ `
            .tooltip-authorpage-stats, .tooltip-predictionspage-stats {
                position: absolute;
                font-size: calc(0.5rem + 0.3vw);
                background-color: #161b22;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;
                width: calc(10rem + 11vw);
                white-space: normal; /* Allows text to wrap when needed */
                word-wrap: break-word; /* Breaks long words if they exceed the width */
                z-index: 1000;
                pointer-events: none; /* Prevent blocking hover */
                opacity: 0;
                transition: opacity 0.2s;
                }`;
    document.body.appendChild(style);

    tooltipElements.forEach((tooltipElement) => {
        tooltipElement.addEventListener("mouseenter", (e) => {
            const tooltip = document.createElement("div");
            tooltip.className = Classname;
            tooltip.innerHTML = I18n.translateString(`predictionsTranslations.${tooltipElement.getAttribute("data-tooltip")}`);
            document.body.appendChild(tooltip);

            const rect = tooltipElement.getBoundingClientRect();

            const clientWidth = document.documentElement.clientWidth;
            if (clientWidth < 768) {
                tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2 + offset}px`;
            } else {
                tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2}px`;
            }

            tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 10}px`;
            tooltip.style.opacity = "1";

            tooltipElement.addEventListener("mouseleave", () => {
                tooltip.style.opacity = "0";
                tooltip.addEventListener("transitionend", () => tooltip.remove());
            });

        });

        // Listen for scroll, touchmove, and wheel events
        window.addEventListener("scroll", () => {
            if (document.querySelector('.tooltip-authorpage-stats')) {
                const tooltip = document.querySelector('.tooltip-authorpage-stats');
                tooltip.style.opacity = "0";
                tooltip.addEventListener("transitionend", () => tooltip.remove());
            }
        }, { passive: true });
        window.addEventListener("wheel", () => {
            if (document.querySelector('.tooltip-authorpage-stats')) {
                const tooltip = document.querySelector('.tooltip-authorpage-stats');
                tooltip.style.opacity = "0";
                tooltip.addEventListener("transitionend", () => tooltip.remove());
            }
        }, { passive: true });
        window.addEventListener("touchmove", () => {
            if (document.querySelector('.tooltip-authorpage-stats')) {
                const tooltip = document.querySelector('.tooltip-authorpage-stats');
                tooltip.style.opacity = "0";
                tooltip.addEventListener("transitionend", () => tooltip.remove());
            }
        }, { passive: true });
        window.addEventListener("popstate", () => {
            if (document.querySelector('.tooltip-authorpage-stats')) {
                const tooltip = document.querySelector('.tooltip-authorpage-stats');
                tooltip.style.opacity = "0";
                tooltip.addEventListener("transitionend", () => tooltip.remove());
            }
        });
    });
}