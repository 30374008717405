import { BaseComponent } from "@symbiotejs/symbiote";

export class PlaceholderHotmatch extends BaseComponent {

    initCallback() {
    }

}

PlaceholderHotmatch.template = /*html*/`

<div class="container p-0 text-white color-dark" id="placeholder-hotmatch">
    <div class="row rounded-top color-dark">
        <div class="col-12 p-0 d-flex justify-content-between bcolor-dark text-white placeholder-glow">
            <div class="d-flex p-1 rounded-start bg-secondary placeholder">
                <div class="placeholder bg-secondary me-2" style="width: 2rem; height: 1.5rem;"></div>
                <span class="placeholder bg-secondary me-2" style="width: 100px; height: 1rem;"></span>
                <div class="placeholder bg-secondary" style="width: 1.5rem; height: 1.5rem;"></div>
            </div>
            <div class="text-end placeholder-glow">
                <span class="badge bg-danger placeholder" style="width: 3.5rem; height: 1.5rem;">&nbsp;</span>
            </div>
        </div>

        <div class="row my-4 d-flex justify-content-center placeholder-glow p-0">
            <div class="col-5 bg-secondary rounded placeholder w-75 p-0" style="height: 5rem;"></div>
        </div>
    </div>

    <div class="row color-dark rounded-bottom">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7 col-xxl-7 d-flex justify-content-center my-2 color-dark">
            <div class="ratio ratio-16x9 placeholder-glow">
                <div class="placeholder bg-secondary w-100 rounded"></div>
            </div>
        </div>

        <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-5 col-xxl-5 d-none d-xl-flex my-2 color-dark">
            <div class="w-100 placeholder-glow">
                <span class="placeholder bg-secondary w-100 rounded" style="height: 100%;"></span>
            </div>
        </div>
    </div>
</div>   
`;
PlaceholderHotmatch.reg('placeholder-hotmatch');



















