export function findMatchType(matchTypeRaw, numberOfGames) {
    let matchTypeFinal = '';
    if (matchTypeRaw === 'best_of' && (numberOfGames !== 2 && numberOfGames !== 1)) {
        matchTypeFinal = 'best_of_X';
    } else if (matchTypeRaw === 'best_of' && numberOfGames === 1) {
        matchTypeFinal = 'single_game';
    } else if (matchTypeRaw === 'best_of' && numberOfGames === 2) {
        matchTypeFinal = 'best_of_2';
    } else if (matchTypeRaw === 'all_games_played') {
        matchTypeFinal = 'all_games_played';
    } else if (matchTypeRaw === 'first_to') {
        matchTypeFinal = 'first_to_X';
    } else {
        matchTypeFinal = `${matchTypeRaw.charAt(0).toUpperCase() + data.match_type.slice(1).replace('_', ' ')} ${numberOfGames.toString()}`;
    }
    return matchTypeFinal;
}