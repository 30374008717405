import { I18n } from '../../utilities/i18n/i18n';
export function setupBookies(containerId, htmlCallback) {
    let count = 0;

    let language = I18n.getUsersPreferredLanguage();
    const container = document.querySelector(containerId);

    if (container) {
        container.innerHTML = '';
    }

    const bookmakersSkinArray = Object.keys(window.savedBookmakers).map(key => {
        const data = window.savedBookmakers[key];
        data.id = key;
        return data;
    });

    bookmakersSkinArray.sort((a, b) => {
        const orderA = a.bookmaker_order !== undefined ? Number(a.bookmaker_order) : Infinity;
        const orderB = b.bookmaker_order !== undefined ? Number(b.bookmaker_order) : Infinity;
        return orderA - orderB;
    });


    bookmakersSkinArray.forEach((bookmakerData) => {
        if (bookmakerData.status === 'draft') {
            return;
        }

        const hasLocationRestriction = Array.isArray(bookmakerData.location_bookmaker) && bookmakerData.location_bookmaker.length > 0;
        if (hasLocationRestriction && bookmakerData.location_bookmaker.includes(window.geoFencingGuard)) {
            return;
        }

        // Call the passed callback function to generate the specific HTML
        const html = htmlCallback(bookmakerData, language, count);

        // Append to the container
        container.insertAdjacentHTML('beforeend', html);
        count++;
    });

    // **Handle No Data Scenario**
    if (container && container.innerHTML === '') {
        container.innerHTML = I18n.translateString("bookmakersSkinList.noBookmakersMsg");
        document.querySelector('.removal-class').style.display = 'none';
        document.querySelector('.removal-title').style.display = 'none';
    }
}